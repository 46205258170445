<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";
  import Circle from "./Circle.svelte";
  import Rectangle from "./Rectangle.svelte";
  export let status: string = null;
  export let type: string;
  export let click: Function = () => {};
</script>

{#if nodeTypes[type].form === "rectangle"}
  <Rectangle {type} {click} {status}   {...$$props}/>
{:else}
  <Circle {type} {click} {status} {...$$props} />
{/if}
