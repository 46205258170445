<script lang="ts">
  // your script goes here
  import { getCtx } from "@components/Editors/StoryEditor/context";
  import StoryForm from "./StoryForm.svelte";
  import StoryPreview from "./StoryPreview.svelte";
  let { editMode } = getCtx();
</script>

{#if $editMode}
  <StoryForm />
{:else}
  <StoryPreview />
{/if}
