<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...$$restProps}
>
  <circle cx="7" cy="10" r="4" stroke="currentColor" stroke-width="2" />
  <circle cx="15" cy="5" r="2" stroke="currentColor" stroke-width="2" />
  <circle cx="15" cy="15" r="2" stroke="currentColor" stroke-width="2" />
  <rect
    x="9"
    y="7.5498"
    width="4"
    height="2"
    transform="rotate(-32 9 7.5498)"
    fill="currentColor"
  />
  <rect
    x="10"
    y="10.7002"
    width="4"
    height="2"
    transform="rotate(32 10 10.7002)"
    fill="currentColor"
  />
</svg>
