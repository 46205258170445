/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Algorithm } from '../models/Algorithm';
import type { ParameterDefinition } from '../models/ParameterDefinition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlgorithmsService {

    /**
     * Get Algorithms
     * @returns Algorithm Successful Response
     * @throws ApiError
     */
    public static getAlgorithmsApiV1AlgorithmsGet(): CancelablePromise<Array<Algorithm>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/algorithms/',
        });
    }

    /**
     * Create Algorithm
     * @param requestBody
     * @returns Algorithm Successful Response
     * @throws ApiError
     */
    public static createAlgorithmApiV1AlgorithmsPost(
        requestBody: Algorithm,
    ): CancelablePromise<Algorithm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/algorithms/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Algorithm
     * @param id
     * @param requestBody
     * @returns Algorithm Successful Response
     * @throws ApiError
     */
    public static updateAlgorithmApiV1AlgorithmsIdPut(
        id: number,
        requestBody: Algorithm,
    ): CancelablePromise<Algorithm> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/algorithms/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Algorithm
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteAlgorithmApiV1AlgorithmsIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/algorithms/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Parameters
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateParametersApiV1AlgorithmsIdParametersPatch(
        id: number,
        requestBody: Array<ParameterDefinition>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/algorithms/{id}/parameters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
