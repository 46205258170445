/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EcosystemType {
    PROVISIONING = 'Provisioning',
    CULTURAL = 'Cultural',
    REGULATORY = 'Regulatory',
}
