<script lang="ts">
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { supportingStore } from "@stores/supportingstore";
  import { getCtx } from "../context";
  import { Label, Helper, Textarea, Input, MultiSelect } from "@core-ui";
  import { type Stakeholder, ElliotType } from "@client";
  import {
    createStakeholder,
    updateStakeholder,
    isNameAlreadyTaken,
  } from "@utils/supporting/stakeholder";
  import FormCreationButtons from "../FormCreationButtons.svelte";

  const { _selectedStakeholder } = supportingStore;
  let { metricFormDialog, editModeOn } = getCtx();
  let initSelectVal;

  const {
    form,
    setFields,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
  } = createForm<Stakeholder>({
    onSubmit: (values) => {
      if (!$editModeOn) {
        createStakeholder(values);
        // clear form
        handleFormReset(emptyStakeholder);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateStakeholder($_selectedStakeholder.id, values);
        // clear form
        handleFormReset(emptyStakeholder);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        elliot_types: string[];
        notes: string[];
      } = {
        name: [],
        elliot_types: [],
        notes: [],
      };
      if (!values.name) errors.name.push("Name is required.");
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedStakeholder &&
          $_selectedStakeholder.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (
        values.elliot_types == undefined ||
        (values.elliot_types && values.elliot_types.length == 0)
      )
        errors.elliot_types.push("elliot_types is required.");
      return errors;
    },
    extend: [reporter],
  });

  const handleFormReset = (sh: Stakeholder): boolean => {
    // does not work for elliot types:
    setInitialValues(sh);
    // by hand: works
    initSelectVal = sh.elliot_types;
    reset();
    return true;
  };

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail.map((e) => e.label);
      } else {
        val = [e.detail.label];
      }
    }
    setFields("elliot_types", val);
  };

  // TODO @refact move this to utils/stakeholders
  const emptyStakeholder = {
    name: "",
    elliot_types: [],
    sectors: [],
    notes: "",
  } as Stakeholder;

  // Empties form values (creation mode):
  $: !$editModeOn && handleFormReset(emptyStakeholder);
  // Set form values with selected stakeholder properties (edition):
  $: $editModeOn &&
    $_selectedStakeholder &&
    handleFormReset($_selectedStakeholder);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn ? "Update stakeholder" : "Create stakeholder"}
  </h1>
  <!-- NAME & INPUT -->
  <div class="flex flex-col items-start justify-start gap-2">
    <!-- NAME -->
    <div class="w-full">
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type stakeholder's name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>
    <!-- ELLIOT TYPES -->
    <div class="w-full">
      <MultiSelect
        items={Object.values(ElliotType)}
        placeholder="Select Elliot types"
        {handleSelectChange}
        initValues={initSelectVal}
        name="elliot_types"
        componentLabel="Elliot types"
        required
        requiredMessage="At least one Elliot type is required."
      />
    </div>
  </div>

  <!-- Notes -->
  <div class="w-full">
    <Label for="notes" class="mt-4 mb-2">Notes</Label>
    <Textarea
      class="mt-2"
      placeholder="Type notes here"
      id="notes"
      name="notes"
      color={"base"}
    />
    <ValidationMessage for="notes" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <FormCreationButtons
    onClose={() => handleFormReset(emptyStakeholder)}
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    create={!$editModeOn}
  />
</form>
