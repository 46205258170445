/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum GoodsBenefitsType {
    GOOD = 'Good',
    BENEFIT = 'Benefit',
}
