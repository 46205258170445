<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import ConnectionDetail from "@components/Editors/EdgeEditor/ConnectionDetail.svelte";
  import Tabs from "./Tabs.svelte";
  import { setCtx as setResourcesCtx } from "@components/Actions/CreateResource/context";

  setResourcesCtx();

  const { _selectedEdge } = dataStore;
</script>

<article class="w-full h-full overflow-x-hidden overflow-y-auto">
  {#if $_selectedEdge}
    <ConnectionDetail />
    <Tabs />
  {/if}
</article>
