<script lang="ts">
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import type { UpdateNode } from "@client";
  import { getCtx } from "@components/Editors/StoryEditor/context";
  import { createForm } from "felte";
  import { dataStore } from "@stores/datastore";
  import { Label, Helper, Textarea, Input } from "@core-ui";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte";
  import { updateStory, isMainStory } from "@utils/story";

  let { _selectedStory } = dataStore;
  let { editOff } = getCtx();

  /**
   * Form Types, methods, schema and validations
   * const { form, data, isValid, isDirty, touched, isSubmitting } = formCtx; NOT WORKING (TRY LATER)
   */
  const { form, data, isValid, errors, isSubmitting, setInitialValues, reset } =
    createForm<UpdateNode>({
      onSubmit: (values) => {
        updateStory($_selectedStory.id, {
          ...values,
        });
        editOff();
      },
      validate(values) {
        const errors: {
          name: string[];
          category: string[];
          description: string[];
        } = {
          name: [],
          category: [],
          description: [],
        };
        if (!values.name) errors.name.push("Title is required.");
        if (!values.category) errors.name.push("Category is required.");

        return errors;
      },
      extend: [reporter],
    });
  const handleFormReset = (values: UpdateNode) => {
    reset();
    setInitialValues(values);
  };

  $: $_selectedStory &&
    handleFormReset({
      name: $_selectedStory.name,
      category: $_selectedStory.category,
      description: $_selectedStory.description,
    } as UpdateNode);
</script>

<!-- FORM -->
<form use:form class="w-full pr-2 mb-4 form-control">
  <!-- NAME -->
  <Label for="name" class="mt-6 mb-1">Title</Label>
  <Input
    class="mt-2"
    placeholder="Type concept title here"
    id="name"
    name="name"
    required
    color={"base"}
  />
  <ValidationMessage for="name" let:messages>
    {#each messages ?? [] as message}
      <Helper class="mt-2" color="red">
        <span>{message}</span>
      </Helper>
    {/each}
  </ValidationMessage>
  <!-- CATEGORY -->
  <Label for="category" class="mt-6 mb-1">Category</Label>
  <Input
    class="mt-2"
    placeholder="Type concept title here"
    id="category"
    name="category"
    required
    color={"base"}
    disabled={!isMainStory($_selectedStory)}
  />
  <ValidationMessage for="category" let:messages>
    {#each messages ?? [] as message}
      <Helper class="mt-2" color="red">
        <span>{message}</span>
      </Helper>
    {/each}
  </ValidationMessage>
  <!-- DESCRIPTION -->
  <Label for="description" class="mt-6 mb-1">Official definition</Label>
  <Textarea
    type="text"
    class="mt-2"
    id="description"
    name="description"
    placeholder="Add official defintion here..."
    color={"base"}
  />
  <ValidationMessage for="description" let:messages>
    {#each messages ?? [] as message}
      <Helper class="mt-2" color="red">
        <span>{message}</span>
      </Helper>
    {/each}
  </ValidationMessage>
  <BlocCancelConfirm
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    vLabel="Save edits"
    xConfirmType="cancelStoryEdition"
    xOnConfirm={() => editOff()}
    vType="success"
  />
</form>
