<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteAlgorithm } from "@utils/supporting/algorithms";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"

  const { metricFormDialog, editModeOn } = getCtx();
  const { _algorithms, _selectedAlgorithms } = supportingStore;

  const showEditModal = (u) => {
    _selectedAlgorithms.set(u);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Agorithm definition</th>
        <th>Parameter definitions</th>            
        <th>Notes</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_algorithms as u}
        <tr>
          <th>{formatAtomicProperty(u.name)}</th>
          <td>
            {formatAtomicProperty(u.definition)}
          </td>              
          <td>
            {#each u.parameter_definitions as param, index}
              {param.name}{#if index < u.parameter_definitions.length - 1}, {/if}                  
            {/each}
          </td>              
          <td>{u.notes}</td>
          <GridAction
            onEdit={() =>showEditModal(u)}
            onDelete={() => deleteAlgorithm(u.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>
