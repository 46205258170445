<script lang="ts">
  import { PlusCircleIcon as Plus } from "@rgossiaux/svelte-heroicons/solid";
  import { getCtx } from "@components/Actions/CreateResource/context";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";

  let ctx = getCtx();
  let { open, close, state } = ctx;

  export let label = "Add evidence";
</script>

<div class={$$restProps.class} {...$$restProps}>
  <Can roles={[Roles.DEV, Roles.CONTENT]}>
    <div class="flex flex-row items-center gap-2 normal-case rounded-xl text-base-content">
      <button
        class="kg-base-button"
        on:click={open}
      >
        <Plus class="w-5 h-5" />
        {label}
      </button>
    </div>
  </Can>
</div>
