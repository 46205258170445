import { writable } from "svelte/store";
import type { Writable } from "svelte/store";
import type { InjectionKey } from "@utils/svelte-typed-context";
import { setContext, getContext } from "@utils/svelte-typed-context";
import { getNodeResourcesAsync, addNodeResourceAsync } from "@utils/node";
import type { Resource, Resources } from "@client";
import { ResourcesService } from "@client";
import { addEdgeResourceAsync, getEdgeResourcesAsync } from "@utils/edge";
import { addStoryResourceAsync, getStoryResourcesAsync } from "@utils/story";
import { dataStore } from "@stores/datastore";
export const key: InjectionKey<CtxState> = Symbol("createResourceCtx");

/**
 * ctx setter
 */
type CtxState = {
  state: Writable<DialogState>;
  close: () => void;
  open: () => void;
  resources: any;
};
type DialogState = {
  isOpen: boolean;
};

let dialogState: Writable<DialogState> = writable({ isOpen: false });
const closeModal = () => dialogState.set({ isOpen: false });
const openModal = () => dialogState.set({ isOpen: true });

export function manageResources() {
  const { subscribe, update, set }: Writable<Resource[]> = writable([]);

  return {
    subscribe,

    init: async (itemType: string, itemId: number) => {
      try {
        let response: Resource[] | undefined = undefined;
        switch (itemType) {
          case "node":
            response = await getNodeResourcesAsync(itemId);
            break;
          case "edge":
            response = await getEdgeResourcesAsync(itemId);
            break;
          case "story":
            response = await getStoryResourcesAsync(itemId);
            break;
          default:
            break;
        }
        if (response) {
          set(response);
          return response;
        }
      } catch (error) {
        throw Error(error);
      }
    },

    insert: async (
      itemType: string,
      itemId: number,
      resourceData: Resource
    ) => {
      try {
        let response: Resources | undefined = undefined;
        switch (itemType) {
          case "node":
            response = await addNodeResourceAsync(itemId, resourceData);
            let node = (dataStore.nodes).find(n => n.id == itemId)
            node.resources.push(response)
            dataStore.updateNodeInAllNodes(node)
            dataStore.updateSelectedNode(node)
            break;
          case "edge":
            response = await addEdgeResourceAsync(itemId, resourceData);
            break;
          case "story":
            response = await addStoryResourceAsync(itemId, resourceData);
            break;
          default:
            break;
        }
        if (response) {
          update((store_state) => [response, ...store_state]);
          return response;
        }
      } catch (error) {
        throw Error(error);
      }
    },

    delete: async (item) => {
      try {
        await ResourcesService.deleteResourceApiV1ResourcesIdDelete(item.id);
        let node = dataStore.nodes.find(n => n.id == dataStore.selectedNode.id)
        node.resources = node.resources.filter(r => r.id != item.id)
        dataStore.updateNodeInAllNodes(node)
        dataStore.updateSelectedNode(node)
        update((store_state) =>
          store_state.filter((r) => r.title !== item.title)
        );
      } catch (error) {
        throw Error(error);
      }
    },

    update: async (
      itemType: string,
      itemId: number,
      resourceData: Resource
    ) => {
      console.log("WIP: not implemented");
    },
  };
}

export const resources_store = manageResources();

export const setCtx = () =>
  setContext(key, {
    state: dialogState,
    close: closeModal,
    open: openModal,
    resources: resources_store,
  });

export const getCtx = () => getContext(key);
