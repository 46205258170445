import type { NodesWithStories } from "@client";
import type { Edge } from "@utils/formatData";

let intervalSize = 5;
let stepSize = 5;

/**
 * Returns the weight of a concept based on its number of connections.
 *
 * @param {NodesWithStories} n - The category which node type
 *   children visibility is to be toggled.
 * @return {boolean} - True if the node type headers under given
 * category are to be shown.
 */
export const weightFromNbOfConnections = (
  graphData: {
    nodes: NodesWithStories[];
    links: Edge[];
  } | null,
  n: NodesWithStories
) => {
  let nbOfConnections = graphData.links
    .filter((l) => !l.fake)
    .filter((l) => l.source.id === n.id || l.target.id === n.id).length;

  return (Math.floor(nbOfConnections / intervalSize) + 1) * stepSize;
};

/**
 * Returns the weight of a concept based on its number of outcoming
 * connections.
 *
 * @param {NodesWithStories} n - The category which node type
 *   children visibility is to be toggled.
 * @return {boolean} - True if the node type headers under given
 * category are to be shown.
 */
export const weightFromNbOfOutcomingConnections = (
  graphData: {
    nodes: NodesWithStories[];
    links: Edge[];
  } | null,
  n: NodesWithStories
) => {
  let nbOfConnections = graphData.links
    .filter((l) => !l.fake)
    .filter((l) => l.source.id === n.id).length;

  return (Math.floor(nbOfConnections / intervalSize) + 1) * stepSize;
};

/**
 * Returns the weight of a concept based on its number of incoming
 * connections.
 *
 * @param {NodesWithStories} n - The category which node type
 *   children visibility is to be toggled.
 * @return {boolean} - True if the node type headers under given
 * category are to be shown.
 */
export const weightFromNbOfIncomingConnections = (
  graphData: {
    nodes: NodesWithStories[];
    links: Edge[];
  } | null,
  n: NodesWithStories
) => {
  let nbOfConnections = graphData.links
    .filter((l) => !l.fake)
    .filter((l) => l.target.id === n.id).length;

  return (Math.floor(nbOfConnections / intervalSize) + 1) * stepSize;
};

/**
 * Returns the weight of a concept based on its number of attachments
 * associated to it.
 *
 * @param {NodesWithStories} n - The category which node type
 *   children visibility is to be toggled.
 * @return {boolean} - True if the node type headers under given
 * category are to be shown.
 */
export const weightFromNbOfAttachments = (
  graphData: {
    nodes: NodesWithStories[];
    links: Edge[];
  } | null,
  n: NodesWithStories
) => {
  return 5 * (n.resources ? n.resources.length + 1 : 1);
};

/**
 * Returns the weight of a concept based on its number of graphs
 * it belongs to.
 *
 * @param {NodesWithStories} n - The category which node type
 *   children visibility is to be toggled.
 * @return {boolean} - True if the node type headers under given
 * category are to be shown.
 */
export const weightFromNbOfAssociatedStories = (
  graphData: {
    nodes: NodesWithStories[];
    links: Edge[];
  } | null,
  n: NodesWithStories
) => {
  return 5 * (n.stories ? n.stories.length + 1 : 1);
};

export const weightNone = (
  graphData: {
    nodes: NodesWithStories[];
    links: Edge[];
  } | null,
  n: NodesWithStories
) => {
  return stepSize;
};
