import { dataStore } from "@stores/datastore";

export const groupByType = () => {
  ungroup();
  const graphData = dataStore.graphData;

  let fakeLinks = [];

  graphData?.nodes.forEach((n1) => {
    graphData.nodes.forEach((n2) => {
      if (n1.id !== n2.id && n1.type === n2.type) {
        fakeLinks.push({ from: n1, to: n2 });
      }
    });
  });
  dataStore.addFakeLinksInGraph(fakeLinks);
};

export const groupByStory = () => {
  ungroup();
  const graphData = dataStore.graphData;

  graphData?.nodes.forEach((n1) => {
    graphData.nodes.forEach((n2) => {
      if (n1.id !== n2.id) {
        if (
          n1.stories[0] &&
          n2.stories[0] &&
          n1.stories[0].id === n2.stories[0].id
        )
          dataStore.addFakeLinkInGraph(n1, n2);
      }
    });
  });
};

export const ungroup = () => {
  const graphData = dataStore.graphData;

  graphData && dataStore.removeFakeLinkInGraph();
};
