import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

import {
  StateMetricsService as service,
} from "@client";
import type { StateMetric as Model, StateMetric } from "@models/StateMetric";
import { supportingStore } from "@stores/supportingstore";

export const createMetric = (model: Model) => {
  try {
    
    service.createStateMetricApiV1StateMetricsPost(model).then((resp) => {
      notifySuccess("State metric succesfully created!");
      supportingStore.addStateMetric(resp);
    });
  } catch (e) {
    notifyFailure(
      `An error occured while creating the state metric: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};

export const updateMetric = async (id: number, model: Model = null) => {
  try {
    
    service.updateStateMetricApiV1StateMetricsIdPut(id, model).then((resp) => {
      notifySuccess("State metric succesfully updated!");      
      supportingStore.updateSelectedStateMetric(resp);
    });
  } catch (e) {
    notifyFailure(
      `An error occurred while updating the state metric: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};

// export const updateMetricParameterDefinitions = async (
//   metric: Model = null,
//   parameter_definitions: ParameterDefinition[]
// ) => {
//   try {
//     if (
//       metric.parameter_definitions &&
//       metric.parameter_definitions.length > 0
//     ) {
//       service
//         .updateStateMetricParametersApiV1StateMetricsIdParametersPatch(
//           metric.id,
//           parameter_definitions
//         )
//         .then((res2) => {
//           notifySuccess(
//             "State metric parameter definitions succesfully updated"
//           );
//           supportingStore.updateSelectedStateMetric(metric);
//         });
//     } else metric.parameter_definitions = [];
//     supportingStore.updateSelectedStateMetric(metric);
//   } catch (e) {
//     notifyFailure(
//       `An error occured while updating state metric parameter definitions: ${e.status}-${e.name}:${e.statusText}`
//     );
//   }
// };

export const deleteMetric = (id: number) => {
  service.deleteStateMetricApiV1StateMetricsIdDelete(id).then(() => {
    supportingStore.removeStateMetric(id);
  });
};

/**
 * Returns true if the provided name is already taken by an existing StateMetric.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean for if name is already in use.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.stateMetrics.some(
      (s: StateMetric) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
