<script lang="ts">
  import { PencilIcon } from "@rgossiaux/svelte-heroicons/solid";
  import { dataStore } from "@stores/datastore";
  import TitleInfoBox from "../../NodeEditor/Header/NodePreview/TitleInfoBox.svelte";
  import { getCtx as storyEditorContext } from "@components/Editors/StoryEditor/context";
  import DescriptionInfoBox from "../../NodeEditor/Header/NodePreview/DescriptionPreview.svelte";
  import { getCtx as getResourceCtx } from "@components/Actions/CreateResource/context";
  import { ResourceType } from "../../../../client/models/ResourceType";
  import Carousel from "svelte-carousel";
  import { PhotographIcon } from "@rgossiaux/svelte-heroicons/outline";
  import axios from "axios";
  import { nodeTypes } from "@utils/nodeTypes.js";
  import OpenAIDialog from "./OpenAIDialog.svelte"
  import { onMount } from 'svelte'
  import {
  PromptService
} from "@client";

  const { editOn } = storyEditorContext();
  let { resources } = getResourceCtx();

  let dialog;
  let open;
  let close;
  let resultOpenAI = ""
  const NS = `sk-proj-hg4lVrpZo2p9SSk76TMsT3BlbkFJoHBtQwL1E7UuISnao915`
  const Derek = `sk-edapsiwrm-vn5f4XspKGqfA0SoHJSyT3BlbkFJXa0MbCNkJOwvEmsp7UiL`
  const derek2 = `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`

  const { _selectedStory } = dataStore;

  const listNodeTypes = Object.keys(nodeTypes);

  let prompt = ""

  onMount(async function() {
    const req = await PromptService.getPromptApiV1OpenaiPromptGet()
    prompt = req.prompt
  });
  

  function askOpenAi() {

    const data = {
      model: "gpt-4",
      max_tokens: 2000,
      temperature: 0.5,
      messages: [
        { role: "system", content: prompt },
        { role: "user", content: `In this context, create a narative of at least 400 words for the story "${$_selectedStory.name}", that include the following ` },
      ],
      n: 1,
      stop: null,
    };

    resultOpenAI = ""
    dialog.open()
    
    listNodeTypes.map(nodeType => {
      const nodesByType = $_selectedStory.nodes.filter(node => node.type == nodeType)
      if(nodesByType.length != 0) {
        data.messages[1].content = data.messages[1].content + `${nodeTypes[nodeType].label}:`
        nodesByType.forEach(node => {data.messages[1].content = data.messages[1].content +`"${node.name}",`})
        data.messages[1].content = data.messages[1].content + `;`
      }
    })

    axios.post('https://api.openai.com/v1/chat/completions', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Derek}`
      }
    })
    .then(response => {
      resultOpenAI = response.data.choices[0].message.content.replaceAll("\n","</br>")
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  function copy() {
    navigator.clipboard.writeText(resultOpenAI.replaceAll("</br>", "/n"));
  }

</script>

<OpenAIDialog bind:this={dialog} bind:open={open} bind:close={close} title="OpenAi suggestion">
  <div
    class="inline-block w-full p-4 text-left align-middle bg-base-200 rounded-2xl flex flex-col" slot="content"
    style="width:130vh; "
  > 
    {#if resultOpenAI == ""}
    <div><span class="loader"></span></div>
    {:else}
      <div>
        <button
          class="normal-case btn btn-sm gap-2 rounded-xl btn-outline text-base-content"
          on:click={() => copy()}>Copy to clipboard</button
        >
      </div>
      {@html resultOpenAI}
    {/if}
    <div>
      <button
        class="normal-case btn btn-sm gap-2 rounded-xl btn-outline text-base-content"
        on:click={() => dialog.close()}>Close</button
      >
    </div>
  </div>
</OpenAIDialog>

{#if $_selectedStory}
  <!-- Type & Actions -->
  <div class="flex items-center justify-between my-2 mr-2">
    <div class="flex-none text-opacity-50 uppercase text-md text-base-content">
      {$_selectedStory.isScenario ? "Scenario" : "Story"}
    </div>
    <div class="flex-none">
      <button class="mr-6 kg-btn-outline" on:click={editOn}>
        <PencilIcon class="w-3 h-3" />
        Edit
      </button>
    </div>
  </div>
  <TitleInfoBox title={$_selectedStory.name} />
  <div class="mt-1 mb-1 text-xs">
    <span>
      Category: {$_selectedStory.category}
    </span>
  </div>
  <div class="mt-1 mb-1 text-xs">
    <span>
      Created by: {$_selectedStory.inserted_by}
    </span>
  </div>
  <!-- Description -->
  <DescriptionInfoBox content={$_selectedStory.description} />
  <button
      class="gap-2 mt-2 mr-6 mb-6 normal-case rounded-xl btn-outline text-base-content btn btn-sm"
      on:click={() => askOpenAi()}>Ask openAI to reformulate the story of "{$_selectedStory.name}"</button
  >
  {#await resources.init("story", $_selectedStory.id) then}
    {#if $resources && $resources.filter((r) => r.type === ResourceType.PHOTO && r.soft_deleted_by === null).length !== 0}
      <Carousel>
        {#each $resources.filter((r) => r.type === ResourceType.PHOTO && r.soft_deleted_by === null) as resource}
          <img
            src={resource.url}
            class="object-cover h-full col-span-2 rounded-r-lg"
            alt={resource.description}
          />
        {/each}
      </Carousel>
    {:else}
      <div
        class="flex flex-col items-center justify-center mr-4 rounded-lg bg-base-300 h-28"
      >
        <PhotographIcon class="opacity-50 w-9 h-9 text-base-content" />
        <span class="opacity-50 text-base-content"
          >There is no picture at the moment</span
        >
      </div>
    {/if}
  {/await}
{/if}

<style>
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }
    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
</style>
