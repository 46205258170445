/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Graph } from '../models/Graph';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GraphService {

    /**
     * Get Graph
     * Get graph data.
     *
     * Return *nodes*, *edges*, *to_unknown* and *unknown* collections.
     * @returns Graph Successful Response
     * @throws ApiError
     */
    public static getGraphApiV1GraphGet(): CancelablePromise<Graph> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/graph/',
        });
    }

    /**
     * Get Story
     * Get story graph data.
     *
     * Return *nodes*, *edges* collections.
     * @param id
     * @returns Graph Successful Response
     * @throws ApiError
     */
    public static getStoryApiV1GraphStoryIdGet(
        id: number,
    ): CancelablePromise<Graph> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/graph/story/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
