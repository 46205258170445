<script lang="ts">
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
    XIcon as ChevronUnfold,
  } from "@rgossiaux/svelte-heroicons/solid";
  import { nodeTypes } from "@utils/nodeTypes.js";
  import { Popover } from "@core-ui";
  import Pastille from "@components/icons/Pastille.svelte";

  export let type;
  export let index;
  export let nodesHierarchy;
  export let toggleTypeDisplay;

  let hasContent : boolean = false

  const setHasContent  = () => {
    hasContent =  nodesHierarchy[type].children.length !== 0 
  }

  $:nodesHierarchy && setHasContent()
</script>

<Popover
  triggeredBy={`#concept-hierarchy-story-category-${type}`}
  class="text-sm font-light w-96"
  placement="right"
  defaultClass="p-0"
  arrow={true}
>
  <div class="grid grid-cols-5">
    <div class="col-span-3 p-3">
      <div class="space-y-2">
        <h3 class="font-semibold text-gray-900 dark:text-white">
          {nodeTypes[type].label}:
        </h3>
        <p class="text-gray-500 dark:text-gray-500">
          {nodeTypes[type].description}
        </p>
      </div>
    </div>
    <img
      src="https://www.timeoutabudhabi.com/cloud/timeoutabudhabi/2021/09/10/JmkbqDyl-216CPHAg-2.jpeg"
      class="object-cover h-full col-span-2 rounded-r-lg"
      alt="Jubail Mangrove Park in Abu Dhabi"
    />
  </div>
</Popover>

<li
  on:click={() => {
    hasContent && toggleTypeDisplay(type);
  }}
  class="flex items-center justify-between pr-1 hover:bg-base-300"
>
  <div
    data-testid={`node-type-${type.toLowerCase()}`}
    class="flex flex-row items-center justify-center w-full gap-2"
  >
    {#if nodesHierarchy[type].display}
      <ChevronD class="flex-none w-6 h-6" />
    {:else}
      <ChevronR class={`flex-none w-6 h-6 ${hasContent ? "" : "text-base-content opacity-50"}`} />
    {/if}
    <Pastille {type} class={hasContent ? "" : "opacity-50"}/>
    <div class="min-w-0 grow">
      <div class={`truncate ${hasContent ? "" : "text-base-content opacity-50"}`}>
        {nodeTypes[type].label}
      </div>
    </div>
  </div>
</li>
