<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
    XIcon,
  } from "@rgossiaux/svelte-heroicons/solid";
  import { isNodeInStoryGraph } from "@utils/story";
  import { nodeTypes } from "@utils/nodeTypes.js";
  import NodeMenu from "../dialog/NodeMenu.svelte";
  import { checkRoles, Roles } from "@utils/decorators";
  import { isInCategory } from "@utils/categories";
  import ArrowPath from "@components/icons/ArrowPath.svelte";
  import classNames from "classnames";
  import { InformationCircleIcon } from "@rgossiaux/svelte-heroicons/outline";
  import NavigateWithConfirm from "@components/AccessControl/NavigateWithConfirm.svelte";
  import { Popover } from "@core-ui";
  import type { NodesWithStories } from "@client";
  import chroma from "chroma-js";
  import { displayStore } from "@stores/displaystore";
  const { _theme } = displayStore;
  export let node;

  export let compact:boolean = false;
  export let nodeClickAction:((node) => {});

  const { _selectedNode, _selectedStory, _nodes } = dataStore;

  class NodeMenuClass {
    bind;

    @checkRoles([Roles.CONCEPT, Roles.STORY])
    onRightClick(e, n) {
      let currentNodeWithStory = $_nodes.find((node) => node?.id == n.id);
      this.bind.show(currentNodeWithStory, { x: e.x, y: e.y });
    }
  }

  const nodeMenu = new NodeMenuClass();
  // Bound node list element
  let liElement;
  let nodeNameEl: HTMLElement;
  let isEllipsisActive = false;

  const checkEllipsisActive = (e: HTMLElement) => {
    isEllipsisActive = false;//e.offsetWidth < e.scrollWidth;
  };

  let color = nodeTypes[node.type].color().toLowerCase();
  let borderRadius = nodeTypes[node.type].form === "rectangle" ? "" : "50%";
  let typeLabel = nodeTypes[node.type].label;

  const statusIcon = (status: string, color: string) => {
    if (status == "rejected") {
      return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-4 h-4 mr-2 rounded-full btn-error"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>';
    }
    if (status == "adopted") {
      return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style="background-color:${color}" stroke="currentColor" aria-hidden="true" class="w-4 h-4 mr-2 bg-red-700 rounded-full stroke-base-300"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>`;
    }
    if (status == "pending") {
      return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2 rounded-full stroke-info"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"></path></svg>';
    }
  };

  // Resolve classNames

  /**
   * Scrolls to node list item if selected.
   */
  function scroll_to_node(n: NodesWithStories) {
    if (n && node.id === n.id && liElement) {
      liElement.scrollIntoView();
    }
  }

  function getColorText(node: NodesWithStories) {
    if (
      ($_selectedNode && node.id === $_selectedNode.id) ||
      node.status == "adopted"
    )
      return "text-base-content";
    else if (node.status == "pending") return "text-info";
    else if (node.status == "rejected") return "text-error";
  }

  function colorWithOpacity(color) {
    const bgColor = $_theme == "light" ? "#FAFAFA" : "#18181B";
    const colorScale = chroma.scale([bgColor, color]);
    return colorScale(0.5).hex();
  }

  $: $_selectedNode && liElement && scroll_to_node($_selectedNode);
  $: nodeNameEl && checkEllipsisActive(nodeNameEl);
  
</script>

<Popover
  triggeredBy={`#node_${node.id}_${compact ? "compact_" : ""}label_info`}
  class="text-sm font-light w-96 z-20"
  placement="right"
  defaultClass="p-0"
  arrow={true}
>
  <div class="grid grid-cols-5">
    <div class="col-span-3 p-3">
      <div class="space-y-2">
        <h3 class="font-semibold text-gray-900 dark:text-white">
          {node.name}
        </h3>
        <p class="text-gray-500 dark:text-gray-500">
          {node.description}
        </p>
      </div>
    </div>
  </div>
</Popover>

<!-- TODO: NodeMenu dialog/popover should be moved to a portal -->
<NodeMenu bind:this={nodeMenu.bind} />
{#if isEllipsisActive}
  <Popover
    triggeredBy={`#node_${node.id}${compact ? "_compact" : ""}`}
    class="text-sm font-light w-96 z-20"
    placement="right"
    defaultClass="p-3"
    arrow={true}
  >
    <div class="flex items-center justify-start w-full gap-2">
      <div
        class="inline-block w-3 h-3 rounded-full"
        style={`background-color: ${color}; 
                     border-radius: ${borderRadius}`}
      />

      <div class="text-xs font-bold uppercase grow">
        {typeLabel}
      </div>
    </div>

    <br />

    <div class="flex items-center justify-start gap-2">
      <span>{@html statusIcon(node.status, color)}</span>
      <span class="text-base">{node.name}</span>
    </div>
  </Popover>
{/if}

<li
  bind:this={liElement}
  id={`node_${node.id}${compact ? "_compact" : ""}`}
  class:ml-3={isInCategory(node.type)}
  on:contextmenu|preventDefault={(e) => nodeMenu.onRightClick(e, node)}
  class="flex items-center justify-between w-full {compact ? "" : "mb-1"} text-base-100 hover:bg-base-300"
  style={`background-color: ${
    $_selectedNode && node.id === $_selectedNode.id
      ? colorWithOpacity(color)
      : ""
  }`}
>
  {#if node.children.length !== 0}
    <span on:click={() => (node.display = !node.display)}>
      {#if node.display}
        <ChevronD class="{compact ? "w-5 h-5" : "w-6 h-6"} text-base-content chevron" style="cursor:pointer;" />
      {:else}
        <ChevronR class="{compact ? "w-5 h-5" : "w-6 h-6"} text-base-content chevron" style="cursor:pointer;" />
      {/if}
    </span>
  {/if}
  {#if node.status == "rejected"}
    <XIcon
      style={`color: ${
        $_selectedNode && node.id === $_selectedNode.id
          ? colorWithOpacity(color)
          : ""
      }`}
      class={`${compact ? "w-3 h-3 mr-1" : "w-4 h-4 mr-2"}  rounded-full ${
        $_selectedNode && node.id === $_selectedNode.id
          ? "dark:bg-error-content bg-error"
          : "btn-error"
      }`}
    />
  {:else if node.status == "pending"}
    <ArrowPath
      class={classNames(
        `${compact ? "w-3 h-3 mr-1" : "w-4 h-4 mr-2"} rounded-full`,
        {
          "stroke-base-200": Boolean(
            $_selectedNode && node.id === $_selectedNode.id
          ),
        },
        {
          "stroke-info": !Boolean(
            $_selectedNode && node.id === $_selectedNode.id
          ),
        }
      )}
    />
  {/if}
  
  {#if nodeClickAction == undefined}
    <NavigateWithConfirm
      aria-selected={$_selectedNode && node.id === $_selectedNode.id}
      aria-expanded={node.children.length !== 0 && node.display}
      aria-hidden={node.children.length !== 0 && !node.display}
      onConfirm={() => dataStore.setSelectedNode(node)}
      id="node_{node.id}_{compact ? "compact_" : ""}label"
      class={classNames(`flex-1 w-full truncate`, getColorText(node))}
      ><div bind:this={nodeNameEl} class="truncate cursor-pointer">
        {node.name}
      </div>
    </NavigateWithConfirm>
  {:else}
    <div
      aria-selected={$_selectedNode && node.id === $_selectedNode.id}
      aria-expanded={node.children.length !== 0 && node.display}
      aria-hidden={node.children.length !== 0 && !node.display}
      on:click={() => {
          nodeClickAction(node);
        }
      }
      id="node_{node.id}_{compact ? "compact_" : ""}label"
      class={classNames(`flex-1 w-full truncate`, getColorText(node))}
      ><div bind:this={nodeNameEl} class="truncate cursor-pointer">
        {node.name}
      </div>
    </div>
  {/if}

  {#if !compact}
    <InformationCircleIcon
      class={classNames(`w-4 h-4`, getColorText(node))}
      id={`node_${node.id}${compact ? "compact_" : ""}_label_info`}
    />
  {/if}
</li>
{#if node.display}
  <li>
    <ul class="{compact ? "ml-2" : "ml-3"}">
      {#each node.children as nodechild, i}
        <svelte:self node={nodechild} {compact} {nodeClickAction}/>
      {/each}
    </ul>
  </li>
{/if}

<style>
  .inTheStory {
    background-color: rgba(255, 0, 0, 0.26);
  }

  .rejected {
    @apply text-error;
  }

  .rejected-active {
    @apply dark:text-error-content text-error;
  }

  .chevron {
    cursor: pointer;
  }
  /* .pending {
    @apply text-info;
  } */
</style>
