import { AlgorithmsService, AlgorithmsService as Service, type ParameterDefinition } from "@client";
import type { Algorithm as Model} from "@models/Algorithm";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createAlgorithm = (model: Model) => {
  try {
    Service.createAlgorithmApiV1AlgorithmsPost(model).then((res) => {
      notifySuccess("Algorithm succesfully created");
      supportingStore.addAlgorithm(res);
      if (model.parameter_definitions) {
        updateAlgorithmParameterDefinitions(res, model.parameter_definitions);
      }
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateAlgorithm = async (id: number, model: Model = null) => {
  try {
    Service.updateAlgorithmApiV1AlgorithmsIdPut(id, model).then(
      (res) => {
        notifySuccess("Algorithm succesfully updated");
        supportingStore.updateSelectedAlgorithm(res);
        updateAlgorithmParameterDefinitions(model, model.parameter_definitions);
      }
    );
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateAlgorithmParameterDefinitions = async (
  algo: Model = null,
  parameter_definitions: ParameterDefinition[]
) => {
  try {
      if (parameter_definitions && parameter_definitions.length>0) {
        AlgorithmsService.updateParametersApiV1AlgorithmsIdParametersPatch(
          algo.id, 
          parameter_definitions
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          notifySuccess("Metric parameter definitions succesfully updated");
          algo.parameter_definitions = parameter_definitions;
          supportingStore.updateSelectedAlgorithm(algo);
        });
      }
      else {
        algo.parameter_definitions = [];
        supportingStore.updateSelectedAlgorithm(algo);
      }
  } catch (e) {
    notifyFailure(`An error occured while updating metric parameter definitions: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deleteAlgorithm = (id: number) => {
  try {
    Service.deleteAlgorithmApiV1AlgorithmsIdDelete(id).then(() => {
      supportingStore.removeAlgorithm(id);
      notifySuccess("Algorithm succesfully deleted");
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

/**
 * Returns true if the provided name is already taken by an existing algo.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean for if name is already in use.
 */
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.algorithms.some(
      (s: Model) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
