  <script>
  import { nodeTypes } from "@utils/nodeTypes";
  import { dataStore } from "@stores/datastore";
  import { supportingStore } from "@stores/supportingstore";
  
  let { _selectedNode } = dataStore;
  let { _envProcess} = supportingStore;


  const getLabel = (prop) => {
    return $_selectedNode[prop.name] != undefined ? $_selectedNode[prop.name]  : prop.emptyText
  }
</script>
<!--
@component
Displays concept properties.
-->

<ul data-testid="custom-property-fields-container" {...$$restProps}>
  {#each nodeTypes[$_selectedNode.type].properties as prop}
    <li>
      <span>{prop.label}</span> : 
      <span class="font-bold">{getLabel(prop)}</span>
    </li>
  {/each}
</ul>
