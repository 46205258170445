<script lang="ts" context="module">
  import Panel from "@components/Panel.svelte";
  import StoryList from "@components/tree/StoryList.svelte";
  import NodesList from "@components/tree/NodesList.svelte";
  import Autocomplete from "@components/Forms/FuseAutocomplete.svelte";
  import { dataStore } from "@stores/datastore";
  import { Divider } from "@core-ui";

  const { _storyHierarchy } = dataStore;
</script>

<Panel data-testid="thesaurus-panel" side="left" expandable collapsable>
  <div class="flex flex-col w-full h-full">
    <StoryList
      data-testid="stories-list"
      class="flex flex-col"
      storyHierarchy={$_storyHierarchy}
      showDeselectButton={true}
      isScenario={false}
    />
    <Divider />
    <div class="flex items-start justify-center w-full mb-10">
      <div class="flex-1">
        <Autocomplete />
      </div>
    </div>
    <!-- <Divider style="margin-top: 40px" /> -->
    <!-- <StoryList
      data-testid="scenarios-list"
      class="flex flex-col"
      storyHierarchy={$_storyHierarchy}
      isScenario={true}
    />
    <Divider /> -->
    <NodesList data-testid="nodes-list" class="overflow-auto scroll-smooth" />
  </div>
</Panel>
