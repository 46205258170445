<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<script>
  import { displayStore } from "@stores/displaystore";
  const { _theme } = displayStore;  
	let clazz;
	export { clazz as class };  
</script>

<svg class="{$_theme === 'dark' ? 'dark_themed_icon' : 'light_themed_icon'}" width="20" height="20"  xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.05002 2.04987C3.36284 0.737047 5.14341 -0.000488281 7.00002 -0.000488281C8.85663 -0.000488281 10.6372 0.737047 11.95 2.04987C13.2628 3.36269 14.0004 5.14326 14.0004 6.99987C14.0004 8.85648 13.2628 10.637 11.95 11.9499L7.00002 16.8999L2.05002 11.9499C1.39993 11.2998 0.884251 10.5281 0.532423 9.67882C0.180596 8.82949 -0.000488281 7.91918 -0.000488281 6.99987C-0.000488281 6.08056 0.180596 5.17025 0.532423 4.32092C0.884251 3.47159 1.39993 2.69989 2.05002 2.04987ZM7.00002 8.99987C7.53045 8.99987 8.03916 8.78915 8.41423 8.41408C8.7893 8.03901 9.00002 7.5303 9.00002 6.99987C9.00002 6.46944 8.7893 5.96073 8.41423 5.58566C8.03916 5.21058 7.53045 4.99987 7.00002 4.99987C6.46958 4.99987 5.96088 5.21058 5.5858 5.58566C5.21073 5.96073 5.00002 6.46944 5.00002 6.99987C5.00002 7.5303 5.21073 8.03901 5.5858 8.41408C5.96088 8.78915 6.46958 8.99987 7.00002 8.99987Z" fill="#FAFAFA"/>
</svg>
<style>
.dark_themed_icon path {
  fill: #FFFFFF;
}
.light_themed_icon path {
  fill: #000000;
}
</style>
