<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import type { StoriesHierarchy } from "@utils/story";
  import StoryMenu from "../dialog/StoryMenu.svelte";
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
  } from "@rgossiaux/svelte-heroicons/solid";
  import ArrowRightOnRectangle from "@components/icons/ArrowRightOnRectangle.svelte";

  import { can, Roles } from "@utils/decorators";
  import NavigateWithConfirm from "@components/AccessControl/NavigateWithConfirm.svelte";
  export let story: StoriesHierarchy;
  const { _selectedStory } = dataStore;
  let storyMenu;
  export let mode: "general" | "node";
  export let isScenario: boolean;

  function toggleStory(e: Event) {
    story.display = !story.display;
  }
  const isStorySelected = (selected): boolean => {
    return selected && story.id === selected.id;
  };

  function showContextMenu(e: PointerEvent) {
    storyMenu.show(story, { x: e.x, y: e.y }, mode);
  }

  const restrictedShowContextMenu = can(
    [Roles.DEV, Roles.STORY],
    showContextMenu
  );

  const byAlphaOrder = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
</script>

<StoryMenu bind:this={storyMenu} />

<li>
  <div
    class="graph-div"
    class:selected-graph={isStorySelected($_selectedStory)}
    on:contextmenu|preventDefault={(e) => {
      restrictedShowContextMenu(e);
    }}
  >
    {#if story.children.length != 0}
      <span on:click={toggleStory}>
        {#if story.display}
          <ChevronD class="w-6 h-6" />
        {:else}
          <ChevronR class="w-6 h-6" />
        {/if}
      </span>
    {/if}
    <NavigateWithConfirm
      class="cursor-pointer grow"
      onConfirm={() => {
        dataStore.setSelectedStory(story);
        dataStore.setSelectedNode(null);
        dataStore.setSelectedEdge(null)
      }}
    >
      {story.name}
    </NavigateWithConfirm>
    {#if isStorySelected($_selectedStory)}
      <span
        on:click={() => dataStore.setSelectedStory(null)}
        class="cursor-pointer"
      >
        <ArrowRightOnRectangle class="w-6 h-6" />
      </span>
    {/if}
  </div>
  {#if story.display && story.children.length > 0}
    {#each story.children.sort(byAlphaOrder) as child}
      <ul>
        <svelte:self story={child} {mode} />
      </ul>
    {/each}
  {/if}
</li>

<style>
  div {
    @apply flex w-full items-center justify-start;
  }
  .graph-div {
    @apply hover:bg-base-300;
  }
  ul {
    @apply flex-col justify-between  pl-5;
  }
  li {
    @apply flex-col justify-between pl-5;
  }
  .selected-graph {
    @apply bg-base-100 font-bold;
  }
</style>
