<script lang="ts">
  import type { SvelteComponent } from "svelte";
  import { Menu, MenuItem, MenuItems } from "@rgossiaux/svelte-headlessui";
  import { PencilIcon } from "@rgossiaux/svelte-heroicons/solid";

  import SuportingMenuButton from "./SupportingMenuButton.svelte";
  import SupportingManagerDialog from "@components/Supporting/SupportingManagerDialog.svelte";
  import MetricForm from "./Metric/Form.svelte";
  import MetricManager from "./Metric/Manager.svelte";
  import StakeholderForm from "./Stakeholder/Form.svelte";
  import StakeholderManager from "./Stakeholder/Manager.svelte";
  import SectorManager from "./Sector/Manager.svelte";
  import MetricOfFunctionalHealthForm from "./MetricOfFunctionalHealth/Form.svelte";
  import MetricOfFormForm from "./MetricOfForm/Form.svelte";
  import StateMetricForm from "./StateMetric/Form.svelte";
  import MetricOfFunctionalHealthManager from "./MetricOfFunctionalHealth/Manager.svelte";
  import MetricOfFormManager from "./MetricOfForm/Manager.svelte";
  import StateMetricManager from "./StateMetric/Manager.svelte";
  import SectorForm from "./Sector/Form.svelte";
  import ParameterManager from "./ParameterDefinition/Manager.svelte";
  import ParameterForm from "./ParameterDefinition/Form.svelte";
  import UnitManager from "./UnitOfMeasure/Manager.svelte";
  import UnitForm from "./UnitOfMeasure/Form.svelte";
  import AlgorithmManager from "./Algorithm/Manager.svelte";
  import AlgorithmForm from "./Algorithm/Form.svelte";
  import EnvProcessManager from "./EnvProcess/Manager.svelte";
  import EnvProcessForm from "./EnvProcess/Form.svelte";
  import OpenAIDialog from "../../components/Editors/StoryEditor/StoryPreview/OpenAIDialog.svelte"

  import { CheckIcon } from "@rgossiaux/svelte-heroicons/solid";
  import { Supporting } from "@utils/supporting";
  import { getCtx } from "./context";
  import ManagerContainer from "./ManagerContainer.svelte";
  import { UnknownSupportingError } from "@utils/supporting";
  import { PromptService } from "@client";
  import Textarea from "@components/Core/forms/Textarea.svelte";
  import { Label } from "@components/Core";

  let {
    metricsManagerDialog,
    metricFormDialog,
    setSupportingClass,
    selectedSupportingClass,
  } = getCtx();

  let dialog;
  let open;
  let close;

  let old_prompt = ""
  let prompt = ""

  async function updatePrompt() {
    await PromptService.putPromptApiV1OpenaiPromptPut({prompt})
    old_prompt = prompt
  }

  async function resetPrompt() {
    const req = await PromptService.resetPromptApiV1OpenaiPromptOptions()
    prompt = req.prompt
    old_prompt = prompt
  }

  let enabledSupportings = [
    "UnitsOfMeasure",
    "Algorithms",
    "Stakeholders",
    "Metrics",
    "Sectors",
    "ParameterDefinitions",
    "StateMetrics",
    "MetricsOfForm",
    "MetricsOfFunctionalHealth",
    "EnvProcess",
  ];

  // Maps supporting manager and form components by name
  // Used for dynamically switching between supporting class.
  // TODO implement a factory pattern here:
  let supportingComponentMapping = {
    Metrics: {
      manager: MetricManager,
      form: MetricForm,
    },
    UnitsOfMeasure: {
      manager: UnitManager,
      form: UnitForm,
    },
    Algorithms: {
      manager: AlgorithmManager,
      form: AlgorithmForm,
    },
    StateMetrics: {
      manager: StateMetricManager,
      form: StateMetricForm,
    },
    MetricsOfForm: {
      manager: MetricOfFormManager,
      form: MetricOfFormForm,
    },
    MetricsOfFunctionalHealth: {
      manager: MetricOfFunctionalHealthManager,
      form: MetricOfFunctionalHealthForm,
    },
    Stakeholders: {
      manager: StakeholderManager,
      form: StakeholderForm,
    },
    Sectors: {
      manager: SectorManager,
      form: SectorForm,
    },
    ParameterDefinitions: {
      manager: ParameterManager,
      form: ParameterForm,
    },
    EnvProcess: {
      manager: EnvProcessManager,
      form: EnvProcessForm,
    },
  };

  function classNames(...classes: (string | false | null | undefined)[]) {
    return classes.filter(Boolean).join(" ");
  }

  function resolveClass({
    active,
    disabled,
  }: {
    active: boolean;
    disabled: boolean;
  }) {
    return classNames(
      "flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer",
      disabled && "cursor-not-allowed opacity-50"
    );
  }

  // The form and manager components associated with currently
  // selected supporting class i.e. the ones used by this component.
  // Used for dynamically switching between supporting classes.
  let formComp, mgrComp: SvelteComponent;

  const openSupportingManager = (supportingName: string) => {
    showManager = true;
    $metricsManagerDialog.show();
    setSupportingClass(supportingName);
  };

  const openOpenAIPrompt = async () => {
    const req = await PromptService.getPromptApiV1OpenaiPromptGet()
    prompt = req.prompt
    old_prompt = prompt
    dialog.open()
  }

  /**
   * Sets the supporting form component to the one associated
   * to currently selected supporting class.
   */
  const setupFormComponent = () => {
    if (supportingComponentMapping.hasOwnProperty($selectedSupportingClass)) {
      formComp = supportingComponentMapping[$selectedSupportingClass].form;
    } else {
      throw new UnknownSupportingError($selectedSupportingClass);
    }
  };

  /**
   * Sets the supporting manager component to the one associated
   * with currently selected supporting class.
   */
  const setupMgrComponent = () => {
    if (supportingComponentMapping.hasOwnProperty($selectedSupportingClass)) {
      mgrComp = supportingComponentMapping[$selectedSupportingClass].manager;
    } else {
      throw new UnknownSupportingError($selectedSupportingClass);
    }
  };

  /**
   * Sets the supporting form and manager components to those
   * associated with currently selected supporting class.
   */
  const setupSupportingComponents = () => {
    setupMgrComponent();
    setupFormComponent();
  };

  let showManager: boolean = false;

  let hover: boolean[] = new Array(Object.values(Supporting).length).fill(
    false
  );

  let hoverOpenAI: boolean = false

  $: $selectedSupportingClass && setupSupportingComponents();
</script>

<SupportingManagerDialog bind:this={$metricsManagerDialog}>
  <ManagerContainer>
    <svelte:component
      this={mgrComp}
      slot="content"
      title="Advanced properties"
    />
  </ManagerContainer>
</SupportingManagerDialog>
<SupportingManagerDialog bind:this={$metricFormDialog}>
  <svelte:component this={formComp} />
</SupportingManagerDialog>

<OpenAIDialog bind:this={dialog} bind:open={open} bind:close={close} title="OpenAi prompt">
  <div
    class="inline-block w-full p-4 text-left align-middle bg-base-200 rounded-2xl flex flex-col" slot="content"
    style="width:120vh; "
  > 
    <Label class="mt-4 mb-2" for="name">Prompt</Label>
    <Textarea
      type="text"
      name="name"
      id="name"
      color={"base"}
      required
      value={prompt}
      rows="5"
      class='mb-2'
      on:keyup={(e) => {prompt = e.target.value}}
    />
    <div>
      <button
        type="submit"
        class={`normal-case btn btn-sm gap-2 btn-success rounded-xl text-success-content`}
        on:click={() => resetPrompt()}
      >
        <CheckIcon class="w-4 h-4" />
        Reset
      </button>
      <button
        type="submit"
        disabled={old_prompt==prompt}
        class={`normal-case btn btn-sm gap-2 btn-success rounded-xl text-success-content`}
        on:click={() => updatePrompt()}
      >
        <CheckIcon class="w-4 h-4" />
        Save
      </button>
    </div>
  </div>
</OpenAIDialog>

<Menu class="h-8">
  <SuportingMenuButton />
  <MenuItems
    class="absolute z-20 mt-2 origin-top-right border divide-y rounded-md shadow-lg outline-none w-60 bg-base-100 border-base-200 divide-base-200 right-10"
  >
    {#each Object.keys(Supporting) as sup, idx}
      {#if enabledSupportings.includes(sup)}
        <MenuItem
          as="div"
          class={resolveClass}
          on:mouseover={() => {
            hover[idx] = true;
          }}
          on:mouseleave={() => {
            hover[idx] = false;
          }}
          on:click={() => openSupportingManager(sup)}
        >
          <p>
            {Object.values(Supporting)[idx]}
          </p>
          {#if hover[idx]}
            <PencilIcon class="w-4 h-4" />
          {/if}
        </MenuItem>
      {:else}
        <MenuItem as="div" disabled class={resolveClass}>
          <p>
            {Object.values(Supporting)[idx]}
          </p>
          {#if hover[idx]}
            <PencilIcon class="w-4 h-4" />
          {/if}
        </MenuItem>
      {/if}
    {/each}
    <MenuItem
          as="div"
          class={resolveClass}
          on:mouseover={() => {
            hoverOpenAI = true;
          }}
          on:mouseleave={() => {
            hoverOpenAI = false;
          }}
          on:click={() => openOpenAIPrompt()}
        >
          <p>
            OpenAI Prompt
          </p>
          {#if hoverOpenAI}
            <PencilIcon class="w-4 h-4" />
          {/if}
        </MenuItem>
  </MenuItems>
</Menu>
