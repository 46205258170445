<script  lang="ts"> 
  import { dataStore } from "@stores/datastore";
  import { supportingStore } from "@stores/supportingstore";
  import { updateNodeEnvprocess } from "@utils/node";
  import { Label, MultiSelect } from "@core-ui";
  import { createForm, getValue  } from 'felte';
  import { reporter } from "@felte/reporter-svelte";
  import { isMandatory, Supporting } from "@utils/supporting";

  const { _envProcess } = supportingStore;
  let { _selectedNode } = dataStore;
  let label = Supporting.EnvProcess
  let required = isMandatory($_selectedNode.type, Supporting.EnvProcess)
  let initValues = $_selectedNode["env_process"]

  const handleChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    } else {
      val = [];
    }
    setFields("field", val);
  };

  export const handleSubmit = () => {
    if($isValid){
      let values = getValue($data, "field").map(f => f.id)
      updateNodeEnvprocess($_selectedNode.id, values)
    }
  };  

  const { form, data,  setFields, setInitialValues, reset, setData, isValid} = createForm({
    validate: (values) => {
      let errors = {field : []}
        if(required && !values.field){
          errors.field.push(`Please select at least one ${label.toLowerCase()}`)
        }
        return errors
    },
    extend: [reporter],
  })
  
</script>
<!--
@component
Allows to add/remove sectors to/from a concept.
-->
{#if $_selectedNode}
  <form use:form class="w-full p-1 form-control">
    <div class="flex flex-col">
      <Label class="mb-2" {required}>{label}:</Label>
      <MultiSelect 
        {initValues}
        items={$_envProcess}
        placeholder={`Select ${label.toLowerCase()}`}
        handleSelectChange={handleChange}
        label="name"
        itemId="id"
        name="field"
        {required}
        requiredMessage={`Please select at least one ${label.toLowerCase()}`}
      />  
    </div>
  </form>
{/if}
