<script lang="ts">
  import type { Edge } from "@utils/formatData";
  import { dataStore } from "@stores/datastore";
  import { Popover } from "@core-ui";
  import NavigateWithConfirm from "@components/AccessControl/NavigateWithConfirm.svelte";
  import { Roles, can } from "@utils/decorators";
  import type { NodesWithStories } from "@client";
  import NodeMenu from "@components/dialog/NodeMenu.svelte";
  export let direction;
  export let n;
  let nodeMenu

  const selectRelatedNode = (link: Edge) => {
    dataStore.setSelectedNode(direction === "from" ? link.source : link.target);
  };

  let connDescEl: HTMLElement;
  let isEllipsisActive = false;

  const checkEllipsisActive = (e: HTMLElement) => {
    isEllipsisActive = e.offsetWidth < e.scrollWidth;
  };

  const restrictedShowContextMenu = can(
    [Roles.DEV, Roles.STORY],
    showContextMenu
  );

  function showContextMenu(e: PointerEvent, node : NodesWithStories) {
    nodeMenu.show(node, { x: e.x, y: e.y });
  }

  $: connDescEl && checkEllipsisActive(connDescEl);
</script>

<NodeMenu bind:this={nodeMenu} context="rightGraphPanel" />

{#if isEllipsisActive}
  <Popover
    triggeredBy={`#concept-hierarchy-story-category-${n.id}`}
    class="w-auto text-sm font-light"
    placement="left"
    defaultClass="p-0"
    arrow={true}
  >
    <div class="flex flex-row m-4">
      <div class={`mr-2`} >
        {direction === "from" ? n.source.name : n.target.name}
      </div>
    </div>
  </Popover>
{/if}
<li class="ml-8 mr-2 list-disc" id={`concept-hierarchy-story-category-${n.id}`}
on:contextmenu|preventDefault={(e) => {
  restrictedShowContextMenu(e, direction === "from" ? n.source : n.target);
}}
>
  <span>
    <div bind:this={connDescEl} class="flex justify-start w-full truncate">
      <NavigateWithConfirm
        class={`mr-2 text-sm hover:cursor-pointer hover:font-bold hover:underline ${isEllipsisActive ? "truncate" : ""}`}
        onConfirm={() => selectRelatedNode(n)}
      >
        {direction === "from" ? n.source.name : n.target.name}
      </NavigateWithConfirm>
      <NavigateWithConfirm
        class="mx-2 text-sm text-gray-500 hover:cursor-pointer hover:font-bold hover:underline"
        onConfirm={() => dataStore.setSelectedEdge(n)}
      >
        See connection
        <!-- {getLinkSectionName(n)} -->
      </NavigateWithConfirm>
    </div>
  </span>
</li>

<style>
  div svg {
    vertical-align: bottom; /* ADDED */
  }
</style>
