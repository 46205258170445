import { get, writable } from "svelte/store";
import type { Writable } from "svelte/store";

import { MetricsService } from "client/services/MetricsService";
import { MetricsOfFormService } from "client/services/MetricsOfFormService";
import { MetricsOfFunctionalHealthService } from "client/services/MetricsOfFunctionalHealthService";
import { StateMetricsService } from "client/services/StateMetricsService";
import { StakeholdersService } from "client/services/StakeholdersService";
import { SectorsService } from "client/services/SectorsService";
import { ParameterDefinitionsService } from "client/services/ParameterDefinitionsService";
import { UnitsOfMeasureService } from "client/services/UnitsOfMeasureService";
import { AlgorithmsService } from "client/services/AlgorithmsService";
import { EnvprocessService } from "client/services/EnvprocessService";
import type { Sector } from "@models/Sector";
import type { Metrics } from "@models/Metrics";
import type { EnvProcessWithLink } from "@models/EnvProcessWithLink"
import type { MetricOfForm } from "@models/MetricOfForm";
import type { MetricOfFunctionalHealth } from "@models/MetricOfFunctionalHealth";
import type { StateMetric } from "@models/StateMetric";
import type { Stakeholder } from "@models/Stakeholder";
import type { ParameterDefinition } from "@models/ParameterDefinition";
import type { UnitOfMeasure } from "@client";
import type { Algorithm } from "@client";

// TODO: Supporting class selected instances could be moved to
// supporting manager/form context.
class SupportingStore {
  // Supporting class collections:
  _metrics: Writable<Metrics[] | []>;
  _metricsOfForm: Writable<MetricOfForm[] | []>;
  _metricsOfFunctionalHealth: Writable<MetricOfFunctionalHealth[] | []>;
  _stateMetrics: Writable<StateMetric[] | []>;
  _stakeholders: Writable<Stakeholder[] | []>;
  _sectors: Writable<Sector[] | []>;
  _parameterDefinitions: Writable<ParameterDefinition[] | []>;
  _unitsOfMeasure: Writable<UnitOfMeasure[] | []>;
  _algorithms: Writable<Algorithm[] | []>;
  _envProcess: Writable<EnvProcessWithLink[] | []>;

  // Supporting class selected instances:
  _selectedMetric: Writable<Metrics | null>;
  _selectedMetricOfForm: Writable<MetricOfForm | null>;
  _selectedMetricOfFunctionalHealth: Writable<MetricOfFunctionalHealth | null>;
  _selectedStateMetric: Writable<StateMetric | null>;
  _selectedStakeholder: Writable<Stakeholder | null>;
  _selectedSector: Writable<Sector | null>;
  _selectedParameterDefinition: Writable<ParameterDefinition | null>;
  _selectedUnitOfMeasure: Writable<UnitOfMeasure | null>;
  _selectedAlgorithms: Writable<Algorithm | null>;
  _selectedEnvProcess: Writable<EnvProcessWithLink | null>;

  constructor() {
    this.initCollections();
    this.initSelected();
  }

  private initSelected() {
    this._selectedMetric = writable();
    this._selectedStakeholder = writable();
    this._selectedSector = writable();
    this._selectedParameterDefinition = writable();
    this._selectedMetricOfForm = writable();
    this._selectedMetricOfFunctionalHealth = writable();
    this._selectedStateMetric = writable();
    this._selectedUnitOfMeasure = writable();
    this._selectedAlgorithms = writable();
    this._selectedEnvProcess = writable();
  }

  private initCollections() {
    this._metrics = writable([]);
    this._sectors = writable([]);
    this._stakeholders = writable([]);
    this._parameterDefinitions = writable([]);
    this._metricsOfForm = writable([]);
    this._metricsOfFunctionalHealth = writable([]);
    this._stateMetrics = writable([]);
    this._unitsOfMeasure = writable([]);
    this._algorithms = writable([]);
    this._envProcess = writable([]);
  }

  // ====METRICS==================================================

  get metrics() {
    return get(this._metrics);
  }

  get metricsOfForm() {
    return get(this._metricsOfForm);
  }

  get metricsOfFunctionalHealth() {
    return get(this._metricsOfFunctionalHealth);
  }

  get stateMetrics() {
    return get(this._stateMetrics);
  }

  get unitsOfMeasure() {
    return get(this._unitsOfMeasure);
  }

  get algorithms() {
    return get(this._algorithms);
  }

  get envProcess() {
    return get(this._envProcess);
  }

  setSelectedMetric = (m: Metrics) => {
    this._selectedMetric.set(m);
  };

  setSelectedMetricOfForm = (m: MetricOfForm) => {
    this._selectedMetricOfForm.set(m);
  };

  setSelectedMetricOfFunctionalHealth = (m: MetricOfFunctionalHealth) => {
    this._selectedMetricOfFunctionalHealth.set(m);
  };

  setSelectedStateMetric = (m: StateMetric) => {
    this._selectedStateMetric.set(m);
  };

  setSelectedUnitOfMeasure = (m: UnitOfMeasure) => {
    this._selectedUnitOfMeasure.set(m);
  };

  setSelecteAlgorithm = (m: Algorithm) => {
    this._selectedAlgorithms.set(m);
  };

  setSelectedEnvProcess = (m: EnvProcessWithLink) => {
    this._selectedEnvProcess.set(m);
  };

  updateSelectedMetric(m: Metrics) {
    this._metrics.update((metrics) => [
      ...metrics.map((metric: Metrics) => {
        return metric.id === m.id ? m : metric;
      }),
    ]);
    this._selectedMetric && this._selectedMetric.set(m);
  }

  updateSelectedMetricOfForm(m: MetricOfForm) {
    this._metricsOfForm.update((metrics) => [
      ...metrics.map((metric: MetricOfForm) => {
        return metric.id === m.id ? m : metric;
      }),
    ]);
    this._selectedMetricOfForm && this._selectedMetricOfForm.set(m);
  }

  updateSelectedMetricOfFunctionalHealth(m: MetricOfFunctionalHealth) {
    this._metricsOfFunctionalHealth.update((metrics) => [
      ...metrics.map((metric: MetricOfFunctionalHealth) => {
        return metric.id === m.id ? m : metric;
      }),
    ]);
    this._selectedMetricOfFunctionalHealth &&
      this._selectedMetricOfFunctionalHealth.set(m);
  }

  updateSelectedStateMetric(m: StateMetric) {
    this._stateMetrics.update((metrics) => [
      ...metrics.map((metric: StateMetric) => {
        return metric.id === m.id ? m : metric;
      }),
    ]);
    this._selectedStateMetric && this._selectedStateMetric.set(m);
  }

  updateSelectedUnitOfMeasure(m: UnitOfMeasure) {
    this._unitsOfMeasure.update((u) => [
      ...u.map((unit: UnitOfMeasure) => {
        return unit.id === m.id ? m : unit;
      }),
    ]);
    this._selectedUnitOfMeasure && this._selectedUnitOfMeasure.set(m);
  }

  updateSelectedAlgorithm(m: Algorithm) {
    this._algorithms.update((u) => [
      ...u.map((algo: Algorithm) => {
        return algo.id === m.id ? m : algo;
      }),
    ]);
    this._selectedAlgorithms && this._selectedAlgorithms.set(m);
  }

  updateSelectedEnvProcess(m: EnvProcessWithLink) {
    this._envProcess.update((u) => [
      ...u.map((ep: EnvProcessWithLink) => {
        return ep.id === m.id ? m : ep;
      }),
    ]);
    this._selectedEnvProcess && this._selectedEnvProcess.set(m);
  }

  removeMetric = (id) => {
    this._metrics.update((m) => [...m.filter((m: Metrics) => m.id !== id)]);
  };

  removeMetricOfForm = (id) => {
    this._metricsOfForm.update((m) => [
      ...m.filter((m: MetricOfForm) => m.id !== id),
    ]);
  };

  removeMetricOfFunctionalHealth = (id) => {
    this._metricsOfFunctionalHealth.update((m) => [
      ...m.filter((m: MetricOfFunctionalHealth) => m.id !== id),
    ]);
  };

  removeStateMetric = (id) => {
    this._stateMetrics.update((m) => [
      ...m.filter((m: StateMetric) => m.id !== id),
    ]);
  };

  removeUnitOfMeasure = (id) => {
    this._unitsOfMeasure.update((u) => [
      ...u.filter((u: UnitOfMeasure) => u.id !== id),
    ]);
  };

  removeAlgorithm = (id) => {
    this._algorithms.update((u) => [
      ...u.filter((u: Algorithm) => u.id !== id),
    ]);
  };

  removeEnvProcess = (id) => {
    this._envProcess.update((u) => [
      ...u.filter((u: EnvProcessWithLink) => u.id !== id),
    ]);
  };

  addMetric = (m) => {
    this._metrics.update((metrics) => [...metrics, m]);
  };

  addMetricOfForm = (m) => {
    this._metricsOfForm.update((metrics) => [...metrics, m]);
  };

  addMetricOFunctionalHealth = (m) => {
    this._metricsOfFunctionalHealth.update((metrics) => [...metrics, m]);
  };

  addStateMetric = (m) => {
    this._stateMetrics.update((metrics) => [...metrics, m]);
  };

  addUnitOfMeasure = (m) => {
    this._unitsOfMeasure.update((uoms) => [...uoms, m]);
  };

  addAlgorithm = (m) => {
    this._algorithms.update((uoms) => [...uoms, m]);
  };

  addEnvProcess = (m) => {
    this._envProcess.update((uoms) => [...uoms, m]);
  };

  // ====STAKEHOLDERS================================================

  get stakeholders() {
    return get(this._stakeholders);
  }

  setSelectedStakeholder = (m: Stakeholder) => {
    this._selectedStakeholder.set(m);
  };

  updateSelectedStakeholder(m: Stakeholder) {
    this._stakeholders.update((stakeholders) => [
      ...stakeholders.map((sh: Stakeholder) => {
        return sh.id === m.id ? m : sh;
      }),
    ]);

    this._selectedStakeholder && this._selectedStakeholder.set(m);
  }

  removeStakeholder = (id) => {
    this._stakeholders.update((m) => [
      ...m.filter((m: Stakeholder) => m.id !== id),
    ]);
  };

  addStakeholder = (m) => {
    this._stakeholders.update((stakeholders) => [...stakeholders, m]);
  };

  // ====SECTORS================================================

  get sectors() {
    return get(this._sectors);
  }

  setSelectedSector = (m: Sector) => {
    this._selectedSector.set(m);
  };

  updateSelectedSector(m: Sector) {
    this._sectors.update((sectors) => [
      ...sectors.map((s: Sector) => {
        return s.id === m.id ? m : s;
      }),
    ]);

    this._selectedSector && this._selectedSector.set(m);
  }

  removeSector = (id) => {
    this._sectors.update((m) => [...m.filter((m: Sector) => m.id !== id)]);
  };

  addSector = (m) => {
    this._sectors.update((sectors) => [...sectors, m]);
  };

  // ====PARAMETER DEFINITIONS=========================================

  get parameterDefinition() {
    return get(this._parameterDefinitions);
  }

  setSelectedParameterDefinition = (m: ParameterDefinition) => {
    this._selectedParameterDefinition.set(m);
  };

  updateSelectedParameterDefinition(m: ParameterDefinition) {
    this._parameterDefinitions.update((parameterDefinitions) => [
      ...parameterDefinitions.map((s: ParameterDefinition) => {
        return s.id === m.id ? m : s;
      }),
    ]);

    this._selectedParameterDefinition &&
      this._selectedParameterDefinition.set(m);
  }

  removeParameterDefinition = (id) => {
    this._parameterDefinitions.update((m) => [
      ...m.filter((m: Sector) => m.id !== id),
    ]);
  };

  addParameterDefinition = (m) => {
    this._parameterDefinitions.update((pds) => [...pds, m]);
  };

  firstLoad = () => {
    MetricsService.getMetricsApiV1MetricsGet().then((r) => {
      this._metrics.set(r);
    });
    MetricsOfFormService.getMetricsOfFormApiV1MetricsOfFormGet().then((r) => {
      this._metricsOfForm.set(r);
    });
    MetricsOfFunctionalHealthService.getMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthGet().then(
      (r) => {
        this._metricsOfFunctionalHealth.set(r);
      }
    );
    StateMetricsService.getStateMetricsApiV1StateMetricsGet().then((r) => {
      this._stateMetrics.set(r);
    });
    StakeholdersService.getStakeholdersApiV1StakeholdersGet().then((r) => {
      this._stakeholders.set(r);
    });
    SectorsService.getSectorsApiV1SectorsGet().then((r) => {
      this._sectors.set(r);
    });
    ParameterDefinitionsService.getParameterDefinitionsApiV1ParameterDefinitionsGet().then(
      (r) => {
        this._parameterDefinitions.set(r);
      }
    );
    UnitsOfMeasureService.getUnitsOfMeasureApiV1UnitsOfMeasureGet().then(
      (r) => {
        this._unitsOfMeasure.set(r);
      }
    );
    AlgorithmsService.getAlgorithmsApiV1AlgorithmsGet().then((r) => {
      this._algorithms.set(r);
    });
    EnvprocessService.getEnvprocessApiV1EnvprocessGet().then((r) => {
      this._envProcess.set(r);
    });
  };
}

export const supportingStore = new SupportingStore();
