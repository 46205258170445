<script lang="ts">
  import classNames from "classnames";
  import { getContext } from "svelte";
  import Wrapper from "@components/Core/utils/Wrapper.svelte";
  import { displayStore } from "@stores/displaystore";

  const background = getContext("background");

  export let value: string = "";

  let wrapped;
  $: wrapped = $$slots.header || $$slots.footer;

  let wrapperClass;
  $: wrapperClass = classNames(
    "w-full rounded-lg",
    displayStore.form.base,
    $$props.class
  );

  let textareaClass;
  $: textareaClass = wrapped
    ? classNames(
        "block w-full",
        "text-sm",
        "border-0 px-0",
        "bg-inherit dark:bg-inherit",
        "focus:outline-none focus:ring-0"
      )
    : classNames(
        wrapperClass,
        "p-2.5 text-sm",
        "focus:ring-base-content focus:border-base-content dark:focus:ring-base-content dark:focus:border-base-content"
      );

  const headerClass = (header) =>
    classNames(
      header ? "border-b" : "border-t",
      "py-2 px-3 border-gray-200 dark:border-gray-600"
    );

  let innerWrapperClass;
  $: innerWrapperClass = classNames(
    "py-2 px-4 bg-white dark:bg-gray-800",
    $$slots.footer || "rounded-b-lg",
    $$slots.header || "rounded-t-lg"
  );
</script>

<Wrapper show={wrapped} class={wrapperClass}>
  {#if $$slots.header}
    <div class={headerClass(true)}>
      <slot name="header" />
    </div>
  {/if}
  <Wrapper show={wrapped} class={innerWrapperClass}>
    <textarea
      bind:value
      on:blur
      on:change
      on:click
      on:focus
      on:keydown
      on:keypress
      on:keyup
      on:mouseenter
      on:mouseleave
      on:mouseover
      on:paste
      {...$$restProps}
      class={textareaClass}
    />
  </Wrapper>
  {#if $$slots.footer}
    <div class={headerClass(false)}>
      <slot name="footer" />
    </div>
  {/if}
</Wrapper>
