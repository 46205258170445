<script lang="ts">
  import Dialog from "./Dialog.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { getCtx } from "@components/Actions/CreateResource/context";

  let item: any;
  let { resources } = getCtx();
  let dialog;
  let dialogRemove;

  function remove() {
    resources.delete(item);
    dialog.close();
  }

  export const show = (it: any, coord) => {
    item = it;
    dialog.show(coord);
  };

  export const close = () => {
    dialog.close();
  };
</script>

<Dialog bind:this={dialog}>
  <div class="p-1 mt-2 mb-2">
    {#if item}
      <Can roles={[Roles.DEV, Roles.CONTENT]}>
        <div class="ml-4">
          <DeleteButton
            label="Remove"
            type="menu"
            icon="Xicon"
            confirmDialog={dialogRemove}
          >
            <DialogConfirmAction
              bind:this={dialogRemove}
              type="removeEvidence"
              actionOnNo={close}
              actionOnYes={() => {
                remove();
              }}
            />
          </DeleteButton>
        </div>
      </Can>
    {/if}
  </div>
</Dialog>

<style>
  span {
    @apply hover:font-bold hover:cursor-pointer;
    margin-left: 16px;
    display: flex;
  }
</style>
