<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import Resources from "@components/Editors/Resources/index.svelte";
  import CreateResource from "@components/Actions/CreateResource/Trigger.svelte";
  import NumberedPastille from "@components/Editors/NumberedPastille.svelte";
  import TabButton from "../Tabs/TabButton.svelte";
  import { PaperClipIcon as PaperClip } from "@rgossiaux/svelte-heroicons/outline";
  import { getCtx as getResourceCtx } from "@components/Actions/CreateResource/context";

  let { resources } = getResourceCtx();

  const { _selectedEdge } = dataStore;

  type TabButtonId = "attachments";
  let activeButtonId: string = "attachments";

  /**
   * Shows the tab content panel associated with given key and hides
   * the other ones. Styles the associated tab button with the
   * "active-button" class.
   *
   * @param {string} id: the id of the tab content panel to show.
   */
  const onTabClicked = (id: TabButtonId) => {
    let elems = document.getElementsByClassName("tabcontent");

    Array.prototype.forEach.call(elems, function (elem) {
      elem.style.display = "none";
    });
    document.getElementById(id).style.display = "block";
    activeButtonId = id;
  };
</script>

<!--
@component
Tabbed panel component for editors.
-->
{#if $_selectedEdge}
  <div class="overflow-hidden flex flex-col h-full w-full">
    <div class="mb-4 border-b border-gray-200 tabs dark:border-gray-700">
          <TabButton
            {activeButtonId}
            tabId="attachments"
            {onTabClicked}
            title=" "
          >
            <PaperClip class="w-6 h-6 mr-2" />
            <NumberedPastille nbr={$resources.length} />
          </TabButton>
    </div>
    <div id="tabs" class="overflow-hidden flex flex-col h-full w-full">
      <div
        id="attachments"
        class="tabcontent"
        style="display: block;"
        role="tabpanel"
        aria-labelledby="attachments-tab"
      >
      <div class="flex items-center justify-between">
        <span class="text-xl font-bold">Evidences</span>
        <CreateResource />
      </div>
      <Resources />
      </div>
    </div>
  </div>
{/if}

<style>
  .active-button {
    border-bottom: 3px solid rgb(21, 207, 207);
  }

  .active-button span {
    @apply text-base-content;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    overflow: auto;
  }
</style>
