<script lang="ts">
  import { createForm } from "felte";

  import { supportingStore } from "@stores/supportingstore";
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import { updateNode } from "@utils/node";
  import { Label } from "@core-ui";

  let metricId: number;

  let { _metrics } = supportingStore;
  let { _selectedNode } = dataStore;

  let editMode: boolean = false;

  export const updateNodeMetric = () => {
    updateNode($_selectedNode.id, {
      metric: metricId === "" ? null :  metricId,
    });
    $_selectedNode.metric = metricId;
    editMode = false;
  };

  const updateMetricId = () => {
    metricId = $_selectedNode.metric ?? "";
  };

  const {
    form,
    setFields,
    isValid,
    isSubmitting,
    createSubmitHandler,
    setInitialValues,
    reset,
  } = createForm<Node>({
    onSubmit: (values) => {
      updateNodeMetric();
    },
  });

  export const handleSubmit = createSubmitHandler({
    onSubmit: (values) => {
      updateNodeMetric();
    },
  });

  $: $_selectedNode && updateMetricId();
</script>

<!--
@component
Concept metric form.
-->
<div class="flex flex-col p-1">
  <Label class="mb-2 ml-1" for="metric">Metric:</Label>

  <select
    class={displayStore.form.base + " grow default p-2.5 text-sm rounded-lg"}      
    name="metric"
    bind:value={metricId}
  >
    <option selected value="">Please select a metric</option>
    {#each $_metrics as metric}
      <option value={metric.id}>
        {metric.name}
      </option>
    {/each}
  </select>
</div>
