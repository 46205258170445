<script lang="ts">
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { CheckIcon } from "@rgossiaux/svelte-heroicons/solid";

  export let isValid: boolean
  export let isSubmitting : boolean

  export let vLabel: string = "Confirm"
  export let xOnConfirm : Function = () => {}
  export let xLabel : string = "Cancel"
  export let xConfirmType = "undefined"
  export let icon = "Xicon"
  export let vType : "base" | "success" = "base"

  let dialogConfirm

</script>

<div class="bloc-cancel-confirm flex items-center justify-end mt-4 ">
  <DeleteButton
    label={xLabel}
    icon={icon}
    type="button"
    confirmDialog={dialogConfirm}
  >
    <DialogConfirmAction
      bind:this={dialogConfirm}
      type={xConfirmType}
      actionOnYes={() => xOnConfirm()}
    />
  </DeleteButton>

  <button
    type="submit"
    disabled={vType == "success" ? (!isValid && isSubmitting) : undefined}
    class={`normal-case btn btn-sm gap-2 btn-success rounded-xl text-success-content
    ${isSubmitting ? "loading btn-disabled " : ""}`}
  >
    <CheckIcon class="w-4 h-4" />
    {isSubmitting ? "Loading..." : vLabel}
  </button>
</div>

<style>
  .bloc-cancel-confirm{
    margin-top:auto;
  }
</style>