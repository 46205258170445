<script lang="ts">
  import { get } from "svelte/store";
  import { authentication } from "@utils/keycloak";
  import type { ExtendedRolesType } from "@utils/decorators";
  import { AnyRoles } from "@utils/decorators";

  export let roles: ExtendedRolesType[] = [AnyRoles.ALL];
  export let not: boolean = false;
  export let forceDisplay: boolean = false; //For debug purposes only

  const isAllowed = () => {
    return not
      ? !roles.includes(get(authentication).role) &&
          !roles.includes(AnyRoles.ALL)
      : roles.includes(get(authentication).role) ||
          roles.includes(AnyRoles.ALL);
  };
</script>

{#if isAllowed() || forceDisplay}
  <slot />
{/if}
