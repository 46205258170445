<script lang="ts">
  export let coord = null;
  export let dismissOnClickOutside: boolean = true;
  export let fullHeight: boolean = false;
  let dialog: HTMLDialogElement;

  export function d() {
    return dialog;
  }

  export function show(c) {
    coord = c;
    if (coord && coord.y >= 700) coord.y = coord.y - 120;
    dialog.showModal();
    if (dismissOnClickOutside) {
      dialog.addEventListener("click", onClick);
    }
  }

  export function close() {
    dialog.close();
  }

  function onClick(event) {
    if (event.target === dialog) {
      // nodeName = "";
      dialog.close();
    }
  }
</script>

<dialog
  bind:this={dialog}
  class={fullHeight ? "h-full" : ""}
  style={coord ? `margin-top:${coord.y}px; margin-left:${coord.x}px` : ""}
>
  <slot syle={"margin-top:8px; margin-bottom:8px"} />
</dialog>

<style>
  dialog {
    @apply bg-base-300 min-w-[224px] drop-shadow-[0_6px_6px_rgba(0,0,0,0.15)] text-sm text-base-content font-medium absolute rounded-lg p-0 overflow-hidden;
  }
  dialog::backdrop {
    @apply bg-black bg-opacity-25;
  }
</style>
