import type { KeycloakWithRole } from "./keycloak";
import {
  setWSStatus,
  setMessages,
  ServerStatus,
  appendMessage,
} from "@stores/notificationStore";

let ws: WebSocket = null;

export const initWebsocket = (auth: KeycloakWithRole) => {
  console.log(auth.profile.username);
  ws = new WebSocket(`ws://localhost:8000/ws/${auth.token}`);
  // ws = new WebSocket(`ws://localhost:8000/ws`);
  // ws.addEventListener("open", (event) => {
  //   ws.send(auth.profile.username);
  // });

  ws.onmessage = function (event) {
    console.log("message received", event);
    appendMessage(event.data);
  };

  ws.onopen = function (e) {
    //ws.send("Websocket initiated");
    console.log("Websocket initiated");
    setWSStatus(ServerStatus.Healthy);
    setMessages([]);
  };

  ws.onerror = function (error) {
    // console.log(error);
    console.log("error", `Websocket error: ${JSON.stringify(error)}`);
    setWSStatus(ServerStatus.Error);
  };

  ws.onclose = function (e) {
    console.log(`CLOSING WEB  SOCKET`);
    // console.log(e);
    setWSStatus(ServerStatus.Closed);
  };
};

export function closeWebsocket() {
  console.log(`TRIGGER CLOSING WEB SOCKET`);
  ws.close(1000, "User shutdown");
  setWSStatus(ServerStatus.ManuallyClosed);
}
