<script lang="ts">
  export let zoomIn
  export let zoomOut
</script>

<button
  class={`${
    open ? "" : "text-opacity-90"
  } rounded-t-xl gap-2 normal-case btn btn-sm`}
  style="border-bottom-left-radius:0rem; border-bottom-right-radius:0rem"
  on:click= {zoomIn()}>
  +
</button>
<button
  class={`${
    open ? "" : "text-opacity-90"
  } rounded-t-xl gap-2 normal-case btn btn-sm`}
  style="border-bottom-left-radius:0rem; border-bottom-right-radius:0rem"
  on:click= {zoomOut()}>
  -
</button>
