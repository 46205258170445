<script>
  import { getCtx } from "../context";
  import { supportingStore } from "@stores/supportingstore";
  import { deleteEnvProcess } from "@utils/supporting/envProcess";
  import { formatAtomicProperty } from "../utils";
  import { dataStore } from "@stores/datastore";
  import GridAction from "../GridAction.svelte";

  /**
   * Dialog state and methods
   */
  const { metricFormDialog, editModeOn } = getCtx();
  const { _envProcess, _selectedEnvProcess, _metrics } = supportingStore;
  const { _nodes } = dataStore;

  const showEditEnvProcess = (ep) => {
    _selectedEnvProcess.set(ep);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Rate Modifier</th>
        <th>Metric Name</th>
        <th>Input Component</th>
        <th>Ouput Component</th>
        <th>Notes</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_envProcess as ep}
        <tr>
          <th>{formatAtomicProperty(ep.name)}</th>
          <td>{formatAtomicProperty(ep.type)}</td>
          <td>{formatAtomicProperty(ep.rateModifier, "-")}</td>
          <td
            >{formatAtomicProperty(
              $_metrics.find((m) => (m.id == ep.metric))?.name,
              "-"
            )}</td
          >
          <td>
            {#each ep.input as el, i}
              {$_nodes.find((n) => n.id == el.id).name +
                (i == ep.input.length - 1 ? "" : ", ")}
            {/each}
          </td>
          <td>
            {#each ep.output as el, i}
              {$_nodes.find((n) => n.id == el.id).name +
                (i == ep.input.length - 1 ? "" : ", ")}
            {/each}
          </td>
          <td>{formatAtomicProperty(ep.notes, "-")}</td>
          <GridAction
            onEdit={() => showEditEnvProcess(ep)}
            onDelete={() => deleteEnvProcess(ep.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>
