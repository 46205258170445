<script lang="ts">
  import { PlusCircleIcon as Plus } from "@rgossiaux/svelte-heroicons/solid";
  import { getCtx } from "@components/Actions/CreateConcept/context";

  let ctx = getCtx();
  let { open } = ctx;
  export let label = "New Concept";
</script>

<div class={$$restProps.class} {...$$restProps}>
  <div class="flex justify-center my-2">
    <button
      class="kg-btn-outline"
      on:click={open}
    >
      <Plus class="w-6 h-6" />
      {label}
    </button>
  </div>
</div>
