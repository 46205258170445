// import { writable } from "svelte/store";
// import type { Writable } from "svelte/store";
// import type { InjectionKey } from "@utils/svelte-typed-context";
// import { setContext, getContext } from "@utils/svelte-typed-context";
// import type MetricsManagerDialog from "@components/dialog/MetricsManagerDialog.svelte";
// import type MetricFormDialog from "@components/dialog/MetricsManagerDialog.svelte";

// export const key: InjectionKey<CtxState> = Symbol("metricManagerContext");

// /**
//  * ctx setter
//  */
// type CtxState = {
//   metricsManagerDialog: Writable<MetricsManagerDialog | undefined>;
//   metricFormDialog: Writable<MetricFormDialog | undefined>;
//   editModeOn: Writable<boolean>;
//   selectedSupportingClass: Writable<String | undefined>;
//   setSupportingClass: (value: String) => void;
// };

// let metricsManagerDialog: Writable<MetricsManagerDialog | undefined> =
//   writable(undefined);
// let metricFormDialog: Writable<MetricFormDialog | undefined> =
//   writable(undefined);
// let editModeOn: Writable<boolean> = writable(false);
// let selectedSupportingClass: Writable<String | undefined> =
//   writable(undefined);
// const setSupportingClass = (supportingName: string) =>
//   selectedSupportingClass.set(supportingName);

// export const setCtx = () =>
//   setContext(key, {
//     metricsManagerDialog: metricsManagerDialog,
//     metricFormDialog: metricFormDialog,
//     editModeOn: editModeOn,
//     selectedSupportingClass: selectedSupportingClass,
//     setSupportingClass: setSupportingClass,
//   });

// export const getCtx = () => getContext(key);

export const formatAtomicProperty = (prop: string, alt: string = "None") => {
  return !prop || prop == "" ? alt : prop;
};
