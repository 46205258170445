<script lang="ts" context="module">
  export enum FormType {
    Create,
    Edit
  }
</script>
<script lang="ts" >
  import { nodeTypes } from "@utils/nodeTypes";
  import { dataStore } from "@stores/datastore";  
  import PropertyField from "./PropertyField.svelte";

  const { _selectedNode } = dataStore;
  export let conceptType: string;
  export let formType: FormType;
  let fields = [PropertyField];

  /**
   * Gathers the values from the dynamic field values and returns 
   * an object built out of them.
   *
   * @return {Object} an object built out of the dynamic field values.
   */
  export const getFieldValues = () => {
    let fieldValues = {}
    fields.forEach( (f) => {
      fieldValues[f.getName()] = f.getVal();
    });
    return fieldValues;
  }

</script>
<!--
@component
Dynamic custom property form hosting fields generated from property definitions
in nodeTypes.ts. 
-->
{#if nodeTypes[conceptType]?.properties}
  <div data-testid="custom-property-fields-container" {...$$restProps}>
    {#each nodeTypes[conceptType].properties as prop, index} 
      {#if (conceptType == "create" && prop.mandatory && prop.field?.type == 'select') }  
        <PropertyField 
          bind:this={fields[index]}
          name={prop.name} 
          label={prop.label} 
          fieldType={prop.field?.type}
          fieldValues={prop.field?.values} 
          required={prop.mandatory}
          />    
          {:else}   
          <PropertyField 
          bind:this={fields[index]}
          required={prop.mandatory}
          name={prop.name} 
          label={prop.label} 
          value={$_selectedNode[prop.name]}           
          fieldType={prop.field?.type} 
          fieldValues={prop.field?.values} />               
      {/if}      
    {/each}
  </div>
{/if}

