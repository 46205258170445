<script lang="ts">
  // TODO: refact: make a generic component for buttons
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";

  import FormCreationButtons from "../FormCreationButtons.svelte";
  import { Label, Helper, Textarea, Input, MultiSelect } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import { getCtx } from "../context";
  import { type Stakeholder, type ParameterDefinition } from "@client";
  import {
    createParameterDefinition,
    updateParameterDefinition,
    isNameAlreadyTaken,
  } from "@utils/supporting/parameterDefinition";

  const { _selectedParameterDefinition, _stakeholders, _unitsOfMeasure } =
    supportingStore;
  let { metricFormDialog, editModeOn } = getCtx();

  // Associated stakeholders
  let stakeholders;
  // Stakeholder properties to be used as Select label/value
  const itemId = "id";
  const label = "name";
  let unit;

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields("stakeholders", val);
  };

  const handleUnitChange = (e) => {
    let val;
    if (e.detail) {
      val = e.detail.id;
      setFields("unit_id", val);
    }
  };

  // TODO: erase this when schema is fixed, this is a workaround
  // cos we cannot add the unit object to the param schema
  const getUnitFromId = (id: number) => {
    let u = $_unitsOfMeasure.filter(function (u) {
      return u.id == id;
    });
    if (u?.length == 1) return u[0];
  };

  /**
   * Form Types, methods, schema and validations
   */
  const {
    form,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
    setFields,
  } = createForm<Stakeholder>({
    onSubmit: (values) => {
      if (!$editModeOn) {
        createParameterDefinition(values);
        // clear form
        handleFormReset(emptyParam);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateParameterDefinition($_selectedParameterDefinition.id, values);
        // clear form
        handleFormReset(emptyParam);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        description: string[];
        unit_id: string[];
      } = {
        name: [],
        description: [],
        unit_id: [],
      };
      if (!values.name) errors.name.push("Name is required.");
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedParameterDefinition &&
          $_selectedParameterDefinition.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (!values.description)
        errors.description.push("Description is required.");
      if (!values.unit_id) errors.unit_id.push("Unit of measure is required.");

      return errors;
    },
    extend: [reporter],
  });

  const handleFormReset = (p: ParameterDefinition): boolean => {
    setInitialValues(p);
    stakeholders = p.stakeholders;
    unit = getUnitFromId(p.unit_id);
    // setFields("unit", p.unit);
    setFields("unit_id", p.unit_id);
    reset();
    return true;
  };

  const emptyParam = {
    unit_id: [],
    name: "",
    stakeholders: [],
    description: "",
  } as ParameterDefinition;

  $: $editModeOn &&
    $_selectedParameterDefinition &&
    handleFormReset($_selectedParameterDefinition);
  $: !$editModeOn && handleFormReset(emptyParam);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn
      ? "Update parameter definition"
      : "Create parameter definition"}
  </h1>
  <div class="flex flex-col items-start justify-start gap-2">
    <!-- NAME - mandatory -->
    <div class="w-full">
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type parameter definition name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- UNIT -->
    <div class="basis-1/2">
      <MultiSelect
        items={$_unitsOfMeasure}
        placeholder="Select associated unit"
        initValues={$editModeOn ? unit : null}
        handleSelectChange={handleUnitChange}
        {itemId}
        {label}
        multiple={false}
        name="unit_id"
        componentLabel="Unit"
        requiredMessage="Unit is required"
        required
      />
    </div>

    <!-- DESCRIPTION - mandatory -->
    <div class="w-full">
      <Label for="description" class="mt-4 mb-2">Description</Label>
      <Textarea
        class="mt-2"
        placeholder="Type description here"
        id="description"
        name="description"
        required
        color={"base"}
      />
      <ValidationMessage for="description" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- MEASURING METHOD - non mandatory-->
    <div class="w-full">
      <Label for="measuring_method" class="mt-4 mb-2">Measuring method</Label>
      <Textarea
        class="mt-2"
        placeholder="Type measuring method here"
        id="measuring_method"
        name="measuring_method"
        color="base"
      />
    </div>

    <div>
      <MultiSelect
        items={$_stakeholders}
        placeholder="Select associated parameter definitions"
        initValues={stakeholders}
        {handleSelectChange}
        {itemId}
        {label}
        name="stakeholders"
        componentLabel="Data providers"
      />
    </div>

    <!-- DATA AVAILABILITY - non mandatory -->
    <div class="w-full">
      <Label for="data_availability" class="mt-4 mb-2">Data availability</Label>
      <Textarea
        class="mt-2"
        placeholder="Type data availability here"
        id="data_availability"
        name="data_availability"
        color="base"
      />
    </div>

    <FormCreationButtons
      onClose={() => handleFormReset(emptyParam)}
      isValid={$isValid}
      isSubmitting={$isSubmitting}
      create={!$editModeOn}
    />
  </div>
</form>
