/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParameterDefinition } from '../models/ParameterDefinition';
import type { Stakeholder } from '../models/Stakeholder';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParameterDefinitionsService {

    /**
     * Get Parameter Definitions
     * @returns ParameterDefinition Successful Response
     * @throws ApiError
     */
    public static getParameterDefinitionsApiV1ParameterDefinitionsGet(): CancelablePromise<Array<ParameterDefinition>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/parameter-definitions/',
        });
    }

    /**
     * Create Parameter Definition
     * @param requestBody
     * @returns ParameterDefinition Successful Response
     * @throws ApiError
     */
    public static createParameterDefinitionApiV1ParameterDefinitionsPost(
        requestBody: ParameterDefinition,
    ): CancelablePromise<ParameterDefinition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/parameter-definitions/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Parameter Definition Data Providers
     * @returns Stakeholder Successful Response
     * @throws ApiError
     */
    public static getParameterDefinitionDataProvidersApiV1ParameterDefinitionsIdDataProvidersGet(): CancelablePromise<Array<Stakeholder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/parameter-definitions/{id}/data-providers',
        });
    }

    /**
     * Update Parameter Definition Data Providers
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateParameterDefinitionDataProvidersApiV1ParameterDefinitionsIdDataProvidersPatch(
        id: number,
        requestBody: Array<Stakeholder>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/parameter-definitions/{id}/data-providers',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Parameter Definition
     * @param id
     * @param requestBody
     * @returns ParameterDefinition Successful Response
     * @throws ApiError
     */
    public static updateParameterDefinitionApiV1ParameterDefinitionsIdPut(
        id: number,
        requestBody: ParameterDefinition,
    ): CancelablePromise<ParameterDefinition> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/parameter-definitions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Parameter Definition
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteParameterDefinitionApiV1ParameterDefinitionsIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/parameter-definitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
