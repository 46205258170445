<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";
  import Pastille from "@components/icons/Pastille.svelte";
  import { displayStore } from "@stores/displaystore";
  import {
    Popover,
    PopoverButton,
    PopoverPanel,
  } from "@rgossiaux/svelte-headlessui";
  import { createPopperActions } from "svelte-popperjs";
  import {
    ChevronDownIcon,
    ChevronUpIcon,
  } from "@rgossiaux/svelte-heroicons/solid";

  const [popperRef, popperContent] = createPopperActions();

  const popperOptions = {
    placement: "top-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [12, 12] } }],
  };
  let typeNames = Object.keys(nodeTypes);
</script>

<Popover let:open>
  <PopoverButton
    class={`${
      open ? "" : "text-opacity-90"
    } rounded-t-xl gap-2 normal-case btn btn-sm`}
    style="border-bottom-left-radius:0rem; border-bottom-right-radius:0rem"
    use={[popperRef]}
  >
    {`Legend`}
    {#if open}
      <ChevronDownIcon class="w-6 h-6" />
    {:else}
      <ChevronUpIcon class="w-6 h-6" />
    {/if}
  </PopoverButton>

  <PopoverPanel use={[[popperContent, popperOptions]]} class="z-20">
    <div
      class="flex flex-row flex-wrap justify-around p-4 rounded-md bg-base-200 w-96"
    >
      {#each typeNames as type}
        <div class="ml-2">
          <Pastille {type} />
          {nodeTypes[type].label}
        </div>
      {/each}
    </div>
  </PopoverPanel>
</Popover>
