<script lang="ts">
  import PartMenu from "@components/dialog/PartMenu.svelte";
  import { dataStore } from "@stores/datastore";
  import { can, Roles } from "@utils/decorators";
  import { createPart, aggregateParentsOfNode } from "@utils/aggregate";
  import NavigateWithConfirm from "@components/AccessControl/NavigateWithConfirm.svelte";

  const { _selectedNode, _nodes } = dataStore;

  let selectedPart;
  let menu;

  const getNodeFromId = (id: number) => {
    return $_nodes.find((n) => n.id === id);
  };

  function showContextMenu(e: PointerEvent) {
    menu.show(selectedPart, { x: e.x, y: e.y });
  }

  const restrictedShowContextMenu = can(
    [Roles.DEV, Roles.CONCEPT],
    showContextMenu
  );
</script>

<!--
@component
Section property section. 
-->

<PartMenu bind:this={menu} />
{#if $_selectedNode}
  <ul>
    {#each $_selectedNode.aggregate as part}
      <li
        class="flex flex-row"
        on:contextmenu|preventDefault={(e) => {
          selectedPart = part;
          restrictedShowContextMenu(e);
        }}
      >
        <NavigateWithConfirm
          class="hover:underline hover:cursor-pointer"
          onConfirm={() =>
            dataStore.setSelectedNode(getNodeFromId(part.nodeId))}
        >
          {getNodeFromId(part.nodeId).name}
        </NavigateWithConfirm>
      </li>
    {/each}
  </ul>
{/if}
