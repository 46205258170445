<script lang="ts">
  import {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  } from "@rgossiaux/svelte-headlessui";
  import { displayStore } from "@stores/displaystore";
  import type { WeightMode } from "@stores/displaystore";
  import { find, propEq } from "rambda";
  import Check from "./Check.svelte";

  let wMode = displayStore.wMode;

  let weightModeOptions = [
    {
      value: "none",
      label: "None",
      description:
        "Concepts weight is the same for all concepts.",
    },
    {
      value: "stories",
      label: "Stories",
      description:
        "Concepts weight is based on the number of stories it belongs to.",
    },
    {
      value: "allConn",
      label: "Connections",
      description:
        "Concepts weight is based on the total number of its connections.",
    },
    {
      value: "inConn",
      label: "Incoming connections",
      description:
        "Concepts weight is based on the number of its incoming connections.",
    },
    {
      value: "outConn",
      label: "Outcoming connections",
      description:
        "Concepts weight is based on the number of its outcoming connections.",
    },
    {
      value: "attachments",
      label: "Attachments",
      description:
        "Concepts weight is based on the total number of its attachments.",
    },
  ];

  type Option = {
    value: WeightMode;
    label: string;
    description: string;
  };

  let selected: Option = find(propEq("value", wMode))(weightModeOptions);

  $: selected && displayStore.setWMode(selected.value);
</script>

<div class="px-2 py-2">
  <RadioGroup
    value={selected}
    on:change={(e) => {
      selected = e.detail;
      close();
    }}
  >
    <RadioGroupLabel class="sr-only">Graph layout</RadioGroupLabel>
    <div class="space-y-2">
      {#each weightModeOptions as option (option.value)}
        <RadioGroupOption
          value={option}
          class={({ active, checked }) =>
            `${
              active
                ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
                : ""
            }
        ${checked ? "bg-sky-900 bg-opacity-75 text-white" : "bg-white"}
          relative rounded-lg shadow-md px-2 py-2 cursor-pointer flex focus:outline-none`}
          let:checked
        >
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
              <div class="text-sm">
                <RadioGroupLabel
                  as="p"
                  class={`font-medium  ${
                    checked ? "text-white" : "text-gray-900"
                  }`}
                >
                  {option.label}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  class={`inline ${checked ? "text-sky-100" : "text-gray-500"}`}
                >
                  <span>
                    {option.description}
                  </span>
                </RadioGroupDescription>
              </div>
            </div>
            {#if checked}
              <div class="flex-shrink-0 text-white">
                <Check />
              </div>
            {/if}
          </div>
        </RadioGroupOption>
      {/each}
    </div>
  </RadioGroup>
</div>
