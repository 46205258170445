<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import Resources from "@components/Editors/Resources/index.svelte";
  import CreateResource from "@components/Actions/CreateResource/Trigger.svelte";
  import NumberedPastille from "@components/Editors/NumberedPastille.svelte";
  import { displayOrders } from "@utils/categories";
  import TypeNodesList from "./TypeNodesList.svelte";
  import TabButton from "../Tabs/TabButton.svelte";

  import { getCtx as getResourceCtx } from "@components/Actions/CreateResource/context";
  import AddNode from "./addNode.svelte";
  import IncludedConceptIcon from "@components/icons/IncludedConceptIcon.svelte";
  import {
    LinkIcon as Link,
    PaperClipIcon as PaperClip,
  } from "@rgossiaux/svelte-heroicons/outline";
  import Divider from "@components/Core/components/Divider.svelte";

  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";

  import { Popover } from "@core-ui";

  let { resources } = getResourceCtx();

  const { _selectedStory } = dataStore;
  
  const popup = [
    { tab: "tab-attachments", title: "Attachments", placement: "top" },
    { tab: "tab-related", title: "Include concept", placement: "top" }
  ];

  type TabButtonId = "related" | "attachments";
  const defaultActiveButtonId:TabButtonId = "attachments";
  let activeButtonId: string = defaultActiveButtonId as string;
  let activeStoryId: int;

  /**
   * Shows the tab content panel associated with given key and hides
   * the other ones. Styles the associated tab button with the
   * "active-button" class.
   *
   * @param {string} id: the id of the tab content panel to show.
   */
  const onTabClicked = (id: TabButtonId) => {
    let elems = document.getElementsByClassName("tabcontent");

    Array.prototype.forEach.call(elems, function (elem) {
      elem.style.display = "none";
    });
    document.getElementById(id)?.style.display = "block";
    activeButtonId = id;
  };

  const onSelectedStoryChange = (newSelectedStory) => {
    if (newSelectedStory){
      if (activeStoryId != newSelectedStory.id){
        onTabClicked(defaultActiveButtonId);
      }
      activeStoryId = newSelectedStory.id;
    }
  };

  $: _selectedStory.subscribe(onSelectedStoryChange);
</script>

{#each popup as { tab, title, placement }}
  <Popover
    triggeredBy={`#${tab}`}
    defaultClass="font-semibold font-light z-20 p-2 bg-base-100  rounded-lg"
    {placement}
    arrow={true}
  >
    <div class="grid bg-base-100">{title}</div>
  </Popover>
{/each}

<!--
@component
Tabbed panel component for editors.
-->
{#if $_selectedStory}
  <div class="overflow-hidden flex flex-col h-full w-full">
    <div class="mb-4 border-b border-gray-200 tabs dark:border-gray-700">
      <TabButton
        {activeButtonId}
        tabId="attachments"
        {onTabClicked}
        title=" "
        id="tab-attachments"
      >
        <PaperClip class="w-6 h-6 mr-2" />
        <NumberedPastille nbr={$resources.length} />
      </TabButton>

      <TabButton 
        {activeButtonId} 
        tabId="related" 
        {onTabClicked} 
        title=" "
        id="tab-related"
      >
        <IncludedConceptIcon class="w-6 h-6 mr-2" />
        <NumberedPastille nbr={$_selectedStory.nodes.length} />
      </TabButton>
    </div>
    <div id="tabs" class="overflow-hidden flex flex-col h-full w-full">
      <div
        id="attachments"
        style="display: block;"
        class="tabcontent"
        role="tabpanel"
        aria-labelledby="attachments-tab"
      >
        <div class="flex items-center justify-between">
          <span class="text-xl font-bold">Evidences</span>
          <CreateResource />
        </div>
        <Resources />
      </div>
      <div
        id="related"
        class="tabcontent"
        role="tabpanel"
        aria-labelledby="related-tab"
      >
        <Can roles={[Roles.DEV, Roles.CONCEPT]}>
          <div class="flex mb-4 text-xl font-bold">Include a concept</div>
          <AddNode />
          <Divider class="mt-4"/>
        </Can>
        <div class="flex mb-4 text-xl font-bold">Included concepts</div>
        {#each displayOrders as display_order, index}
          {#each display_order.nodeTypes as type}
            <TypeNodesList {type} {index} compact={true} />
          {/each}
        {/each}
      </div>
    </div>
  </div>
{/if}

<style>
  .active-button {
    border-bottom: 3px solid rgb(21, 207, 207);
  }

  .active-button span {
    @apply text-base-content;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    overflow: auto;
  }
</style>
