<script lang="ts">
  export let nbr: number;
</script>

<span
  {...$$props}
  class={`flex items-center justify-center pastille-nbr bg-base-300 ${$$props.class}`}
  >{nbr}</span
>

<style>
  .pastille-nbr {
    border-radius: 16px;
    padding: 0.08rem 0.5rem;
    text-align: center;
  }

  .active-button .pastille-nbr {
    @apply bg-neutral-content;
  }
</style>
