<script lang="ts">
  import Story from "@components/tree/Story.svelte";
  import type { StoriesHierarchy } from "@utils/story";
  import {
    ChevronDoubleDownIcon as DChevronD,
    ChevronDoubleRightIcon as DChevronR,
    XIcon as ChevronUnfold,
  } from "@rgossiaux/svelte-heroicons/solid";

  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import ButtonCreateGraph from "@components/ButtonCreateGraph.svelte";
  import NumberedPastille from "@components/Editors/NumberedPastille.svelte";

  export let isScenario: boolean;
  export let storyHierarchy: StoriesHierarchy[];
  export let displayNewButton: boolean = true;
  export let displayPastille: boolean = false;

  let display = true;
  let filteredGraphs;
  let filteredCategory

  const setFilteredGraphs = () => {

    filteredCategory = storyHierarchy.sort((a, b) => {
        const nameA = a.category.toUpperCase();
        const nameB = b.category.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      }).map(e => e.category)
      
    filteredCategory=filteredCategory.filter((v,i) => filteredCategory.indexOf(v) === i)
                                      .map(category => {
                                        const obj = { category, stories: []}
                                        return obj
                                      }); 

    storyHierarchy
      .filter((el) => el.isScenario == isScenario)
      .forEach(sto => {
        filteredCategory.forEach(cat=> {
          if(cat.category==sto.category)
            cat.stories.push(sto)
        })
      })

    filteredGraphs = storyHierarchy
      .filter((el) => el.isScenario == isScenario)
      .sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB) {
          comparison = 1;
        } else if (nameA < nameB) {
          comparison = -1;
        }
        return comparison;
      });
  };

  $: storyHierarchy && setFilteredGraphs();
</script>

<div data-testid="stories-tree" {...$$restProps}>
  <div
    class="flex flex-row items-center justify-start w-full h-10 gap-2 mt-3 mb-1 cursor-pointer"
  >
    <span on:click={() => (display = !display)}>
      {#if storyHierarchy.length === 0}
        <ChevronUnfold class="w-6 h-6 bg-base-300" />
      {:else if display}
        <DChevronD class="w-6 h-6" />
      {:else}
        <DChevronR class="w-6 h-6" />
      {/if}
    </span>
    <div
      class="flex items-center flex-1 gap-2 text-xl font-bold"
      on:click={() => (display = !display)}
    >
      {isScenario ? "Scenarios" : "Stories"}
      {#if displayPastille}
        <NumberedPastille nbr={filteredGraphs.length} />
      {/if}
    </div>
    {#if displayNewButton}
      <Can roles={[Roles.DEV, Roles.STORY]}>
        <ButtonCreateGraph
          label={isScenario ? "New scenario" : "New story"}
          {isScenario}
        />
      </Can>
    {/if}
  </div>
  {#if display}
    <ul class="flex-none">
      {#each filteredCategory as cat}
        <li>{cat.category}:</li>
        {#each cat.stories as story}
          <Story
            {story}
            {isScenario}
            mode={displayPastille ? "node" : "general"}
          />
        {/each}
      {/each}
      <!-- {JSON.stringify(filteredCategory)} -->
      <!-- {#each filteredGraphs as story}
        <Story
          {story}
          {isScenario}
          mode={displayPastille ? "node" : "general"}
        />
      {/each} -->
    </ul>
  {/if}
</div>
