<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteStakeholder } from "@utils/supporting/stakeholder";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"

  const { metricFormDialog, editModeOn } = getCtx();
  const { _stakeholders, _selectedStakeholder } = supportingStore;

  const showEditStateMetric = (sh) => {
    _selectedStakeholder.set(sh);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Elliot types</th>
        <th>Notes</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_stakeholders as sh}
        <tr>
          <th>{formatAtomicProperty(sh.name)}</th>
          <td>{sh.elliot_types == [] ? "none" : sh.elliot_types}</td>       
          <td>{formatAtomicProperty(sh.notes)}</td>
          <GridAction
            onEdit={() => showEditStateMetric(sh)}
            onDelete = {() => deleteStakeholder(sh.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>