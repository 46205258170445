<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import { getNodeNameById } from "@utils/node";

  let { _selectedNode } = dataStore;
  const noPressureAgentMessage = "No data.";
</script>
<!--
@component
Displays pressure type concept agent if any.
-->
{#if $_selectedNode.type == "pressure"}
  <div>
    <span >Agent:</span>
    <span class="font-bold">
      {#if $_selectedNode.pressure_agent_id}
        {getNodeNameById($_selectedNode.pressure_agent_id)}
      {:else}
        {noPressureAgentMessage}
      {/if}
    </span>
  </div>
{/if} 
