<script lang="ts">
  import classNames from "classnames";
  export let rounded = true;
  export let withShadow = true;
  export let variant: "base" | "primary" | "secondary" | "contrast" = "base";

  const sharedClass = "flex p-3 w-full items-center justify-start gap-1";
  const colorBaseClass = `bg-base-100 text-base-content`; //css not compiled yet
  const colorContrastClass = `bg-base-300 text-base-content dark:bg-neutral dark:text-neutral-content`; //css not compiled yet
  const colorPrimaryClass = `bg-primary text-primary-content`; //css not compiled yet
  const colorSecondaryClass = `bg-secondary text-secondary-content`; //css not compiled yet
  const shadowClass = `shadow-md`;
  let boxCompiledClass = classNames(
    sharedClass,
    { "rounded-lg": rounded },
    withShadow && shadowClass,
    variant == "base" && colorBaseClass,
    variant == "primary" && colorPrimaryClass,
    variant == "secondary" && colorSecondaryClass,
    variant == "contrast" && colorContrastClass,
    $$props.class
  );
</script>

<div class={boxCompiledClass}>
  <slot name="left"><!-- optional fallback --></slot>
  <slot><!-- optional fallback --></slot>
  <slot name="right"><!-- optional fallback --></slot>
</div>
