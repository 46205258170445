<script lang="ts">
/*
 * @component
 * Used to display both form and manager for all supporting classes.
 */
import { Dialog } from "@core-ui";

  let dialog;

  export const close = () => {
    dialog.close();
  };

  export const show = () => {
    dialog.show();
  };
</script>

<Dialog bind:this={dialog} dismissOnClickOutside={false}>
  <slot />
</Dialog>
