<script lang="ts">
  // TODO: refact: metric object should be mapped in concept model 
  // and served as a propert by the Web service. Then, remove
  // the metric name handling logic from the component.
  import { dataStore } from "@stores/datastore";
  import { supportingStore } from "@stores/supportingstore";

  let { _selectedNode } = dataStore;
  let { _metrics } = supportingStore;
  let metricName: string;

  const getMetricName = () => {
    let mName = "No data."
    if ($_selectedNode.metric) {
      let metric = $_metrics.filter((m) => m.id == $_selectedNode.metric);
      if (metric.length > 0) {
        mName = metric[0].name;
      } 
    }
    return mName;
  };

  const updateMetricName = () => {
    metricName = getMetricName();
  };

  $: $_selectedNode && updateMetricName();
</script>

<div>
  <span>Metrics:</span>
  <span class="font-bold">{metricName}</span>    
</div>
