<script lang="ts">
  import { CheckIcon, XIcon } from "@rgossiaux/svelte-heroicons/solid";
  import ArrowPath from "./ArrowPath.svelte";
  export let status: string = null;
</script>

{#if status == "adopted"}
  <CheckIcon class="w-3 h-3" fill="fill" />
{:else if status == "rejected"}
  <XIcon class="w-3 h-3 rounded-full" fill="fill" />
{:else if status == "pending"}
  <ArrowPath class="w-3 h-3 rounded-full stroke-base-300" />
{/if}
