<script>
  import { getCtx } from "../context";
  import { supportingStore } from "@stores/supportingstore";
  import { deleteMetric } from "@utils/supporting/metric";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"

  /**
   * Dialog state and methods
   */
  const { metricFormDialog, editModeOn } = getCtx();
  const { _metrics, _selectedMetric } = supportingStore;

  const showEditMetric = (metric) => {
    _selectedMetric.set(metric);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Unit of measure</th>
        <th>Quantitative/qualitative</th>
        <th>Algorithm</th>
        <th>Parameter definitions</th>
        <th>Notes</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_metrics as metric}
        <tr>
          <th>{formatAtomicProperty(metric.name)}</th>
          <td>{formatAtomicProperty(metric.unit?.name)}</td>
          <td>{formatAtomicProperty(metric.isQualitative ?  "Qualitative" : "Quantitative") }</td>
          <td>{formatAtomicProperty(metric?.algorithm?.name)}</td>
          <td>
            {#each metric.parameter_definitions as param, index}
              {param.name}{#if index < metric.parameter_definitions.length - 1}, {/if}                  
            {/each}
          </td>
          <td>{formatAtomicProperty(metric.notes)}</td>
          <GridAction
            onEdit={() => showEditMetric(metric)}
            onDelete = {() => deleteMetric(metric.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>