import { toast } from "@zerodevx/svelte-toast";

let theme = {
  "--toastWidth": "100%",
};

export const successToast = (m: string) =>
  toast.push(m, {
    theme: {
      ...theme,
      "--toastBackground": "#48BB78",
      "--toastBarBackground": "#2F855A",
    },
  });

export const warningToast = (m: string) =>
  toast.push(m, {
    theme: {
      ...theme,
      "--toastBackground": "#F97316",
      "--toastBarBackground": "brown",
    },
  });

export const failureToast = (m: string) =>
  toast.push(m, {
    theme: {
      ...theme,
      "--toastBackground": "#F56565",
      "--toastBarBackground": "#C53030",
    },
  });
