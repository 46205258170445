<script lang="ts" context="module">
  export enum FormType {
    Create,
    Edit,
  }
</script>

<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import { nodeTypes } from "@utils/nodeTypes";
  import PropertyInfoBox from "./Header/NodePreview/PropertyInfoBox.svelte";

  const { _selectedNode } = dataStore;
</script>

<!--
@component
Property/supporting class section for a concept. 
Displays: 
-concept type custom properties 
-supporting classes
-->
{#if nodeTypes[$_selectedNode.type]?.properties}
  <PropertyInfoBox />
{/if}
