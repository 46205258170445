<script lang="ts">
  import { Menu, MenuItem, MenuItems } from "@rgossiaux/svelte-headlessui";
  import ThemeSwitcher from "@components/Core/ThemeSwitcher.svelte";
  import { authentication } from "@utils/keycloak";
  import AvatarMenuButton from "@components/Core/UserDisplay/AvatarMenuButton.svelte";
  import { closeWebsocket } from "@utils/websocket";

  function classNames(...classes: (string | false | null | undefined)[]) {
    return classes.filter(Boolean).join(" ");
  }

  function resolveClass({
    active,
    disabled,
  }: {
    active: boolean;
    disabled: boolean;
  }) {
    return classNames(
      "flex justify-between w-full px-4 py-2 text-sm leading-5 text-left",
      disabled && "cursor-not-allowed opacity-50"
    );
  }
</script>

{#if $authentication}
  <Menu style="height: 32px">
    <AvatarMenuButton />
    <MenuItems
      class="absolute z-20 w-56 mt-2 origin-top-right border divide-y rounded-md shadow-lg outline-none bg-base-100 border-base-200 divide-base-200 right-10"
    >
      <div class="px-4 py-3">
        <p class="text-m font-medium leading-5">
          {$authentication && `${$authentication.profile.username}`}
        </p>
        <p class="text-sm leading-5 truncate">
          {$authentication &&
            `${
              $authentication.role === "Dev" ? "Admin" : $authentication.role
            }`}
        </p>
      </div>

      <MenuItem as="div" class={resolveClass}>
        <ThemeSwitcher withLabel />
      </MenuItem>

      <MenuItem
        as="div"
        on:click={() => {
          $authentication.logout();
          closeWebsocket();
        }}
        class={resolveClass}
      >
        Sign out
      </MenuItem>
    </MenuItems>
  </Menu>
{:else}
  <div>Sign In</div>
{/if}
