/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Status {
    ADOPTED = 'adopted',
    REJECTED = 'rejected',
    PENDING = 'pending',
}
