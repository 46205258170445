<script lang="ts">
  import classNames from "classnames";
  import type { SelectOptionType } from "@components/Core/types";

  export let items: SelectOptionType[] | any[] = [];
  let value: string;
  export let placeholder: string = "Choose option ...";
  export let underline: boolean = false;
  export let size: "sm" | "md" | "lg" = "md";
  export let withLabel: boolean = false;
  export let required: boolean = true;
  export let getId = (a) => {
    let id = "";
    if (a?.value) {
      id = a.value;
    }
    else if (a?.id) {
      id = a.id;
    }
    return id;
  };
  export let setLabel = (a) => a.label;
  export let formatLabel: (
    value: string | number | SelectOptionType
  ) => string | undefined = undefined;

  export let defaultClass: string =
    "text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-base-content focus:border-base-content dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-base-content dark:focus:border-base-content";

  export let underlineClass: string =
    "text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer";

  const common = "block w-full";
  const sizes = {
    sm: "text-sm p-2",
    md: "text-sm p-2.5",
    lg: "text-base py-3 px-4",
  };

  let selectClass;
  $: selectClass = classNames(
    common,
    underline ? underlineClass : defaultClass,
    sizes[size],
    underline && "!px-0",
    $$restProps.class
  );
</script>

<select {...$$restProps} bind:value class={selectClass} {required} on:change on:input>
  <option disabled={required} selected value="">{placeholder}</option>
  {#if withLabel}
    {#each items as item}
      <option value={getId(item)}>
        {setLabel(item)}
      </option>
    {/each}
  {:else}
    {#each items as value}
      <option {value}>
        {#if formatLabel}
          {formatLabel(value)}
        {:else}
          {value}
        {/if}
      </option>
    {/each}
  {/if}
</select>
