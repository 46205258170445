<script lang="ts">
  // your script goes here
  import { getCtx } from "@components/Editors/NodeEditor/context";
  import NodeForm from "./NodeForm.svelte";
  import NodePreview from "./NodePreview.svelte";
  let { editMode } = getCtx();
</script>

{#if $editMode}
  <NodeForm />
{:else}
  <NodePreview />
{/if}
