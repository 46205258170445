import { MetricsService } from "@client";
import type { Metrics, ParameterDefinition } from "@client";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createMetric = (metricData: Metrics) => {
  try {
    let params = metricData.parameter_definitions;
    MetricsService.createMetricApiV1MetricsPost(metricData).then((resp) => {
      notifySuccess("Metric succesfully created!");
      supportingStore.addMetric(resp);
      if (params) {
        updateMetricParameterDefinitions(resp, params);
      }
    });
  } catch (e) {
    notifyFailure(`An error occured while creating metric: ${e.status}-${e.name}:${e.statusText}`);
  }    
};

export const updateMetricParameterDefinitions = async (
  metric: Metrics = null,
  parameter_definitions: ParameterDefinition[]
) => {
  try {
      if (parameter_definitions && parameter_definitions.length>0) {
        MetricsService.updateMetricParametersApiV1MetricsIdParametersPatch(
          metric.id, 
          parameter_definitions
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          notifySuccess("Metric parameter definitions succesfully updated");
          metric.parameter_definitions = parameter_definitions;
          supportingStore.updateSelectedMetric(metric);
        });
      }
      else 
        metric.parameter_definitions = [];
      supportingStore.updateSelectedMetric(metric);
  } catch (e) {
    notifyFailure(`An error occured while updating metric parameter definitions: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateMetric = async (
  metricId: number,
  metric: Metrics = null
) => {
  try {
    MetricsService.updateMetricApiV1MetricsIdPut(
      metricId,
      metric
    ).then(resp => {
      notifySuccess("Metric succesfully created!");
      supportingStore.updateSelectedMetric(resp);
      updateMetricParameterDefinitions(metric, metric.parameter_definitions);
    });    
  } catch (e) {
    notifyFailure(`An error occurred while creating the metric: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deleteMetric = (id: number) => {
  MetricsService.deleteMetricApiV1MetricsIdDelete(id).then(() => {
    supportingStore.removeMetric(id);
  });
};

/**
 * Returns true if the provided name is already taken by an existing Metric.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean true if name is already in use. Else false.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.metrics.some(
      (s: Metrics) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};