<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import { nodeTypes } from "@utils/nodeTypes";
  import type { Edge } from "@utils/formatData";
  import LinkType from "./linkType.svelte";
  const { _selectedNode, _graphData } = dataStore;
  let links = [];
  let sortedLinks = {};
  let sections;
  let directionNodes;
  export let direction = "from";
  let sectionName = direction === "from" ? "sectionfrom" : "sectionto";
  let sectionDir = direction === "from" ? "Incoming" : "Outcoming";

  $: if ($_graphData && $_selectedNode) {
    sections = nodeTypes[$_selectedNode.type][sectionName];
    directionNodes = nodeTypes[$_selectedNode.type][direction];
    links =
      direction === "from"
        ? $_graphData.links
            .filter((l: Edge) => !l.fake)
            .filter((l: Edge) => l.target.id === $_selectedNode.id)
        : $_graphData.links
            .filter((l: Edge) => !l.fake)
            .filter((l: Edge) => l.source.id === $_selectedNode.id);

    for (const [k, value] of Object.entries(nodeTypes)) {
      sortedLinks[k] =
        direction === "from"
          ? links.filter((l: Edge) => l.source.type === k)
          : links.filter((l: Edge) => l.target.type === k);
    }
  }
</script>

{#if $_selectedNode}
  {#each Object.entries(sortedLinks) as [section, links], i}
    <LinkType {section} {direction} {links} nodeType={$_selectedNode.type}/>
  {/each}
{/if}

<style>
  h2 {
    @apply font-bold
    text-xl
    py-4;
  }
  h3 {
    @apply font-bold;
  }
  li {
    @apply pl-2;
  }
</style>
