import { SectorsService } from "@client";
import type { Sector } from "@models/Sector";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createSector = (sector: Sector) => {
  try {
    SectorsService.createSectorApiV1SectorsPost(sector).then((res) => {
      notifySuccess("Sector succesfully created");
      if (sector.stakeholders) {
        SectorsService.updateSectorStakeholdersApiV1SectorsIdStakeholdersPatch(
          res.id,
          sector.stakeholders
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          res.stakeholders = sector.stakeholders;
          supportingStore.addSector(res);
          notifySuccess("Stakeholders succesfully added to the new sector");
        });
      } else {
        res.stakeholders = [];
        supportingStore.addStakeholder(res);
      }
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateSectorStakeholders = async (sector: Sector = null) => {
  try {
    if (sector.stakeholders) {
      SectorsService.updateSectorStakeholdersApiV1SectorsIdStakeholdersPatch(
        sector.id,
        sector.stakeholders
      ).then((res2) => {
        notifySuccess("Sector members succesfully updated");
      });
    } else sector.stakeholders = [];
    supportingStore.updateSelectedSector(sector);
  } catch (e) {
    notifyFailure(
      `An error occured while updating sector members: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};

export const updateSector = async (sectorId: number, sector: Sector = null) => {
  try {
    SectorsService.updateSectorApiV1SectorsIdPut(sectorId, sector).then(
      (res) => {
        notifySuccess("Sector succesfully updated");
        if (sector.stakeholders) {
          updateSectorStakeholders(sector);
        } else {
          res.stakeholders = [];
          supportingStore.updateSelectedSector(res);
        }
      }
    );
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deleteSector = (id: number) => {
  try {
    SectorsService.deleteSectorApiV1SectorsIdDelete(id).then(() => {
      supportingStore.removeSector(id);
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

/**
 * Returns true if the provided name is already taken by an existing sector.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean for if name is already in use.
 */
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.sectors.some(
      (s: Sector) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
