<script lang="ts">
  import Pastille from "@components/icons/Pastille.svelte";
  import type { Edge } from "@utils/formatData";
  import type { NodesWithStories } from "@client";
  import { dataStore } from "@stores/datastore";
  import Fuse from "fuse.js";
  import { displayStore } from "@stores/displaystore";
  import { Divider } from "@core-ui";

  const { _nodes } = dataStore;
  const { _theme } = displayStore;

  type GenericItem = string | {};

  export let inputPlaceholder = "Search all concepts...";

  /** track focus on input, but is also used to show/hide results container
   * TODO: eventually, add `showResults` variable
   */
  let isFocused = false;

  /** use search among docs */
  let query = "";

  /** for debouncing */
  let timer;

  /** To search results because could not find a way so store them
   * inside Map props and $$invalidate DOM elements
   */
  let indexesHits = Object.create(null);
  let indexesMap = new Map();

  let indexesHits2 = Object.create(null);
  let indexesMap2 = new Map();

  const optionsNode = {
    includeScore: true,
    ignoreLocation: true,
    keys: [
      { name: "name", weight: 0.01 },
    ],
    threshold:0.0
  };

  const optionsNode2 = {
    includeScore: true,
    ignoreLocation: true,
    keys: [
      { name: "description", weight: 0.01 },
    ],
    threshold:0.0
  };

  /**
   * Debouncing query and search to avoid
   * main thread bloating
   * @param event
   * @returns {void}
   */
  const debounce = (event: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      isFocused = true;
      query = event.target.value;
    }, 350);
  };

  const onFocus = () => (isFocused = true);
  /**
   * Dispatch on CustomEvent to handle custom
   * hitClick in parent component
   * @param {MouseEvent}event
   * @param {string | Object}item
   */
  function handleHitClick(
    event: MouseEvent,
    indexName: string,
    item: GenericItem
  ) {
    isFocused = false;
    handleNodeHitClick(item, indexName);
    query = indexesMap.get(indexName).formatHitFn(item);
  }

  function handleHitClick2(
    event: MouseEvent,
    indexName: string,
    item: GenericItem
  ) {
    isFocused = false;
    handleNodeHitClick2(item, indexName);
    query = indexesMap2.get(indexName).formatHitFn(item);
  }

  /** hide results container when input is focused*/
  function handleInputKeydown(event) {
    if (event.key === "Escape") {
      isFocused = false;
    }
  }

  /**
   * Hide results container when input is focused
   * @param {any} event need to find type KeyboardEventHandler with Target
   * @param {string} indexName use to discrimate behaviour in dispatched event, should be the indexname used in indexesMap
   * @param {GenericItem} item comes from indexed data
   */
  function handleHitKeydown(event, indexName: string, item: GenericItem) {
    if (event.key === "Enter" && item) {
      isFocused = false;
      handleNodeHitClick(item, indexName);
      query = indexesMap.get(indexName).formatHitFn(item);
    }
    if (event.key === "Escape" && item) {
      isFocused = false;
    }
  }

  function handleHitKeydown2(event, indexName: string, item: GenericItem) {
    if (event.key === "Enter" && item) {
      isFocused = false;
      handleNodeHitClick2(item, indexName);
      query = indexesMap2.get(indexName).formatHitFn(item);
    }
    if (event.key === "Escape" && item) {
      isFocused = false;
    }
  }

  function handleNodeHitClick(
    item: NodesWithStories | Edge,
    indexName: string
  ): void {
    if (indexName === "nodesIndex") {
      let nws = item as NodesWithStories;
      setInputText(customFormatNodeHit(nws));
      dataStore.setSelectedNode(nws);
    }
  }

  function handleNodeHitClick2(
    item: NodesWithStories | Edge,
    indexName: string
  ): void {
    if (indexName === "nodesIndex") {
      let nws = item as NodesWithStories;
      setInputText(customFormatNodeHit2(nws));
      dataStore.setSelectedNode(nws);
    }
  }

  function customFormatNodeHit(item: NodesWithStories): string {
    return `${item?.name} (${item?.type})`;
  }

  function customFormatNodeHit2(item: NodesWithStories): string {
    return `${item?.name} (${item?.type}) desc: ${item?.description}`;
  }

  function runIndexesSearch() {
    if (indexesMap.size > 0) {
      for (const [key, value] of indexesMap) {
        const hits = value["index"].search(query);
        /** Should be OK, but even though the map is updated DOM is not invalidated properly
         * Will open an issue on Svelte repo
         * for now, Object works better in this use case*/
        // indexesMap.set(key, { ...indexesMap.get(key), hits: hits.slice(0, 5) });

          const hitsSorted = hits.sort((a,b) => {
          if(a.item.type === b.item.type){
            if (a.item.name < b.item.name) {
              return -1;
            }
            if (a.item.name > b.item.name) {
              return 1;
            }
            return 0;
          } else {
            if (a.item.type < b.item.type) {
              return -1;
            }
            if (a.item.type > b.item.type) {
              return 1;
            }
            return 0;
          }
        })

        indexesHits[key] = hitsSorted;//.slice(0, 10)
      }
    }
    if (indexesMap2.size > 0) {
      for (const [key, value] of indexesMap2) {
        const hits = value["index"].search(query);
        /** Should be OK, but even though the map is updated DOM is not invalidated properly
         * Will open an issue on Svelte repo
         * for now, Object works better in this use case*/
        // indexesMap.set(key, { ...indexesMap.get(key), hits: hits.slice(0, 5) });

          const hitsSorted = hits.sort((a,b) => {
          if(a.item.type === b.item.type){
            if (a.item.name < b.item.name) {
              return -1;
            }
            if (a.item.name > b.item.name) {
              return 1;
            }
            return 0;
          } else {
            if (a.item.type < b.item.type) {
              return -1;
            }
            if (a.item.type > b.item.type) {
              return 1;
            }
            return 0;
          }
        })

        indexesHits2[key] = hitsSorted;//.slice(0, 10)
      }
    }
  }

  let searchInput;

  export function setInputText(text) {
    searchInput.value = text;
  }

  function mapIndexesConfig<T extends NodesWithStories[]>(
    data: T
  ): Map<string, Object> {
    indexesMap.set("nodesIndex", {
      index: new Fuse(data, optionsNode),
      formatHitFn: customFormatNodeHit,
    });
    /** BUG: TODO: can be avoided, if we find when graphData construct
     *  is done infering NodeObject and LinkObject
     *  With this code, works fin in both cases
     */
    return indexesMap;
  }

  function mapIndexesConfig2<T extends NodesWithStories[]>(
    data: T
  ): Map<string, Object> {
    indexesMap2.set("nodesIndex", {
      index: new Fuse(data, optionsNode2),
      formatHitFn: customFormatNodeHit2,
    });
    /** BUG: TODO: can be avoided, if we find when graphData construct
     *  is done infering NodeObject and LinkObject
     *  With this code, works fin in both cases
     */
    return indexesMap2;
  }

  $: query && runIndexesSearch();
  $: $_nodes && mapIndexesConfig($_nodes) 
  $: $_nodes && mapIndexesConfig2($_nodes);
</script>

<div
  class="absolute flex flex-col w-auto gap-2 px-1 py-1 mt-1
   overflow-auto text-sm font-medium bg-base-content text-white dark:text-base-200 border- border-gray-200 rounded-2xl shadow-lg"
  style="max-height:356px; left:10px; right:10px"
>
  <div class="relative">
    <div
      class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
    >
      <svg
        aria-hidden="true"
        class="w-5 h-5 text-gray-500 dark:text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        /></svg
      >
    </div>
    <input
      type="search"
      name="node-search"
      id="search"
      class={`border-none focus:ring-0 w-full rounded-lg block p-1 pl-10 bg-base-content text-white dark:text-base-200 placeholder-base-100 ${
        $_theme === "dark" ? "" : "cancelButtonDark"
      }`}
      required
      placeholder={inputPlaceholder}
      autocomplete="off"
      on:focus={onFocus}
      on:input={debounce}
      on:keydown={handleInputKeydown}
      bind:this={searchInput}
    />
  </div>
  {#if query.length > 2 && isFocused}
    <Divider
      class="my-0"
      style={`border-color:${$_theme === "dark" ? "#000000" : "#FFFFFF"}`}
    />
    {#each [...indexesMap] as [key, value]}
      <div
        class={`flex-1 overflow-auto scroll-track ${
          $_theme === "dark"
            ? "scroll-thumb-dark scroll-track-dark"
            : "scroll-thumb scroll-track"
        }`}
      >
        {#if indexesHits[key]?.length > 0 && query.length > 2 && isFocused}
          <div class="flex items-center justify-between">
            <div class="text-white dark:text-base-200">
              ({indexesHits[key]?.length} results)
            </div>
          </div>
          <ul
            class="w-full mt-3 mb-5 overflow-auto text-sm font-medium bg-base-content text-white dark:text-base-200 shadow-lg"
          >
            {#each indexesHits[key] as hit}
              <li
                tabindex="0"
                class="w-full px-4 py-2 hover:bg-base-300 hover:text-base-content dark:hover:text-gray-100"
                on:click={(e) => handleHitClick(e, key, hit.item)}
                on:keydown={(e) => handleHitKeydown(e, key, hit.item)}
              >
                <Pastille
                  type={hit.item.type}
                  class="mr-2"
                />{value.formatHitFn(hit.item)}
              </li>
            {/each}
          </ul>
        {:else if query.length > 2 && isFocused}
          <div class="mt-3 italic text-gray-400">No item found.</div>
        {/if}
      </div>
    {/each}
    <Divider
      class="my-0"
      style={`border-color:${$_theme === "dark" ? "#000000" : "#FFFFFF"}`}
    />
    {#each [...indexesMap2] as [key, value]}
      <div
        class={`flex-1 overflow-auto scroll-track ${
          $_theme === "dark"
            ? "scroll-thumb-dark scroll-track-dark"
            : "scroll-thumb scroll-track"
        }`}
      >
        {#if indexesHits2[key]?.length > 0 && query.length > 2 && isFocused}
          <div class="flex items-center justify-between">
            <div class="text-white dark:text-base-200">
              ({indexesHits2[key]?.length} results)
            </div>
          </div>
          <ul
            class="w-full mt-3 mb-5 overflow-auto text-sm font-medium bg-base-content text-white dark:text-base-200 shadow-lg"
          >
            {#each indexesHits2[key] as hit}
              <li
                tabindex="0"
                class="w-full px-4 py-2 hover:bg-base-300 hover:text-base-content dark:hover:text-gray-100"
                on:click={(e) => handleHitClick2(e, key, hit.item)}
                on:keydown={(e) => handleHitKeydown2(e, key, hit.item)}
              >
                <Pastille
                  type={hit.item.type}
                  class="mr-2"
                />{value.formatHitFn(hit.item)}
              </li>
            {/each}
          </ul>
        {:else if query.length > 2 && isFocused}
          <div class="mt-3 italic text-gray-400">No item found.</div>
        {/if}
      </div>
    {/each}
  {/if}
</div>

<style>
  li:last-child {
    @apply border-none;
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
      no-repeat 50% 50%;
    background-size: contain;
    opacity: 0.9;
    cursor: pointer;
  }

  .cancelButtonDark::-webkit-search-cancel-button {
    filter: invert(100%);
  }

  .scroll-thumb::-webkit-scrollbar-thumb {
    background-color: #ffffff;
  }

  .scroll-thumb-dark::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

  .scroll-track::-webkit-scrollbar-track {
    background-color: #52525b;
    border-radius: 8px;
  }

  .scroll-track-dark::-webkit-scrollbar-track {
    background-color: #d4d4d8;
    border-radius: 8px;
  }
</style>
