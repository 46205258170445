<!-- Adapts CommentSection to reflect the mockup design.-->
<script lang="ts">
  import { marked } from "marked";
  import {
    CheckIcon as Check,
    PencilAltIcon,
    ChevronDownIcon as DChevronD,
    ChevronRightIcon as DChevronR,
  } from "@rgossiaux/svelte-heroicons/solid";
  import classNames from "classnames";
  import { dataStore } from "@stores/datastore";
  import {displayStore} from "@stores/displaystore";
  import { updateNode } from "@utils/node";
  import { getCtx } from "./context";
  import DeleteButton from "./edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  export let content;
  let txtArea;
  let regex_crochet_simple = /(\[ |\[$|\[\n)/gm;
  let regex_parenthese = /\(.*\)/gm;
  let display:boolean = false;
  let confirmCancelComment;
  const { _selectedNode } = dataStore;
  const { editComment, setEditComment } = getCtx();

  function setDisplay(val:boolean){display = val}

  function saveComment(e) {
    setEditComment(false);
    updateNode($_selectedNode.id, { comment: content });
  }

  function autoCompleteMarkupLink(e) {
    content = content.replaceAll(regex_crochet_simple, "[]()");
    const ind = content.indexOf("[]()");
    if (ind !== -1) {
      txtArea.focus();
      txtArea.selectionEnd = ind + 1;
    }
  }

  function autoCompleteLink(e) {
    const groupP = content.match(regex_parenthese);
    groupP &&
      groupP.forEach((g) => {
        const p = content.indexOf(g);
        if (p < txtArea.selectionEnd && txtArea.selectionEnd < p + g.length) {
        }
      });
  }

  function keyPress(e) {
    autoCompleteMarkupLink(e);
    autoCompleteLink(e);
  }

  $: $_selectedNode && setDisplay(false);
</script>

<!--
@component
Displays concept comment if any.
-->
<div data-testid="comments-widget" {...$$restProps}>
  <div
    class="flex flex-row items-end w-full h-10 gap-2 pt-6 pb-3 cursor-pointer"
  >
    <span on:click={() => setDisplay(!display)}>
      {#if display}
        <DChevronD class="w-6 h-6" />
      {:else}
        <DChevronR class="w-6 h-6" />
      {/if}
    </span> <span class="flex-1 text-xl font-bold">Comments</span>
    <Can roles={[Roles.DEV, Roles.CONCEPT]}>
      {#if !$editComment}
        <div
          class="flex flex-row items-center gap-2 mr-6 normal-case rounded-xl text-base-content hover:underline"
          on:click={() => {setDisplay(true); setEditComment(true)}}
        >
          <PencilAltIcon class="w-4 h-4" />
          <span>Write comment</span>
        </div>
      {/if}
    </Can>
  </div>
</div>
{#if display}
  <div class="w-full px-2 pt-2">
    {#if $editComment}
      <div class="flex flex-row items-end w-full mb-3 justify-right">
        <span class="italic opacity-50 text-base-content">
          Please type "-----" and begin a new paragraph before typing a comment.
        </span>
      </div>
      <textarea
        class={classNames(displayStore.form.base + displayStore.textSizes.md + " w-full rounded-lg")}
        cols="120"
        rows="10"
        bind:value={content}
        bind:this={txtArea}
        on:keyup={keyPress}
      />
      <div class="flex items-center justify-between">
        <DeleteButton
          icon="Xicon"
          label="Cancel"
          confirmDialog={confirmCancelComment}
        >
          <DialogConfirmAction
            bind:this={confirmCancelComment}
            type="exitConceptEdition"
            actionOnYes={() => setEditComment(false)}
          />
        </DeleteButton>

        <button
          class="gap-2 normal-case btn btn-sm btn-success rounded-xl text-success-content"
          on:click={saveComment}
        >
          <Check class="w-4 h-4 " />
          Confirm comment
        </button>
      </div>
    {:else if content}
      <div
        class="items-center justify-start w-full p-3 rounded-lg shadow-none bg-base-300 text-base-content dark:bg-neutral dark:text-neutral-content"
      >
        {@html marked(content)}
      </div>
    {:else}
      <div class="flex flex-row items-end w-full mb-3 justify-right">
        <span class="italic opacity-50 text-base-content"> No comment has been added</span>


      </div>
    {/if}
  </div>
{/if}

<style>
  .slim-paragraph > p {
    padding: 0px;
  }
</style>
