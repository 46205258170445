<script lang="ts">
  import ArrowRightOnRectangle from "@components/icons/ArrowRightOnRectangle.svelte";
  import { dataStore } from "@stores/datastore";

  const {
    _selectedNode,
    _selectedStory,
    _selectedEdge,
  } = dataStore;

  const formatLabel = (label) => {
    if (label === "node") {
      return "concept";
    }
    return label;
  };
</script>

{#if $_selectedStory && !$_selectedNode && !$_selectedEdge}
  <button
    class={`gap-2 normal-case btn btn-sm bg-black dark:bg-white text-white dark:text-black border-none`}
    style={`border-radius: 0rem 0rem 1rem 1rem;`}
    on:click={() => {
      dataStore.unSelect();
    }}
  >
    story mode
    <ArrowRightOnRectangle class="w-6 h-6" />
  </button>
{:else if $_selectedStory && ($_selectedNode || $_selectedEdge)}
  <button
    class={`gap-2 normal-case btn btn-sm bg-black dark:bg-white text-white dark:text-black border-none`}
    style={`border-radius: 0rem 0rem 0rem 1rem; padding-right:2px`}
    on:click={() => {
      dataStore.unSelect();
    }}
  >
    story mode
    <ArrowRightOnRectangle class="w-6 h-6" />
  </button>
{/if}

{#if $_selectedStory && ($_selectedNode || $_selectedEdge)}
  <button
    class={`gap-2 normal-case btn btn-sm bg-black dark:bg-white text-white dark:text-black border-none`}
    style={`border-radius: 0rem 0rem 0rem 0rem;  padding-right:2px;  padding-left:2px`}
  >
    /
  </button>
{/if}

{#if ($_selectedNode || $_selectedEdge) && !$_selectedStory}
  <button
    class={`gap-2 normal-case btn btn-sm bg-black dark:bg-white text-white dark:text-black border-none`}
    style={`border-radius: 0rem 0rem 1rem 1rem;`}
    on:click={() => {
      dataStore.setSelectedNode(null);
    }}
  >
    {$_selectedNode ? "node" : "edge"} mode
    <ArrowRightOnRectangle class="w-6 h-6" />
  </button>
{:else if $_selectedStory && ($_selectedNode || $_selectedEdge)}
  <button
    class={`gap-2 normal-case btn btn-sm bg-black dark:bg-white text-white dark:text-black border-none`}
    style={`border-radius: 0rem 0rem 1rem 0rem; padding-left:2px`}
    on:click={() => {
      dataStore.setSelectedNode(null);
    }}
  >
    {$_selectedNode ? "node" : "edge"} mode
    <ArrowRightOnRectangle class="w-6 h-6" />
  </button>
{/if}
