import { dataStore } from "@stores/datastore";
import { supportingStore } from "@stores/supportingstore";
import { getNodeById } from "@utils/node";

export enum Supporting {
  UnitsOfMeasure = "Units of measure",
  Algorithms = "Algorithms",
  Metrics = "Metrics",
  StateMetrics = "Metrics of state",
  MetricsOfForm = "Metrics of form",
  MetricsOfFunctionalHealth = "Metrics of functional health",
  ParameterDefinitions = "Parameters",
  Sectors = "Sectors",
  Stakeholders = "Stakeholders",
  EnvProcess = "Environmental process",
  Agent = "Agent",
}

// TODO @refact merge this with nodeTypes strucutre
export const nodeSupportingConf = {
  activity: [
    {
      supporting: Supporting.Sectors,
      mandatory: false,
    },
  ],
  response: [
    {
      supporting: Supporting.Sectors,
      mandatory: true,
    },
  ],
  component: [
    {
      supporting: Supporting.StateMetrics,
      mandatory: false,
    },
    {
      supporting: Supporting.EnvProcess,
      mandatory: false,
    },
  ],
  pressure: [
    {
      supporting: Supporting.Agent,
      mandatory: true,
    },
  ],
};

export const hasSupporting = (
  conceptType: string,
  supporting: Supporting
): boolean => {
  if (nodeSupportingConf[conceptType]) {
    return (
      nodeSupportingConf[conceptType].filter((t) => t.supporting == supporting)
        .length == 1
    );
  }
  return false;
};

export const isMandatory = (conceptType: string, sup: Supporting) => {
  if (hasSupporting(conceptType, sup)) {
    return (
      nodeSupportingConf[conceptType].filter(
        (t) => t.supporting == sup && t.mandatory == true
      ).length == 1
    );
  } else throw new UnmappedSupportingError(conceptType, sup);
};

export const getSelectedNodeMetrics = () => {
  return dataStore.selectedNode.metric
    ? supportingStore.metrics.filter(
        (m: any) => m.id == dataStore.selectedNode.metric
      )
    : [];
};

export const getSelectedNodeAgent = () => {
  let agent;
  if (dataStore.selectedNode.pressure_agent_id)
    agent = getNodeById(dataStore.selectedNode.pressure_agent_id);
  return agent;
};

export const hasSelectedNodeSupportingDefined = (): boolean => {
  return (
    getSelectedNodeMetrics().length > 0 ||
    (hasSupporting(dataStore.selectedNode.type, Supporting.Agent) &&
      getSelectedNodeAgent()) ||
    (hasSupporting(dataStore.selectedNode.type, Supporting.Sectors) &&
      (dataStore.selectedNode.sectors ?? []).length > 0) ||
    (hasSupporting(dataStore.selectedNode.type, Supporting.StateMetrics) &&
      dataStore.selectedNode.state_metric_id)
  );
};

export class UnknownSupportingError extends Error {
  constructor(supporting: string) {
    super(
      `The following supporting name was used in components but wasn't previously defined: ${supporting}.`
    );
    Object.setPrototypeOf(this, UnknownSupportingError.prototype);
  }
}

export class UnmappedSupportingError extends Error {
  constructor(conceptType: string, sup: Supporting) {
    super(
      `${sup} supporting class is not mapped to ${conceptType} conept type`
    );
    Object.setPrototypeOf(this, UnmappedSupportingError.prototype);
  }
}
