<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import { nodeTypes } from "@utils/nodeTypes.js";
  import { isInCategory } from "@utils/categories";
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
  } from "@rgossiaux/svelte-heroicons/solid";
  export let node;
  import OutcomingIcon from "@components/icons/OutcomingIcon.svelte";
  import IncomingIcon from "@components/icons/IncomingIcon.svelte";
  import NodeMenu from "@components/dialog/NodeMenu.svelte";
  import { can, Roles } from "@utils/decorators";
  import type { NodesWithStories } from "@client";
  import NumberedPastille from "../NumberedPastille.svelte";
  import { Popover } from "@components/Core";
  import LinkEntry from "../NodeEditor/LinkEntry.svelte";

  export let compact:boolean = false;

  const { _selectedNode, _graphData } = dataStore;
  let open = false;
  let noConnection: boolean = true 
  const setConnection = () => {
    noConnection = $_graphData.links.filter((l) => l.source.id === node.id || l.target.id === node.id).length == 0;
  }
  const listNodeTypes = Object.keys(nodeTypes);

  let nodeNameEl: HTMLElement;
  let nodeMenu
  let isEllipsisActive = false;

  const checkEllipsisActive = (e: HTMLElement) => {
    isEllipsisActive = e.offsetWidth < e.scrollWidth;
  };

  function toggleOpen() {
    open = !open;
  }


  const restrictedShowContextMenu = can(
    [Roles.DEV, Roles.STORY],
    showContextMenu
  );

  function showContextMenu(e: PointerEvent, node : NodesWithStories) {
    nodeMenu.show(node, { x: e.x, y: e.y });
  }

  function outcomingConnectionName(nodeType : string) : string{
    let link = $_graphData.links.find((l) => l.source.id === node.id && l.target.type === nodeType)
    return nodeTypes[node.type].sectionto[
                  nodeTypes[node.type].to.findIndex(
                    (e) => e === link.target.type
                  )
                ]
  }

  function incomingConnectionName(nodeType: string) : string {
    let link = $_graphData.links.find((l) => l.target.id === node.id && l.source.type === nodeType)
    return nodeTypes[link.source.type].sectionto[
                  nodeTypes[link.source.type].to.findIndex(
                    (e) => e === node.type
                  )
                ]
  }

  $: nodeNameEl && checkEllipsisActive(nodeNameEl);
  $: node && setConnection();
</script>


<NodeMenu bind:this={nodeMenu} context="rightGraphPanel" />

<li
  id={`node_${node.id}`}
  class:ml-3={isInCategory(node.type)}
  class="flex items-center w-full {compact ? "" : "mb-1"} hover:bg-base-300"
  style={`background-color: ${
    $_selectedNode && node.id === $_selectedNode.id
      ? nodeTypes[node.type].color().toLowerCase()
      : "none"
  }`}
  on:click={() => {
    !noConnection && toggleOpen();
  }}
  on:contextmenu|preventDefault={(e) => {
    restrictedShowContextMenu(e, node);
  }}
>
  {#if open}
    <ChevronD class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron" style="cursor:pointer;" />
  {:else}
    <ChevronR class={`flex-none ${compact ? "w-5 h-5" : "w-6 h-6" } chevron ${noConnection ? "text-base-content opacity-50" : ""}`}  style="cursor:pointer;"/>
  {/if}
  {#if isEllipsisActive}
    <Popover
      triggeredBy={`#story-concept-${node.id}`}
      class="w-auto text-sm font-light"
      placement="left"
      defaultClass="p-0"
      arrow={true}
      anchorOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    >
      <div class="flex flex-row {compact ? "m-3" : "m-4" }">
        <div class={compact ? "mr-1" : "mr-2" } >
          {node.name}
        </div>
      </div>
    </Popover>
  {/if}
  <div id={`story-concept-${node.id}`}
    on:click={() => dataStore.setSelectedNode(node)}
    bind:this={nodeNameEl}
    class="truncate hover:cursor-pointer hover:font-bold hover:underline"
  >
    {node.name}
  </div>
</li>
{#if open}
  <div class="{compact ? "mb-3" : "mb-4" }">
    {#each listNodeTypes as nodeType}
      {#if $_graphData.links.find((l) => l.source.id === node.id && l.target.type === nodeType)}
      <div class="flex flex-row items-center {compact ? "ml-1" : "ml-2" }" style={`color: ${nodeTypes[nodeType].color()}`}>
          <OutcomingIcon type={nodeType} />
          <span class="font-bold ">{outcomingConnectionName(nodeType)}</span>
          <span class="ml-1 text-sm">({nodeTypes[nodeType].label})</span>
          <NumberedPastille nbr={$_graphData.links.filter((l) => l.source.id === node.id && l.target.type === nodeType).length} />
        </div>

        <ul class="{compact ? "mb-1" : "mb-2" } list-item">
          {#each $_graphData.links.filter((l) => l.source.id === node.id && l.target.type === nodeType) as link}
          <LinkEntry n={link} direction="to" />
          {/each}
        </ul>
      {/if}
    {/each}
  </div>
  <div class="{compact ? "mb-3" : "mb-4" }">
    {#each listNodeTypes as nodeType}
      {#if $_graphData.links.find((l) => l.target.id === node.id && l.source.type === nodeType)}
        <div class="flex flex-row items-center {compact ? "ml-1" : "ml-2" }" style={`color: ${nodeTypes[nodeType].color()}`}>
            <IncomingIcon type={nodeType} />
            <span class="font-bold">{incomingConnectionName(nodeType)}</span>
            <span class="ml-1 text-sm">({nodeTypes[nodeType].label})</span>
            <NumberedPastille nbr={$_graphData.links.filter((l) => l.target.id === node.id && l.source.type === nodeType).length} />
        </div>

        <ul class="{compact ? "mb-1" : "mb-2" } list-item">
          {#each $_graphData.links.filter((l) => l.target.id === node.id && l.source.type === nodeType) as link}
          <LinkEntry n={link} direction="from"/>
          {/each}
        </ul>
      {/if}
    {/each}
  </div>
{/if}

<style>
  .inTheStory {
    background-color: rgba(255, 0, 0, 0.26);
  }

  .rejected {
    @apply text-error;
  }

  .rejected-active {
    @apply dark:text-error-content text-error;
  }

  .disabled {
    @apply text-error;
  }
  
  .chevron {
    cursor: pointer;
  }

  /* .pending {
    @apply text-info;
  } */
</style>
