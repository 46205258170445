import {
  find,
  eqProps,
  propEq,
  curry,
  prop as getProp,
  groupBy,
  match,
  filter,
  isEmpty,
  not,
} from "rambda";

/**
 *
 * @param key Prop to find
 * @param value Value to match
 * @param array List of items to scan
 * @returns
 */
export const getItemFromKeyValue = (
  key: string,
  value: string,
  array: Object[]
): Object => {
  return find(propEq(key, value), array);
};

export const getItemsFromKeyValue = <T>(
  key: string,
  value: string,
  array: T[]
): T[] => {
  return filter(propEq(key, value), array) as T[];
};
export const getItemFromKeyValueCurry = curry(getItemFromKeyValue);

export const hasItemsFromKeyValue = <T>(
  key: string,
  value: string,
  array: T[]
): boolean => {
  return not(isEmpty(getItemsFromKeyValue(key, value, array)));
};
export const hasItemsFromKeyValueCurry = curry(hasItemsFromKeyValue);

/**
 * Fix this
 * @param attr
 * @param list
 * @returns
 */
export const groupByAttr = (attr, list) => groupBy(getProp(attr), list);

export const groupByAttrCurry = curry(groupByAttr);
