import { writable } from "svelte/store";
import type { Writable } from "svelte/store";
import type { InjectionKey } from "@utils/svelte-typed-context";
import { setContext, getContext } from "@utils/svelte-typed-context";

export const key: InjectionKey<CtxState> = Symbol("storyEditionContext");

/**
 * ctx setter
 */
type CtxState = {
  editMode: Writable<Boolean>;
  editComment: Writable<Boolean>;
  editOn: () => void;
  editOff: () => void;
  setEditComment: (value: boolean) => void;
};

let editMode: Writable<Boolean> = writable(false);
let editComment: Writable<Boolean> = writable(false);
const editOff = () => editMode.set(false);
const editOn = () => editMode.set(true);
const setEditComment = (value: boolean) => editComment.set(value);

export const setCtx = () =>
  setContext(key, {
    editMode: editMode,
    editOff: editOff,
    editOn: editOn,
    editComment,
    setEditComment,
  });

export const getCtx = () => getContext(key);
