<script lang="ts">
  import { get } from "svelte/store";
  import Autocomplete from "@components/Autocomplete.svelte";
  import { authentication } from "@utils/keycloak";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { type Node, Type } from "@client";
  import { getCtx } from "@components/Actions/CreateConcept/context";
  import { createForm } from "felte";
  import Select from "svelte-select";
  import { nodeTypes } from "@utils/nodeTypes";
  import { setArrayFromKeys } from "@utils/core/object";
  import { getItemFromKeyValueCurry as getItemFromKeyValue } from "@utils/core/array";
  import {
    afterCreateNodeAsync,
    createNodeAsync,
    isNameAlreadyTaken,
    setSectors,
  } from "@utils/node";
  import {
    Label,
    Input,
    Select as SimpleSelect,
    MultiSelect,
    Textarea,
    Helper,
    DynamicField,
  } from "@core-ui";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte";
  import { Roles } from "@utils/decorators";
  import { hasSupporting, isMandatory, Supporting } from "@utils/supporting";
  import { supportingStore } from "@stores/supportingstore";
  import { dataStore } from "@stores/datastore";
  import { onMount } from "svelte";

  const { _nodes } = dataStore;
  const { _sectors } = supportingStore;

  let errorHelperClass: string = "my-3";

  let pressureAgentAutoComplete;
  let pressureAgentPlaceHolder = "n agent...";
  //Pressure agent node:
  let pressureAgent;

  let baseErrors = {
    name: [],
    type: [],
    comment: [],
    maslow_level: [],
    ecosystem_service_type: [],
    goods_benefits_type: [],
    impact_type: [],
    // capital_type: [],
    response_type: [],
    realm: [],
    sectors: [],
    pressure_agent_id: [],
  };

  let allErrors = baseErrors;
  const purgeAllErrors = () => {
    for (let e of Object.keys(allErrors)) {
      allErrors[e] = [];
    }
  };

  const defaultField = ["type", "name", "comment", "description"];

  const getNodeFromName = (name: string) => {
    return $_nodes.find((n) => n.name === name);
  };

  function onPressureAgentSelected(name) {
    pressureAgent = getNodeFromName(name);
  }

  function getSelectedPressureAgentId() {
    if (pressureAgentAutoComplete === undefined) return null;
    pressureAgent = getNodeFromName(pressureAgentAutoComplete.getValue());
    return pressureAgent ? pressureAgent.id : null;
  }

  function getPotentialAgentNodes() {
    return $_nodes
      .filter(
        (n) => n.type === Type.COMPONENT || n.type === Type.GOOD_AND_BENEFIT
      )
      .map((n) => n.name);
  }

  const buildErrors = () => {
    allErrors = baseErrors;
    for (let p of nodeTypes[$data.type].properties) {
      allErrors[p.name] = [];
    }
  };

  export let initialValues: {
    type?: Type;
    parentId?: number;
  } = {};

  let { close } = getCtx();
  export let onClose = close;

  const types = setArrayFromKeys(nodeTypes);
  let typeKeys = setArrayFromKeys(nodeTypes).map((type) => type.key);
  let isSectorsEmpty: boolean = true;

  const {
    form,
    data,
    isValid,
    setFields,
    isSubmitting,
    errors,
    setInitialValues,
    reset,
    interacted,
    resetField,
  } = createForm<Node>({
    onSubmit: async (values) => {
      try {
        let payload = {
          description: `description of the node: ${values.name}`,
          parentId: initialValues?.parentId,
          pressure_agent_id: pressureAgent?.id,
          ...values,
        } as Node;
        if ($data.type == "pressure") {
          payload.pressure_agent_id = getSelectedPressureAgentId();
          // Felte added a Node property to the payload beacause of the
          // autocomplete field. So we work around it:
          delete payload.Node;
        }

        const response = await createNodeAsync(payload);
        if (response) {
          setSectors(response.id, values.sectors);
          response.sectors = values.sectors;
          afterCreateNodeAsync(response);
          onClose();
        }
      } catch (e) {}
    },
    validate(values) {
      purgeAllErrors();
      const errors = allErrors;
      if (!values.name) errors.name.push("Title is required.");
      if (isNameAlreadyTaken(values.name))
        errors.name.push("Title is already taken.");
      if (!values.type) errors.type.push("Type is required.");
      if (get(authentication).role === Roles.STORY && !values.comment)
        errors.comment.push(`Comment is required for ${Roles.STORY}`);

      if (values.type) {
        if (nodeTypes[values.type]?.properties) {
          for (let p of nodeTypes[values.type].properties) {
            const name = p.name;
            const label = p.label;
            const mandatory = p.mandatory;
            if (mandatory) {
              if (!values[name]) errors[name].push(`${label} is required.`);
            }
          }
        }
      }
      if (values.type == "pressure") {
        if (!getSelectedPressureAgentId())
          errors.pressure_agent_id.push("An agent is required.");
      }
      if (
        values.type &&
        hasSupporting(values.type, Supporting.Sectors) &&
        isMandatory(values.type, Supporting.Sectors) &&
        (values.sectors == undefined ||
          (values.sectors && values.sectors.length == 0))
      ) {
        errors.sectors.push("At least one sector is required.");
      }
      return errors;
    },
    extend: [reporter],
  });

  const formatLabel = (value) => {
    return getItemFromKeyValue("key", value)(types).label;
  };

  const setFormWithInitialValues = () => {
    if (initialValues) {
      setInitialValues({
        type: initialValues.type,
        name: "",
        comment: "",
        description: "",
        sectors: [],
      });
      reset();
    }
  };

  const cleanForm = () => {
    if ($interacted == "type") {
      if ($data.type) {
        for (let v of Object.keys($data)) {
          if (!defaultField.includes(v)) {
            resetField(v as keyof Node);
          }
        }
      }
    }
  };

  const handleSectorsChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    } else {
      val = undefined;
    }
    setFields("sectors", val);
  };

  onMount(() => {
    purgeAllErrors();
  });

  $: isSectorsEmpty = !$data?.sectors;
  $: initialValues && setFormWithInitialValues();
  $: $interacted && cleanForm();
  $: $data && nodeTypes[$data.type] && buildErrors();
</script>

<!-- FORM -->
<!-- TODO: Create reusable FormInputs components -->
<form use:form class="w-full form-control">
  <div class="mt-2">
    <!-- <fieldset> -->
    <!-- TYPE -->
    <!-- <Label class="mt-4 mb-2" for="type">Type</Label>
    <SimpleSelect
      id="type"
      name="type"
      required
      items={typeKeys}
      {formatLabel}
      disabled={Boolean(initialValues?.type)}
    />
    <ValidationMessage for="type" let:messages>
      <Helper class="mt-2" color="red">
        {#each messages ?? [] as message}
          {message}
        {/each}
      </Helper>
    </ValidationMessage> -->

    <Label class="mt-4 mb-2" for="name">Title</Label>
    <Input
      type="text"
      name="name"
      id="name"
      placeholder="ex: my new concept"
      color="base"
      required
    />
    <ValidationMessage for="name" let:messages>
      <Helper class="mt-2" color="red">
        {#each messages ?? [] as message}
          {message}
        {/each}
      </Helper>
    </ValidationMessage>

    {#if $data.type}
      {#if nodeTypes[$data.type]?.properties}
        {#each nodeTypes[$data.type].properties as prop}
          {#if prop.mandatory}
            <DynamicField
              name={prop.name}
              label={prop.label}
              required={prop.mandatory}
              fieldType={prop.field?.type}
              options={prop.field?.values}
              errors={$errors[prop.name]}
            />
          {/if}
        {/each}
      {/if}
    {/if}

    {#if $data.type}
      {#if hasSupporting($data.type, Supporting.Sectors) && isMandatory($data.type, Supporting.Sectors)}
        <!-- SECTORS -->
        <div>
          <MultiSelect
            items={$_sectors}
            placeholder="Select associated sectors"
            handleSelectChange={handleSectorsChange}
            label="name"
            itemId="id"
            name="sectors"
            required={true}
            requiredMessage="Please select at least one sector"
            componentLabel="Sectors"
          />
          <Helper class="mt-2" color="red" {errorHelperClass}>
            {#each $errors["sectors"] ?? [] as message}
              <span>Sectors are required</span>
            {/each}
          </Helper>

          {#if isSectorsEmpty}
            <p>
              <span
                class="pt-20 text-xs font-normal text-gray-500 text-red-700 dark:text-gray-300 dark:text-red-500"
              >
                At least one sector is required.
              </span>
            </p>
          {/if}
        </div>
      {/if}
      {#if $data.type == "pressure"}
        <Label for="sectors" class="mt-4 mb-2">
          Agent <span class="text-red-500"> *</span>
        </Label>
        <Autocomplete
          bind:this={pressureAgentAutoComplete}
          list={getPotentialAgentNodes()}
          nodeType={pressureAgentPlaceHolder}
          getInput={onPressureAgentSelected}
        />
      {/if}
    {/if}

    <Label class="mt-4 mb-2" for="comment">Comment</Label>
    <Textarea
      placeholder="Enter your comment here..."
      name="comment"
      id="comment"
      required={get(authentication).role === Roles.STORY}
    />
    <ValidationMessage for="comment" let:messages>
      <Helper class="mt-2" color="red">
        {#each messages ?? [] as message}
          {message}
        {/each}
      </Helper>
    </ValidationMessage>

    <BlocCancelConfirm
      isValid={$isValid}
      isSubmitting={$isSubmitting}
      vLabel="Confirm creation"
      xConfirmType="cancelConceptCreation"
      xOnConfirm={() => {close(); onClose()}}
    />
  </div>
</form>
