import { writable } from "svelte/store";
import type { Writable } from "svelte/store";
import type { InjectionKey } from "@utils/svelte-typed-context";
import { setContext, getContext } from "@utils/svelte-typed-context";

export const key: InjectionKey<CtxState> = Symbol("statusReviewContext");

/**
 * ctx setter
 */
type CtxState = {
  showForm: Writable<Boolean>;
  closeForm: () => void;
  openForm: () => void;
};

let showForm: Writable<Boolean> = writable(false);
const closeForm = () => showForm.set(false);
const openForm = () => showForm.set(true);

export const setCtx = () =>
  setContext(key, {
    showForm: showForm,
    closeForm: closeForm,
    openForm: openForm,
  });

export const getCtx = () => getContext(key);
