<script lang="ts">
  // TODO: refact: move to parent folder as it handles both preview and form.
  import type { SvelteComponent } from "svelte";

  import { dataStore } from "@stores/datastore";
  import { hasSupporting, Supporting } from "@utils/supporting";
  import SectorForm from "../../edit/SectorForm.svelte";
  import EnvProcessForm from "../../edit/EnvProcessForm.svelte";
  import MetricForm from "../../edit/MetricForm .svelte";
  import AgentForm from "../../edit/AgentForm.svelte";
  import StateMetricForm from "../../edit/StateMetricForm.svelte";
  import { updateNode } from "@utils/node";
  import PropertyPreview from "./PropertyPreview.svelte";
  import PropertyForm, {
    FormType,
  } from "@components/Editors/NodeEditor/edit/PropertyForm.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import { getCtx } from "@components/Editors/NodeEditor/context";
  import DeleteButton from "../../edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import SupportingPreview from "./SupportingPreview.svelte";
  import {
    CheckIcon as Check,
    PencilIcon,
  } from "@rgossiaux/svelte-heroicons/solid";
  let { editPropertiesMode } = getCtx();
  let { _selectedNode } = dataStore;
  let confirmCancelEdition;
  let propForm: PropertyForm;
  // Bound children form submit handlers:
  let handleStateMetricSubmit;
  let handleAgentSubmit;
  // Direct ref to children forms (TODO: bind submit handler function instead)
  let metricForm: SvelteComponent;
  let sectorForm: SvelteComponent;
  let envProcessForm: SvelteComponent;

  const updateNodeProperties = () => {
    let fieldValues = propForm.getFieldValues();
    updateNode($_selectedNode.id, fieldValues);
    handleGeneralSubmit();
    $editPropertiesMode = false;
  };
  const handleGeneralSubmit = () => {
    if (metricForm) metricForm.handleSubmit();
    if (sectorForm) sectorForm.handleSubmit();
    if (handleStateMetricSubmit) handleStateMetricSubmit();
    if (handleAgentSubmit) handleAgentSubmit();
    if (envProcessForm) envProcessForm.handleSubmit();
  };
</script>

<!--
@component
Panel displaying concept properties and associated form.
-->
<div>
  <div class="flex flex-row items-center justify-between pb-4 pr-2">
    <div class="text-xl font-bold">Properties</div>
    <Can roles={[Roles.DEV, Roles.CONCEPT]}>
      {#if !$editPropertiesMode}
        <div
          class="flex flex-row items-center gap-2 mr-6 normal-case rounded-xl text-base-content hover:underline"
          on:click={() => {
            $editPropertiesMode = true;
          }}
        >
          <PencilIcon class="w-4 h-4" />
          <span> Edit </span>
        </div>
      {:else}
        <div class="flex flex-row items-center justify-end">
          <DeleteButton
            icon="Xicon"
            label="Cancel"
            confirmDialog={confirmCancelEdition}
          >
            <DialogConfirmAction
              bind:this={confirmCancelEdition}
              type="exitConceptEdition"
              actionOnYes={() => {
                $editPropertiesMode = false;
              }}
            />
          </DeleteButton>
          <button
            class={`gap-2 normal-case btn btn-sm btn-success rounded-xl text-success-content`}
            on:click={updateNodeProperties}
          >
            <Check class="w-4 h-4" />
            Save edits
          </button>
        </div>
      {/if}
    </Can>
  </div>
</div>
{#if $_selectedNode}
  <div class="pb-4">
    {#if $editPropertiesMode}
      <PropertyForm
        bind:this={propForm}
        conceptType={$_selectedNode.type}
        formType={FormType.Edit}
      />
      {#if hasSupporting($_selectedNode.type, Supporting.Agent)}
        <AgentForm bind:handleSubmit={handleAgentSubmit} />
      {/if}

      <!-- Metric form -->
      <MetricForm bind:this={metricForm} />

      {#if hasSupporting($_selectedNode.type, Supporting.Sectors)}
        <SectorForm bind:this={sectorForm} />
      {/if}

      {#if hasSupporting($_selectedNode.type, Supporting.EnvProcess)}
        <EnvProcessForm bind:this={envProcessForm} />
      {/if}

      {#if hasSupporting($_selectedNode.type, Supporting.StateMetrics)}
        <StateMetricForm bind:handleSubmit={handleStateMetricSubmit} />
      {/if}
    {:else}
      <PropertyPreview />
      <SupportingPreview />
    {/if}
  </div>
{/if}
