<script lang="ts">
  import { MenuButton } from "@rgossiaux/svelte-headlessui";
  import { rolesInfo } from "@utils/decorators";
  import { authentication } from "@utils/keycloak";
</script>

<span class="avatar">
  <MenuButton
    class="w-8 h-8 overflow-hidden shadow-lg rounded-2xl border-base-200 bg-base-100"
  >
    <img
      src={rolesInfo[$authentication.role].imgSrc}
      alt={`menu-avatar-img-${$authentication.role.toLowerCase()}`}
    />
  </MenuButton>
</span>
