<script lang="ts">
  import { addStory, isNameValid, type StoriesHierarchy } from "@utils/story";
  import Dialog from "./Dialog.svelte";
  import Form from "@components/Editors/StoryEditor/Form.svelte";

  export let isScenario: boolean = false;

  let id: number = null;
  let cat: string = null;
  let dialog;
  let storyName: string = "";
  let graphDescription: string = "";
  let graphType: "scenario" | "story";
  let errors: { [inputName: string]: any } = {};
  let submitted: boolean;

  export const show = (coord, storyParent: StoriesHierarchy | null = null) => {
    id = storyParent ? storyParent.id : null;
    cat = storyParent ? storyParent.category : undefined;
    errors = {};
    submitted = false;
    dialog.show(coord);
    graphType = `${isScenario ? "scenario" : "story"}`;
  };

  const validateName = () => {
    errors["storyName"] = {
      ...errors["storyName"],
      isValid: isNameValid(storyName),
    };
  };

</script>

<Dialog bind:this={dialog} dismissOnClickOutside={false}>
  <div class="p-3 w-72">
    <div class="mb-3">
      <h1
        aria-labelledby={`New ${graphType}`}
        class="text-xl text-base-content"
      >
        New {graphType}
      </h1>
      {#if graphType === "scenario"}
        <p
          aria-describedby="{graphType} are graph that represent potential situations; they may include concepts and relationships which do not currenly exist."
          class="text-opacity-50 text-base-content"
        >
          {graphType} are graph that represent potential situations; they may include
          concepts and relationships which do not currenly exist.
        </p>
      {:else}
        <p
          aria-describedby="{graphType} are graph that represent how we understand the existing
        situation"
          class="text-opacity-50 text-base-content"
        >
          {graphType} are graph that represent how we understand the existing situation
        </p>
      {/if}

      <Form
        bind:dialog
        initialValues={{
          name: undefined,
          category: cat,
          description: undefined,
          parentId: id,
          isScenario: isScenario,
        }}
      />
    </div>
  </div></Dialog
>

<style>
  h1 {
    font-size: revert;
    padding-bottom: 13px;
  }
</style>
