<script lang="ts">
  import { isNil } from "rambda";
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import Type from "./Type.svelte";
  import Node from "./Node.svelte";

  const { _nodesHierarchy, _alternativeNodesHierarchy, _selectedNode } = dataStore;

  // Prop: the name of the node type:
  export let type;
  // Prop: the index of the node type:
  export let index;

  export let compact:boolean = false;
  export let nodeClickAction:((node) => {});
  export let useAlternativeContext:boolean = false;

  function collapseAllNodes() {
    if (isNil($_selectedNode)) {
      dataStore.displayHierarchy(false);
      displayStore.setAllCategoriesDisplay(false);
    }
  }

  $: isNil($_selectedNode) && collapseAllNodes();
</script>

<!--
@component
Tree view for a node type and its children nodes.
-->
{#if useAlternativeContext && $_alternativeNodesHierarchy}
  <Type {type} {index} {compact} {nodeClickAction} {useAlternativeContext} />
  {#if $_alternativeNodesHierarchy[type].display}
    <ul class={compact ? "ml-2" : "ml-3"}>
      {#each $_alternativeNodesHierarchy[type].children as node (node.id)}
        <Node {node} {compact} {nodeClickAction} />
      {/each}
    </ul>
  {/if}
{:else if $_nodesHierarchy}
  <Type {type} {index} {compact} {nodeClickAction} {useAlternativeContext} />
  {#if $_nodesHierarchy[type].display}
    <ul class={compact ? "ml-2" : "ml-3"}>
      {#each $_nodesHierarchy[type].children as node (node.id)}
        <Node {node} {compact} {nodeClickAction} />
      {/each}
    </ul>
  {/if}
{/if}

