<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteMetricOfFunctionalHealth } 
    from "@utils/supporting/metricOfFunctionalHealth";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"
 
  const { metricFormDialog, editModeOn } = getCtx();
  const { 
    _metricsOfFunctionalHealth, 
    _selectedMetricOfFunctionalHealth 
  } = supportingStore;

  const showEditMetric = (metric) => {
    _selectedMetricOfFunctionalHealth.set(metric);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Definition</th>
        <th>Unit of measure</th>
        <th>Quantitative/qualitative</th>                   
        <th>Algorithm</th>
        <th>Parameter definitions</th>             
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_metricsOfFunctionalHealth as metric}
        <tr>
          <td>{formatAtomicProperty(metric.name)}</td>
          <td>{formatAtomicProperty(metric.definition)}</td>
          <td>{metric.unit?.name}</td>
          <td>{metric.isQualitative ?  "Qualitative" : "Quantitative" }</td>                  
          <td>{formatAtomicProperty(metric?.algorithm?.name)}</td>
          <td>
            {#each metric.parameter_definitions as param, index}
              {param.name}{#if index < metric.parameter_definitions.length - 1}, {/if}                  
            {/each}
          </td>              
          <GridAction
            onEdit={() => showEditMetric(metric)}
            onDelete = {() => deleteMetricOfFunctionalHealth(metric.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>