import { get, writable } from "svelte/store";
// import { v4 as uuid } from "@lukeed/uuid";

export enum ServerStatus {
  Pending = "Connexion pending",
  Healthy = "Connexion healthy",
  Error = "Server error",
  Closed = "Connexion closed",
  ManuallyClosed = "Connexion closed manually",
}

export enum ResourceAction {
  Insert = "insert",
  Update = "update",
  Delete = "delete",
}

export enum ResourceType {
  Concept = "Node",
  Connexion = "Edge",
  Story = "Story",
}

/**
 *  Message example schema   
 * {
      id: 1,
      content: "Node was updated",
      type: "update",
      resource: "Node"
      author_name: "Maurice",
    },
 */
type AuditLogNotification = {
  id: string;
  content: string;
  type: ResourceAction;
  resource: ResourceType;
  author_name: string;
};

/**
 * Init Websocket Server Status store
 */
export const wsServerStatus = writable(ServerStatus.Pending);

/**
 * Init broadcased server store
 * TODO : Messages should be replaced by AuditLogNotification
 * eg: export const auditLogNNotifications = writable([] as AuditLogNotification[]);
 */
export const messages = writable([] as string[]);

/**
 *
 * @returns
 */
export function getWSServerStatus() {
  return get(wsServerStatus);
}

/**
 *
 * @returns
 */
export function getMessages() {
  return get(messages);
}

/**
 *
 * @returns
 */
export function setWSStatus(status: ServerStatus) {
  wsServerStatus.set(status);
}

/**
 *
 * @returns
 */
export function setMessages(msg: string[]) {
  messages.set(msg);
}

export function appendMessage(message: string) {
  messages.update((m) => [message, ...m]);
}
