<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";
  import PastillInnerIcon from "./PastillInnerIcon.svelte";
  export let type: string;
  export let click: Function = () => {};
  export let status: string = null;
</script>

<div
  class={`h-3 w-3 inline-block ${$$props.class}`}
  style="background-color:{nodeTypes[type].color()}"
  on:click={() => click()}
>
  <PastillInnerIcon {status} />
</div>
