<script lang="ts">
  import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from "@rgossiaux/svelte-headlessui";
  import { ChevronRightIcon } from "@rgossiaux/svelte-heroicons/solid";
  import { getCtx } from "@components/Actions/CreateResource/context";
  import { getItemsFromKeyValue } from "@utils/core/array";
  import { resourceTypeOptions } from "@components/Actions/CreateResource/Form.svelte";
  import classnames from "classnames";
  import LinkItem from "./LinkItem.svelte";
  let { resources } = getCtx();
</script>

<div class="w-full rounded-2xl">
  {#each resourceTypeOptions as { value, label }}
  <div class="mb-5 ">
    <Disclosure let:open>
      <DisclosureButton
        class= "flex items-center justify-start w-full gap-2 font-medium text-left rounded-lg hover:bg-base-300 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
        <ChevronRightIcon
          class={classnames(
            "w-6 h-6 text-base-content",
            { "transform rotate-90": open }
          )}
        />
        <div>
          {label}
          <span
            class={classnames("indicator-item badge", {
              "badge-error":
                getItemsFromKeyValue("type", value, $resources).length == 0,
            })}>{getItemsFromKeyValue("type", value, $resources).length}</span
          >
        </div>
      </DisclosureButton>
      <DisclosurePanel class="px-4 pb-2 text-md">
        {#if getItemsFromKeyValue("type", value, $resources)}
          <ul class="ml-8 list-disc">
            {#each getItemsFromKeyValue("type", value, $resources) as item}
              <LinkItem {item} />
            {/each}
          </ul>
        {/if}
      </DisclosurePanel>
    </Disclosure>
  </div>
  {/each}
</div>
