<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import { createEventDispatcher, onMount } from "svelte";
  import { Status } from "@client";
  import { Divider } from "@core-ui";
  import {
    setCtx as setStatusReviewCtx,
    getCtx as getStatusReviewCtx,
  } from "@components/Editors/StatusReview/context";
  import { setCtx as setResourcesCtx } from "@components/Actions/CreateResource/context";
  import { getCtx as getNodeEditorCtx } from "@components/Editors/NodeEditor/context";
  import CommentSectionAlternate from "./CommentSectionAlternate.svelte";
  import Tabs from "./Tabs.svelte";
  import StatusReview from "@components/Editors/StatusReview/index.svelte";
  import Header from "@components/Editors/NodeEditor/Header/index.svelte";

  /**
   * Context setters
   */
  setStatusReviewCtx();
  setResourcesCtx();
  let { closeForm } = getStatusReviewCtx();
  let { editOff } = getNodeEditorCtx();

  const { _theme } = displayStore;
  const { _selectedNode } = dataStore;
  const dispatch = createEventDispatcher();

  /**
   * Forwards the custom 'attachment-added' event to parent
   * component.
   *
   * @param e: the event ot be dispatched.
   */
  const forwardAttachmentAdded = (e) => {
    dispatch("attachment-added", e.detail);
  };

  $: $_theme && $_selectedNode && dataStore.updateSelectedNode($_selectedNode);
  $: $_selectedNode && editOff();
  $: $_selectedNode && closeForm();
</script>

<div class="flex flex-col w-full h-full">
  {#if $_selectedNode}
    {#if $_selectedNode.status == Status.PENDING}
      <StatusReview />
    {/if}
    <Header />
    <div class="mt-6" />
    <Divider />
    <CommentSectionAlternate content={$_selectedNode.comment} />
    <Tabs on:attachment-added={forwardAttachmentAdded} />
  {/if}
</div>
