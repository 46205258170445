<script lang="ts">
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { getCtx } from "../context";
  import {
    createMetricOfForm,
    updateMetricOfForm,
    isNameAlreadyTaken,
  } from "@utils/supporting/metricOfForm";
  import {
    Label,
    Helper,
    Textarea,
    MultiSelect,
    Input,
    RadioGroup,
  } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import FormCreationButtons from "../FormCreationButtons.svelte";

  let { metricFormDialog, editModeOn } = getCtx();
  const {
    _selectedMetricOfForm,
    _parameterDefinitions,
    _unitsOfMeasure,
    _algorithms,
  } = supportingStore;

  // Parameter defintion properties to be used as MultiSelect label/value
  const itemId = "id";
  const label = "name";
  // Init values for parameter definition MultiSelect
  let initParamDefinitions;
  let initUnit;
  let initAlgorithm;
  let isQualitative = "qualitative";

  const {
    form,
    data,
    setFields,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
  } = createForm({
    onSubmit: (values) => {
      // TODO user setFields on radio group change instead:
      values.isQualitative =
        values.isQualitativeAsString == "qualitative" ? true : false;
      if (!$editModeOn) {
        createMetricOfForm(values);
        // clear form
        handleFormReset(emptyMetric);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateMetricOfForm($_selectedMetricOfForm.id, values);
        // clear form
        handleFormReset(emptyMetric);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        unit: string[];
        definition: string[];
        parameter_definitions: string[];
        algorithm: string[];
      } = {
        name: [],
        unit: [],
        definition: [],
        algorithm: [],
        parameter_definitions: [],
      };
      if (!values.name) errors.name.push("Name is required.");
      // if (!values.definition)
      //   errors.definition.push("Algorithm definition is required.");
      if (values.isQualitativeAsString == "quantitative") {
        if (!values.algorithm_id)
          errors.algorithm.push(
            "Algorithm is required for quantitative metrics."
          );
      } else {
        if ((values.parameter_definitions ?? []).length == 0) {
          errors.parameter_definitions.push(
            "At least one parameter definition is required  for qualitative metrics.."
          );
        }
      }

      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedMetricOfForm &&
          $_selectedMetricOfForm.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (!values.unit_id) errors.unit.push("Unit is required.");
      return errors;
    },
    extend: [reporter],
  });

  const handleFormReset = (metric) => {
    metric.isQualitativeAsString = metric.isQualitative
      ? "qualitative"
      : "quantitative";
    setInitialValues(metric);
    initParamDefinitions = metric.parameter_definitions;

    // Voodoo class workaround!
    // Without this, unit and algo select are not reset...
    // The worst in this is we cannot figure why...
    initUnit = $_unitsOfMeasure[0];
    initUnit = metric.unit;
    initAlgorithm = $_algorithms[0];
    initAlgorithm = metric.algorithm;

    isQualitative = metric.isQualitative ? "qualitative" : "quantitative";
    setFields("unit", metric.unit);
    setFields("algorithm", metric.algorithm);
    setFields("unit_id", metric.unit_id);
    setFields("algorithm_id", metric.algorithm_id);
    setFields("parameter_definitions", metric.parameter_definitions);
    reset();
  };

  const handleUnitChange = (e) => {
    if (e.detail) {
      setFields("unit_id", e.detail.id);
      setFields("unit", e.detail);
    }
  };

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields("parameter_definitions", val);
  };

  const handleAlgorithmChange = (e) => {
    if (e.detail) {
      setFields("algorithm_id", e.detail.id);
      setFields("algorithm", e.detail);
    }
  };

  const emptyMetric = {
    name: "",
    chemical_form_def: "",
    biological_form_def: "",
    physics_form_def: "",
    spatiotemporal_form_def: "",
    quantity_def: "",
    parameter_definitions: [],
    unit: null,
    isQualitative: false,
    isQualitativeAsString: "quantitative",
  };

  $: $editModeOn &&
    $_selectedMetricOfForm &&
    handleFormReset($_selectedMetricOfForm);
  $: !$editModeOn && handleFormReset(emptyMetric);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn ? "Update Metric of form" : "Create Metric of form"}
  </h1>
  <!-- FORM -->

  <!-- NAME -->
  <div class="flex items-start justify-start gap-2">
    <div>
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type metric of form name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <div class="basis-1/2">
      <MultiSelect
        items={$_unitsOfMeasure}
        placeholder="Select associated unit"
        initValues={$editModeOn ? initUnit : null}
        handleSelectChange={handleUnitChange}
        {itemId}
        {label}
        multiple={false}
        name="unit_id"
        componentLabel="Unit"
        requiredMessage="Unit is required"
        required
      />
    </div>
  </div>

  <div class="flex items-start justify-start gap-2">
    <div>
      <Label for="type_form_def" class="mt-4 mb-2"
        >Qualitative/Quantitative</Label
      >
      <RadioGroup
        name="isQualitativeAsString"
        selected={isQualitative}
        items={[
          {
            label: "Quantitative",
            value: "quantitative",
          },
          {
            label: "Qualitative",
            value: "qualitative",
          },
        ]}
        required
      />
    </div>

    {#if $data.isQualitativeAsString == "quantitative"}
      <!-- ALGORITHM -->
      <div>
        <MultiSelect
          items={$_algorithms}
          placeholder="Select associated algorithm"
          initValues={initAlgorithm}
          handleSelectChange={handleAlgorithmChange}
          {itemId}
          {label}
          multiple={false}
          name="algorithm_id"
          componentLabel="Algorithm"
          requiredMessage="Algorithm is required"
          required
        />
      </div>
    {:else}
      <!-- PARAMETER DEFINITIONS -->
      <div>
        <MultiSelect
          items={$_parameterDefinitions}
          placeholder="Select associated parameter definitions"
          initValues={initParamDefinitions}
          {handleSelectChange}
          {itemId}
          {label}
          name="parameter_definitions"
          componentLabel="Parameter definitions"
          requiredMessage="At Least one parameter definition is required"
          required
        />
        <ValidationMessage for="parameter_definitions" let:messages>
          {#each messages ?? [] as message}
            <Helper class="mt-2" color="red">
              <span>{message}</span>
            </Helper>
          {/each}
        </ValidationMessage>
      </div>
    {/if}
  </div>

  <div class="flex items-start justify-start gap-2">
    <!-- Chemical form definition -->
    <div class="grow">
      <Label for="chemical_form_def" class="mt-4 mb-2"
        >Chemical form definition</Label
      >
      <Textarea
        type="text"
        id="chemical_form_def"
        name="chemical_form_def"
        placeholder="Add chemical form definition..."
        color="base"
      />
    </div>

    <!-- Biological form definition -->
    <div class="grow">
      <Label for="biological_form_def" class="mt-4 mb-2"
        >Biological form definition</Label
      >
      <Textarea
        type="text"
        id="biological_form_def"
        name="biological_form_def"
        placeholder="Add biological form definition..."
        color="base"
      />
    </div>
  </div>

  <div class="flex items-start justify-start gap-2">
    <!-- Physics form definition -->
    <div class="grow">
      <Label for="physics_form_def" class="mt-4 mb-2"
        >Physics form definition</Label
      >
      <Textarea
        type="text"
        id="physics_form_def"
        name="physics_form_def"
        placeholder="Add physics form definition..."
        color="base"
      />
    </div>

    <!-- Spatiotemporal form definition -->
    <div class="grow">
      <Label for="spatiotemporal_form_def" class="mt-4 mb-2"
        >Spatio-temporal form definition</Label
      >
      <Textarea
        type="text"
        id="spatiotemporal_form_def"
        name="spatiotemporal_form_def"
        placeholder="Add spatiotemporal form definition..."
        color="base"
      />
    </div>
  </div>

  <!-- Quantity form definition -->
  <div>
    <Label for="quantity_def" class="mt-4 mb-2">Quantity definition</Label>
    <Textarea
      type="text"
      id="quantity_def"
      name="quantity_def"
      placeholder="Add quantity definition..."
      color="base"
    />
  </div>

  <FormCreationButtons
    onClose={() => {
      handleFormReset(emptyMetric);
      $metricFormDialog.close();
    }}
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    create={!$editModeOn}
  />
</form>
