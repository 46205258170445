<script lang="ts">
  import {
    PlusIcon as Plus,
  } from "@rgossiaux/svelte-heroicons/solid";

  import Dialog from "./Dialog.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import CreateConcept from "@components/Actions/CreateConcept.svelte";
  import { nodeTypes } from "@utils/nodeTypes.js";

  export let context: "graph" | "thesaurusPanel" | "rightGraphPanel" =
    "thesaurusPanel";
  let category;

  let dialog;
  let createConcept;

  export const show = (c, coord) => {
    category = c;
    dialog.show(coord);
  };

  export const close = () => {
    dialog.close();
  };
</script>

<CreateConcept bind:this={createConcept} />
<Dialog bind:this={dialog}>
  <div class="p-1 mt-2 mb-2">
    {#if category}
      <Can roles={[Roles.DEV, Roles.CONCEPT]}>
        {#if context == "thesaurusPanel"}
          <div
            class="flex flex-row ml-4 hover:font-bold hover:cursor-pointer"
            on:click={() => {
              createConcept.show(null, category);
              dialog.close();
            }}
          >
            <Plus class="w-5 h-5 mr-2" />
            <p>
              Create a {nodeTypes[category].label}
            </p>
          </div>
        {/if}
      </Can>
    {/if}
  </div>
</Dialog>

<style>
  span {
    @apply hover:font-bold hover:cursor-pointer;
    margin-left: 16px;
    display: flex;
  }
</style>
