// @ts-nocheck
import { AggregationsService } from "@client";
import type { NodesWithStories } from "@client";
import { dataStore } from "@stores/datastore";
import { updateDataStoreAfterUpdate } from "./node";
import { failureToast as notifyFailure } from "../utils/toast-theme";

export const aggregateParentsOfNode = (nodeId: number): NodesWithStories[] => {
  return dataStore.nodes
    .filter((n) => n.type === "component")
    .filter((n) => {
      return n.aggregate.find((a) => a.nodeId === nodeId);
    });
};

export const createPart = async (aggregateId: number, nodeId: number) => {
  try {
    const aggregate =
      await AggregationsService.insertAggregationApiV1AggregationsPost({
        aggregateId,
        nodeId,
      });
    let nodeToUpdate = dataStore.nodes.find((n) => n.id === aggregateId);
    nodeToUpdate.aggregate.push(aggregate);
    updateDataStoreAfterUpdate("Success!", nodeToUpdate);
  } catch (e) {
    notifyFailure(`${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deletePart = async (id: number) => {
  try {
    let res =
      await AggregationsService.deleteAggregationApiV1AggregationsIdDelete(id);
    let nodeToUpdate = dataStore.nodes.find((n) => {
      return n.aggregate.find((a) => a.id == id);
    });
    nodeToUpdate.aggregate = nodeToUpdate.aggregate.filter((a) => a.id != id);
    updateDataStoreAfterUpdate("Success!", nodeToUpdate);
  } catch (e) {
    console.error(e);
  }
};
