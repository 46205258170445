/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ResourceType {
    PHOTO = 'photo',
    FORMAL_DEFINITION = 'formal_definition',
    SCIENTIFIC_PAPER = 'scientific_paper',
    REPORT = 'report',
    MONITORING_DATA = 'monitoring_data',
}
