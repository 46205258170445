import {
  NodesService,
  Type,
  type Node,
  type NodeBase,
  type Resource,
  type UpdateStatus,
  type Sector,
} from "@client";
import type { NodesWithStories, UpdateNode } from "@client";
import { dataStore } from "@stores/datastore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../utils/toast-theme";
import { createEdge } from "./edge";
import type { Edge } from "@utils/formatData";
import { aggregateParentsOfNode } from "./aggregate";
import { hasItemsFromKeyValueCurry } from "@utils/core/array";

export const createNode = (
  type: Type,
  name: string,
  comment: string = "",
  originNode: NodesWithStories = null,
  dir?: "to" | "from"
) => {
  let node: Node = {
    type,
    name,
    description: `description of the node: ${name}`,
    comment: `${comment} \n \n ---`,
  };
  NodesService.insertNodeApiV1NodesPost(node).then((res) => {
    if (originNode) {
      dir == "to" ? createEdge(originNode, res) : createEdge(res, originNode);
    }
    dataStore.addNodeToAllNodes(res);
    dataStore.addNodeToNodesHierarchy(res);
    dataStore.setSelectedNode(res);
  });
};

export const createNodeAsync = (nodeData: Node) => {
  return NodesService.insertNodeApiV1NodesPost(nodeData);
};

export const afterCreateNodeAsync = (node: NodesWithStories): void => {
  notifySuccess(`The concept ${node.name} has been saved`);
  dataStore.addNodeToAllNodes(node);
  dataStore.addNodeToNodesHierarchy(node);
  dataStore.setSelectedNode(node);
};

export const getNodeResourcesAsync = (nodeId: number) => {
  return NodesService.getNodeResourcesApiV1NodesIdResourcesGet(nodeId);
};

export const addNodeResourceAsync = (nodeId: number, data: Resource) => {
  return NodesService.addResourceToNodeApiV1NodesIdResourcesPost(nodeId, data);
};

export const saveNode = (node: Node) => {
  NodesService.insertNodeApiV1NodesPost(node).then((res) => {
    dataStore.addNodeToAllNodes(res);
    dataStore.addNodeToNodesHierarchy(res);
    dataStore.setSelectedNode(res);
  });
};

export const updateNode = async (nodeId: number, data: UpdateNode = null) => {
  try {
    const response = await NodesService.putNodeApiV1NodesIdPut(nodeId, data);
    updateDataStoreAfterUpdate("Success!", response);
  } catch (e) {
    notifyFailure(`${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateNodeStatus = async (id: number, data: UpdateStatus) => {
  try {
    const newNode = await NodesService.updateStatusApiV1NodesIdStatusPut(
      id,
      data
    );
    updateDataStoreAfterUpdate(
      `The status has been updated to ${newNode.status}`,
      newNode
    );
  } catch (e) {
    console.log(e);
  }
};

export const updateDataStoreAfterUpdate = (
  successMessage: string,
  newNode: NodesWithStories
) => {
  successMessage !== "" && notifySuccess(successMessage);
  dataStore.updateSelectedNode(newNode);
  dataStore.updateNodeInAllNodes(newNode);
  dataStore.updateNodeInNodesHierarchy(newNode);
  dataStore.updateNodeInGraph(newNode);
  dataStore.updateNodeFromStoriesHierarchy(newNode);
};

export const removeFromAggregate = (id: number) => {
  const parentNode = aggregateParentsOfNode(id);
  parentNode.forEach((parent) => {
    parent.aggregate = parent.aggregate.filter((a) => a.nodeId != id);
    updateDataStoreAfterUpdate("", parent);
  });
};

export const deleteNode = (id: number) => {
  NodesService.deleteNodeApiV1NodesIdDelete(id).then(() => {
    notifySuccess(`The node has been deleted`);
    removeFromAggregate(id);
    let ids: Set<number> = new Set([id]);
    dataStore.removeNodeToAllNodes(id);
    dataStore.removeNodeToNodesHierarchy(id);
    dataStore.graphData.links.forEach((edge) => {
      if (isEdgeOf(edge, id)) {
        if (isSingleIncomingEdgeOf(edge) && hasSingleEdge(edge.target.id)) {
          ids.add(edge.target.id);
        }
        if (isSingleOutcomingEdgeOf(edge) && hasSingleEdge(edge.source.id)) {
          ids.add(edge.source.id);
        }
        dataStore.removeLinkInGraph(edge.id);
      }
    });
    dataStore.removeNodeInGraph(ids);
    dataStore.removeNodesFromStoriesHierarchy([id]);
    refreshInterfaceAfterDeletion(id);
  });
};

const refreshInterfaceAfterDeletion = (node_id: number) => {
  if (dataStore.selectedNode && dataStore.selectedNode.id == node_id) {
    dataStore.setSelectedNode(null);
  }
  if (
    dataStore.selectedEdge &&
    (dataStore.selectedEdge.source.id == node_id ||
      dataStore.selectedEdge.target.id == node_id)
  ) {
    dataStore.setSelectedEdge(null);
  }
};

export const createSubNode = (type: Type, name: string, id: number) => {
  let node = {
    type,
    name,
    description: `description of the node: ${name}`,
    comment: `comment of the node: ${name}`,
    parentId: id,
  };
  NodesService.insertNodeApiV1NodesPost(node).then((res) => {
    dataStore.addNodeToAllNodes(res);
    dataStore.addNodeToNodesHierarchy(res);
    dataStore.setSelectedNode(res);
  });
};

/**
 * Returns true if provided edge is the only incoming edge of its target node.
 * Else returns false.
 *
 * @param {Edge} edge the edge to check
 * @returns wether the edge is the only incoming edge of its target node.
 */
export const isSingleIncomingEdgeOf = (edge: Edge): boolean => {
  return (
    dataStore.graphData.links.filter((e) => e.target.id == edge.target.id)
      .length == 1
  );
};

/**
 * Returns true if provided edge is the only outcoming edge of its source node.
 * Else returns false.
 *
 * @param {Edge} edge the edge to check
 * @returns wether the edge is the only outcoming edge of its source node.
 */
export const isSingleOutcomingEdgeOf = (edge: Edge): boolean => {
  return (
    dataStore.graphData.links.filter((e) => e.source.id == edge.source.id)
      .length == 1
  );
};

/**
 * Returns true if the provided edge connects the node with given id.
 * Else returns false.
 *
 * @param {Edge} edge the edge to check
 * @param {number} node_id the id of the node
 * @returns wether the edge connects the node with given id with another node.
 */
export const isEdgeOf = (edge: Edge, node_id: number): boolean => {
  return edge.target.id === node_id || edge.source.id === node_id;
};

/**
 * Returns true if the provided edge connects two nodes inside a cluster of nodes (eg: a story).
 * Else returns false.
 *
 * @param {Edge} edge the edge to check
 * @param { Node[] | NodeBase[] | NodesWithStories[]} nodes the cluster of nodes to check
 * @returns wether the edge connects two nodes withiin a cluster of nodes.
 */
export const isEdgeWithinNodesCluster = (
  edge: Edge,
  nodes: Node[] | NodeBase[] | NodesWithStories[]
): boolean => {
  return (
    hasItemsFromKeyValueCurry("id", edge.target.id)(nodes) &&
    hasItemsFromKeyValueCurry("id", edge.source.id)(nodes)
  );
};

/**
 * Returns true if the provided edge is connected as source or target to a cluster of nodes (eg: a story)
 * Equialent to surrounding edges
 * Else returns false.
 *
 * @param {Edge} edge the edge to check
 * @param {Node[] | NodeBase[] | NodesWithStories[]} nodes the cluster of nodes to check
 * @returns wether the edge connects two nodes withiin a cluster of nodes.
 */
export const isEdgeOfNodesCluster = (
  edge: Edge,
  nodes: Node[] | NodeBase[] | NodesWithStories[]
): boolean => {
  return (
    hasItemsFromKeyValueCurry("id", edge.target.id)(nodes) ||
    hasItemsFromKeyValueCurry("id", edge.source.id)(nodes)
  );
};

export const hasSingleEdge = (node_id: number): boolean => {
  return (
    dataStore.graphData.links.filter((e) => isEdgeOf(e, node_id)).length == 1
  );
};

export const edgesOf = (node_id: number) => {
  return dataStore.graphData.links
    .filter((l) => !l.fake)
    .filter((l) => l.target.id === node_id || l.source.id === node_id);
};

/**
 * Returns true if the provided name is already taken by another existing node.
 * @param {string}name
 *
 * @returns {boolean} boolean for if name is already in use
 */
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return (dataStore.nodes as NodesWithStories[]).some(
      (node) => node.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};

/**
 * Returns true if the provided edge connects the node with given id.
 * Else returns false.
 *
 * @param {string} name value to check
 * @returns boolean for if name is undefined or already in use.
 */
export const checkName = (name: string): boolean => {
  if (!name) {
    return true;
  }
  return isNameAlreadyTaken(name);
};

/**
 * Returns true if the provided edge connects the node with given id.
 * Else returns false.
 *
 * @param {number} id id of the node on which a connection will be created
 * @param {string} name value to check
 * @param {"to" | "from "} dir direction of the connection
 * @returns true if the connection already exists or the name is already used
 */
export const checkNameForConnection = (
  id: number,
  name: string,
  dir: "to" | "from"
): boolean => {
  let isAlreadyConnected = false;
  if (dir == "to") {
    isAlreadyConnected = dataStore.graphData.links.some(
      (l) =>
        l.source.id == id && l.target.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    isAlreadyConnected = dataStore.graphData.links.some(
      (l) =>
        l.source.name.toLowerCase() == name.toLowerCase() && l.target.id == id
    );
  }

  return isAlreadyConnected || name == "";
};

export const addSector = (nodeId: number, sector) => {
  let nodeToUpdate = dataStore.nodes.find((n) => n.id === nodeId);
  nodeToUpdate.sectors.push(sector);
  updateDataStoreAfterUpdate("Success!", nodeToUpdate);
  return NodesService.addSectorToNodeApiV1NodesIdSectorsPost(nodeId, sector);
};

export const setSectors = (nodeId: number, sectors) => {
  return NodesService.updateNodeSectorsApiV1NodesIdSectorsPatch(
    nodeId,
    sectors
  );
};

export const removeSector = (nodeId: number, sector) => {
  let nodeToUpdate = dataStore.nodes.find((n) => n.id === nodeId);
  nodeToUpdate.sectors = nodeToUpdate.sectors.filter((s) => s.id != sector.id);
  updateDataStoreAfterUpdate("Success!", nodeToUpdate);
  return NodesService.removeSectorFromNodeApiV1NodesIdSectorsSectorIdDelete(
    nodeId,
    sector.id
  );
};

export const getNodeNameById = (id: number) => {
  let n = dataStore.nodes.find((n) => n.id === id);
  return n ? n.name : undefined;
};

export const getNodeById = (id: number) => {
  return  dataStore.nodes.find((n) => n.id === id);
};


export const updateNodeEnvprocess = (id: number, envProcessIds : number[]) => {
  NodesService.addEnvprocessToNodeApiV1NodesIdEnvProcessPost(id, envProcessIds)
}

NodesService;
