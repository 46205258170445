<script lang="ts">
  import ItemStatusIcon from "@components/icons/ItemStatus.svelte";
  import Carousel from "svelte-carousel";
  import { dataStore } from "@stores/datastore";
  import { nodeTypes } from "@utils/nodeTypes";
  import SynonymsPreview from "./NodePreview/SynonymsPreview .svelte";
  import TitleInfoBox from "./NodePreview/TitleInfoBox.svelte";
  import { getCtx as nodeEditorContext } from "@components/Editors/NodeEditor/context";
  import InsideAbuDhabiPreview from "./NodePreview/InsideAbuDhabiPreview.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import { ResourceType } from "../../../../client/models/ResourceType";
  import EditButton from "../edit/EditButton.svelte";
  import DescriptionInfoBox from "../../NodeEditor/Header/NodePreview/DescriptionPreview.svelte";
  import { PhotographIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { getCtx as getResourceCtx } from "@components/Actions/CreateResource/context";
  import { updateNode } from "@utils/node";
  import {
    XIcon,
    CheckIcon,
  } from "@rgossiaux/svelte-heroicons/solid";

  let { _selectedNode: node } = dataStore;
  let { resources } = getResourceCtx();
  const { editOn } = nodeEditorContext();
  $: $node;
</script>

{#if $node}
  <!-- Type & Actions -->
  <div class="flex items-center justify-between my-2 mr-2">
    <div class="flex-none text-opacity-50 uppercase text-md text-base-content">
      {nodeTypes[$node.type].label}
    </div>
    <div class="flex-none">
      <Can roles={[Roles.DEV, Roles.CONCEPT]}>
        <EditButton showIcon onClick={editOn} />
      </Can>
    </div>
  </div>
  <!-- Title -->
  <div class="flex items-center justify-start gap-2">
    <ItemStatusIcon type={$node.type} status={$node.status} />
    <TitleInfoBox title={$node.name} color={nodeTypes[$node.type].color()} />
  </div>
  <div class="flex items-center justify-start mt-1 mb-1 text-xs">
      {#if $node.validated }
        <CheckIcon class="w-4 h-4 mr-2 rounded-full btn-success" />
      {:else}
        <XIcon class="w-4 h-4 mr-2 rounded-full btn-error" />
      {/if}
      <span>Created by: {$node.inserted_by}</span> 
      <button
        type="button"
        class="ml-2 kg-btn-outline"
        style="padding: 6px; height: 1.6rem; min-height: 1.6rem;"
        on:click={() => updateNode($node.id, { validated: !$node.validated })}
      >
        {$node.validated ? "invalidate" : "validate"}
      </button>
  </div>
  <!-- Synonyms -->
  <SynonymsPreview synonyms={$node.synonyms} />
  {#await resources.init("node", $node.id) then}
    {#if $resources && $resources.filter((r) => r.type === ResourceType.PHOTO && r.soft_deleted_by === null).length !== 0}
      <Carousel>
        {#each $resources.filter((r) => r.type === ResourceType.PHOTO && r.soft_deleted_by === null) as resource}
          <img
            src={resource.url}
            class="object-cover h-full col-span-2 rounded-r-lg"
            alt={resource.description}
          />
        {/each}
      </Carousel>
    {:else}
      <div
        class="flex flex-col items-center justify-center mr-4 rounded-lg bg-base-300 h-28"
      >
        <PhotographIcon class="opacity-50 w-9 h-9 text-base-content" />
        <span class="opacity-50 text-base-content"
          >There is no picture at the moment</span
        >
      </div>
    {/if}
  {/await}
  <!-- {#if $node.resources && $node.resources.filter((r) => r.type === ResourceType.PHOTO).length !== 0}
    <Carousel>
      {#each $node.resources.filter((r) => r.type === ResourceType.PHOTO) as resource}
        <img
          src={resource.url}
          class="object-cover h-full col-span-2 rounded-r-lg"
          alt={resource.description}
        />
      {/each}
    </Carousel>
  {:else}
  <div class="flex flex-col items-center justify-center mr-4 rounded-lg bg-base-300 h-28">
    <PhotographIcon class="opacity-50 w-9 h-9 text-base-content" />
    <span class="opacity-50 text-base-content">There is no picture at the moment</span>
  </div>
  {/if} -->
  <!-- Occurrence in Abu Dhabi?-->
  <InsideAbuDhabiPreview
    insideAD={$node.inside_ad}
    outsideAD={$node.outside_ad}
  />
  <!-- Description -->
  <DescriptionInfoBox content={$node.description} />
{/if}
