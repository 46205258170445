<script lang="ts">
  import { dataStore } from "@stores/datastore.js";
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
    XIcon as ChevronUnfold,
  } from "@rgossiaux/svelte-heroicons/solid";
  import { InformationCircleIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { nodeTypes } from "@utils/nodeTypes.js";
  import { Popover } from "@core-ui";
  import Pastille from "@components/icons/Pastille.svelte";
  import CategoryMenu from "@components/dialog/CategoryMenu.svelte";
  import { checkRoles, Roles } from "@utils/decorators";

  export let type;
  export let index;
  const { _nodesHierarchy, _alternativeNodesHierarchy } = dataStore;

  class NodeMenuClass {
    bind;

    @checkRoles([Roles.CONCEPT, Roles.STORY])
    onRightClick(e, nodeCategory) {
      this.bind.show(nodeCategory, { x: e.x, y: e.y });
    }
  }
  
  export let compact:boolean = false;
  export let nodeClickAction:((node) => {});
  export let useAlternativeContext:boolean = false;
  const nodeMenu = new NodeMenuClass();

</script>

<Popover
  triggeredBy={`#concept-hierarchy-tree-category-${type}`}
  class="text-sm font-light w-96 z-20"
  placement="right"
  defaultClass="p-0"
  arrow={true}
>
  <div class="grid grid-cols-5">
    <div class="col-span-3 p-3">
      <div class="space-y-2">
        <h3 class="font-semibold text-gray-900 dark:text-white">
          {nodeTypes[type].label}:
        </h3>
        <p class="text-gray-500 dark:text-gray-500">
          {nodeTypes[type].description}
        </p>
      </div>
    </div>
    <img
      src={nodeTypes[type].bannerImgSrc}
      class="object-cover h-full col-span-2 rounded-r-lg"
      alt="Jubail Mangrove Park in Abu Dhabi"
    />
  </div>
</Popover>

<CategoryMenu bind:this={nodeMenu.bind} />
<li
  on:click={() => {
      if (useAlternativeContext){
        ($_alternativeNodesHierarchy[type].display = !$_alternativeNodesHierarchy[type].display)
        
      }else {
        ($_nodesHierarchy[type].display = !$_nodesHierarchy[type].display)
      }
    }
  }
  on:contextmenu|preventDefault={(e) => nodeMenu.onRightClick(e, type)}
  class="flex items-center justify-between {compact ? "" : "py-1" } pr-1 hover:bg-base-300"
>
  <div
    data-testid={`node-type-${type.toLowerCase()}`}
    class="flex flex-row items-center {compact ? "" : "justify-center" } w-full gap-2"
  >
    {#if useAlternativeContext}
      {#if $_alternativeNodesHierarchy[type].children.length === 0}
        <ChevronUnfold class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron text-base-300" style="cursor:pointer;" />
      {:else if $_alternativeNodesHierarchy[type].display}
        <ChevronD class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron" style="cursor:pointer;" />
      {:else}
        <ChevronR class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron" style="cursor:pointer;" />
      {/if}
    {:else}
      {#if $_nodesHierarchy[type].children.length === 0}
        <ChevronUnfold class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron text-base-300" style="cursor:pointer;" />
      {:else if $_nodesHierarchy[type].display}
        <ChevronD class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron" style="cursor:pointer;" />
      {:else}
        <ChevronR class="flex-none {compact ? "w-5 h-5" : "w-6 h-6" } chevron" style="cursor:pointer;" />
      {/if}
    {/if}
    <Pastille {type} />
    <div class="min-w-0 {compact ? "" : "grow" }">
      <div class="truncate">
        {nodeTypes[type].label}
      </div>
    </div>
    {#if !compact}
      <InformationCircleIcon
        class="w-4 h-4"
        style={`color: ${nodeTypes[type].color().toLowerCase()}`}
        id={`concept-hierarchy-tree-category-${type}`}
      />
    {/if}
  </div>
</li>

<style>
  .chevron {
    cursor: pointer;
  }
</style>