<script lang="ts">
  import { Type } from "@client";
  import { dataStore } from "@stores/datastore";
  import Dialog from "../dialog/Dialog.svelte";
  import { setCtx } from "./CreateConcept/context";
  import Form from "./CreateConcept/Form.svelte";
  setCtx();

  export const show = (coord, conceptType: string, nodeId: number) => {
    dialog.show(coord);
    type = conceptType as Type;
    parentId = nodeId;
    parentName = dataStore.nodes.find((n) => n.id == parentId).name;
  };

  function setInitialValues() {
    initialValues = {
      parentId,
      type,
    };
  }

  let dialog;
  let type: Type;
  let parentId: number;
  let initialValues;
  let parentName: string;

  $: type && setInitialValues();
  $: parentId && setInitialValues();
</script>

<Dialog bind:this={dialog} dismissOnClickOutside={false}>
  <div
    class="inline-block w-full max-w-md p-6 overflow-hidden text-left align-middle bg-base-200 rounded-2xl"
  >
    <h3 class="text-lg font-medium leading-6 text-base-content ">
      New sub-concept of {parentName}
    </h3>
    <Form {initialValues} onClose={() => dialog.close() } />
  </div>
</Dialog>
