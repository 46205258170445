/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ImpactType {
    OVERSUPPLY = 'Oversupply',
    UNDERSUPPLY = 'Undersupply',
}
