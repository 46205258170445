<script lang="ts">
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { Status } from "@client";
  import type { UpdateNode } from "@client";
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { CheckIcon as Check } from "@rgossiaux/svelte-heroicons/solid";
  import { getCtx } from "@components/Editors/NodeEditor/context";
  import { createForm } from "felte";
  import { dataStore } from "@stores/datastore";

  import { updateNode } from "@utils/node";

  import { Label, Helper, Textarea, Input } from "@core-ui";
  import InsideAbuDhabiForm from "../edit/InsideAbuDhabiForm.svelte";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";

  let { _selectedNode: node } = dataStore;
  let { editOff } = getCtx();

  /**
   * Form Types, methods, schema and validations
   * const { form, data, isValid, isDirty, touched, isSubmitting } = formCtx; NOT WORKING (TRY LATER)
   */

  const { form, data, isValid, errors, isSubmitting, setInitialValues, reset } =
    createForm<UpdateNode>({
      onSubmit: (values) => {
        updateNode($node.id, {
          ...values,
        });
        editOff();
      },
      validate(values) {
        const errors: {
          name: string[];
          synonyms: string[];
          outside_ad: string[];
          inside_ad: string[];
          description: string[];
        } = {
          name: [],
          synonyms: [],
          outside_ad: [],
          inside_ad: [],
          description: [],
        };
        if (!values.name) errors.name.push("Title is required.");

        return errors;
      },
      extend: [reporter],
    });
  const handleFormReset = (values: UpdateNode) => {
    reset();
    setInitialValues(values);
  };

  $: $node &&
    handleFormReset({
      name: $node.name,
      synonyms: $node.synonyms,
      description: $node.description,
      inside_ad: $node.inside_ad,
      outside_ad: $node.outside_ad,
    } as UpdateNode);
</script>

<!-- FORM -->
<form use:form class="w-full pr-2 mb-4 form-control">
  <!-- <fieldset> -->
  <!-- <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    Update Concept
  </h1> -->
  <!-- NAME -->
  <Label for="name" class="mt-6 mb-1">Title</Label>
  <Input
    class="mt-2"
    placeholder="Type concept title here"
    id="name"
    name="name"
    required
    color={"base"}
  />
  <ValidationMessage for="name" let:messages>
    {#each messages ?? [] as message}
      <Helper class="mt-2" color="red">
        <span>{message}</span>
      </Helper>
    {/each}
  </ValidationMessage>
  <!-- SYNONYMS -->
  <Can roles={[Roles.DEV, Roles.CONCEPT]}>
    <Label for="synonyms" class="mt-6 mb-1">Synonyms</Label>
    <Input
      class="mt-2"
      placeholder="Add synonyms here..."
      id="synonyms"
      name="synonyms"
      color={"base"}
    />
    <ValidationMessage for="synonyms" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </Can>
  <!-- adPresent -->
  <Label class="mt-6 mb-1">Occurrence</Label>
  <InsideAbuDhabiForm inside_ad={node.inside_ad} outside_ad={node.outside_ad} />
  <ValidationMessage for="synonyms" let:messages>
    {#each messages ?? [] as message}
      <Helper class="mt-2" color="red">
        <span>{message}</span>
      </Helper>
    {/each}
  </ValidationMessage>
  <!-- DESCRIPTION -->
  <Label for="description" class="mt-6 mb-1">Official definition</Label>
  <Textarea
    type="text"
    class="mt-2"
    id="description"
    name="description"
    placeholder="Add official defintion here..."
    color={"base"}
  />
  <ValidationMessage for="description" let:messages>
    {#each messages ?? [] as message}
      <Helper class="mt-2" color="red">
        <span>{message}</span>
      </Helper>
    {/each}
  </ValidationMessage>

  <BlocCancelConfirm
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    vLabel="Save edits"
    vType="success"
    xConfirmType="exitConceptEdition"
    xOnConfirm={() => editOff()}
  />
</form>
