<script lang="ts">
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import MainLayout from "@layouts/Main.svelte";
  import BlankLayout from "@layouts/Blank.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { AnyRoles, Roles } from "@utils/decorators";
  import Graph from "@components/Graph/Graph.svelte";
  import Header from "@components/Header.svelte";
  import ThesaurusPanel from "@components/Main/ThesaurusPanel.svelte";
  import ContentPanel from "@components/Main/ContentPanel.svelte";
  import "../utils/websocket";
  import { setCtx } from "@components/Editors/NodeEditor/context";
  import { setCtx as setCtxStory } from "@components/Editors/StoryEditor/context";
  import { authentication } from "@utils/keycloak";
  import { closeWebsocket } from "@utils/websocket";

  setCtx();
  setCtxStory();

  let toastDefaultOptions = {
    duration: 4000, // duration of progress bar tween to the `next` value
    initial: 1, // initial progress bar value
    next: 0, // next progress value
    pausable: false, // pause progress bar tween on mouse hover
    dismissable: true, // allow dismiss with close button
    reversed: true, // insert new toast to bottom of stack
    intro: { x: 25 }, // toast intro fly animation settings
    theme: {}, // css var overrides
    classes: [], // user-defined classes
  };
</script>

<Can
  roles={[Roles.DEV, Roles.CONTENT, Roles.CONCEPT, Roles.STORY, Roles.VIEWER]}
>
  <MainLayout>
    <SvelteToast options={toastDefaultOptions} slot="toast" />
    <Header slot="header" />
    <ContentPanel slot="rightPanel" />
    <ThesaurusPanel slot="leftPanel" />
    <Graph slot="graph" />
  </MainLayout>
</Can>
<Can roles={[Roles.DISABLED]}>
  <BlankLayout>
    <Header slot="header" />
    <div class="flex flex-col items-center justify-center w-full h-full">
      <div class="flex-none">
        account not activated, please contact your administrator
      </div>
      <button
        class="flex-none btn"
        on:click={() => {
          $authentication.logout();
          closeWebsocket();
        }}>Disconnect</button
      >
    </div>
  </BlankLayout>
</Can>
