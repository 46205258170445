<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteMetric } from "@utils/supporting/stateMetric";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"
  
  const { metricFormDialog, editModeOn } = getCtx();
  const { _stateMetrics, _selectedStateMetric } = supportingStore;

  const showEditMetric = (metric) => {
    _selectedStateMetric.set(metric);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <!-- <th>Unit of measure</th>
        <th>Algorithm definition</th>
        <th>Parameter definitions</th> -->
        <th>Metric of form</th>
        <th>Metric of func. health</th>
        <!-- <th>Notes</th> -->
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_stateMetrics as metric}
        <tr>
          <td>{formatAtomicProperty(metric.name)}</td>
          <!-- <td>{formatAtomicProperty(metric.unit)}</td>
          <td>{formatAtomicProperty(metric.definition)}</td>
          <td>
            {#each metric.parameter_definitions as param, index}
              {param.name}{#if index < metric.parameter_definitions.length - 1}, {/if}                  
            {/each}
          </td> -->
          <td>{metric.metrics_of_form ? metric.metrics_of_form.name : 'None'}</td>
          <td>{metric.metrics_of_functional_health ? metric.metrics_of_functional_health.name : 'None'}</td>
          <!-- <td>{formatAtomicProperty(metric.notes)}</td> -->
          <GridAction
            onEdit={() => showEditMetric(metric)}
            onDelete = {() => deleteMetric(metric.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>
