import {
  StoriesService,
  type UpdateStory,
  type NodesWithStories,
  type Stories,
  type StoriesWithNodes,
  type Resource,
} from "@client";
import type { Edge } from "@utils/formatData";
import { dataStore } from "@stores/datastore";
import { successToast, failureToast } from "./toast-theme";

export type StoriesHierarchy = StoriesWithNodes & {
  children: StoriesHierarchy[];
  display: boolean;
};

const addToStoryHierarchy = (s: StoriesHierarchy) => {
  dataStore._storyHierarchy.update((stories) => {
    let newHierarchy: StoriesHierarchy[] = stories;
    if (s.path.split(".").length == 1) {
      newHierarchy.push(s);
      return newHierarchy;
    } else {
      recursiveAdd(newHierarchy, s, true);
      return newHierarchy;
    }
  });
};

const recursiveAdd = (
  stories: StoriesHierarchy[],
  story: StoriesHierarchy,
  display: boolean = false
) => {
  let pathSplit = story.path.split(".");
  let elem;
  for (let [index, pathV] of pathSplit.entries()) {
    if (index !== pathSplit.length - 1) {
      elem = stories.find((n) => n.id == +pathV);
      elem.display = display;
    } else {
      // TODO : recursive if we nedd more than 1 degre of nesting
      elem.children.push(story);
    }
  }
};

const removeFromStoryHierarchy = (id: number) => {
  dataStore._storyHierarchy.update((stories) => {
    let newHierarchy: StoriesHierarchy[] = stories;
    newHierarchy = recursiveRemove(newHierarchy, id);
    return newHierarchy;
  });
};

const recursiveRemove = (stories: StoriesHierarchy[], id: number) => {
  let story = stories.find((s) => s.id == id);
  if (story) return [...stories.filter((s) => s.id != id)];
  else {
    for (let elem of stories) {
      elem.children = recursiveRemove(elem.children, id);
    }
  }
  return stories;
};

const updateStoryHierarchy = (s: StoriesHierarchy) => {
  dataStore._storyHierarchy.update((stories) => {
    let newHierarchy: StoriesHierarchy[] = stories;
    let storyIndex = newHierarchy.findIndex((story) => story.id == s.id);
    let pathSplit = s.path.split(".");
    let elem = newHierarchy;
    for (let [index, pathV] of pathSplit.entries()) {
      if (index !== pathSplit.length - 1) {
        elem = elem.find((n) => n.id == +pathV).children;
      } else {
        storyIndex = elem.findIndex((story) => story.id == s.id);
        elem[storyIndex] = s;
      }
    }
    return newHierarchy;
  });
};

export const isNodeofStory = (
  n: NodesWithStories,
  s: StoriesHierarchy = dataStore.selectedStory
): boolean => {
  return n?.stories.some((story) => story.id === s.id);
};

export const storyOfNode = (n: NodesWithStories) => {
  let storyId = n.stories[0]?.id;
  return dataStore.stories.find((s) => s.id === storyId);
};

export const storyOfEdge = (e: Edge) => {
  let storyId1 = e.source?.stories[0]?.id;
  let storyId2 = e.target?.stories[0]?.id;
  return storyId1 === storyId2
    ? dataStore.stories.find((s) => s.id === storyId1)
    : null;
};

export const isEdgeofStory = (e: Edge): boolean => {
  return isNodeofStory(e.source) && isNodeofStory(e.target);
};

export const isEdgeofStoryGraph = (e: Edge): boolean => {
  return isNodeInStoryGraph(e.source) && isNodeInStoryGraph(e.target);
};

export const isNodeOfParentOrChildStory = (
  n: NodesWithStories,
  relatedStory: StoriesHierarchy
): boolean => {
  return n.stories.some((story) => {
    return story.path.split(".").includes(String(relatedStory.id));
  });
};

export const isNodeInStoryGraph = (node: NodesWithStories): boolean => {
  let selected = dataStore.selectedStory;
  if (!isMainStory(selected) || isEmptyMainStory(selected)) {
    return isNodeofStory(node);
  } else {
    return isNodeOfParentOrChildStory(node, selected);
  }
};

export const isMainStory = (story: StoriesHierarchy): boolean => {
  return story.path.split(".").length == 1 ;
};

export const isEmptyMainStory = (story: StoriesHierarchy): boolean => {
  return isMainStory(story) && story.children.length == 0;
};

export const addStory = (story): void => {
  StoriesService.insertApiV1StoriesPost(story).then((s) => {
    let story: StoriesHierarchy = {
      ...s,
      display: false,
      children: [],
      nodes: [],
    };
    dataStore.updateStoryInAllStory(story)
    addToStoryHierarchy(story);
    dataStore.setSelectedNode(null);
    dataStore.setSelectedStory(story);
    successToast(
      `The ${s.isScenario ? "scenario" : "story"} ${s.name} is saved`
    );
  });
};

export const updateStory = (id: number, data: UpdateStory): void => {
  StoriesService.updateApiV1StoriesIdPost(id, data).then((story) => {
    dataStore._selectedStory.update((ss) => {
      return { ...ss, ...story };
    });
    dataStore.updateStoryInAllStory(dataStore.selectedStory)
    updateStoryHierarchy(dataStore.selectedStory);
    for(let storyChild of dataStore.selectedStory.children){
      storyChild.category = dataStore.selectedStory.category
      dataStore.updateStoryInAllStory(storyChild)
      updateStoryHierarchy(storyChild);
    }
    successToast(`The graph ${story.name} has been updated`);
  }).catch(()=> {
    failureToast(`Error during the update of the graph`)
  });
};

export const deleteStory = (id: number): void => {
  StoriesService.deleteApiV1StoriesIdDelete(id).then((res) => {
    let selected = dataStore.selectedStory;
    if (selected && selected.id == id) {
      dataStore._selectedStory.set(null);
    }
    let deletedStory = dataStore.stories.find((s) => s.id == id);
    dataStore._stories.update((stories) => [
      ...stories.filter((s) => s.id != id),
    ]);
    removeFromStoryHierarchy(id);
    successToast(
      `The ${deletedStory.isScenario ? "scenario" : "story"} has been deleted`
    );
  });
};

export const getStoryResourcesAsync = (storyId: number) => {
  return StoriesService.getStoryResourcesApiV1StoriesIdResourcesGet(storyId);
};

export const addStoryResourceAsync = (storyId: number, data: Resource) => {
  return StoriesService.addResourceToStoryApiV1StoriesIdResourcesPost(
    storyId,
    data
  );
};

export const addNodeToCurrentStory = (ns: NodesWithStories[]) => {
  if(dataStore.selectedStory) {
    StoriesService.insertNodesApiV1StoriesIdNodesPost(
      dataStore.selectedStory.id,
      ns.map((n) => n.id)
    ).then((res) => {
      ns.forEach((n) => {
        n.stories.push(dataStore.selectedStory);
        dataStore.updateNodeInGraph(n);
        dataStore.updateNodeInNodesHierarchy(n);
        dataStore.updateNodeInAllNodes(n);
        dataStore.insertNodesFromCurrentStory(n);
        if(dataStore.selectedNode && n.id == dataStore.selectedNode.id ) {
          dataStore.updateSelectedNode(n)
        }
        dataStore.updateNodeFromStoriesHierarchy(n)
      });
    });
  }
};

export const deleteNodeFromCurrentStory = (ns: NodesWithStories[]) => {
  StoriesService.deleteNodesApiV1StoriesIdNodesDelete(
    dataStore.selectedStory.id,
    ns.map((n) => n.id)
  ).then((res) => {
    let ids: number[] = [];
    ns.forEach((n) => {
      n.stories = n.stories.filter((s) => s.id !== dataStore.selectedStory.id);
      dataStore.updateNodeInGraph(n);
      dataStore.updateNodeInNodesHierarchy(n);
      dataStore.updateNodeInAllNodes(n);
      ids.push(n.id);
    });
    dataStore.removeNodesFromCurrentStory(ids);
    dataStore.updateStoryInAllStory(dataStore.selectedStory)
    updateStoryHierarchy(dataStore.selectedStory)
    if(dataStore.selectedNode){
      dataStore.updateSelectedNode(dataStore.nodes.find(n => dataStore.selectedNode.id == n.id))
    }
  });
};

export const deleteNodeFromStory = (
  ns: NodesWithStories,
  story: StoriesHierarchy
) => {
  StoriesService.deleteNodesApiV1StoriesIdNodesDelete(story.id, [ns.id]).then(
    (res) => {
      ns.stories = ns.stories.filter((s) => s.id !== story.id);
      story.nodes = story.nodes.filter(n => n.id != ns.id)
      dataStore.updateNodeInGraph(ns);
      dataStore.updateNodeInNodesHierarchy(ns);
      dataStore.updateNodeInAllNodes(ns);
      dataStore.removeNodesFromCurrentStory([ns.id]);
      dataStore.updateStoryInAllStory(story)
      updateStoryHierarchy(story);
      if(dataStore.selectedNode){
        dataStore.updateSelectedNode(dataStore.nodes.find(n => dataStore.selectedNode.id == n.id))
      }
    }
  );
};

export const storyFormatData = (stories: StoriesHierarchy[]) => {
  let hierarchy: StoriesHierarchy[] = [];
  stories
    .sort((s1, s2) =>
      s1.path.split(".").length > s2.path.split(".").length
        ? 1
        : s1.path.split(".").length === s2.path.split(".").length
        ? 0
        : -1
    )
    .forEach((s) => {
      s.children = [];
      s.display = false;
      const pathSplit = s.path.split(".");
      if (pathSplit.length == 1) {
        hierarchy.push(s);
      } else {
        recursiveAdd(hierarchy, s);
      }
    });
  return hierarchy;
};

export const storyType = (
  story: StoriesHierarchy = null
): "scenario" | "story" => {
  if (!story) story = dataStore.selectedStory;
  return story.isScenario ? "scenario" : "story";
};

export const isNameValid = (name: string): boolean => {
  if (name == "") return false;
  return !dataStore.stories.some((s) => s.name == name);
};
