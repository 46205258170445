/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Aggregation } from '../models/Aggregation';
import type { Aggregations } from '../models/Aggregations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AggregationsService {

    /**
     * Insert Aggregation
     * @param requestBody
     * @returns Aggregations Successful Response
     * @throws ApiError
     */
    public static insertAggregationApiV1AggregationsPost(
        requestBody: Aggregation,
    ): CancelablePromise<Aggregations> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/aggregations/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Aggregation
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteAggregationApiV1AggregationsIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/aggregations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
