<script lang="ts">
  import { PlusCircleIcon as Plus } from "@rgossiaux/svelte-heroicons/solid";
  import CreateStory from "./dialog/CreateStory.svelte";
  import { displayStore } from "@stores/displaystore";

  displayStore.theme;

  let createStory;
  export let label = "New story";
  export let isScenario = false;
  const storyBtnStyle =
    "border-2 border-story-400 hover:bg-story-600 hover:border-story-600 dark:text-story-400 dark:hover:text-black";
  const scenarioBtnStyle =
    "border-2 border-scenario-400 hover:bg-scenario-600 hover:border-scenario-600 dark:text-scenario-400 dark:hover:text-black";
  const baseBtnStyle = `gap-2 normal-case btn btn-sm  rounded-xl text-black bg-transparent`;
</script>

<CreateStory bind:this={createStory} {isScenario} />
<div {...$$restProps}>
  <div class="flex justify-center my-2">
    <button
      class="kg-btn-outline"
      on:click={() => {
        createStory.show();
      }}
    >
      <Plus class="w-6 h-6" />
      {label}
    </button>
  </div>
</div>
