<script lang="ts">
  import {
    deleteNodeFromStory,
    deleteStory,
    isEmptyMainStory,
    isMainStory,
    type StoriesHierarchy,
  } from "@utils/story";
  import { PlusIcon as Plus } from "@rgossiaux/svelte-heroicons/solid";
  import CreateSubStory from "./CreateSubStory.svelte";
  import Dialog from "./Dialog.svelte";
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import { Divider } from "@core-ui";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { dataStore } from "@stores/datastore";

  const { _selectedNode } = dataStore;

  let story: StoriesHierarchy;
  let mode;
  let coordinates;
  let dialog;
  let dialogDeleteStory;
  let createSubStory;

  export const show = (s: StoriesHierarchy, coord, m = "general") => {
    story = s;
    mode = m;
    coordinates = coord;
    dialog.show(coord);
  };
</script>

<CreateSubStory bind:this={createSubStory} isScenario={story?.isScenario} />

<Dialog bind:this={dialog}>
  {#if mode === "general"}
    {#if story && isMainStory(story)}
      <div
        class="mt-3 menu-item"
        on:click={(e) => {
          createSubStory.show(coordinates, story);
          dialog.close();
        }}
      >
        <Plus class="w-5 h-5" /> Create a sub {Boolean(story?.isScenario)
          ? "scenario"
          : "story"}
      </div>
    {/if}
    {#if story && isMainStory(story) && (!isMainStory(story) || isEmptyMainStory(story))}
      <Divider />
    {/if}
    {#if story && (!isMainStory(story) || isEmptyMainStory(story))}
    <div class="mt-3 mb-3 ml-3 menu-item">
      <DeleteButton type="menu" confirmDialog={dialogDeleteStory}>
        <DialogConfirmAction
        bind:this={dialogDeleteStory}
        type="deleteStory"
        actionOnYes={() => {
          deleteStory(story.id);
          dialog.close();
        }}
        />
      </DeleteButton>
    </div>
    {/if}
  {:else if mode === "node"}
    {#if story && (!isMainStory(story) || isEmptyMainStory(story))}
    <div class="mt-3 mb-3 ml-3 menu-item ">
      <DeleteButton
        type="menu"
        icon="Xicon"
        confirmDialog={dialogDeleteStory}
        label="Remove graph"
      >
        <DialogConfirmAction
          bind:this={dialogDeleteStory}
          type="removeNodeFromStory"
          actionOnYes={() => {
            deleteNodeFromStory($_selectedNode, story);
            dialog.close();
          }}
        />
      </DeleteButton>
    </div>
    {/if}
  {/if}
</Dialog>

<style>
  .menu-item {
    @apply mx-2 my-1 flex items-center justify-start gap-1 hover:cursor-pointer hover:font-bold cursor-pointer p-2;
  }

  .delete {
    @apply text-error hover:text-warning-content dark:text-error-content;
  }
</style>
