import { get } from "svelte/store";
import { authentication } from "./keycloak";

enum Roles {
  DEV = "Dev",
  VIEWER = "Viewer",
  STORY = "Story Developer",
  CONCEPT = "Concept Manager",
  CONTENT = "Content Manager",
  DISABLED = "Disabled",
}

export type RolesInfo = {
  [key in Roles]: {
    imgSrc: string;
  };
};

export const rolesInfo: RolesInfo = {
  Dev: {
    imgSrc: "/assets/avatars/default.jpeg",
  },
  Viewer: {
    imgSrc: "/assets/avatars/default.jpeg",
  },
  "Story Developer": {
    imgSrc: "/assets/avatars/default.jpeg",
  },
  "Concept Manager": {
    imgSrc: "/assets/avatars/default.jpeg",
  },
  "Content Manager": {
    imgSrc: "/assets/avatars/default.jpeg",
  },
  Disabled: {
    imgSrc: "/assets/avatars/default.jpeg",
  },
};

enum AnyRoles {
  ALL = "all",
}
type ExtendedRoles = Roles | AnyRoles;

function checkRoles(roles: Roles[]) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const original = descriptor.value;

    descriptor.value = function (...args) {
      if (isUserRoleInOrDev(roles)) return original.call(this, ...args);
    };
  };
}

function can(roles, callback) {
  return (...args) => {
    if (isUserRoleIn(roles)) {
      return callback(...args);
    }
  };
}

function cannot<T extends (...a: Roles[]) => Roles>(
  roles: ExtendedRoles[],
  callback: T
): T {
  return ((...args) => {
    if (!isUserRoleIn(roles)) {
      return callback(...args);
    }
  }) as T;
}

export { checkRoles, can, cannot, Roles, AnyRoles };
export type {
  ExtendedRoles as ExtendedRolesType,
  Roles as RolesType,
  AnyRoles as AnyRolesType,
};

/**
 * Returns true if the user role is in given list or if he/she
 * is a superadmin (i.e his/her role is AnyRoles.ALL).
 *
 * @bug ?? shouldn't second part of the condition  be
 * get(authentication).role === AnyRoles.ALL ??
 *
 *
 * @param {ExtendedRoles[]} roles the role list to check.
 */
export const isUserRoleIn = (roles: ExtendedRoles[]): boolean => {
  return (
    roles.includes(get(authentication).role) || roles.includes(AnyRoles.ALL)
  );
};

/**
 * Returns true if the user role is in given list or if he/she
 * has a DEV role.
 *
 * @param {Roles[]} roles the role list to check.
 */
export const isUserRoleInOrDev = (roles: Roles[]): boolean => {
  return (
    roles.includes(get(authentication).role) ||
    get(authentication).role === Roles.DEV
  );
};
