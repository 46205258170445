<script lang="ts">
  import Dialog from "../dialog/Dialog.svelte";

  let dialog;

  export const show = (coord) => {
    dialog.show(coord);
  };

  export const close = () => {
    dialog.close();
  };
</script>

<Dialog bind:this={dialog}>
  <iframe
    width="800"
    height="600"
    src="https://www.youtube.com/embed/ZN-a3wtpuAw"
  />
</Dialog>

<style>
  span {
    display: flex;
  }

  .invalid {
    display: none;
  }
</style>
