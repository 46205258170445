<script lang="ts">
  export let synonyms: string = "";
  const noSynonymMessage = "No synonyms";
</script>
<!--
@component
Displays concept synonyms if any.
-->
<span class="mt-2 mb-4 text-xs opacity-50 text-base-content">
  {synonyms ? synonyms : noSynonymMessage}
</span>