<script lang="ts">
  import { dataStore } from "@stores/datastore";

  let { _selectedNode } = dataStore;
</script>
<!--
@component
Displays concept sectors if any.
-->
{#if $_selectedNode.sectors}
  <div class="pb-4">
    <div>
      <span>Sectors:</span>
      <span class="font-bold">
        {$_selectedNode.sectors.length > 0
          ? $_selectedNode.sectors.map((s) => s.name)
          : "No data."}
      </span>
    </div>
  </div>
{/if}
