<script lang="ts">
  import {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  } from "@rgossiaux/svelte-headlessui";
  import { groupByStory, groupByType, ungroup } from "@utils/grouping";
  import { displayStore } from "@stores/displaystore";
  import type { Perfo } from "@stores/displaystore";
  import { find, propEq } from "rambda";
  import Check from "./Check.svelte";

  let radioValue = displayStore.perfo;

  let graphLayoutOptions = [
    {
      value: "speed",
      label: "Speed",
      description:
        "Give priority to the speed of interaction with the graph. Suitable for smoother navigation",
    },
    {
      value: "aesthetic",
      label: "Aesthetic",
      description:
        "Prioritizes concepts and connections representation. Suitable for better visualization.",
    },
  ];

  type Option = {
    value: Perfo;
    label: string;
    description: string;
  };

  let selected: Option = find(propEq("value", radioValue))(graphLayoutOptions);

  // function setGraphLayout() {
  //   switch (selected.value) {
  //     case "speed":
  //       groupByType();
  //       break;
  //     case "aesthetic":
  //       groupByStory();
  //       break;
  //     default:
  //       ungroup();
  //   }
  // }
  // $: selected && setGraphLayout();
  $: selected && displayStore.setPerfo(selected.value);
</script>

<div class="px-2 py-2">
  <RadioGroup
    value={selected}
    on:change={(e) => {
      selected = e.detail;
      close();
    }}
  >
    <RadioGroupLabel class="sr-only">Graph layout</RadioGroupLabel>
    <div class="space-y-2">
      {#each graphLayoutOptions as option (option.value)}
        <RadioGroupOption
          value={option}
          class={({ active, checked }) =>
            `${
              active
                ? "ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60"
                : ""
            }
        ${checked ? "bg-sky-900 bg-opacity-75 text-white" : "bg-white"}
          relative rounded-lg shadow-md px-2 py-2 cursor-pointer flex focus:outline-none`}
          let:checked
        >
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
              <div class="text-sm">
                <RadioGroupLabel
                  as="p"
                  class={`font-medium  ${
                    checked ? "text-white" : "text-gray-900"
                  }`}
                >
                  {option.label}
                </RadioGroupLabel>
                <RadioGroupDescription
                  as="span"
                  class={`inline ${checked ? "text-sky-100" : "text-gray-500"}`}
                >
                  <span>
                    {option.description}
                  </span>
                </RadioGroupDescription>
              </div>
            </div>
            {#if checked}
              <div class="flex-shrink-0 text-white">
                <Check />
              </div>
            {/if}
          </div>
        </RadioGroupOption>
      {/each}
    </div>
  </RadioGroup>
</div>
