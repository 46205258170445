<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import SectorsPreview from "./SectorsPreview.svelte";
  import AgentPreview from "./AgentPreview.svelte";
  import EnvProcessPreview from "./EnvProcessPreview.svelte";
  import StateMetricPreview from "./StateMetricPreview.svelte";
  import { hasSupporting, Supporting} from "@utils/supporting";
  import MetricPreview from "./MetricPreview.svelte";

  let { _selectedNode } = dataStore;
</script>

<!--
@component
Displays concept properties.
-->
{#if hasSupporting($_selectedNode.type, Supporting.Agent)}
  <AgentPreview />
{/if}
<MetricPreview />
{#if hasSupporting($_selectedNode.type, Supporting.EnvProcess)}
  <EnvProcessPreview />
{/if}
{#if hasSupporting($_selectedNode.type, Supporting.Sectors)}
  <SectorsPreview />
{/if}
{#if hasSupporting($_selectedNode.type, Supporting.StateMetrics)}
  <StateMetricPreview />
{/if}
