<script lang="ts">
  import { displayStore } from "@stores/displaystore";
</script>

<main data-theme={displayStore.theme}>
  <header id="header"><slot name="header" /></header>
  <div class="h-full" id="content"><slot /></div>
</main>

<style>
  main {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    margin: 0;
    overflow: auto;
  }
  /* [id^="header"] {
    grid-column: 1/-1;
  } */
</style>
