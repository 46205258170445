<script lang="ts">
  import classNames from "classnames";
  let defaultClass = "text-xl font-bold";
  export let title: string = "";
  export let color: string = "";
</script>
<!--
@component
Displays concept title.
-->
<div style="color:{color}" class={classNames(defaultClass, $$props.class)}>
  {title}
</div>
