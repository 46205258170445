<script lang="ts">
  import { Type } from "@client";
  import Dialog from "../dialog/Dialog.svelte";
  import { setCtx } from "./CreateConcept/context";
  import Form from "./CreateConcept/Form.svelte";
  import { nodeTypes } from "@utils/nodeTypes.js";
  setCtx();

  export const show = (coord, conceptType: string) => {
    dialog.show(coord);
    type = conceptType as Type;
  };

  function setInitialValues() {
    initialValues = {
      type,
    };
  }

  let dialog;
  let type: Type;
  let initialValues;

  $: type && setInitialValues();
</script>

<Dialog bind:this={dialog} dismissOnClickOutside={false}>
  <div
    class="inline-block w-full max-w-md p-6 overflow-hidden text-left align-middle bg-base-200 rounded-2xl"
  >
    {#if type}
      <h3 class="text-lg font-medium leading-6 text-base-content ">
        Create new {nodeTypes[type].label}
      </h3>
    {/if}
    <Form {initialValues} onClose={() => dialog.close() } />
  </div>
</Dialog>
