/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Resource } from '../models/Resource';
import type { Stories } from '../models/Stories';
import type { StoriesWithNodes } from '../models/StoriesWithNodes';
import type { Story } from '../models/Story';
import type { UpdateStory } from '../models/UpdateStory';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StoriesService {

    /**
     * Get All
     * @returns StoriesWithNodes Successful Response
     * @throws ApiError
     */
    public static getAllApiV1StoriesGet(): CancelablePromise<Array<StoriesWithNodes>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/stories/',
        });
    }

    /**
     * Insert
     * @param requestBody
     * @returns Stories Successful Response
     * @throws ApiError
     */
    public static insertApiV1StoriesPost(
        requestBody: Story,
    ): CancelablePromise<Stories> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/stories/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get One
     * @param id
     * @returns StoriesWithNodes Successful Response
     * @throws ApiError
     */
    public static getOneApiV1StoriesIdGet(
        id: number,
    ): CancelablePromise<StoriesWithNodes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/stories/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update
     * @param id
     * @param requestBody
     * @returns StoriesWithNodes Successful Response
     * @throws ApiError
     */
    public static updateApiV1StoriesIdPost(
        id: number,
        requestBody: UpdateStory,
    ): CancelablePromise<StoriesWithNodes> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/stories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteApiV1StoriesIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/stories/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Insert Nodes
     * @param id
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static insertNodesApiV1StoriesIdNodesPost(
        id: number,
        requestBody: Array<number>,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/stories/{id}/nodes',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Nodes
     * @param id
     * @param requestBody
     * @returns number Successful Response
     * @throws ApiError
     */
    public static deleteNodesApiV1StoriesIdNodesDelete(
        id: number,
        requestBody: Array<number>,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/stories/{id}/nodes',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Story Resources
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getStoryResourcesApiV1StoriesIdResourcesGet(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/stories/{id}/resources',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Resource To Story
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addResourceToStoryApiV1StoriesIdResourcesPost(
        id: number,
        requestBody: Resource,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/stories/{id}/resources',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
