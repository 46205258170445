<script lang="ts">
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { PencilIcon } from "@rgossiaux/svelte-heroicons/solid";
  export let onEdit: Function = () => {}
  export let onDelete: Function = () => {}

  let deleteSupportingClass;
</script>

<td class="w-4">
    <PencilIcon class="w-4 h-4 hover:cursor-pointer"
    on:click={() => onEdit()}
    />
</td>
<td class="w-4">
  <DeleteButton
    label=""
    type="menu"
    confirmDialog={deleteSupportingClass}
  >
    <DialogConfirmAction
      bind:this={deleteSupportingClass}
      type="deleteSupportingClass"
      actionOnYes={() => onDelete()}
    />
  </DeleteButton>
</td>