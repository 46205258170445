/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetricOfForm } from '../models/MetricOfForm';
import type { ParameterDefinition } from '../models/ParameterDefinition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetricsOfFormService {

    /**
     * Get Metrics Of Form
     * @returns MetricOfForm Successful Response
     * @throws ApiError
     */
    public static getMetricsOfFormApiV1MetricsOfFormGet(): CancelablePromise<Array<MetricOfForm>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/metrics-of-form/',
        });
    }

    /**
     * Create Metric Of Form
     * @param requestBody
     * @returns MetricOfForm Successful Response
     * @throws ApiError
     */
    public static createMetricOfFormApiV1MetricsOfFormPost(
        requestBody: MetricOfForm,
    ): CancelablePromise<MetricOfForm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/metrics-of-form/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Metric Of Form
     * @param id
     * @param requestBody
     * @returns MetricOfForm Successful Response
     * @throws ApiError
     */
    public static updateMetricOfFormApiV1MetricsOfFormIdPut(
        id: number,
        requestBody: MetricOfForm,
    ): CancelablePromise<MetricOfForm> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/metrics-of-form/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Metric Of Form
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteMetricOfFormApiV1MetricsOfFormIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/metrics-of-form/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Parameters
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateParametersApiV1MetricsOfFormIdParametersPatch(
        id: number,
        requestBody: Array<ParameterDefinition>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/metrics-of-form/{id}/parameters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
