import { get, writable } from "svelte/store";
import type { Writable } from "svelte/store";

import { CategoryName } from "@utils/categories";

type Panel = {
  left: boolean;
  right: boolean;
  rightWidthSize: number;
  leftWidthSize: number;
};

// To manage the concept type categories visibility
type CategoryDisplay = {
  [key in CategoryName]: boolean;
};

export type Side = "left" | "right";
export type Theme = "light" | "dark";
export type GraphDimension = "2D" | "3D";
export type Group = "noGroup" | "type" | "story";
export type Perfo = "speed" | "aesthetic";
// Mode to compute the weight (size) of concepts in graph vie<w
export type WeightMode =
  | "none"
  | "inConn"
  | "outConn"
  | "allConn"
  | "stories"
  | "attachments";

class DisplayStore {
  _display: Writable<Panel>;
  _categoryDisplay: Writable<CategoryDisplay>;
  _theme: Writable<Theme>;
  _graphDim: Writable<GraphDimension>;
  _group: Writable<Group>;
  _perfo: Writable<Perfo>;
  // Mode to compute the weight (size) of concepts in graph vie<w
  _wMode: Writable<WeightMode>;
  // Boolean to pause/resume graph animation:
  _graphEngineOn = writable(true);
  _editMode: Writable<boolean>;

  RIGHT_DEFAULT_WIDTH = 500;
  LEFT_DEFAULT_WIDTH = 390;
  DEFAULT_MIN_WIDTH = 250;
  COLLAPSE_DEFAULT_WIDTH = 24;

  constructor() {
    this.__initDisplay();
    this.__initTheme();
    this.__initGraphDim();
    this.__initCategoryDisplay();
    this.__initGroup();
    this.__initWeightMode();
    this.__initPerfo();
    this.__initEditMode();
  }

  private __initTheme() {
    const storedTheme = localStorage.getItem("theme");
    this._theme = writable(storedTheme as Theme);
    this._theme.subscribe((value) => {
      localStorage.setItem("theme", value === "dark" ? "dark" : "light");
    });
  }

  private __initGraphDim() {
    const storedGraphDim = localStorage.getItem("graphDim");
    this._graphDim = writable(storedGraphDim as GraphDimension);
    this._graphDim.subscribe((value) => {
      localStorage.setItem("graphDim", value === "3D" ? "3D" : "2D");
    });
  }

  private __initGroup() {
    const storedGroup = localStorage.getItem("group");
    this._group = writable(storedGroup as Group);
    this._group.subscribe((value) => {
      value !== null
        ? localStorage.setItem("group", value)
        : localStorage.setItem("group", "noGroup");
    });
  }

  private __initWeightMode() {
    const storedWMode = localStorage.getItem("wMode");
    this._wMode = writable(storedWMode as WeightMode);
    this._wMode.subscribe((value) => {
      value !== null
        ? localStorage.setItem("wMode", value)
        : localStorage.setItem("wMode", "none");
    });
  }

  private __initPerfo() {
    let storedPerfo = localStorage.getItem("perfo");
    storedPerfo = storedPerfo === undefined ? "speed" : storedPerfo;
    this._perfo = writable(storedPerfo as Perfo);
    this._perfo.subscribe((value) => {
      value !== null
        ? localStorage.setItem("perfo", value)
        : localStorage.setItem("perfo", "speed");
    });
  }

  private __initDisplay() {
    this._display = writable(JSON.parse(localStorage.getItem("display")));
    this._display.subscribe(
      (value) =>
        (localStorage.display = value
          ? JSON.stringify(value)
          : JSON.stringify({
              left: true,
              right: true,
              rightWidthSize: this.RIGHT_DEFAULT_WIDTH,
              leftWidthSize: this.LEFT_DEFAULT_WIDTH,
            }))
    );
  }
  private __initEditMode() {
    this._editMode = writable(false);
  }

  set editMode(value: boolean) {
    this._editMode.set(value);
  }

  get editMode() {
    return get(this._editMode);
  }

  get graphEngineOn() {
    return get(this._graphEngineOn);
  }

  get display() {
    return get(this._display);
  }

  get categoryDisplay() {
    return get(this._categoryDisplay);
  }

  get theme() {
    return get(this._theme);
  }

  get graphDim() {
    return get(this._graphDim);
  }

  get group() {
    return get(this._group);
  }

  get wMode() {
    return get(this._wMode);
  }

  get perfo() {
    return get(this._perfo);
  }

  toggleDisplay(side: Side) {
    const newDisplay = { ...this.display };
    this.setDisplay(side, !newDisplay[side]);
  }

  setDisplay(side: Side, show: boolean) {
    const newDisplay = { ...this.display };
    newDisplay[side] = show;
    if (newDisplay[side]) {
      side === "left"
        ? (newDisplay.leftWidthSize = this.LEFT_DEFAULT_WIDTH)
        : (newDisplay.rightWidthSize = this.RIGHT_DEFAULT_WIDTH);
    } else {
      side === "left"
        ? (newDisplay.leftWidthSize = this.COLLAPSE_DEFAULT_WIDTH)
        : (newDisplay.rightWidthSize = this.COLLAPSE_DEFAULT_WIDTH);
    }
    this._display.set(newDisplay);
  }

  resizePanel(side: Side, widthDelta: number) {
    const key = side === "right" ? "rightWidthSize" : "leftWidthSize";
    const newDisplay = { ...this.display };
    newDisplay[key] = newDisplay[key] + widthDelta;
    if (newDisplay[key] < this.DEFAULT_MIN_WIDTH) {
      newDisplay[side] = false;
      newDisplay[key] = this.COLLAPSE_DEFAULT_WIDTH;
    } else {
      newDisplay[side] = true;
    }
    this._display.set(newDisplay);
  }

  /**
   * Instanciates and returns the LocalStorage based writable category
   * display Map.
   */
  private __initCategoryDisplay() {
    let catDisplay = JSON.parse(localStorage.getItem("catDisplay"));
    catDisplay = catDisplay ? catDisplay : {};
    Object.values(CategoryName).forEach((cat) => {
      if (catDisplay[cat] === undefined) {
        catDisplay[cat] = true;
      }
    });
    this._categoryDisplay = writable(catDisplay);
    this._categoryDisplay.subscribe((value) => {
      localStorage.catDisplay = JSON.stringify(value);
    });
  }

  /**
   * Sets the app theme.
   *
   * @param {string} theme - The app theme to switch to.
   */
  setTheme(theme: Theme) {
    this._theme.set(theme);
  }

  setGraphDim(graphDim: GraphDimension) {
    this._graphDim.set(graphDim);
  }

  setGroup(group: Group) {
    this._group.set(group);
  }

  setWMode(wMode: WeightMode) {
    this._wMode.set(wMode);
  }

  setPerfo(perfo: Perfo) {
    this._perfo.set(perfo);
  }
  /**
   * Sets the visibility of node types for a given category according to the
   * display param.
   *
   * @param {CategoryName} cat - The category which visibility is to
   *   be set.
   * @param {boolean} display - true if the node types are to be shown.
   *   Else false.
   */
  setCategoryDisplay(cat: CategoryName, display: boolean) {
    this._categoryDisplay.update((map) => {
      map[cat] = display;
      return map;
    });
  }

  /**
   * Returns the visibility of the node type headers under given
   * category.
   *
   * @param {CategoryName} cat - The category which node type
   *   children visibility is to be toggled.
   * @return {boolean} - True if the node type headers under given
   * category are to be shown.
   */
  getCategoryDisplay(cat: CategoryName): boolean {
    return this.categoryDisplay[cat];
  }

  /**
   * Toggles the visibility of the node type headers under a given
   * category.
   *
   * @param {CategoryName} cat - The category which node type
   *   children visibility is to be toggled.
   */
  toggleCategoryDisplay(cat: CategoryName) {
    this.setCategoryDisplay(cat, !this.categoryDisplay[cat]);
  }

  /**
   * Sets the visibility of the node type headers under all
   * categories according to the 'open' param.
   *
   * @param {Boolean} open - True if the node types are to be shown.
   *   Else false.
   */
  setAllCategoriesDisplay(open: boolean) {
    Object.keys(this.categoryDisplay).forEach((key: string) => {
      this.setCategoryDisplay(key as CategoryName, open);
    });
  }

  form = {
    base: "bg-gray-50 text-gray-900 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 \
          border-gray-300 dark:border-gray-600 \
          focus:border-base-content focus:ring-base-content dark:focus:base-content dark:focus:base-content",
    tinted:
      "bg-gray-50 text-gray-900 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400 \
            border-gray-300 dark:border-gray-500 ",
    green:
      "bg-green-50 text-green-900 placeholder-green-700 dark:bg-gray-700 \
            border-green-500 dark:border-green-400 \
            focus:ring-green-500 focus:border-green-500 dark:focus:border-green-500 dark:focus:ring-green-500 ",
    red: "bg-red-50 text-red-900 placeholder-red-700 dark:bg-gray-700 \
          border-red-500 dark:border-red-400 \
          focus:ring-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500",
  };

  textSizes = { sm: "sm:text-xs", md: "text-sm", lg: "sm:text-base" };
}

export const displayStore = new DisplayStore();
