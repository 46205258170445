/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Realm {
    TERRESTRIAL = 'Terrestrial',
    SUBTERRANEAN = 'Subterranean',
    FRESHWATER = 'Freshwater',
    MARINE = 'Marine',
    ATMOSPHERE = 'Atmosphere',
    TERRESTRIAL_SUBTERRANEAN = 'Terrestrial-Subterranean',
    TERRESTRIAL_FRESHWATER = 'Terrestrial-Freshwater',
    TERRESTRIAL_MARINE = 'Terrestrial-Marine',
    TERRESTRIAL_ATMOSPHERE = 'Terrestrial-Atmosphere',
    SUBTERRANEAN_FRESHWATER = 'Subterranean-Freshwater',
    SUBTERRANEAN_MARINE = 'Subterranean-Marine',
    SUBTERRANEAN_ATMOSPHERE = 'Subterranean-Atmosphere',
    FRESHWATER_MARINE = 'Freshwater-Marine',
    FRESHWATER_ATMOSPHERE = 'Freshwater-Atmosphere',
    MARINE_ATMOSPHERE = 'Marine-Atmosphere',
}
