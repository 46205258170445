<script lang="ts">

  import { createForm } from "felte";

  import { MultiSelect, Label } from "@core-ui";
  import { dataStore } from "@stores/datastore";
  import { supportingStore } from "@stores/supportingstore";
  import { updateNode, } from "@utils/node";
  import { type Node } from "@client";
  import { isMandatory, Supporting } from "@utils/supporting";


  let { _selectedNode } = dataStore;
  let { _stateMetrics } = supportingStore;
  // StateMetric properties to be used as Select label/value
  const itemId = 'id';
  const label = 'name';

  let sm;
  let required = isMandatory($_selectedNode.type, Supporting.StateMetrics)


  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields("state_metric", val);
  };

  
  const handleFormReset = (n: Node) => {
    setInitialValues(n);
    reset();
  };

  const { form, setFields, isValid, isSubmitting, createSubmitHandler, setInitialValues, reset } =
    createForm<Node>({
      onSubmit: (values) => {
        if (values.state_metric)
          updateNode(
            $_selectedNode.id, 
            {state_metric_id: values.state_metric[0].id}
          );
      }
    });

  const updateStateMetric = () => {
    sm = getStateMetric();
  };

  const getStateMetric = () => {
    if ($_selectedNode.state_metric_id) {
      let metric = $_stateMetrics.filter((m) => m.id == $_selectedNode.state_metric_id);
      if (metric.length > 0) {
        return metric[0];
      } else {
        return [];
      }
    }
    return "No data.";
  };

  export const handleSubmit = createSubmitHandler({
    onSubmit: (values) => {
      if (values.state_metric)
      
          updateNode(
            $_selectedNode.id, 
            {state_metric_id: values.state_metric[0].id}
          );
    },
  });
  
  $: $_selectedNode && updateStateMetric();  
  $: $_selectedNode && handleFormReset($_selectedNode);
</script>
<!--
@component
Concept state metric form.
-->
<form use:form class="w-full p-1 form-control">
  <Label class="mb-2" {required}>State metric:</Label>
   <MultiSelect 
      items={$_stateMetrics}
      placeholder="Select a state metric"
      handleSelectChange={handleSelectChange}
      initValues={sm || []}
      {itemId} {label} 
      name="state_metric"
      multiple={false}
      {required}
      multiFullItemClearable={false} 
    />  
</form>
