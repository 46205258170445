/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Sector } from '../models/Sector';
import type { Stakeholder } from '../models/Stakeholder';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StakeholdersService {

    /**
     * Get Stakeholders
     * @returns Stakeholder Successful Response
     * @throws ApiError
     */
    public static getStakeholdersApiV1StakeholdersGet(): CancelablePromise<Array<Stakeholder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/stakeholders/',
        });
    }

    /**
     * Create Stakeholder
     * @param requestBody
     * @returns Stakeholder Successful Response
     * @throws ApiError
     */
    public static createStakeholderApiV1StakeholdersPost(
        requestBody: Stakeholder,
    ): CancelablePromise<Stakeholder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/stakeholders/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Stakeholder
     * @param id
     * @param requestBody
     * @returns Stakeholder Successful Response
     * @throws ApiError
     */
    public static putStakeholderApiV1StakeholdersIdPut(
        id: number,
        requestBody: Stakeholder,
    ): CancelablePromise<Stakeholder> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/stakeholders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Stakeholder
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteStakeholderApiV1StakeholdersIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/stakeholders/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Stakeholder Sectors
     * @returns Stakeholder Successful Response
     * @throws ApiError
     */
    public static getStakeholderSectorsApiV1StakeholdersIdSectorsGet(): CancelablePromise<Array<Stakeholder>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/stakeholders/{id}/sectors',
        });
    }

    /**
     * Update Stakeholder Sectors
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateStakeholderSectorsApiV1StakeholdersIdSectorsPatch(
        id: number,
        requestBody: Array<Sector>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/stakeholders/{id}/sectors',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
