import { writable } from "svelte/store";
import type { Writable } from "svelte/store";
import type { InjectionKey } from "@utils/svelte-typed-context";
import { setContext, getContext } from "@utils/svelte-typed-context";

export const key: InjectionKey<CtxState> = Symbol("createConceptCtx");
/**
 * ctx setter
 */
type CtxState = {
  state: Writable<DialogState>;
  close: () => void;
  open: () => void;
};
type DialogState = {
  isOpen: boolean;
};

let dialogState: Writable<DialogState> = writable({ isOpen: true });
const closeModal = () => dialogState.set({ isOpen: false });
const openModal = () => dialogState.set({ isOpen: true });

export const setCtx = () =>
  setContext(key, {
    state: dialogState,
    close: closeModal,
    open: openModal,
  });

export const getCtx = () => getContext(key);
