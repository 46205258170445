<script lang="ts" context="module">
  import Fuse from "fuse.js";

  import type { LinkObject, NodeObject } from "force-graph";
  import type { Edges } from "@models/Edges";
  import { dataStore } from "@stores/datastore";
  import type { Edge } from "@utils/formatData";
  import type { NodesWithStories } from "@client";
  import Settings from "@components/Graph/Settings/Settings.svelte";

  //TODO : move these types elsewhere (discuss with teams where)
  export type GraphNodesWithStories = NodeObject & NodesWithStories;
  export interface LinkObjectWithId extends LinkObject {
    id: string;
  }
  export type EdgeObject = LinkObject & Edges;
  export interface GraphLinkObject extends EdgeObject {
    source: GraphNodesWithStories;
    target: GraphNodesWithStories;
  }

  const optionsNode = {
    includeScore: true,
    keys: [
      { name: "name", weight: 0.01 },
      { name: "type", weight: 0.01 },
      { name: "description", weight: 0.01 },
    ],
  };

  const optionsEdge = {
    includeScore: true,
    keys: ["id", "source.name", "target.name"],
  };

  function customFormatNodeHit(item: NodesWithStories): string {
    return `${item?.name} (${item?.type})`;
  }

  function customFormatLinkHit(item: Edge): string {
    return `${item?.source?.name} -(${item?.id})-> ${item?.target?.name} `;
  }

  function mapIndexesConfig<
    T extends { nodes: NodesWithStories[]; links: LinkObjectWithId[] | Edge[] }
  >(graphData: T): Map<string, Object> {
    indexesMap.set("nodesIndex", {
      index: new Fuse(graphData.nodes, optionsNode),
      formatHitFn: customFormatNodeHit,
    });
    /** BUG: TODO: can be avoided, if we find when graphData construct
     *  is done infering NodeObject and LinkObject
     *  With this code, works fin in both cases
     */
    const lightLinksData = graphData.links.map((link) => {
      const source =
        typeof link.source === "number"
          ? graphData.nodes.find((node) => {
              return node.id == link.source;
            })
          : link.source;

      const target =
        typeof link.target === "number"
          ? graphData.nodes.find((node) => {
              return node.id == link.target;
            })
          : link.target;

      return {
        ...link,
        source: source,
        target: target, // ISSUE: as graphdata link data is a blackbox, unable to get edge detail here
      };
    });
    indexesMap.set("edgesIndex", {
      index: new Fuse(lightLinksData, optionsEdge),
      formatHitFn: customFormatLinkHit,
    });
    return indexesMap;
  }

  let indexesMap = new Map();
</script>

<script lang="ts">
  const { _graphData } = dataStore;

  $: $_graphData && mapIndexesConfig($_graphData);
</script>

<div class="absolute z-10 flex flex-col items-center justify-center w-full">
  <div class="flex items-center justify-center flex-1 w-4/5 mt-4">
    <div class="flex-none"><Settings /></div>
  </div>
</div>
