/** @module categories */

import { Type } from "../client/models/Type";

/*
 * Graph node types can be subtypes of a top-level node type
 * e.g., in the case of the DAPSI graph used in the app, "component",
 * "ecological service" and "environment process" nodes are subtypes of
 * the "state change" node type.
 *
 * This is handled in the font using node (type) categories.
 *
 * Categories are only a UI concept for the tree view. The data model
 * (nodes and edges) doesn't know/care about them.
 */

/**
 * This enumeration stands for the different categories names.
 *
 * @enum {string}
 *
 * @see https://archive.epa.gov/ged/tutorial/web/pdf/dpsir_module_2.pdf
 * @see https://ueaeprints.uea.ac.uk/id/eprint/63305/1/Published_manuscript.pdf
 */
export enum CategoryName {
  STATE_CHANGE = "state change",
}

/**
 * Defines the display order for the different node types categories
 * to be displayed in the graph tree view. Also defines relationships between
 * the node types and the categories used in the app.
 *
 * @impl this object has been introduced to implement
 *       categories in the app (without touching the structures in
 *       the store) AND introducing a display order of tree root entries
 *       (being node types or categories).
 *
 * @refactor type this (introduce a "display_order" type and make this an
 *           array of "display_order" elements).
 */
export const displayOrders = [
  {
    nodeCategory: null,
    nodeTypes: [Type.DRIVER],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.ACTIVITY],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.COMPLIMENTARY_CAPITAL],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.PRESSURE],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.COMPONENT],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.ECOSYSTEM_SERVICE],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.GOOD_AND_BENEFIT],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.IMPACT_ON_WELFARE],
  },
  {
    nodeCategory: null,
    nodeTypes: [Type.RESPONSE],
  },
];

/**
 * Returns true if given Type belongs to a category. Else returns false.
 *
 * @param {Type} nodeType - The Type to be checked.
 * @returns True if given Type belongs to a category. Else false.
 */
export let isInCategory = (nodeType: Type) => {
  return !displayOrders.some(
    (elem) =>
      elem.nodeTypes.indexOf(nodeType) !== -1 && elem.nodeCategory === null
  );
};

/**
 * Returns the CategoryName the Type belongs to.
 *
 * @param {Type|string} nodeType:  - The Type to retrieve the category of.
 * @returns the CategoryName the Type belongs to.
 */
export const getTypeCategory = (
  nodeType: Type | string
): CategoryName | undefined => {
  const cat = displayOrders.find((item) => {
    return item.nodeTypes.indexOf(nodeType as Type) !== -1;
  });
  return cat?.nodeCategory;
};
