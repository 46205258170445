<script lang="ts">
  import classNames from "classnames";

  export let color: "gray" | "green" | "red" | "disabled" = "gray";
  export let defaultClass: string = "text-sm font-medium block";
  export let show: boolean = true;
  export let required: boolean = false;

  let node: HTMLLabelElement;

  const colorClasses = {
    gray: "text-gray-900 dark:text-gray-300",
    green: "text-green-700 dark:text-green-500",
    red: "text-red-700 dark:text-red-500",
    disabled: "text-gray-400 dark:text-gray-500",
  };

  $: {
    const control: HTMLInputElement = node?.control as HTMLInputElement;
    if (control) {
      color = control.disabled ? "disabled" : color;
      required = control.required;
    }
  }

  $: labelClass = classNames(defaultClass, colorClasses[color], $$props.class);
</script>

{#if show}
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label bind:this={node} {...$$restProps} class={labelClass} class:required={required}
    ><slot /></label
  >
{:else}
  <slot />
{/if}

<style>
  .required:after {
    content: " *";
    color: red;
  }
</style>
