<script lang="ts">
  export let message: string;
  import { nodeTypes } from "@utils/nodeTypes";
</script>

<div class="flex flex-col items-center justify-center w-full gap-4">
  <div class="rounded-r-lg">
    <img
    src="assets/tutorial_dapsiwrm.png"
    class="object-cover col-span-2 "
    alt="Dapsiwrm class diagram"
    style="width:100%"
  /> 
  </div>
  <div style="padding-left: 30px; font-size:12px" >
    <p>The DAPSI(W)R(M) framework is a comprehensive approach to environmental management that considers social, economic, and environmental factors.</p>

    <p>It consists of six core elements: Drivers, Activities, Pressures, State, Impacts, and Responses :</p>

    <ul style="list-style:disc; padding:20px">
        <li><b style="color:{nodeTypes["driver"].color()}">Drivers</b>: Basic human needs and wants - based on Maslow's hierarchy of needs.</li>
        <li><b style="color:{nodeTypes["activity"].color()}">Activities</b>: The things Individuals or groups of people "do" to satisfy their needs and wants.</li>
        <li><b style="color:{nodeTypes["complimentary_capital"].color()}">Complementary Capital</b>: The social or economic capital inputs to (or outputs of) <b style="color:{nodeTypes["activity"].color()}">Activities</b>.​</li>
        <li><b style="color:{nodeTypes["pressure"].color()}">Pressures</b>: The chemical and/or biological and/or physical additions or subtractions to components of the environment resulting from <b style="color:{nodeTypes["activity"].color()}">activities</b>. <b style="color:{nodeTypes["pressure"].color()}">Pressure</b> causes result in changes in state to environmental components.</li>
        <li><b style="color:{nodeTypes["component"].color()}">State - Components</b>: The concepts that constitute the Environment, including biotic, chemical or physical (energy) concepts. Some concepts are aggregations of "lower level" concepts.</li>
        <li><b style="color:{nodeTypes["ecosystem_service"].color()}">State - Ecosystem Services</b>: Environmental components and processes that have the potential to be converted (by an <b style="color:{nodeTypes["activity"].color()}">Activity</b>) into human-usable <b style="color:{nodeTypes["good_and_benefit"].color()}">goods and benefits</b>.</li>
        <li><b style="color:{nodeTypes["good_and_benefit"].color()}">Impacts - Goods and Benefits</b>: Are human-usable goods and benefits which result from a human Action and an <b style="color:{nodeTypes["ecosystem_service"].color()}">Ecosystem Service</b>.</li>
        <li><b style="color:{nodeTypes["impact_on_welfare"].color()}">Impacts - Human Welfare</b>: Are the impacts on individuals and society of an over or undersupply of <b style="color:{nodeTypes["good_and_benefit"].color()}">Goods and Benefits</b> relative to their Needs/Wants (<b style="color:{nodeTypes["driver"].color()}">Drivers</b>)</li>
        <li><b style="color:{nodeTypes["response"].color()}">Responses</b><b> (as measures)</b>: The various types of intervention that can be applied to adapt to or mitigate against <b style="color:{nodeTypes["impact_on_welfare"].color()}">Impacts on Human Welfare</b>.</li>
    </ul>
    
    <p>The framework helps to understand and communicate the environment as a system and its interdependencies with social and economic systems by highlighting the cause-effect relationships between the different elements. The DAPSI(W)R(M) framework provides decision-makers with a systematic and objective approach to environmental management that can help to address the complexities of environmental issues and support sustainable development by integrating social, economic, and environmental factors.</p>
  </div>
</div>

<style>
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }

  li {
    padding-bottom: 5px;
  }
</style>
