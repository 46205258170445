<svelte:options accessors={true} />

<script lang="ts">
  import Trash from "@rgossiaux/svelte-heroicons/outline/Trash";
  import Dialog from "./Dialog.svelte";
  import {
    ExclamationCircleIcon,
    MinusCircleIcon,
  } from "@rgossiaux/svelte-heroicons/outline";

  type ConfigType =
    | "exitConceptEdition"
    | "cancelConceptCreation"
    | "cancelLinkCreation"
    | "deleteConnection"
    | "deleteConcept"
    | "deleteStory"
    | "removeNodeFromStory"
    | "cancelStoryCreation"
    | "cancelStoryEdition"
    | "deleteSupportingClass"
    | "cancelSupportingClassCreation"
    | "cancelSupportingClassEdit"
    | "removePart"
    | "removeEvidence"
    | "undefined";

  type Config = {
    type: "warning" | "delete" | "remove";
    title: string;
    noLabel: string;
    yesLabel: string;
    text?: string[];
  };

  let config: { [key in ConfigType]: Config } = {
    exitConceptEdition: {
      type: "warning",
      title: "Exit concept edit",
      noLabel: "Do not exit",
      yesLabel: "Yes, exit",
      text: [
        "Are you sure you want to stop editing this concept?",
        "The informations already written will not be saved",
      ],
    },
    cancelConceptCreation: {
      title: "Cancel concept creation",
      type: "warning",
      noLabel: "Do not cancel",
      yesLabel: "Yes, cancel",
      text: [
        "Are you sure you want to cancel this concept creation?",
        "The information already written will be deleted",
      ],
    },
    cancelConnectionCreation: {
      title: "Cancel connection creation",
      type: "warning",
      noLabel: "Do not cancel",
      yesLabel: "Yes, cancel",
      text: [
        "Are you sure you want to cancel this connection creation?",
        "The information already written will be deleted",
      ],
    },
    deleteConcept: {
      title: "Delete concept",
      type: "delete",
      noLabel: "Do not delete",
      yesLabel: "Yes, delete",
      text: [
        `Are you sure you want to delete this concept`,
        "Every associated connections will be deleted",
      ],
    },
    deleteConnection: {
      title: "Delete connection",
      type: "delete",
      noLabel: "Do not delete",
      yesLabel: "Yes, delete",
      text: ["Are you sure you want to delete this connection?"],
    },
    deleteStory: {
      title: `Remove graph`,
      type: "delete",
      noLabel: "Do not delete",
      yesLabel: "Yes, delete",
      text: ["Are you sure you want to delete this graph?"],
    },
    removeNodeFromStory: {
      title: `Remove concept`,
      type: "remove",
      noLabel: "Do not remove",
      yesLabel: "Yes, remove",
      text: ["Are you sure you want to remove the concept from this graph?"],
    },
    cancelStoryCreation: {
      title: `Cancel graph creation`,
      type: "warning",
      noLabel: "Do not cancel",
      yesLabel: "Yes, cancel",
      text: [
        "Are you sure you want to cancel this graph creation?",
        "The information already written will be deleted",
      ],
    },
    cancelStoryEdition: {
      type: "warning",
      title: "Exit graph edition",
      noLabel: "Do not cancel",
      yesLabel: "Yes, cancel",
      text: [
        "Are you sure you want to leave the edition of this graph?",
        "The informations already written will not be saved",
      ],
    },
    deleteSupportingClass: {
      title: `Delete supporting class`,
      type: "delete",
      noLabel: "Do not delete",
      yesLabel: "Yes, delete",
      text: ["Are you sure you want to delete this supporting class?"],
    },
    cancelSupportingClassCreation: {
      title: `Cancel supporting class creation`,
      type: "warning",
      noLabel: "Do not cancel",
      yesLabel: "Yes, cancel",
      text: [
        "Are you sure you want to cancel this supporting class creation?",
        "The information already written will be deleted",
      ],
    },
    cancelSupportingClassEdit: {
      title: `Cancel supporting class edit`,
      type: "warning",
      noLabel: "Do not cancel",
      yesLabel: "Yes, cancel",
      text: [
        "Are you sure you want to cancel this supporting class edit?",
        "The information already written will be deleted",
      ],
    },
    removePart: {
      title: `Remove part`,
      type: "remove",
      noLabel: "Do not remove",
      yesLabel: "Yes, remove",
      text: [
        "Are you sure you want to remove this component from the aggregation?",
      ],
    },
    removeEvidence: {
      title: `Remove evidence`,
      type: "remove",
      noLabel: "Do not remove",
      yesLabel: "Yes, remove",
      text: ["Are you sure you want to remove this evidence?"],
    },
    undefined: {
      title: "Are you sure",
      type: "delete",
      noLabel: "No",
      yesLabel: "Yes",
      text: [],
    },
  };

  export let type: ConfigType;
  let classBtnYes: string;

  export function show(coord) {
    dialog.show(coord);
    if (config[type].type == "warning") {
      classBtnYes = "btn-warning";
    } else {
      classBtnYes = "btn-error";
    }
  }

  export function close() {
    dialog.close();
  }

  export let actionOnYes: Function = () => {};
  export let actionOnNo: Function = () => {};

  function handleYes() {
    actionOnYes();
    dialog.close();
  }

  function handleNo() {
    actionOnNo();
    dialog.close();
  }
  let dialog;
</script>

<Dialog bind:this={dialog} dismissOnClickOutside={false}>
  <div class="flex flex-row p-6">
    <div class="mr-4">
      {#if config[type].type == "warning"}
        <ExclamationCircleIcon
          class={`rounded-full btn-warning p-2 w-10 h-10`}
        />
      {:else if config[type].type == "remove"}
        <MinusCircleIcon class={`rounded-full btn-error p-2 w-10 h-10`} />
      {:else if config[type].type == "delete"}
        <Trash class={`rounded-full btn-error p-2 w-10 h-10`} />
      {/if}
    </div>
    <div>
      <div>
        <h1 class="font-medium">{config[type].title}</h1>
        {#each config[type].text as text}
          <p class="font-normal text-gray-400">
            {text}
          </p>
        {/each}
      </div>

      <div class="flex flex-row justify-end pt-6">
        <buton class="mr-6 kg-btn-outline" on:click={handleNo}
          >{config[type].noLabel}</buton
        >
        <buton
          class={`gap-2 normal-case rounded-xl btn btn-sm ${classBtnYes}`}
          on:click={handleYes}>{config[type].yesLabel}</buton
        >
      </div>
    </div>
  </div>
</Dialog>
