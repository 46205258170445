/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Metrics } from '../models/Metrics';
import type { ParameterDefinition } from '../models/ParameterDefinition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetricsService {

    /**
     * Get Metrics
     * @returns Metrics Successful Response
     * @throws ApiError
     */
    public static getMetricsApiV1MetricsGet(): CancelablePromise<Array<Metrics>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/metrics/',
        });
    }

    /**
     * Create Metric
     * @param requestBody
     * @returns Metrics Successful Response
     * @throws ApiError
     */
    public static createMetricApiV1MetricsPost(
        requestBody: Metrics,
    ): CancelablePromise<Metrics> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/metrics/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Metric
     * @param id
     * @param requestBody
     * @returns Metrics Successful Response
     * @throws ApiError
     */
    public static updateMetricApiV1MetricsIdPut(
        id: number,
        requestBody: Metrics,
    ): CancelablePromise<Metrics> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/metrics/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Metric
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteMetricApiV1MetricsIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/metrics/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Metric Parameters
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateMetricParametersApiV1MetricsIdParametersPatch(
        id: number,
        requestBody: Array<ParameterDefinition>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/metrics/{id}/parameters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
