  <script  lang="ts"> 
  import { dataStore } from "@stores/datastore";
  import { supportingStore } from "@stores/supportingstore";
  import { addSector } from "@utils/node";
  import { Label, MultiSelect } from "@core-ui";
  import { removeSector } from "@utils/node";
  import { type Sector } from "@client";
  import { createForm, getValue  } from 'felte';
  import { reporter } from "@felte/reporter-svelte";
  import { isMandatory, Supporting } from "@utils/supporting";

  const { _sectors } = supportingStore;
  let { _selectedNode } = dataStore;

  const handleSectorsChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    } else {
      val = [];
    }
    setFields("sectors", val);
  };

  export const handleSubmit = () => {
    if($isValid){
      let currentSectorsId = $_selectedNode.sectors.map(s => s.id)
      let sectorsToAdd = getValue($data, "sectors")?.filter(s =>  !currentSectorsId.includes(s.id))
      let sectorsToRemove = $_selectedNode.sectors.filter(s => !getValue($data, "sectors")?.map(r => r.id).includes(s.id))
      for(let sec of sectorsToAdd){
        addSector($_selectedNode.id, sec);
      }
      for(let sec of sectorsToRemove){
        removeSector($_selectedNode.id, sec);
      }
    }
  };  

  const { form, data,  setFields, setInitialValues, reset, setData, isValid} = createForm({
    validate: (values) => {
      let errors = {sectors : []}
        if(isMandatory($_selectedNode.type, Supporting.Sectors) && !values.status){
          errors.sectors.push("Please select at least one sector")
        }
        return errors
    },
    extend: [reporter],
  })
  
</script>
<!--
@component
Allows to add/remove sectors to/from a concept.
-->
{#if $_selectedNode}
  <form use:form class="w-full p-1 form-control">
    <div class="flex flex-col">
      <Label class="mb-2" required={isMandatory($_selectedNode.type, Supporting.Sectors)}>Sectors:</Label>
      <MultiSelect 
        items={$_sectors}
        placeholder="Select associated sectors"
        handleSelectChange={handleSectorsChange}
        initValues={$_selectedNode.sectors}
        label="name"
        itemId="id"
        name="sectors"
        required={isMandatory($_selectedNode.type, Supporting.Sectors) }
        requiredMessage="Please select at least one sector"
    />  
    </div>
  </form>
{/if}
