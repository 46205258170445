<script lang="ts">
  import { Box } from "@core-ui";
  import { Emitter } from "@core-ui";
  import { slide } from "svelte/transition";
  import { Roles } from "@utils/decorators";
  import Can from "@components/AccessControl/Can.svelte";
  import { ChevronUpIcon } from "@rgossiaux/svelte-heroicons/solid";
  import NodeReviewForm from "@components/Editors/StatusReview/NodeReviewForm.svelte";
  import { getCtx } from "@components/Editors/StatusReview/context";

  let { showForm, openForm, closeForm } = getCtx();
  let active = false;
</script>

{#if !active}
  <div class="w-full pr-3">
    <Box variant="contrast">
      {#if !$showForm}
        <div name="right" class="flex-none">
          <Emitter />
        </div>
        <div class="grow">This concept needs peer-review.</div>
        <Can roles={[Roles.DEV, Roles.CONCEPT]}>
          <div name="left" class="flex-none">
            <button class="btn btn-sm btn-ghost normal" on:click={openForm}
              >Review</button
            >
          </div>
        </Can>
      {:else}
        <div transition:slide class="w-full">
          <div class="flex items-center gap-2 align-between">
            <div class="grow">Review Concept status</div>
            <ChevronUpIcon
              class="flex-none w-6 h-6 cursor-pointer"
              on:click={closeForm}
            />
          </div>
          <NodeReviewForm />
        </div>
      {/if}
    </Box>
  </div>
{/if}

<style>
  /* your styles go here */
</style>
