<script lang="ts">
  import Autocomplete from "@components/Autocomplete.svelte";
  import { dataStore } from "@stores/datastore";
  import { Type } from "@client";
  import { createPart, aggregateParentsOfNode } from "@utils/aggregate";


  const { _selectedNode, _nodes } = dataStore;
  let autoComplete;
  let listNodeForPart;
  let nodeName;
  let saveDisable = false;
  let listExistantPart = [];

  function setNodeName(name) {
    nodeName = name;
    saveDisable = !listNodeForPart.includes(nodeName);
  }

  function create_part() {
    let nodeToAdd = getNodeFromName(nodeName);
    createPart($_selectedNode.id, nodeToAdd.id);
    autoComplete.cleanInput();
  }

  function getNodeFromName(name: string){
    return $_nodes.find((n) => n.name === name);
  };

  function updateExistantPart() {
    listExistantPart = $_selectedNode.aggregate.map((n) => n.nodeId);
  }

  function updateListNodeForPart() {
    if ($_selectedNode) {
      const aggregateParent = aggregateParentsOfNode($_selectedNode.id);
      listNodeForPart = $_nodes
        .filter((n) => n.type === Type.COMPONENT)
        .filter((n) => n.id !== $_selectedNode.id)
        .filter((n) => !listExistantPart.includes(n.id))
        .filter((n) => !aggregateParent.find((n2) => n2.id === n.id))
        .map((n) => n.name);
    }
  }

  $: $_selectedNode && updateExistantPart();
  $: $_selectedNode && updateListNodeForPart();
  $: $_nodes && updateListNodeForPart();
</script>

{#if $_selectedNode}
  <div class="flex gap-2 mt-1 top-container">
    <Autocomplete
      bind:this={autoComplete}
      list={listNodeForPart}
      nodeType={"components"}
      getInput={setNodeName}
    />
    <button
      class="mt-1 kg-base-button"
      disabled={saveDisable}
      on:click={() => create_part()}>Aggregate</button
    >
  </div>
{/if}