<script lang="ts">
  import { getSelectedItem } from "@stores/derivedstore";
  import { getCtx } from "@components/Actions/CreateResource/context";
  import GroupedList from "@components/Editors/Resources/GroupedList.svelte";
  import ListPlaceholder from "@components/Core/skeleton/ListPlaceholder.svelte";

  let { resources } = getCtx();
</script>

<div>
  {#await resources.init($getSelectedItem.type, $getSelectedItem.id)}
    <ListPlaceholder />
  {:then}
    <GroupedList />
  {:catch error}
    <div class="shadow-lg alert alert-error">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 w-6 h-6 stroke-current"
          fill="none"
          viewBox="0 0 24 24"
          ><path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          /></svg
        >
        <span>Something went wrong: {error.message}</span>
      </div>
    </div>
  {/await}
</div>
