<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteUnitOfMeasure } from "@utils/supporting/unitOfMeasure";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"


  const { metricFormDialog, editModeOn } = getCtx();
  const { _unitsOfMeasure, _selectedUnitOfMeasure } = supportingStore;

  const showEditModal = (u) => {
    _selectedUnitOfMeasure.set(u);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Unit</th>
        <th>Notes</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_unitsOfMeasure as u}
        <tr>
          <th>{formatAtomicProperty(u.name)}</th>
          <td>
            {formatAtomicProperty(u.unit)}
          </td>              
          <td>{u.notes}</td>
          <GridAction
            onEdit={() => showEditModal(u)}
            onDelete = {() => deleteUnitOfMeasure(u.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>