<script context="module" lang="ts">
  import type { SizeType, FormSizeType } from "@components/Core/types";
  export function clampSize(s: SizeType) {
    return s && s === "xs" ? "sm" : s === "xl" ? "lg" : s;
  }
</script>

<script lang="ts">
  import Wrapper from "@components/Core/utils/Wrapper.svelte";
  import classNames from "classnames";
  import { getContext } from "svelte";
  import type { InputType } from "../types";
  import { displayStore } from "@stores/displaystore";

  export let type: InputType = "text";
  export let value: string | number = "";
  export let size: FormSizeType | undefined = undefined;
  export let defaultClass: string =
    "block w-full disabled:cursor-not-allowed disabled:opacity-50";
  export let color: "base" | "green" | "red" = "base";


  // tinted if put in component having its own background
  let background: boolean = getContext("background");

  let group: { size: SizeType } = getContext("group");

  // you need to this to avoid 2-way binding
  const setType = (node: HTMLInputElement, _type: string) => {
    node.type = _type;
    return {
      update(_type: string) {
        node.type = _type;
      },
    };
  };

  
  const leftPadding = { sm: "pl-9", md: "pl-10", lg: "pl-11" };
  const rightPadding = { sm: "pr-9", md: "pr-10", lg: "pr-11" };
  const inputPadding = { sm: "p-2", md: "p-2.5", lg: "p-4" };

  $: _size = size || clampSize(group?.size) || "md";
  let inputClass: string;
  $: {
    const _color = color === "base" && background ? "tinted" : color;
    inputClass = classNames(
      defaultClass,
      $$slots.left && leftPadding[_size],
      $$slots.right && rightPadding[_size],
      displayStore.form[_color],
      inputPadding[_size],
      displayStore.textSizes[_size],
      group || "rounded-lg",
      group && "first:rounded-l-lg last:rounded-r-lg",
      group && "border-l-0 first:border-l last:border-r",
      $$props.class
    );
  }
  let floatClass =
    "flex absolute inset-y-0 items-center pointer-events-none text-gray-500 dark:text-gray-400";
</script>

<Wrapper class="relative w-full" show={$$slots.left || $$slots.right}>
  {#if $$slots.left}
    <div class="{floatClass} left-0 pl-2.5"><slot name="left" /></div>
  {/if}
  <slot props={{ ...$$restProps, class: inputClass }}>
    <input
      {...$$restProps}
      bind:value
      on:blur
      on:change
      on:click
      on:focus
      on:keydown
      on:keypress
      on:keyup
      on:mouseover
      on:mouseenter
      on:mouseleave
      on:paste
      on:input
      use:setType={type}
      class={inputClass}
    />
  </slot>
  {#if $$slots.right}
    <div class="{floatClass} right-0 pr-2.5"><slot name="right" /></div>
  {/if}
</Wrapper>
