<script lang="ts">

  import { createForm } from "felte";

  import { dataStore } from "@stores/datastore";
  import { Type } from "@client";
  import { type Node } from "@client";
  import { updateNode, getNodeNameById } from "@utils/node";
  import Autocomplete from "@components/Autocomplete.svelte";
  import Label from "@components/Core/forms/Label.svelte";
  import { isMandatory, Supporting } from "@utils/supporting";


  let { _selectedNode, _nodes } = dataStore;
  
  // properties to be used as Select label/value
  const itemId = 'id';
  const label = 'name';
  let pressureAgentAutoComplete;
  let pressureAgent;
  let pressureAgentPlaceHolder = "n agent...";
  let agentName;
  let required = isMandatory($_selectedNode.type, Supporting.Agent)

  
  const updateNodeAgent = () => {
    updateNode($_selectedNode.id, {pressure_agent_id: pressureAgent.id});
  };

  function onPressureAgentSelected(name) {
    pressureAgent = getNodeFromName(name);
  }

  function getPotentialAgentNodes() {
    return $_nodes
      .filter((n) => (n.type === Type.COMPONENT || n.type === Type.GOOD_AND_BENEFIT))
      .map((n) => n.name);
  }  

  const updateAgentName = () => {
    if ( $_selectedNode.pressure_agent_id)
      agentName = getNodeNameById($_selectedNode.pressure_agent_id);
  };


  const getNodeFromName = (name: string) => {
    return $_nodes.find((n) => n.name === name);
  }
  
  const handleFormReset = (n: Node) => {
    setInitialValues(n);
    reset();
  };

  const dismiss = () => {

  };

  const { form, setFields, isValid, isSubmitting, createSubmitHandler, setInitialValues, reset } =
    createForm<Node>({
      onSubmit: (values) => {
        if (values.state_metric)
          updateNode(
            $_selectedNode.id, 
            {state_metric_id: values.state_metric[0].id}
          );
        // clear form
        dismiss();
      }
    });


  export const handleSubmit = createSubmitHandler({
    onSubmit: (values) => {
        updateNodeAgent();
        // clear form
        dismiss();
    },
  });
  
  $: $_selectedNode && handleFormReset($_selectedNode);
  $: $_selectedNode && $_selectedNode.type == "pressure" && updateAgentName();

</script>
<!--
@component
Form for concept pressure agent.
-->
<form use:form class="w-full p-1 form-control">
  <Label for="sectors" class="mb-2" {required}>
    Agent:
  </Label>      
  <Autocomplete
    bind:this={pressureAgentAutoComplete}
    value={agentName}
    list={getPotentialAgentNodes()}
    nodeType={pressureAgentPlaceHolder}
    getInput={onPressureAgentSelected}
  />
</form>
