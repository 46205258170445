import { 
  MetricsOfFormService as service, 
  type ParameterDefinition
} from "@client";
import type { MetricOfForm, MetricOfForm as Model } from "@models/MetricOfForm";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createMetricOfForm = (model: Model) => {
  let params = model.parameter_definitions;  
  try {
    service.createMetricOfFormApiV1MetricsOfFormPost(model).then((resp) => {
      notifySuccess("Metric succesfully created!");
      supportingStore.addMetricOfForm(resp);
      resp.parameter_definitions = params;
      updateMetricParameterDefinitions(resp, params);      
    });
  } catch (e) {
    notifyFailure(`An error occured while creating metric of form: ${e.status}-${e.name}:${e.statusText}`);
  }    
};

export const updateMetricOfForm = async (
  metricId: number,
  model: Model = null
) => {
  let params = model.parameter_definitions;  
  try {
    service.updateMetricOfFormApiV1MetricsOfFormIdPut(
      metricId,
      model
    ).then(resp => {
      notifySuccess("Metric of form succesfully created!");
      supportingStore.updateSelectedMetricOfForm(resp);
      resp.parameter_definitions = params;
      updateMetricParameterDefinitions(resp, params);      
    });    
  } catch (e) {
    notifyFailure(`An error occurred while updating the metric of form: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateMetricParameterDefinitions = async (
  metric: Model = null,
  parameter_definitions: ParameterDefinition[]
) => {
  try {
    if (
      metric.parameter_definitions &&
      metric.parameter_definitions.length > 0
    ) {
      service
        .updateParametersApiV1MetricsOfFormIdParametersPatch(
          metric.id,
          parameter_definitions
        )
        .then((res2) => {
          notifySuccess(
            "State metric parameter definitions succesfully updated"
          );
          supportingStore.updateSelectedStateMetric(metric);
        });
    } else metric.parameter_definitions = [];
    supportingStore.updateSelectedStateMetric(metric);
  } catch (e) {
    notifyFailure(
      `An error occured while updating state metric parameter definitions: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};

export const deleteMetricOfForm = (id: number) => {
  service.deleteMetricOfFormApiV1MetricsOfFormIdDelete(id).then(() => {
    supportingStore.removeMetricOfForm(id);
  });
};

/**
 * Returns true if the provided name is already taken by an existing MetricOfForm.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean true if name is already in use. Else false.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.metricsOfForm.some(
      (s: MetricOfForm) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
