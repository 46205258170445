<script lang="ts">
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { Label, Helper, Textarea, Input } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import { getCtx } from "../context";
  import { type Algorithm } from "@client";
  import {
    createAlgorithm,
    isNameAlreadyTaken,
    updateAlgorithm,
  } from "@utils/supporting/algorithms";
  import MultiSelect from "@components/Core/forms/MultiSelect.svelte";
  import FormCreationButtons from "../FormCreationButtons.svelte";

  const { _selectedAlgorithms, _parameterDefinitions } = supportingStore;
  let { metricFormDialog, editModeOn } = getCtx();
  // Parameter defintion properties to be used as MultiSelect label/value
  const itemId = "id";
  const label = "name";
  // Init values for parameter definition MultiSelect
  let initParamDefinitions;

  const {
    form,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
    setFields,
  } = createForm<Algorithm>({
    onSubmit: (values) => {
      if (!$editModeOn) {
        createAlgorithm(values);
        // clear form
        handleFormReset(emptyAlgo);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateAlgorithm($_selectedAlgorithms.id, values);
        // clear form
        handleFormReset(emptyAlgo);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        definition: string[];
        parameter_definitions: string[];
        notes: string[];
      } = {
        name: [],
        definition: [],
        parameter_definitions: [],
        notes: [],
      };
      if (!values.name) errors.name.push("Name is required.");
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedAlgorithms &&
          $_selectedAlgorithms.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (!values.definition)
        errors.definition.push("Description is required.");
      if ((values.parameter_definitions ?? []).length == 0) {
        errors.parameter_definitions.push(
          "At least one parameter definition is required."
        );
      }
      return errors;
    },
    extend: [reporter],
  });

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields("parameter_definitions", val);
  };

  const handleFormReset = (a: Algorithm): boolean => {
    setInitialValues(a);
    initParamDefinitions = a.parameter_definitions;
    setFields("parameter_definitions", a.parameter_definitions);
    reset();
    return true;
  };

  const emptyAlgo = {
    name: "",
    description: "",
    parameter_definitions: [],
    notes: "",
  } as Algorithm;

  $: $editModeOn &&
    $_selectedAlgorithms &&
    handleFormReset($_selectedAlgorithms);
  $: !$editModeOn && handleFormReset(emptyAlgo);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn ? "Update algorithm" : "Create algorithm"}
  </h1>
  <!-- NAME & INPUT -->
  <div class="flex flex-col items-start justify-start gap-2">
    <!-- NAME -->
    <div class="w-full">
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type unit of measure name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- ALGORITHM DEFINITIONS -->
    <div class="w-full">
      <Label for="definition" class="mt-4 mb-2">Definition</Label>
      <Input
        class="mt-2"
        placeholder="Type definition here"
        id="definition"
        name="definition"
        required
        color={"base"}
      />
      <ValidationMessage for="definition" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- PARAMETER DEFINITIONS -->
    <div>
      <MultiSelect
        items={$_parameterDefinitions}
        placeholder="Select associated parameter definitions"
        initValues={initParamDefinitions}
        {handleSelectChange}
        {itemId}
        {label}
        name="parameter_definitions"
        componentLabel="Parameter definitions"
        requiredMessage="At Least one parameter definition is required"
        required
      />
      <ValidationMessage for="parameter_definitions" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- Notes -->
    <div class="w-full">
      <Label for="notes" class="mt-4 mb-2">Notes</Label>
      <Textarea
        class="mt-2"
        placeholder="Type notes here"
        id="notes"
        name="notes"
        color={"base"}
      />
    </div>

    <FormCreationButtons
      onClose={() => {
        handleFormReset(emptyAlgo);
      }}
      isValid={$isValid}
      isSubmitting={$isSubmitting}
      create={!$editModeOn}
    />
  </div>
</form>
