<script lang="ts">
  export let coord = null;
  export let dismissOnClickOutside: boolean = true;

  export let hideBackground: boolean = false;
  let dialog: HTMLDialogElement;

  let clazz =
    "bg-base-300 min-w-[224px] drop-shadow-[0_35px_35px_rgba(0,0,0,0.85)] text-sm text-base-content font-normal absolute rounded-lg p-0 overflow-hidden";

  export { clazz as class };

  export function d() {
    return dialog;
  }

  export function show(c) {
    coord = c;
    if (coord && coord.y >= 700) coord.y = coord.y - 120;
    dialog.showModal();
    if (dismissOnClickOutside) {
      dialog.addEventListener("click", onClick);
    }
  }

  export function close() {
    dialog.close();
  }

  function onClick(event) {
    if (event.target === dialog) {
      dialog.close();
    }
  }
</script>

<dialog
  bind:this={dialog}
  class={clazz}
  class:hide={hideBackground}
  class:base={!hideBackground}
  {...$$props}
  style={coord ? `margin-top:${coord.y}px; margin-left:${coord.x}px` : ""}
>
  <slot />
</dialog>

<style>
  .hide::backdrop {
    @apply bg-black;
  }
  .base::backdrop {
    @apply bg-black bg-opacity-25;
  }
</style>
