<script lang="ts">
  import { TrashIcon } from "@rgossiaux/svelte-heroicons/outline";
  import MinusCircle from "@rgossiaux/svelte-heroicons/outline/MinusCircle";
    import EditButton from "./EditButton.svelte";
  export let label = "Delete";
  export let icon: "Trash" | "Xicon" = "Trash";
  export let type: "menu" | "button" = "button";

  export let confirmDialog;
</script>

{#if type == "button"}
  <EditButton 
    buttonLabel={label}
    onClick={() => confirmDialog.show() } />
{:else}
  <div class="flex flex-row hover:font-bold hover:cursor-pointer" on:click={() => confirmDialog.show()}>
    {#if icon == "Trash"}
      <TrashIcon class={`w-5 h-5 mr-2 ${(label == "" ? "" : "text-error")}`} />
    {:else}
      <MinusCircle class="w-5 h-5 mr-2 text-error"/>
    {/if}
    <span class ="text-error">
      {label}
    </span>
  </div>
{/if}

<slot />

