<script lang="ts">
  // TODO: refact: metric object should be mapped in concept model
  // and served as a propert by the Web service. Then, remove
  // the metric name handling logic from the component.
  import { dataStore } from "@stores/datastore";
  import { supportingStore } from "@stores/supportingstore";
  import { getCtx } from "@components/Editors/NodeEditor/context";

  let { _selectedNode } = dataStore;
  let { _stateMetrics } = supportingStore;
  let { editPropertiesMode } = getCtx();

  let smName = "";

  const getStateMetricName = () => {
    if ($_selectedNode.state_metric_id) {
      let metric = $_stateMetrics.filter(
        (m) => m.id == $_selectedNode.state_metric_id
      );
      if (metric.length > 0) {
        return metric[0].name;
      } else {
        return "None yet";
      }
    }
    return "None yet";
  };

  const updateStateMetricName = () => {
    smName = getStateMetricName();
  };

  $: $_selectedNode && updateStateMetricName();
  $: $editPropertiesMode && updateStateMetricName();
</script>

{#if $_selectedNode.type == "component"}
  <div>
    <span>State Metric:</span>
    <span class="font-bold">
      {#if $_selectedNode.state_metric_id}
        {smName}
      {:else}
        No data.
      {/if}
    </span>
  </div>
{/if}
