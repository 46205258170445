<script lang="ts">
  import type { Story } from "@client";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { addStory, isNameValid } from "@utils/story";
  import { Label, Input, Textarea, Helper } from "@core-ui";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte"
  import { onMount } from "svelte";
  /**
   * Dialog state and methods
   */

  export let initialValues: Story;
  export let dialog = undefined;


  type StoryFormData = {
    name: string;
    description?: string;
  };

  let defaultErrors: {
          name: string[];
          description: string[];
        } = { name: [], description: [] };


  let allErrors = defaultErrors;
  const purgeAllErrors = () => {
    for (let e of Object.keys(allErrors)) {
      allErrors[e] = [];
    }
  };   
  /**
   * Form Types, methods, schema and validations
   */

  const { form, isValid, isSubmitting, reset } =
    createForm<StoryFormData>({
      onSubmit: (values) => {
        addStory({ ...initialValues, ...values });
        reset();
        dialog.close();
      },
      validate(values) {
        purgeAllErrors();
        let errors = allErrors
        if (!values.name) errors.name.push("Title is required.");
        if (values.name && !isNameValid(values.name))
          errors.name.push("Title is already taken.");
        return errors;
      },
      extend: [reporter],
    });

  onMount(() => {
    purgeAllErrors();
  })

</script>

<!-- FORM -->
<!-- TODO: Create reusable FormInputs components -->
<form use:form class="w-full form-control">
  <!-- <fieldset> -->

  <!-- TYPE -->
  <Label class="mt-4 mb-2" for="name">Title</Label>
  <Input
    type="text"
    name="name"
    id="name"
    placeholder={`ex: my new ${
      initialValues.isScenario ? "scenario" : "story"
    }`}
    color={"base"}
    required
  />
  <ValidationMessage for="name" let:messages>
    <Helper class="mt-2" color="red">
      {#each messages ?? [] as message}
        {message}
      {/each}
    </Helper>
  </ValidationMessage>

  <Label class="mt-4 mb-2" for="description">Description</Label>
  <Textarea
    placeholder="Enter your description here..."
    name="description"
    id="description"
  />
  
  <ValidationMessage for="description" let:messages>
    <Helper class="mt-2" color="red">
      {#each messages ?? [] as message}
        {message}
      {/each}
    </Helper>
  </ValidationMessage>

  <BlocCancelConfirm
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    vLabel="Confirm creation"
    xConfirmType="cancelStoryCreation"
    xOnConfirm={() => {reset() ; dialog.close()}}
  />
</form>
