<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import { dataStore } from "@stores/datastore";
  import CTA from "@components/Editors/CTA.svelte";
  import Panel from "@components/Panel.svelte";
  import NodeEditor from "@components/Editors/NodeEditor/NodeEditor.svelte";
  import EdgeEditor from "@components/Editors/EdgeEditor/EdgeEditor.svelte";
  import StoryEditor from "@components/Editors/StoryEditor/StoryEditor.svelte";
  
  const dispatch = createEventDispatcher();
  const { _selectedNode, _selectedEdge, _selectedStory } = dataStore;

  /**
   * Forwards the custom 'attachment-added' event to parent
   * component.
   * 
   * @param e
   */
  function forwardAttachmentAdded(e) {
    dispatch('attachment-added', e.detail);
  }  

</script>

<Panel side="right" showOnSelect expandable collapsable overflowAuto={!$_selectedNode && !$_selectedEdge && !$_selectedStory}>
  {#if $_selectedNode}
    <NodeEditor on:attachment-added={forwardAttachmentAdded}/>
  {:else if $_selectedEdge}
    <EdgeEditor />
  {:else if $_selectedStory}
    <StoryEditor />
  {:else}
    <CTA message="Click on concepts or connections or stories to display data" />
  {/if}
</Panel>
