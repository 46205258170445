<script lang="ts">
  import Can from "@components/AccessControl/Can.svelte";
  import { AnyRoles } from "@utils/decorators";
  import { displayStore } from "@stores/displaystore";
  $: innerWidth = 0;
  $: innerHeight = 0;

  const { _display, _theme } = displayStore;
  let dark = false;
  $: dark = Boolean($_theme == "dark");
</script>

<svelte:window bind:innerWidth bind:innerHeight />

<main
  data-theme={$_theme}
  class:dark
  style={`grid-template-columns: ${$_display.leftWidthSize}px 1fr ${$_display.rightWidthSize}px`}
>
  <slot name="toast" />
  <header id="row-1"><slot name="header" /></header>
  <Can roles={[AnyRoles.ALL]}
    ><aside class="w-full h-full overflow-visible" style="max-height: calc(100vh - 50px)">
      <slot name="leftPanel" />
    </aside></Can
  >
  <Can roles={[AnyRoles.ALL]}><section><slot name="graph" /></section></Can>
  <Can roles={[AnyRoles.ALL]}
    ><aside class="w-full h-full overflow-visible" style="max-height: calc(100vh - 50px)">
      <slot name="rightPanel" />
    </aside></Can
  >
</main>

<style>
  :root {
    --toastContainerTop: auto;
    --toastContainerRight: auto;
    --toastContainerBottom: 2rem;
    --toastContainerLeft: calc(50vw - 2rem);
  }
  main {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    margin: 0;
    overflow: auto;
  }
  [id^="row"] {
    grid-column: 1/-1;
  }
</style>
