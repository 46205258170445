<script lang="ts">
  import { PencilIcon as Pen } from "@rgossiaux/svelte-heroicons/solid";

  export let onClick: Function = () => {};
  export let buttonLabel = "Edit"
  export let showIcon = false;
</script>

<!--
@component
Generic "Edit" button. 
-->
<div {...$$restProps}>
  <div class="my-2">
    <button
      type="button"
      class="mr-4 kg-btn-outline"
      on:click={() => onClick()}
    >
    {#if showIcon}
      <Pen class="w-4 h-4" />
    {/if}
      {buttonLabel}
    </button>
  </div>
</div>
