<script lang="ts">
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { Status } from "@client";
  import type { UpdateStatus } from "@client";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte"
  import { getCtx } from "@components/Editors/StatusReview/context";
  import { createForm } from "felte";
  import { dataStore } from "@stores/datastore";
  import { updateNodeStatus } from "@utils/node";
  import { Label, Helper, Textarea, Select } from "@core-ui";

  let { _selectedNode: node } = dataStore;
  let { closeForm } = getCtx();
  let statusOptions = Object.values(Status);

  /**
   * Form Types, methods, schema and validations
   * const { form, data, isValid, isDirty, touched, isSubmitting } = formCtx; NOT WORKING (TRY LATER)
   */

  const { form, data, isValid, errors, isSubmitting, setInitialValues, reset } =
    createForm<UpdateStatus>({
      onSubmit: (values) => {
        updateNodeStatus($node.id, {
          ...values,
          comment: $node.comment + "\n \n --- \n" + values.comment,
        });
        closeForm();
      },
      validate(values) {
        const errors: { status: string[]; comment: string[] } = {
          status: [],
          comment: [],
        };
        if (!values.status) errors.status.push("Status is required.");
        if (values.status == Status.PENDING)
          errors.status.push("Cannot review Concept with pending status.");
        if (values.status == Status.REJECTED || values.comment != "")
          errors.comment.push(
            "Comment must not be empty with REJECTED status."
          );
        if (values.status == Status.REJECTED && values.comment != "")
          errors.comment = [];
        return errors;
      },
      extend: [reporter],
    });
  const handleFormReset = (values: UpdateStatus) => {
    reset();
    setInitialValues(values);
  };

  $: $node &&
    handleFormReset({
      status: $node.status,
      comment: "",
    } as UpdateStatus);
</script>

<!-- FORM -->
<form use:form class="w-full form-control">
  <div class="mt-2">
    <!-- <fieldset> -->
    <!-- STATUS -->
    <Label for="status" class="mt-4 mb-2">Status</Label>
    <Select
      class="mt-2"
      placeholder="Choose review status..."
      items={statusOptions}
      id="status"
      name="status"
      required
      bind:value={$data.status}
    />
    <ValidationMessage for="status" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
    <!-- COMMENT -->
    <Label for="comment" required={$data.status === Status.REJECTED} class="mt-4 mb-2">Comment</Label>
    <Textarea
      type="text"
      id="comment"
      name="comment"
      placeholder="Add reviews comment here..."
      required={$data.status === Status.REJECTED}
      color={"base"}
    />
    <ValidationMessage for="comment" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <BlocCancelConfirm
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    vLabel="Confirm feedback"
    xConfirmType="undefined"
    xOnConfirm={() => closeForm()}
  />
</form>
