<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import Type from "./Type.svelte";
  import Node from "./Node.svelte";

  export let compact:boolean = false;

  const { _selectedStory, _nodes } = dataStore;

  let nodesHierarchy = {};

  function toggleTypeDisplay(type) {
    nodesHierarchy[type].display = !nodesHierarchy[type].display;
  }

  function initNodesHierarchy() {
    nodesHierarchy = {
      driver: { display: false, children: [] },
      activity: { display: false, children: [] },
      component: { display: false, children: [] },
      pressure: { display: false, children: [] },
      good_and_benefit: { display: false, children: [] },
      ecosystem_service: { display: false, children: [] },
      response: { display: false, children: [] },
      impact_on_welfare: { display: false, children: [] },
      complimentary_capital: { display: false, children: [] },
    };
    $_selectedStory.nodes.forEach((n) =>
      nodesHierarchy[n.type].children.push($_nodes.find(node => node.id == n.id))
    );
  }

  initNodesHierarchy();

  $: $_selectedStory && initNodesHierarchy();
  export let type;
  export let index;
</script>

<!--
@component
Tree view for a node type and its children nodes.
-->
<Type {type} {index} {nodesHierarchy} {toggleTypeDisplay} />
{#if nodesHierarchy[type].display}
  <ul class="ml-4">
    {#each nodesHierarchy[type].children as node (node.id)}
      <Node {node} {compact} />
    {/each}
  </ul>
{/if}
