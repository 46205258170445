<script lang="ts">
  import { dataStore } from "@stores/datastore";

  let { _selectedNode } = dataStore;
</script>
<!--
@component
Displays concept sectors if any.
-->
{#if $_selectedNode}
  <div class="pb-4">
    <div>
      <span>Environmental process:</span>
      <span class="font-bold">
        {Object.keys($_selectedNode).includes("env_process") && $_selectedNode.env_process.length > 0
          ? $_selectedNode.env_process.map((s) => s.name)
          : "No data."}
      </span>
    </div>
  </div>
{/if}
