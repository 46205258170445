<script lang="ts">
  import {
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
  } from "@rgossiaux/svelte-headlessui";
  import { AdjustmentsIcon } from "@rgossiaux/svelte-heroicons/solid";
  import Layout from "./LayoutSettings.svelte";
  import WeightModeSettings from "./WeightModeSettings.svelte";
  import TabButton from "@components/Editors/Tabs/TabButton.svelte";
  import DimSwitcher from "@components/Core/3DSwitcher.svelte";
  import PerfoSettings from "./PerfoSettings.svelte";
  import { afterUpdate, beforeUpdate, onDestroy, onMount } from "svelte";

  function classNames(...classes: (string | false | null | undefined)[]) {
    return classes.filter(Boolean).join(" ");
  }

  function resolveClass({
    active,
    disabled,
  }: {
    active: boolean;
    disabled: boolean;
  }) {
    return classNames(
      "flex flex-col justify-between w-full px-2 py-2 text-sm leading-5 text-left",
      disabled && "cursor-not-allowed opacity-50"
    );
  }

  type TabButtonId = "layout" | "weighting" | "performance" | "dim";
  let activeButtonId: string = "layout";

  const onTabClicked = (id: TabButtonId) => {
    if (id === "dim") return;
    let elems = document.getElementsByClassName("tabcontent");

    Array.prototype.forEach.call(elems, function (elem) {
      elem.style.display = "none";
    });
    document.getElementById(id).style.display = "block";
    activeButtonId = id;
  };

  const updateOpenSettings = () => {
    activeButtonId = "layout";
  };
</script>

<div class="w-full max-w-sm px-2">
  <Menu class="mr-5" style="height: 32px">
    <MenuButton
      class="gap-2 normal-case rounded-xl btn btn-sm"
      on:click={() => {
        updateOpenSettings();
      }}
    >
      <AdjustmentsIcon class="w-6 h-6" />
      <span>Settings</span>
    </MenuButton>
    <MenuItems
      class="absolute z-20 w-[365px] mt-2 origin-top-right border divide-y rounded-md shadow-lg outline-none bg-base-100 border-base-200 divide-base-200 right-10"
    >
      <MenuItem as="div" class={resolveClass}>
        <div class="flex">
          <TabButton
            {activeButtonId}
            tabId="layout"
            {onTabClicked}
            title="Layout"
          />
          <TabButton
            {activeButtonId}
            tabId="weighting"
            {onTabClicked}
            title="Weighting mode"
          />
          <TabButton
            {activeButtonId}
            tabId="performance"
            {onTabClicked}
            title="Performance"
          />
          <TabButton {activeButtonId} tabId="dim" {onTabClicked} title=""
            ><DimSwitcher withLabel /></TabButton
          >
        </div>
        <div id="tabs">
          <div
            id="layout"
            style="display: block;"
            class="tabcontent"
            role="tabpanel"
            aria-labelledby="layout-tab"
          >
            <Layout />
          </div>
          <div
            id="weighting"
            class="tabcontent"
            role="tabpanel"
            aria-labelledby="weighting-tab"
          >
            <WeightModeSettings />
          </div>
          <div
            id="performance"
            class="tabcontent"
            role="tabpanel"
            aria-labelledby="performance-tab"
          >
            <PerfoSettings />
          </div>
        </div>
      </MenuItem>
    </MenuItems>
  </Menu>
</div>

<style>
  .active-button {
    border-bottom: 3px solid rgb(21, 207, 207);
  }

  .active-button span {
    @apply text-base-content;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    overflow: auto;
  }
</style>
