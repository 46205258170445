<script lang="ts">
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";

  import FormCreationButtons from "../FormCreationButtons.svelte";
  import { Label, Helper, Textarea, Input, MultiSelect } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import { getCtx } from "../context";
  import { type Stakeholder, type Sector } from "@client";
  import {
    createSector,
    isNameAlreadyTaken,
    updateSector,
  } from "@utils/supporting/sectors";

  const { _selectedSector, _stakeholders } = supportingStore;
  let { metricFormDialog, editModeOn } = getCtx();

  // Associated stakeholders
  let stakeholders;
  // Stakeholder properties to be used as Select label/value
  const itemId = "id";
  const label = "name";

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }

    setFields("stakeholders", val);
  };

  const {
    form,
    setFields,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
  } = createForm<Sector>({
    onSubmit: (values) => {
      if (!$editModeOn) {
        createSector(values);
        // clear form
        handleFormReset(emptyStakeholder);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateSector($_selectedSector.id, values);
        // clear form
        handleFormReset(emptyStakeholder);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        notes: string[];
      } = {
        name: [],
        notes: [],
      };
      if (!values.name) errors.name.push("Name is required.");
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedSector &&
          $_selectedSector.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");

      return errors;
    },
    extend: [reporter],
  });

  const handleFormReset = (sh: Sector): boolean => {
    setInitialValues(sh);
    stakeholders = sh.stakeholders;
    reset();
    return true;
  };

  const emptyStakeholder = {
    name: "",
    stakeholders: [],
    notes: "",
  } as Stakeholder;

  $: $editModeOn && $_selectedSector && handleFormReset($_selectedSector);
  $: !$editModeOn && handleFormReset(emptyStakeholder);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn ? "Update sector" : "Create sector"}
  </h1>
  <!-- NAME & INPUT -->
  <div class="flex flex-col items-start justify-start gap-2">
    <!-- NAME -->
    <div class="w-full">
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type sector name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- STAKEHOLDERS -->
    <div class="w-full">
      <MultiSelect
        items={$_stakeholders}
        placeholder="Select associated stakeholders"
        {handleSelectChange}
        initValues={stakeholders}
        {itemId}
        {label}
        name="stakeholders"
        componentLabel="Members"
      />
    </div>

    <!-- Notes -->
    <div class="w-full">
      <Label for="notes" class="mt-4 mb-2">Notes</Label>
      <Textarea
        class="mt-2"
        placeholder="Type notes here"
        id="notes"
        name="notes"
        color={"base"}
      />
    </div>

    <FormCreationButtons
      onClose={() => handleFormReset(emptyStakeholder)}
      isValid={$isValid}
      isSubmitting={$isSubmitting}
      create={!$editModeOn}
    />
  </div>
</form>
