<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteMetricOfForm } from "@utils/supporting/metricOfForm";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"

  const { metricFormDialog, editModeOn } = getCtx();
  const { _metricsOfForm, _selectedMetricOfForm } = supportingStore;

  const showEditMetric = (metric) => {
    _selectedMetricOfForm.set(metric);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Unit of measure</th>
        <th>Quantitative/qualitative</th>                        
        <th>Algorithm</th>
        <th>Parameter definitions</th>            
        <th>Chemical form definition</th>
        <th>Biological form definition</th>
        <th>Physics of form definition</th>
        <th>Spatiotemporal form definition</th>
        <th>Quantity definition</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_metricsOfForm as metric}
        <tr>
          <th>{formatAtomicProperty(metric.name)}</th>
          <td>{formatAtomicProperty(metric.unit?.name)}</td>              
          <td>{metric.isQualitative ?  "Qualitative" : "Quantitative" }</td>              
          <td>{formatAtomicProperty(metric?.algorithm?.name)}</td>
          <td>
            {#each metric.parameter_definitions as param, index}
              {param.name}{#if index < metric.parameter_definitions.length - 1}, {/if}                  
            {/each}
          </td>
          <td>{formatAtomicProperty(metric.chemical_form_def)}</td>
          <td>{formatAtomicProperty(metric.biological_form_def)}</td>
          <td>{formatAtomicProperty(metric.physics_form_def)}</td>
          <td>{formatAtomicProperty(metric.spatiotemporal_form_def)}</td>
          <td>{formatAtomicProperty(metric.quantity_def)}</td>              
          <GridAction
            onEdit={() => showEditMetric(metric)}
            onDelete = {() => deleteMetricOfForm(metric.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>