<script lang="ts">
  import Graph from "@components/Graph/Graph2D.svelte";
  import Graph3D from "@components/Graph/Graph3D.svelte";
  import { displayStore } from "@stores/displaystore";

  const { _graphDim } = displayStore;
</script>

{#if $_graphDim === "2D"}
  <Graph />
{:else}
  <Graph3D />
{/if}
