<script lang="ts">
  import Category from "@components/tree/Category.svelte";
  import TypeNodesList from "./TypeNodesList.svelte";

  export let displayOrders:any;
  export let categoryDisplay:any;

  export let compact:boolean = false;
  export let conceptFilter:string = "";
  export let nodeClickAction:((node) => {});
  export let useAlternativeContext:boolean = false;

</script>

<div id="{compact ? "compact_" : ""}tree_container" {...$$restProps}>
  <ul id="nodesTree">
    {#each displayOrders as display_order, index}
      <!-- {#if display_order.nodeCategory !== null}
        <Category {...display_order} />
        {#if categoryDisplay[display_order.nodeCategory]}
          {#each display_order.nodeTypes as type}
            {#if conceptFilter.length == 0 || conceptFilter == type}
              <TypeNodesList {type} {index} {compact} {nodeClickAction} {useAlternativeContext} />
            {/if}
          {/each}
        {/if}
      {:else} -->
        {#each display_order.nodeTypes as type}
          {#if conceptFilter.length == 0 || conceptFilter == type}
            <TypeNodesList {type} {index} {compact} {nodeClickAction} {useAlternativeContext} />
          {/if}
        {/each}
      <!-- {/if} -->
    {/each}
  </ul>
</div>

<style>
  #compact_tree_container{
    max-height: 250px;
    overflow: auto;
  }
</style>