<script lang="ts">
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import FormCreationButtons from "../FormCreationButtons.svelte";
  import { getCtx } from "../context";

  import {
    createEnvProcess,
    updateEnvProcess,
    isNameAlreadyTaken,
  } from "@utils/supporting/envProcess";

  import {
    Label,
    Helper,
    Textarea,
    MultiSelect,
    Input,
    Select,
  } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import { EnvProcessType, type EnvProcess } from "@client";
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import classNames from "classnames";
  
  const { _selectedEnvProcess, _metrics } = supportingStore;
  const { _nodes } = dataStore;

  let { metricFormDialog, editModeOn } = getCtx();

  // Parameter defintion properties to be used as MultiSelect label/value
  const itemId = "id";
  const label = "name";
  let default_value = {
    input: [],
    output: [],
  };
  const {
    form,
    data,
    setFields,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
  } = createForm({
    onSubmit: (values: any) => {
      values.input = values.input.map((i) => i.id);
      values.output = values.output.map((i) => i.id);
      values.metric = values.metric == null ? null : Number(values.metric)
      if (!$editModeOn) {
        // values.isQualitative = values.isQualitativeAsString == "qualitative" ? true : false;
        createEnvProcess(values as EnvProcess);
        // clear form
        handleFormReset();
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateEnvProcess($_selectedEnvProcess.id, values);
        // clear form
        handleFormReset();
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        type: string[];
        rateModifier: string[];
        metric: string[];
        notes: string[];
      } = {
        name: [],
        type: [],
        rateModifier: [],
        metric: [],
        notes: [],
      };

      if (!values.name) errors.name.push("Name is required.");
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedEnvProcess &&
          $_selectedEnvProcess.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (!values.type) errors.type.push("Type is required.");

      return errors;
    },
    extend: [reporter],
  });

  const handleSelectChange = (
    e: CustomEvent,
    namefield: "input" | "output"
  ) => {
    let val = [];
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields(namefield, val);
  };

  const handleFormReset = (ep = emptyEP) => {
    default_value.input = ep.input;
    default_value.output = ep.output;
    setInitialValues(ep);
    reset();
  };

  const emptyEP = {
    input: [],
    output: [],
    name: "",
    type: "",
    rateModifier: "",
    metric: null,
    notes: "",
  };

  $: $editModeOn &&
    $_selectedEnvProcess &&
    handleFormReset($_selectedEnvProcess);
  $: !$editModeOn && handleFormReset();
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn
      ? "Update Environmental Process"
      : "Create Environmental Process"}
  </h1>
  <!-- NAME & INPUT -->
  <div class="flex items-start justify-start gap-2">
    <!-- NAME -->
    <div class="basis-1/2">
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type environmental process name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <div class="basis-1/2">
      <Label for="type" class="mt-4 mb-2">Type</Label>
      <Select
        class="mt-2"
        placeholder="Select a type"
        id="type"
        items={Object.values(EnvProcessType)}
        name="type"
        withLabel={false}
        required
        color={"base"}
      />
      <ValidationMessage for="type" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>
  </div>

  <div class="basis-1/2">
    <MultiSelect
      items={$_nodes}
      placeholder="Select associated input"
      initValues={default_value.input}
      handleSelectChange={(e) => handleSelectChange(e, "input")}
      {itemId}
      {label}
      name="input"
      componentLabel="Input component"
      required={false}
    />
  </div>

  <div class="basis-1/2">
    <MultiSelect
      items={$_nodes}
      placeholder="Select associated output"
      initValues={default_value.output}
      handleSelectChange={(e) => handleSelectChange(e, "output")}
      {itemId}
      {label}
      name="output"
      componentLabel="Output component"
      required={false}
    />
  </div>

  <!-- rateModifier -->
  <div class="basis-1/2">
    <Label for="rateModifier" class="mt-4 mb-2">Rate Modifier</Label>
    <Textarea
      class="mt-2"
      placeholder="Type rate modifier here"
      id="rateModifier"
      name="rateModifier"
      color={"base"}
    />
  </div>

  <div class="basis-1/2">
    <Label for="metric" class="mt-4 mb-2">Metric</Label>
    <select 
      name="metric" id = "metric"
      class={classNames(displayStore.form.base, displayStore.textSizes.md, "w-full rounded-lg")}>
      <option selected value={null}>Select a metric</option>
        {#each $_metrics as item}
          <option value={item.id}>
            {item.name}
          </option>
        {/each}
    </select>
    <ValidationMessage for="metric" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <!-- Notes -->
  <div class="basis-1/2">
    <Label for="notes" class="mt-4 mb-2">Notes</Label>
    <Textarea
      class="mt-2"
      placeholder="Type notes here"
      id="notes"
      name="notes"
      color={"base"}
    />
  </div>

  <FormCreationButtons
    onClose={() => handleFormReset()}
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    create={!$editModeOn}
  />
</form>
