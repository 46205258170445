import Keycloak from "keycloak-js";
import { writable } from "svelte/store";
import type { Writable } from "svelte/store";
import { dataStore } from "@stores/datastore";
import { supportingStore } from "@stores/supportingstore";
import { OpenAPI } from "@client";
import { Roles } from "@utils/decorators";

type AuthenticationWithRole = {
  role: Roles;
};

export type KeycloakWithRole = Keycloak & AuthenticationWithRole;

const authentication: Writable<KeycloakWithRole> = writable();

const keycloak = new Keycloak({
  url: "/auth",
  realm: "eadkg",
  clientId: "eadkg_front",
}) as KeycloakWithRole;

async function initKeycloak() {
  await keycloak.init({
    onLoad: "login-required",
  });
  OpenAPI.TOKEN = keycloak.token;
  await keycloak.loadUserProfile();
  const roles: Roles[] = Object.values(Roles);
  const allRoles = keycloak.realmAccess.roles.filter((r: Roles) =>
    roles.includes(r)
  );
  keycloak["role"] = allRoles.find((r) => r !== Roles.DISABLED) as Roles;
  // keycloak["role"] =
  //   allRoles.length === 1
  //     ? Roles.DISABLED
  // : (allRoles.find((r) => r !== Roles.DISABLED) as Roles);
  dataStore.firstLoad();
  supportingStore.firstLoad();
  authentication.set(keycloak);
}

export { authentication, initKeycloak };
