<script lang="ts">
  export let items = [];
  export let selected: string;
  export let name: string;
  export let onRadioGroupChange = (e) => {};

</script>

{#each items as item}
  <div>
    <label>
      <input
        type="radio"
        id={item.value}
        {name}
        value={item.value}
        checked={selected == item.value ? true : null}
        on:change={onRadioGroupChange}
      />
      {item.label}
    </label>
  </div>
{/each}
