<script lang="ts">
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { getCtx } from "./context";
  import { CheckIcon } from "@rgossiaux/svelte-heroicons/solid";

  let { metricFormDialog } = getCtx();

  export let onClose: Function = () => {};
  export let isValid: boolean;
  export let isSubmitting: boolean;
  export let create: boolean = true;
  let dialogCancel;
</script>

<div class="flex items-center justify-end w-full mt-4">
  <DeleteButton
    label="Cancel"
    icon="Xicon"
    type="button"
    confirmDialog={dialogCancel}
  >
    <DialogConfirmAction
      bind:this={dialogCancel}
      type={create
        ? "cancelSupportingClassCreation"
        : "cancelSupportingClassEdit"}
      actionOnYes={() => {
        onClose();
        $metricFormDialog.close();
      }}
    />
  </DeleteButton>

  <button
    type="submit"
    disabled={!isValid && isSubmitting}
    class={`normal-case btn btn-sm gap-2 btn-success rounded-xl text-success-content
    ${isSubmitting ? "loading btn-disabled" : ""}`}
  >
    <CheckIcon class="w-4 h-4" />
    {isSubmitting ? "Loading..." : "Confirm"}
  </button>
</div>
