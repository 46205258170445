/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UnitOfMeasure } from '../models/UnitOfMeasure';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UnitsOfMeasureService {

    /**
     * Get Units Of Measure
     * @returns UnitOfMeasure Successful Response
     * @throws ApiError
     */
    public static getUnitsOfMeasureApiV1UnitsOfMeasureGet(): CancelablePromise<Array<UnitOfMeasure>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/units_of_measure/',
        });
    }

    /**
     * Create Unit Of Measure
     * @param requestBody
     * @returns UnitOfMeasure Successful Response
     * @throws ApiError
     */
    public static createUnitOfMeasureApiV1UnitsOfMeasurePost(
        requestBody: UnitOfMeasure,
    ): CancelablePromise<UnitOfMeasure> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/units_of_measure/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Unit Of Measure
     * @param id
     * @param requestBody
     * @returns UnitOfMeasure Successful Response
     * @throws ApiError
     */
    public static putUnitOfMeasureApiV1UnitsOfMeasureIdPut(
        id: number,
        requestBody: UnitOfMeasure,
    ): CancelablePromise<UnitOfMeasure> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/units_of_measure/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Unit Of Measure
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteUnitOfMeasureApiV1UnitsOfMeasureIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/units_of_measure/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
