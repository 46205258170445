<script lang="ts">
  import { displayStore } from "@stores/displaystore";
  import type { SelectOptionType } from "@components/Core/types";
  import Select from "svelte-select";
  import Label from "./Label.svelte";
  
  const { _theme} = displayStore;
  // TODO @refact: externalize error message management to felte
  // Props
  export let value: string[] | number[] = [];
  export let items: SelectOptionType[] | string[] | number[] | any[]= [];
  // the form field label
  export let componentLabel = null;  
  export let placeholder="Please select one or more";
  // Item properties to be used as Select value (itemId) and list label (label)
  export let itemId;
  export let label;
  export let name = 'name';
  export let clazz = '';
  export let requiredMessage = '';
  export let required: boolean = false;
  export let initValues = [];
  export let justValues = [];
  export let handleSelectChange = (e) => {};
  export let multiple: boolean = true;
  export let clearable: boolean = true;
  export let multiFullItemClearable: boolean = true;
  
  let isEmpty: boolean = false;

  function handleSelectChangeBefore(e) {

    if (e.detail) {
      value = (Array.isArray(e.detail)) ?  e.detail : [e.detail];
      isEmpty = false;
    }
    else {
      value = undefined;
      isEmpty = true;
    }
    handleSelectChange(e);
  }
</script>

<div>
  {#if componentLabel}
  <Label for={name} class="mt-4 mb-2">
    {componentLabel}
    {#if required}
      <span class="text-red-500"> *</span>
    {/if}
  </Label>
  {/if}
  <Select
    on:input={handleSelectChangeBefore}
    bind:value={initValues}
    bind:justValue={justValues}      
    {itemId} {label} {name} {placeholder} {items} 
    id={name} 
    class={clazz}
    hasError={required && isEmpty} 
    containerStyles="width:250px;" 
    multiple={multiple}
    multiFullItemClearable={multiFullItemClearable}
    clearable
    --error-background={$_theme == "dark" ? "#3f3f46" : "#f9fafb"}
    --error-border = {$_theme == "dark"? "#3F3F46" : "E4E4E7"}
    --list-max-height="180px"
    --multi-item-color="#3f3f46"
    --item-color={$_theme == "dark" ? "#f9fafb" : "black" } 
    --item-hover-color = "black"
    --background = {$_theme == "dark" ? "#374151" : "#f9fafb"} 
    --list-background = {$_theme == "dark" ? "#3f3f46" : "#f9fafb"} 
    --item-hover-bg = "#e5e7eb"
    --border = {`1px solid ${$_theme == "dark" ? "#4b5563" : "#D1D5DB"}`}
    --border-hover = {`1px solid ${$_theme == "dark" ? "#4b5563" : "#D1D5DB"}`}
    --list-border = {`1px solid ${$_theme == "dark" ? "#4b5563" : "#3f3f46"}`}
    --border-focused = {`2px solid ${$_theme == "dark" ? "#FAFAFA" : "#333333"}`}
  />
  {#if required && isEmpty}
    <p>
      <span class="pt-20 text-xs font-normal text-gray-500 text-red-700 dark:text-gray-300 dark:text-red-500">{requiredMessage}</span>
    </p>
  {/if}
</div>
