<script lang="ts">
  import { marked } from "marked";

  export let content: string = "";
</script>
<!--
@component
Displays concept description if any.
-->
<div class="mt-1 mb-1 mr-12">
  <span class="text-justify">
    {#if content}
      {@html marked(content)}
    {:else}
      Description not available
    {/if}
  </span>
</div>
