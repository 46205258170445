<script lang="ts">
  // TODO: refact: move to app wide generic component folder.
  export let activeButtonId;
  export let tabId;
  export let onTabClicked;
  export let title;
  export let id = "default";
</script>

<button
  on:click={(e) => {
    onTabClicked(tabId);
    e.stopPropagation();
  }}
  class:active-button={tabId == activeButtonId}
  class="flex items-center justify-center flex-1 px-2 py-4 mr-2 text-sm font-normal text-center text-gray-500 border-b-2 border-transparent rounded-t-lg grow tab-button hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300"
  {id}
  data-tabs-target="#resources"
  type="button"
  role="tab"
  aria-controls="resources"
  aria-selected="false"
>
  {#if title !== ""}
    <span class="mr-1">{title}</span>
  {/if}
  <slot />
</button>

<style>
  .active-button {
    border-bottom: 3px solid;
    @apply border-b-gray-300;
    @apply text-base-content;
    @apply font-medium;
  }

  .active-button .pastille-nbr {
    @apply bg-neutral-content;
  }
</style>
