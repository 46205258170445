<script lang="ts">
  import {
    XIcon,
    CheckIcon,
  } from "@rgossiaux/svelte-heroicons/solid";
  import ArrowPath from "@components/icons/ArrowPath.svelte";
  export let status: string = null;
  export let type: string;
  export let click: Function = () => {};
</script>

<!-- {#if nodeTypes[type].form === "rectangle"}
  <Rectangle {type} {click} {status} />
{:else}
  <Circle {type} {click} {status} />
{/if} -->
{#if status == "rejected"}
  <XIcon class="w-4 h-4 m-2 rounded-full btn-error" />
{:else if status == "adopted"}
  <CheckIcon class="w-4 h-4 m-0 rounded-full btn-success" />
{:else if status == "pending"}
  <ArrowPath class="w-4 h-4 rounded-full btn-info" />
{/if}
