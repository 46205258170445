/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MetricOfFunctionalHealth } from '../models/MetricOfFunctionalHealth';
import type { ParameterDefinition } from '../models/ParameterDefinition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetricsOfFunctionalHealthService {

    /**
     * Get Metrics Of Functional Health
     * @returns MetricOfFunctionalHealth Successful Response
     * @throws ApiError
     */
    public static getMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthGet(): CancelablePromise<Array<MetricOfFunctionalHealth>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/metrics-of-functional-health/',
        });
    }

    /**
     * Create Metrics Of Functional Health
     * @param requestBody
     * @returns MetricOfFunctionalHealth Successful Response
     * @throws ApiError
     */
    public static createMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthPost(
        requestBody: MetricOfFunctionalHealth,
    ): CancelablePromise<MetricOfFunctionalHealth> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/metrics-of-functional-health/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Metrics Of Functional Health
     * @param id
     * @param requestBody
     * @returns MetricOfFunctionalHealth Successful Response
     * @throws ApiError
     */
    public static updateMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthIdPut(
        id: number,
        requestBody: MetricOfFunctionalHealth,
    ): CancelablePromise<MetricOfFunctionalHealth> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/metrics-of-functional-health/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Metrics Of Functional Health
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/metrics-of-functional-health/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Parameters
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateParametersApiV1MetricsOfFunctionalHealthIdParametersPatch(
        id: number,
        requestBody: Array<ParameterDefinition>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/metrics-of-functional-health/{id}/parameters',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
