<script lang="ts">
  import { dataStore } from "@stores/datastore";
  import type { NodeBase } from "@client";
  import { storyType } from "@utils/story";
  import { Divider } from "@core-ui";
  import Tabs from "./Tabs.svelte";
  import {
    setCtx as setStatusReviewCtx,
    getCtx as getStatusReviewCtx,
  } from "@components/Editors/StatusReview/context";
  import { getCtx as getStoryEditorCtx } from "@components/Editors/StoryEditor/context";
  import { setCtx as setResourcesCtx } from "@components/Actions/CreateResource/context";
  import Header from "@components/Editors/StoryEditor/StoryPreview/index.svelte";
  const { _selectedStory, _graphData } = dataStore;

  let nodes: NodeBase[];
  let sType: string;

  setStatusReviewCtx();
  setResourcesCtx();
  let { closeForm } = getStatusReviewCtx();
  let { editOff } = getStoryEditorCtx();

  $: if ($_selectedStory && $_graphData) {
    nodes = $_selectedStory.nodes;
    sType = storyType();
  }
  $: $_selectedStory && editOff();
  $: $_selectedStory && closeForm();
</script>

<article class="w-full h-full overflow-x-hidden overflow-y-auto">
  {#if $_selectedStory}
    <Header />
    <Divider />
    <Tabs />
  {/if}
</article>

<style>
  span {
    @apply m-0;
  }
  li {
    @apply flex
    m-0
    items-center;
  }
</style>
