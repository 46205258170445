<script lang="ts">
  import type { Aggregations } from "@client";
  import Dialog from "./Dialog.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { deletePart } from "@utils/aggregate";
  import { dataStore } from "@stores/datastore";
  import { ClipboardIcon } from "@rgossiaux/svelte-heroicons/solid";
  import Divider from "@components/Core/components/Divider.svelte";

  let element: Aggregations;

  let dialog;
  let dialogRemove;

  function removePart() {
    deletePart(element.id);
    dialog.close();
  }

  export const show = (el: Aggregations, coord) => {
    element = el;
    dialog.show(coord);
  };

  export const close = () => {
    dialog.close();
  };
</script>

<Dialog bind:this={dialog}>
  <div class="p-1 mt-2 mb-2">
    {#if element}
      <span on:click={() => dataStore.setSelectedNode(dataStore.nodes.find(e => e.id == element.id))}>
        <ClipboardIcon class="w-5 h-5 mr-2"/>
          View detail
      </span>
      
      <Can roles={[Roles.DEV, Roles.CONCEPT]}>
        <Divider/>
        <div class="ml-4">
          <DeleteButton 
            label="Remove" 
            type="menu" 
            icon="Xicon" 
            confirmDialog={dialogRemove}
          >
            <DialogConfirmAction
              bind:this={dialogRemove}
              type="removePart"
              actionOnNo={close}
              actionOnYes={() => {removePart()}}
            />
          </DeleteButton>
        </div>
      </Can>
    {/if}
  </div>
</Dialog>

<style>
  span {
    @apply hover:font-bold hover:cursor-pointer;
    margin-left: 16px;
    display: flex;
  }
</style>
