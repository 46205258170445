import { ParameterDefinitionsService } from "@client";
import type { ParameterDefinition } from "@models/ParameterDefinition";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createParameterDefinition = (param: ParameterDefinition) => {
  try {
    ParameterDefinitionsService.createParameterDefinitionApiV1ParameterDefinitionsPost(param).then((res) => {
      notifySuccess("Parameter definition succesfully created");
      // Insert associated data providers:
      if (param.stakeholders && param.stakeholders.length>0) {
        ParameterDefinitionsService.updateParameterDefinitionDataProvidersApiV1ParameterDefinitionsIdDataProvidersPatch(
          res.id, 
          param.stakeholders
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          notifySuccess("Data providers succesfully added to the new parameter definition");
          res.stakeholders = param.stakeholders;
          supportingStore.addParameterDefinition(res);
        });
      }
      else {
        res.stakeholders = [];
        supportingStore.addParameterDefinition(res);
      }
    });
  } catch (e) {
    notifyFailure(`An error occured while creating the new parameter definition: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateParameterDefinition = async (
  parameterDefinitionId: number,
  param: ParameterDefinition = null
) => {
  try {
    ParameterDefinitionsService.updateParameterDefinitionApiV1ParameterDefinitionsIdPut(
      parameterDefinitionId,
      param
    ).then((res) => {
      notifySuccess("Parameter definition succesfully updated");
      if (param.stakeholders && param.stakeholders.length>0 ) {
        ParameterDefinitionsService.updateParameterDefinitionDataProvidersApiV1ParameterDefinitionsIdDataProvidersPatch(
          res.id, 
          param.stakeholders
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          notifySuccess("Parameter definition data providers succesfully updated");          
          res.stakeholders = param.stakeholders;
          supportingStore.updateSelectedParameterDefinition(res);
        });
      }
      else {
        res.stakeholders = [];
        supportingStore.updateSelectedParameterDefinition(res);
      }
    });    
  } catch (e) {
    notifyFailure(`An error occured while updating the parameter definition: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deleteParameterDefinition = (id: number) => {
  try {
    ParameterDefinitionsService.deleteParameterDefinitionApiV1ParameterDefinitionsIdDelete(id).then(() => {
      notifySuccess("Parameter definition succesfully deleted");        
      supportingStore.removeParameterDefinition(id);
    });
  }
  catch (e) {
    notifyFailure(`An error occured while deleting the new parameter definition: ${e.status}-${e.name}:${e.statusText}`);
  }  
};

/**
 * Returns true if the provided name is already taken by an existing ParameterDefinition.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean true if name is already in use. Else false.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.parameterDefinition.some(
      (s: ParameterDefinition) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
