<script context="module" lang="ts">
  import type { SelectOptionType } from "@components/Core/types";
  export const resourceTypeOptions: SelectOptionType[] = [
    { value: "photo", label: "Photo" },
    { value: "formal_definition", label: "Formal definition" },
    { value: "scientific_paper", label: "Scientific paper" },
    { value: "report", label: "Report" },
    { value: "monitoring_data", label: "Montoring data" },
  ];
</script>

<script lang="ts">
  import { validator } from "@felte/validator-zod";
  import * as zod from "zod";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte"
  import { getCtx } from "@components/Actions/CreateResource/context";
  import { createForm } from "felte";
  import { getSelectedItem } from "@stores/derivedstore";
  import { Input, Label, Helper, Textarea, Select } from "@core-ui";

  /**
   * Dialog state and methods
   */
  let { close, resources } = getCtx();

  /**
   * Form Types, methods, schema and validations
   * const { form, data, isValid, isDirty, touched, isSubmitting } = formCtx; NOT WORKING (TRY LATER)
   */
  const schema = zod.object({
    type: zod.string().nonempty({
      message: "Type is required.",
    }),
    title: zod.string().nonempty({
      message: "Title is required.",
    }),
    url: zod
      .string()
      .url({
        message: "Link must have URL format.",
      })
      .nonempty({
        message: "Link is required.",
      }),
    description: zod.string(),
  });

  const { form, data, isValid, errors, isSubmitting } = createForm<
    zod.infer<typeof schema>
  >({
    onSubmit: async (values) => {
      try {
        if ($getSelectedItem) {
          const response = await resources.insert(
            $getSelectedItem.type,
            $getSelectedItem.id,
            values
          );
          if (response) {
            close();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    extend: validator({ schema }),
  });
</script>

<!-- FORM -->
<!-- TODO: Create reusable FormInputs components -->
<form use:form class="w-full form-control">
  <div class="mt-2">
    <!-- <fieldset> -->
    <!-- TYPE -->
    <Label for="type" class="mt-4 mb-2">Type</Label>
    <Select
      class="mt-2"
      placeholder="Select evidence type..."
      withLabel
      items={resourceTypeOptions}
      id="type"
      name="type"
      required
      bind:value={$data.type}
    />
    <Helper class="mt-2" color="red">
      {#each $errors.type ?? [] as message}
        <span>{message}</span>
      {/each}
    </Helper>
    <!-- TITLE -->
    <Label for="title" class="mt-4 mb-2">Title</Label>
    <Input
      type="text"
      id="title"
      name="title"
      placeholder="ex: my new evidence"
      required
      color={"base"}
    />
    <Helper class="mt-2" color="red">
      {#each $errors.title ?? [] as message}
        <span>{message}</span>
      {/each}
    </Helper>

    <!-- URL -->
    <Label for="url" class="mt-4 mb-2">Link</Label>
    <Input
      type="text"
      name="url"
      id="url"
      placeholder="ex: https://www.my-evidence.info/document.pdf"
      required
    />
    <Helper class="mt-2" color="red">
      {#each $errors.url ?? [] as message}
        <span>{message}</span>
      {/each}
    </Helper>
    <!-- DESCRIPTION -->
    <Label for="description" class="mt-4 mb-2">Description</Label>
    <Textarea
      placeholder="Please enter description here..."
      name="description"
      id="description"
      rows="3"
      required
    />
    <Helper class="mt-2" color="red">
      {#each $errors.description ?? [] as message}
        <span>{message}</span>
      {/each}
    </Helper>
  </div>
  
  <BlocCancelConfirm
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    vLabel="Add evidence"
    xOnConfirm={() => close()}
  />
</form>
