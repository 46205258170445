<script lang="ts">
  import { isNil } from "rambda";
  import { displayStore } from "@stores/displaystore";
  import { dataStore } from "@stores/datastore";
  import classNames from "classnames";
  import {
    ChevronDoubleRightIcon as Rarrow,
    ChevronDoubleLeftIcon as Larrow,
  } from "@rgossiaux/svelte-heroicons/solid";

  type Side = "left" | "right";

  export let expandable: boolean = false;
  export let collapsable: boolean = false;
  export let showOnSelect = false;
  export let side: Side = "left";
  export let overflowAuto = false;
  let isExpanding: boolean = false;
  let expandStartX: number = 0;
  let expandEndX: number = 0;
  let delta: number = 0;
  let showContent: boolean = true;

  const { _display } = displayStore;
  const { _selectedNode, _selectedEdge, _selectedStory } = dataStore;

  $: show(
    !isNil($_selectedNode) || !isNil($_selectedEdge) || !isNil($_selectedStory)
  );

  function handleToogle() {
    displayStore.toggleDisplay(side);
    showContent = $_display[side];
  }

  /**
   * Sets the visibility of the panel.
   *
   * @param {boolean} toggleOn - true if the panel is be shown.
   *   Else false.
   */
  function show(toggleOn: boolean) {
    if (showOnSelect) {
      displayStore.setDisplay(side, toggleOn);
      showContent = $_display[side];
    }
  }

  function calcDelta(side: Side, xStart: number, xEnd: number) {
    return side === "left"
      ? expandEndX - expandStartX
      : expandStartX - expandEndX;
  }

  function startExpanding(e: MouseEvent) {
    isExpanding = true;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    expandStartX = e.pageX;
  }

  function endExpanding(e: MouseEvent, side: Side) {
    if (isExpanding) {
      expandEndX = e.pageX;
      delta = calcDelta(side, expandStartX, expandEndX);
      displayStore.resizePanel(side, delta);
    }
    isExpanding = false;
  }

  function whileExpanding(e: MouseEvent, side: Side) {
    if (isExpanding) {
      expandEndX = e.pageX;
      delta = calcDelta(side, expandStartX, expandEndX);
      // TODO: implement icrement resize for realtime GUI refresh
      // displayStore.resizePanel(side, delta);
    }
  }

  $: showContent = $_display[side];
</script>

<svelte:window
  on:mouseup={(e) => {
    if (expandable) endExpanding(e, side);
  }}
  on:mousemove={(e) => {
    if (expandable) whileExpanding(e, side);
  }}
/>
<div
  class={classNames(
    "flex flex-row w-full h-full overflow-auto bg-base-200 overflow-visible",
    {
      "flex-row border-r-1 border-base-content border-opacity-25": Boolean(
        side === "left"
      ),
    },
    {
      "flex-row-reverse border-r-1 border-base-content border-opacity-25":
        Boolean(side === "right"),
    }
  )}
  {...$$restProps}
>
  {#if showContent}
    <div
      data-testid="panel-content"
      class="flex-1 h-full p-3 content-max-width relative {overflowAuto ? "overflow-auto" : ""}"
    >
      <slot />
    </div>
  {/if}
  <div
    data-testid="panel-size-actions"
    class="flex flex-col flex-none w-6 h-full"
  >
    {#if collapsable}
      <div
        class="flex-none h-10 cursor-pointer base-200"
        on:click={() => handleToogle()}
      >
        {#if ($_display[side] && side === "left") || (!$_display[side] && side === "right")}
          <Larrow class="w-6 h-6" />
        {:else if ($_display[side] && side === "right") || (!$_display[side] && side === "left")}
          <Rarrow class="w-6 h-6" />
        {/if}
      </div>
    {/if}
    <div
      class="flex-1 h-full"
      class:hover:bg-base-300={expandable}
      class:hover:cursor-move={expandable}
      on:mousedown={(e) => {
        if (expandable) startExpanding(e);
      }}
    />
  </div>
</div>

<style>
  .content-max-width {
    width: calc(100% - 1.5rem);
  }
</style>
