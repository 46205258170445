<script lang="ts">
    import Label from "@components/Core/forms/Label.svelte";

  export let outside_ad: boolean|null;
  export let inside_ad: boolean|null;
</script>
<!--
@component
"present in EAD" property form.
-->
  <div>
    <input 
      type="checkbox" 
      id="inside_ad"
      name="inside_ad"
      style="outline-width: 0px;"
      class="shadow-none outline-none appearance-none text-primary bg-base-300"
      bind:value={inside_ad} 
    >
    <label for="insideAD" class="mr-2">Inside Abu Dhabi</label>    
    <input 
      type="checkbox" 
      id="outside_ad" 
      class="outline-none appearance-none text-primary bg-base-300"  
      name="outside_ad" 
      style="outline-none;"          
      bind:value={outside_ad}
    >      
    <label for="outsideAD">Outside Abu Dhabi</label>    
  </div>

