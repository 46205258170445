<script lang="ts">
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Transition,
    TransitionChild,
  } from "@rgossiaux/svelte-headlessui";
  import { getCtx } from "@components/Actions/CreateResource/context";

  import { displayStore } from "@stores/displaystore";

  const { _theme } = displayStore;

  export let fullHeight: boolean = false;
  export let title = "Add new evidence";
  export let description = "";

  /**
   * Dialog state and methods
   */
  let context = getCtx();
  let { state, close } = context;
</script>

<Transition appear show={$state.isOpen}>
  <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" on:close={close} {fullHeight}>
    <div data-theme={$_theme} class="min-h-screen px-4 text-center bg-black/25">
      <TransitionChild
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogOverlay class="fixed inset-0" />
      </TransitionChild>

      <TransitionChild
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div
          class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl bg-base-200 rounded-2xl"
        >
          <DialogTitle
            as="h3"
            class="text-lg font-medium leading-6 text-base-content "
          >
            {title}
          </DialogTitle>
          <DialogTitle
            as="h5"
            class="font-medium leading-6 text-md text-base-300 "
          >
            {description}
          </DialogTitle>
          <slot name="content">
            <div>Add content here</div>
          </slot>
        </div>
      </TransitionChild>
    </div>
  </Dialog>
</Transition>

<style>
  .label-required {
    content: "*";
    color: red;
  }

  input[required] + label:before {
    content: "*";
    color: red;
  }
</style>
