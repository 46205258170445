/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StateMetric } from '../models/StateMetric';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StateMetricsService {

    /**
     * Get State Metrics
     * @returns StateMetric Successful Response
     * @throws ApiError
     */
    public static getStateMetricsApiV1StateMetricsGet(): CancelablePromise<Array<StateMetric>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/state-metrics/',
        });
    }

    /**
     * Create State Metric
     * @param requestBody
     * @returns StateMetric Successful Response
     * @throws ApiError
     */
    public static createStateMetricApiV1StateMetricsPost(
        requestBody: StateMetric,
    ): CancelablePromise<StateMetric> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/state-metrics/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update State Metric
     * @param id
     * @param requestBody
     * @returns StateMetric Successful Response
     * @throws ApiError
     */
    public static updateStateMetricApiV1StateMetricsIdPut(
        id: number,
        requestBody: StateMetric,
    ): CancelablePromise<StateMetric> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/state-metrics/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete State Metric
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteStateMetricApiV1StateMetricsIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/state-metrics/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
