<script lang="ts" > 
	import { onMount} from 'svelte';
  import { supportingStore } from "@stores/supportingstore";
  import { Input, RadioGroup, Label, MultiSelect} from "@core-ui";
  import { createForm, getValue  } from 'felte';
  import { displayStore } from '@stores/displaystore';
  
  export let name: string;
  export let label: string;
  export let fieldType: string;
  export let fieldValues: any;    
  export let value   : string | null;
  export let required: boolean

  /**
   * Returns the field value
   *
   * @return {string} the field value.
   */
  export function getVal() {
      value =  getValue($data, name)
      return value == "" ? null : value ;
  }

  /**
   * Returns the field name.
   *
   * @return {string} the field name.
   */
  export function getName() {
    return name;
  }  


  const { _envProcess } = supportingStore
  
  const { form, data,  setFields, setInitialValues, reset, setData } = createForm({
  })

  onMount(async () => {
    if(fieldType != "select"){
      setInitialValues({name: setValue()})
    }
  });
  
  const setValue = () => {
    setFields(name, value);
    return value
  }


</script>
<!--
@component
Dynamic custom property form field based on property definition 
in nodeTypes.ts. 
-->
<form use:form class="w-full p-1 form-control">
  <Label for={name} {required} class="block mb-2 font-bold">{label}:</Label>

  {#if fieldType == "select"}
    <select
      class={`${displayStore.form.base} ${displayStore.textSizes.md} rounded-lg`}
      name={name} 
      id={name} 
      {required}
      bind:value={value}>
      <option disabled={required} selected value="">Select {label}</option>
      {#each fieldValues as val} 
            <option value={val} >{val}</option>
      {/each}
    </select>
  {:else if fieldType == "input"}
  <Input
    class="mt-2 default"
    placeholder="Type environmental process name here"
    id={name}
    {name}
    
  />
  {:else if fieldType == "radiogroup"}
  <RadioGroup
    {name}
    selected={value}
    items={fieldValues}
  />
  {/if}
</form>

<style>
  .default{
    @apply text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-base-content focus:border-base-content dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-base-content dark:focus:border-base-content
  }
</style>