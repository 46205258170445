<script lang="ts">
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import { getCtx } from "@components/Editors/NodeEditor/context";

  export let onConfirm: Function = () => {};

  const { 
    editMode, 
    editOff, 
    editComment, 
    editSupportingMode, 
    editPropertiesMode,
    setEditComment,
    setEditPropertiesMode,
    setEditSupportingMode
  } = getCtx();

  const isInEditMode = () => {
    return ($editMode || $editComment || 
      $editSupportingMode || $editPropertiesMode)
  }

  const leaveEditMode = () => {
    editOff();
    setEditComment(false);
    setEditPropertiesMode(false);
    setEditSupportingMode(false);
  }

  let dialogExitConceptEdition;

  function onConfirmNavigation() {
    if (isInEditMode()) {
      dialogExitConceptEdition.actionOnYes = () => {
        leaveEditMode()
        onConfirm();
      };
      dialogExitConceptEdition.show(null);
    } else {
      onConfirm();
    }
  }
</script>

<span {...$$props} on:click={onConfirmNavigation}>
  <slot />
</span>
<DialogConfirmAction
  bind:this={dialogExitConceptEdition}
  type="exitConceptEdition"
/>
