/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ResponseType {
    REGULATORY = 'Regulatory',
    REMEDIAL = 'Remedial',
    BEHAVIOUR_CHANGE = 'Behaviour change',
}
