<script lang="ts">
  import {
    PlusIcon as Plus,
    LinkIcon,
  } from "@rgossiaux/svelte-heroicons/solid";

  import { dataStore } from "@stores/datastore";
  import type { NodesWithStories, Type } from "@client";
  import {
    isNodeofStory,
    addNodeToCurrentStory,
    deleteNodeFromCurrentStory,
    isEmptyMainStory,
    isMainStory,
    storyType,
  } from "@utils/story";
  import { deleteNode } from "@utils/node";
  import GraphIcon from "@components/icons/GraphIcon.svelte";

  import Dialog from "./Dialog.svelte";
  import { Divider } from "@core-ui";
  import CreateLink from "./CreateLink.svelte";
  import Can from "@components/AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import DeleteButton from "@components/Editors/NodeEditor/edit/DeleteButton.svelte";
  import CreateSubConcept from "@components/Actions/CreateSubConcept.svelte";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";

  export let context: "graph" | "thesaurusPanel" | "rightGraphPanel" =
    "thesaurusPanel";
  let nodeClicked: NodesWithStories;

  let dialog;
  let dialogDeleteConcept;
  let dialogRemoveConcept;
  let createLink;
  let createSubConcept;

  const { _selectedStory, _nodes } = dataStore;

  function deleteN(n) {
    deleteNode(n.id);
    dialog.close();
  }

  export const show = (n: NodesWithStories, coord) => {
    nodeClicked = n;
    dialog.show(coord);
  };

  export const close = () => {
    dialog.close();
  };
</script>

<CreateSubConcept bind:this={createSubConcept} />
<CreateLink bind:this={createLink} />
<Dialog bind:this={dialog}>
  <div class="p-1 mt-2 mb-2">
    {#if nodeClicked}
      <Can roles={[Roles.DEV, Roles.STORY]}>
        {#if context != "rightGraphPanel"}
          <span
            on:click={(e) => {
              createLink.show(null, nodeClicked);
              dialog.close();
            }}
          >
            <LinkIcon class="w-5 h-5 mr-2" /> Create a connection
          </span>
          <Divider />
        {/if}
        {#if $_selectedStory && nodeClicked && (!isMainStory($_selectedStory) || isEmptyMainStory($_selectedStory)) && !isNodeofStory(nodeClicked)}
          <span
            on:click={() => {
              addNodeToCurrentStory([nodeClicked]);
              dialog.close();
            }}
            ><GraphIcon class="w-5 h-5 mr-2" /> Add to current {storyType()}
          </span>
        {/if}

        {#if $_selectedStory && nodeClicked && isNodeofStory(nodeClicked)}
          <div class="ml-4">
            <DeleteButton
              label={"Remove from current " + storyType()}
              type="menu"
              icon="Xicon"
              confirmDialog={dialogRemoveConcept}
            >
              <DialogConfirmAction
                bind:this={dialogRemoveConcept}
                type="removeNodeFromStory"
                actionOnYes={() => {
                  deleteNodeFromCurrentStory([nodeClicked]);
                  dialog.close();
                }}
              />
            </DeleteButton>
          </div>
        {/if}
      </Can>
      <Can roles={[Roles.DEV, Roles.CONCEPT]}>
        {#if context == "thesaurusPanel"}
          <Divider />
          <div
            class="flex flex-row mt-3 ml-4 hover:font-bold hover:cursor-pointer"
            on:click={() => {
              createSubConcept.show(null, nodeClicked.type, nodeClicked.id);
              dialog.close();
            }}
          >
            <Plus class="w-5 h-5 mr-2" />
            <p>
              Create a sub-{nodeClicked.type}
            </p>
          </div>
          <Divider />
          <div class="ml-4">
            <DeleteButton type="menu" confirmDialog={dialogDeleteConcept}>
              <DialogConfirmAction
                bind:this={dialogDeleteConcept}
                type="deleteConcept"
                actionOnYes={() => deleteN(nodeClicked)}
              />
            </DeleteButton>
          </div>
        {/if}
      </Can>
    {/if}
  </div>
</Dialog>

<style>
  span {
    @apply hover:font-bold hover:cursor-pointer;
    margin-left: 16px;
    display: flex;
  }
</style>
