/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Sector } from '../models/Sector';
import type { Stakeholder } from '../models/Stakeholder';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SectorsService {

    /**
     * Get Sectors
     * @returns Sector Successful Response
     * @throws ApiError
     */
    public static getSectorsApiV1SectorsGet(): CancelablePromise<Array<Sector>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/sectors/',
        });
    }

    /**
     * Create Sector
     * @param requestBody
     * @returns Sector Successful Response
     * @throws ApiError
     */
    public static createSectorApiV1SectorsPost(
        requestBody: Sector,
    ): CancelablePromise<Sector> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/sectors/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Sector
     * @param id
     * @param requestBody
     * @returns Sector Successful Response
     * @throws ApiError
     */
    public static updateSectorApiV1SectorsIdPut(
        id: number,
        requestBody: Sector,
    ): CancelablePromise<Sector> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/sectors/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Sector
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteSectorApiV1SectorsIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/sectors/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Stakeholders
     * @returns Sector Successful Response
     * @throws ApiError
     */
    public static getStakeholdersApiV1SectorsIdStakeholdersGet(): CancelablePromise<Array<Sector>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/sectors/{id}/stakeholders',
        });
    }

    /**
     * Update Sector Stakeholders
     * @param id
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static updateSectorStakeholdersApiV1SectorsIdStakeholdersPatch(
        id: number,
        requestBody: Array<Stakeholder>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/V1/sectors/{id}/stakeholders',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
