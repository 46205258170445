<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
  stroke="currentColor"
  fill="currentColor"
  {...$$restProps}
>
  <g clip-path="url(#clip0_3684_4356)">
    <path d="M10.0417 13.1833C8.45832 13.1833 7.20832 11.85 7.29166 10.35C7.37499 8.84998 8.54166 7.59998 10.125 7.59998C11.7083 7.59998 12.9583 8.93331 12.875 10.4333C12.7917 11.9333 11.5417 13.1833 10.0417 13.1833Z"/>
    <path d="M7.70004 13.2833L7.16671 13.75L5.81671 14.8833C5.74171 14.9166 5.69171 15.0166 5.71671 15.1C5.95004 15.725 5.85004 16.3833 5.28337 16.7833C4.77504 17.3333 4.01671 17.4416 3.45004 17.125C2.59171 16.7416 2.31671 15.775 2.62504 14.9416C3.00837 14.0833 3.97504 13.8083 4.80837 14.1166L4.91671 14.1666C5.02504 14.2166 5.10004 14.1833 5.18337 14.1583L6.94171 12.6916C7.02504 12.6027 7.12226 12.625 7.23337 12.7583C7.40004 12.9583 7.56671 13.1666 7.70004 13.2916V13.2833Z"/>
    <path d="M17.3917 15.3749C17.0833 15.9666 16.5167 16.3249 15.8083 16.2416C15.175 16.1916 14.625 15.8083 14.45 15.2416C14.3417 14.8999 14.3167 14.6083 14.3917 14.2749C14.4333 14.1999 14.3917 14.0916 14.325 14.0499C13.925 13.7416 13.4083 13.4749 13 13.1666L12.85 13.0916C12.775 13.0499 12.8167 12.9749 12.85 12.9083C12.8833 12.8416 13.075 12.6499 13.1917 12.4333C13.2667 12.2833 13.3417 12.3249 13.4167 12.3666L14.1917 12.8666L14.8917 13.3249C14.9667 13.3666 15.0417 13.3999 15.075 13.3249C15.8333 12.7833 16.8 12.9166 17.3417 13.6666C17.5583 13.9666 17.6667 14.3083 17.6917 14.6083C17.65 14.8666 17.4917 15.1666 17.375 15.3833L17.3917 15.3749Z"/>
    <path d="M8.51672 7.04999C8.40839 7.09999 8.02506 6.23332 7.80839 5.88332C7.75839 5.77499 7.68339 5.74166 7.57506 5.79166C6.65006 5.87499 5.86672 5.12499 5.85839 4.23332C5.77506 3.29999 6.52506 2.51666 7.42506 2.49999C8.35006 2.41666 9.13339 3.16666 9.14172 4.05832C9.15839 4.24166 9.17506 4.43332 9.08339 4.65832C8.99172 4.88332 8.87506 5.19999 8.65839 5.29999C8.55006 5.34999 8.60006 5.45832 8.56672 5.53332C8.78339 5.88332 9.14172 6.75832 9.14172 6.75832L8.51672 7.03332V7.04999Z"/>
  </g>
  <defs>
    <clipPath id="clip0_3684_4356">
      <rect width="15.2" height="14.8" transform="translate(2.5 2.5)"/>
    </clipPath>
  </defs>
</svg>
