/** @module formatData */

import type { Edges, NodesWithStories } from "../client";
import type { ValueOfType } from "./nodeTypes";

export interface NodeHierarchy extends NodesWithStories {
  display: boolean;
  children: NodeHierarchy[];
}

export type Edge = {
  id: number;
  source: NodesWithStories;
  target: NodesWithStories;
  fake: boolean;
  linkedId?: number;
  inserted_by: string;
  validated: boolean;
};

export type NodesHierarchy = {
  [key in ValueOfType]: { display: boolean; children: NodeHierarchy[] };
};

export type Graph = {
  allNodes: NodesWithStories[];
  nodesHierarchy: NodesHierarchy;
  nodes: NodesWithStories[];
  links: Edge[];
};

const recupChildren = (nodes, id) => {
  return nodes.find((n) => n.id === Number(id)).children;
};

export const formatData = (data): Graph => {
  const nodesHierarchy = {
    driver: { display: true, children: [] },
    activity: { display: true, children: [] },
    component: { display: true, children: [] },
    pressure: { display: true, children: [] },
    good_and_benefit: { display: true, children: [] },
    ecosystem_service: { display: true, children: [] },
    response: { display: true, children: [] },
    impact_on_welfare: { display: true, children: [] },
    complimentary_capital: { display: true, children: [] },
  };

  const nodes = data.nodes.filter((n) =>
    data.edges.find((e) => e.fromId === n.id || e.toId === n.id)
  );
  const allNodes = data.nodes;
  data.nodes
    .sort((e, n) =>
      e.path.split(".").length > n.path.split(".").length
        ? 1
        : e.path.split(".").length === n.path.split(".").length
        ? 0
        : -1
    )
    .forEach((n) => {
      let newN = { ...n };
      newN.children = [];
      newN.display = false;
      const pathSplit = n.path.split(".");
      if (pathSplit.length === 1) {
        nodesHierarchy[n.type].children.push(newN);
      } else {
        let nodes = nodesHierarchy[n.type].children;
        for (let [index, pathV] of pathSplit.entries()) {
          if (index !== pathSplit.length - 1) {
            nodes = recupChildren(nodes, pathV);
          } else {
            nodes.push(newN);
          }
        }
      }
    });

  const links = data.edges
    .filter((e) => {
      return (
        data.nodes.find((n) => n.id == e.fromId) &&
        data.nodes.find((n) => n.id == e.toId)
      );
    })
    .map((e: Edges) => ({
      id: e.id,
      source: e.fromId,
      target: e.toId,
      linkedId: e.linkedId,
      inserted_by: e.inserted_by,
      validated: e.validated
    }));

  return { allNodes, nodesHierarchy, nodes, links };
};
