import { UnitsOfMeasureService as Service } from "@client";
import type { UnitOfMeasure as Model} from "@models/UnitOfMeasure";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createUnitOfMeasure = (sector: Model) => {
  try {
    Service.createUnitOfMeasureApiV1UnitsOfMeasurePost(sector).then((res) => {
      notifySuccess("Unit of measure succesfully created");
        supportingStore.addUnitOfMeasure(res);
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateUnitOfMeasure = async (id: number, sector: Model = null) => {
  try {
    Service.putUnitOfMeasureApiV1UnitsOfMeasureIdPut(id, sector).then(
      (res) => {
        notifySuccess("Unit of measure succesfully updated");
        supportingStore.updateSelectedUnitOfMeasure(res);
      }
    );
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deleteUnitOfMeasure = (id: number) => {
  try {
    Service.deleteUnitOfMeasureApiV1UnitsOfMeasureIdDelete(id).then(() => {
      supportingStore.removeUnitOfMeasure(id);
      notifySuccess("Unit of measure succesfully deleted");
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

/**
 * Returns true if the provided name is already taken by an existing unit.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean for if name is already in use.
 */
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.unitsOfMeasure.some(
      (s: Model) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
