<script lang="ts">
  import MarkerIcon from "@components/icons/MarkerIcon.svelte";

  export let insideAD: boolean | null = false;
  export let outsideAD: boolean | null = false;
</script>
<!--
@component
Displays adPresent concept property (possible values: inside, outside or unknown).
-->
<div class="flex flex-row mt-1 mb-1 item-start icon-text text-m">
  <div style="color:red;" class="flex items-end">
    <MarkerIcon class="w-3.5 h-4 pr-2 icon-inline"/>
  </div>

  {#if insideAD || outsideAD} 
    {#if insideAD} 
      <span class="pl-1 pr-2"> Inside Abu Dhabi
      </span>  
    {/if}       
    {#if outsideAD} 
      <span> Outside Abu Dhabi</span>
    {/if}
  {:else}   
    <span>Unknown</span>
  {/if}
</div> 
