<script lang="ts" context="module">
  import type { LinkObject, NodeObject } from "force-graph";
  import type { Edges } from "@models/Edges";

  //TODO : move these types elsewhere (discuss with teams where)
  export type GraphNodesWithStories = NodeObject & NodesWithStories;
  export interface LinkObjectWithId extends LinkObject {
    id: string;
  }
  export type EdgeObject = LinkObject & Edges;
  export interface GraphLinkObject extends EdgeObject {
    source: GraphNodesWithStories;
    target: GraphNodesWithStories;
  }
</script>

<script lang="ts">
  import {
    forceManyBody as d3ForceManyBody,
    forceCenter as d3ForceCenter,
  } from "d3-force-3d";
  import { onMount } from "svelte";
  import ForceGraph from "force-graph";
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import { nodeTypes } from "@utils/nodeTypes";
  import { deleteEdge, edgeEnable } from "@utils/edge";
  import { isEdgeWithinNodesCluster, isEdgeOfNodesCluster } from "@utils/node";
  import { isNodeInStoryGraph, storyOfNode } from "@utils/story";
  import Dialog from "@components/dialog/Dialog.svelte";
  import NodeMenu from "@components/dialog/NodeMenu.svelte";
  import Graphtoolbar from "@components/Graph/Toolbar/GraphToolbar.svelte";
  import Legend from "@components/Graph/Legend.svelte";
  import {
    drawNode,
    color,
    scaleNodeColor,
    drawEdge,
    colorEdge,
    drawNodeRectangle,
  } from "./drawAndColor";
  import {
    weightFromNbOfConnections,
    weightFromNbOfOutcomingConnections,
    weightFromNbOfIncomingConnections,
    weightFromNbOfAssociatedStories,
    weightFromNbOfAttachments,
    weightNone
  } from "./conceptSizes";
  import type { Edge } from "@utils/formatData";
  import type { NodesWithStories, StoriesBase } from "@client";
  import type { ForceGraphInstance } from "force-graph";
  import type { SvelteComponentTyped } from "svelte/internal";
  import { isNil } from "rambda";
  import { isUserRoleInOrDev, Roles } from "@utils/decorators";
  import DeleteButton from "../Editors/NodeEditor/edit/DeleteButton.svelte";
  import { getCtx } from "../Editors/NodeEditor/context";
  import DialogConfirmAction from "@components/dialog/DialogConfirmAction.svelte";
  import Mode from "./mode.svelte";
  import Zoom from "./Zoom.svelte";
  let w: number;
  let h: number;
  let graphWidth = 0;
  let divGraph: HTMLDivElement;
  let Graph: ForceGraphInstance;
  let nodeMenu: SvelteComponentTyped; //in case lihe this, maybe we shoud type component
  let dialog: SvelteComponentTyped; //in case lihe this, maybe we shoud type component
  let dialogExitConceptEdition;
  let dialogDeleteConnection;
  let styles = {
    "border-color": "#ffffff",
    "border-type": "",
  };

  let edge = null;
  const {
    _graphData,
    _selectedNode,
    _selectedStory,
    _selectedEdge,
    _updateSelection,
  } = dataStore;
  const { _display, _theme, _wMode, _perfo } = displayStore;

  let hoveredNode = null
  let displayFriendNode = []

  let graphDataDisplay =  {nodes:[], links:[]}

  const weightCalcul = {
    allConn: weightFromNbOfConnections,
    outConn: weightFromNbOfOutcomingConnections,
    inConn: weightFromNbOfIncomingConnections,
    stories: weightFromNbOfAssociatedStories,
    attachments: weightFromNbOfAttachments,
    none: weightNone
  };

  const nodePaint = (
    n: GraphNodesWithStories,
    ctx: CanvasRenderingContext2D
  ): void => {
    const story = storyOfNode(n);
    ctx.beginPath();
    ctx.shadowBlur = 0;

    const weight = weightCalcul[$_wMode]($_graphData, n);
    n.radius = weight;
    color(ctx, nodeTypes[n.type].color());

    // if a story is selected
    if ($_selectedStory) {
      // if the node is in the selected story
      if (isNodeInStoryGraph(n)) {
        // if the node is selected
        if ($_selectedNode && n.id === $_selectedNode.id) {
          scaleNodeColor({
            ctx: ctx,
            color: nodeTypes[n.type].color(),
            scale: 1,
            theme: $_theme,
          });
        }
        // if the node isn't selected
        else {
          scaleNodeColor({
            ctx: ctx,
            color: nodeTypes[n.type].color(),
            scale: 1,
            theme: $_theme,
          });
        }
      } 
      // if the node is not in the selected story
      else {
        let sc:1 | 0.75 | 0.5 | 0.25 | 0.1 | 0.0 = friendOfHoveredNode(n) ? 1 : 0;
        scaleNodeColor({
          ctx: ctx,
          color: nodeTypes[n.type].color(),
          scale: sc,
          theme: $_theme,
        });
      }
    }
    // if no story is selected
    else {
      if ($_selectedNode && $_graphData) {
        const listTarget = $_graphData.links
          .filter((l) => !l.fake)
          .filter((l) => l.source.id === $_selectedNode.id)
          .map((l) => l.target);
        const listSource = $_graphData.links
          .filter((l) => !l.fake)
          .filter((l) => l.target.id === $_selectedNode.id)
          .map((l) => l.source);
        const listNodes = [...listTarget, ...listSource];

        if (n.id === $_selectedNode.id)
          scaleNodeColor({
            ctx: ctx,
            color: nodeTypes[n.type].color(),
            scale: 1,
            theme: $_theme,
          });
        else if (listNodes.find((node) => node.id === n.id))
          scaleNodeColor({
            ctx: ctx,
            color: nodeTypes[n.type].color(),
            scale: 0.5,
            theme: $_theme,
          });
        else
          scaleNodeColor({
            ctx: ctx,
            color: nodeTypes[n.type].color(),
            scale: 0.1,
            theme: $_theme,
          });
      } else {
        scaleNodeColor({
          ctx: ctx,
          color: nodeTypes[n.type].color(),
          scale: 1,
          theme: $_theme,
        });
      }
    }
    nodeTypes[n.type].form === "rectangle"
      ? drawNodeRectangle(ctx, n, weight)
      : drawNode(ctx, n, weight);
    ctx.shadowBlur = 0;
  };

  const friendOfHoveredNode = (n:GraphNodesWithStories) => {
    return hoveredNode && graphDataDisplay.links.some((l:Edge)=> {
                        return l.target.id == n.id && l.source.id == hoveredNode.id || l.source.id == n.id && l.target.id == hoveredNode.id
                      })
  }

  const edgePaint = (
    e: GraphLinkObject & Edge,
    ctx: CanvasRenderingContext2D
  ): void => {
    if (e.fake) return;
    ctx.beginPath();
    colorEdge(ctx);

    if ($_selectedNode && $_graphData) {
      const listLink = $_graphData.links
        .filter((l) => !l.fake)
        .filter(
          (l) =>
            l.source.id === $_selectedNode.id ||
            l.target.id === $_selectedNode.id
        );

      if (listLink.find((l) => l.id === e.id)) ctx.globalAlpha = 1;
      else ctx.globalAlpha = 0.1;
    } else {
      ctx.globalAlpha = 1;
    }

    if ($_selectedStory) {
      switch (true) {
        case isEdgeWithinNodesCluster(e, $_selectedStory.nodes):
          ctx.lineWidth = 2;
          ctx.globalAlpha = 1;
          break;
        case isEdgeOfNodesCluster(e, $_selectedStory.nodes):
          ctx.globalAlpha = hoveredNode ? e.source.id ===  hoveredNode.id || e.target.id ===  hoveredNode.id ? 0.5 : 0.0 : 0.0;
          break;
        default:
          ctx.globalAlpha = 0.15;
      }
    }

    drawEdge(ctx, e);
    colorEdge(ctx);
  };

  const setParticlesNumber = (obj: LinkObject & Edge) => {
    if (obj.fake) return 0;
    if ($_selectedNode) {
      if ($_graphData) {
        const listLink = $_graphData.links
          .filter((l) => !l.fake)
          .filter(
            (l) =>
              l.source.id === $_selectedNode.id ||
              l.target.id === $_selectedNode.id
          );

        return listLink.some((l) => l.id === obj.id) ? 4 : 0;
      }
    } else {
      return 4;
    }
    // if a story is selected
    if ($_selectedStory) {
      // TODO : scope on links inside story (n=4) and links on story edge (n = 2)
      if (isEdgeWithinNodesCluster(obj, $_selectedStory.nodes)) {
        return 8;
      }
      if (isEdgeOfNodesCluster(obj, $_selectedStory.nodes)) {
        return 2;
      }
      return 0;
    } else {
      return 4;
    }
  };

  const setParticleWidth = (obj: LinkObject & Edge) => {
    if (obj.fake) return 0;
    if ($_selectedNode && $_graphData) {
      const listLink = $_graphData.links
        .filter((l) => !l.fake)
        .filter(
          (l) =>
            l.source.id === $_selectedNode.id ||
            l.target.id === $_selectedNode.id
        );

      return listLink.some((l) => l.id === obj.id) ? 4 : 0;
    }
    // if a story is selected
    if ($_selectedStory) {
      // TODO : scope on links inside story (n=4) and links on story edge (n = 2)
      if (isEdgeWithinNodesCluster(obj, $_selectedStory.nodes)) {
        return 8;
      }
      if (isEdgeOfNodesCluster(obj, $_selectedStory.nodes)) {
        return 2;
      }
      return 0;
    } else {
      return 4;
    }
  };

  const particlePaint = (obj: LinkObject) => {
    return $_theme == "light" ? "#BFBFBF" : "#BFBFBF";
  };

  const { editMode, editOff, editOn } = getCtx();

  const nodeHover = (n: GraphNodesWithStories): void => {
    hoveredNode = n
    Graph.nodeCanvasObject(nodePaint)
  }

  const nodeClick = (n: GraphNodesWithStories): void => {
    if ($editMode) {
      dialogExitConceptEdition.actionOnYes = () => {
        dataStore.setSelectedNode(n);
      };
      dialogExitConceptEdition.show(null);
    } else {
      dataStore.setSelectedNode(n);
    }
  };

  const onZoom = ({ k, x, y }) => {
    const coord = Graph.getGraphBbox();
    $_graphData;
  };

  // TODO: revoir avec anglélique le survol
  // const nodeHover = (n: GraphNodesWithStories): void => {
  //   if (n) {
  //     if (
  //       $_selectedStory === null ||
  //       ($_selectedStory && isNodeInStoryGraph(n))
  //     )
  //       highlightNode = n;
  //   } else highlightNode = null;
  // };

  /**
   * Returns HTMl markup representation of the concept description to be
   * used in hover tooltips.
   *
   * @param {GraphNodesWithStories} n - The concept to generate the HTMl markup
   * representation of.
   * @return {string} - HTMl markup representation of provided concept
   * description to be used in hover tooltips.
   */
  export const conceptDescription = (n: GraphNodesWithStories): string => {
    // It would have been better if we could use component level CSS rules
    // but they're not taken into account in the canvas...
    let color = nodeTypes[n.type].color();
    let borderRadius = nodeTypes[n.type].form === "rectangle" ? "" : "50%";
    let typeLabel = nodeTypes[n.type].label;

    const statusIcon = (status: string, color: string) => {
      if (status == "rejected") {
        return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-4 h-4 mr-2 rounded-full btn-error"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>';
      }
      if (status == "adopted") {
        return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" style="background-color:${color}" stroke="currentColor" aria-hidden="true" class="w-4 h-4 mr-2 bg-red-700 rounded-full stroke-base-300"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>`;
      }
      if (status == "pending") {
        return '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2 rounded-full stroke-info"><path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"></path></svg>';
      }
    };

    return `
      <div style="padding:12px">
        <span class="inline-block w-2 h-2 mr-1 rounded-full" 
              style="background-color: ${color}; 
                    border-radius: ${borderRadius}">
        </span>
        
        <span class="flex-1 text-xs font-bold uppercase ">
            ${typeLabel}
        </span>

        <br>

        <div class="flex flex-row">
          <span>${statusIcon(n.status, color)}</span>
          <span class="text-base">${n.name}</span>
        </div>
        <div class="flex flex-row" style="opacity:0.6">
          <span style="font-size:12px">Click the concept node on the graph for more information</span>
        </div>
      </div>
    `;
  };

  const edgeLabel = (e: GraphLinkObject): string => {
    let indexTarget = nodeTypes[e.source.type].to.findIndex(
      (el) => el == e.target.type
    );
    return `
      <div style="padding:12px">
        ${e.source.name} > ${
      nodeTypes[e.source.type].sectionto[indexTarget]
    } > ${e.target.name}
      </ div> `;
  };

  const bgClick = (): void => {
    if ($_selectedStory) {
      centerGraphOnSelectedStory()
    } else if($_graphData) {
      Graph.graphData($_graphData);
      Graph.zoomToFit(500, 96);
    }
    dataStore.setSelectedNode(null);
    dataStore.setSelectedEdge(null);
    // dataStore.setSelectedStory(null);
  };

  const nodeRightClick = (
    node: GraphNodesWithStories,
    event: MouseEvent
  ): void => {
    nodeMenu.show(node, { x: event.x, y: event.y });
  };

  const linkRightClick = (link: GraphLinkObject, event: MouseEvent): void => {
    edge = link;
    if (isUserRoleInOrDev([Roles.STORY])) {
      dialog.show({ x: event.x, y: event.y });
    }
  };

  const linkClick = (link: GraphLinkObject & Edge, event: MouseEvent) => {
    dataStore.setSelectedEdge(link);
  };

  function deleteLink(link: GraphLinkObject & Edge): void {
    deleteEdge(link.id);
    dialog.close();
  }

  onMount(() => {
    graphInit();
  });

  function graphInit() {
    Graph = ForceGraph()(divGraph)
      .graphData({ nodes: [], links: [] })
      .width(w)
      .height(h)
      .nodeId("id")
      .nodeRelSize(1)
      .nodeVal((node) => Math.pow(node.radius, 2))
      .nodeCanvasObject(nodePaint)
      .linkCanvasObject(edgePaint)
      .linkVisibility(edgeEnable)
      .nodeLabel(conceptDescription)
      .linkLabel(edgeLabel)
      .onBackgroundClick(bgClick)
      .onLinkClick(linkClick)
      .onNodeClick(nodeClick)
      .onNodeRightClick(nodeRightClick)
      .onZoom(onZoom)
      .onNodeHover(nodeHover)
      // .linkCurvature(0.1)
      .onLinkRightClick(linkRightClick)
      .cooldownTime(60000)
      .linkSource("source")
      .linkTarget("target")
      // .d3AlphaMin(0.02)
      .d3VelocityDecay(0.5)
      .autoPauseRedraw(true)
      .d3Force("center", null); //center, charge, link
    // .onEngineStop(() => Graph.zoomToFit(400));
  }

  function setGraphWidth(width: number): void {
    if (Graph && width) {
      width > 0 ? Graph.width(width) : Graph.width(0);
    }
  }

  function setGraphHeight(height: number): void {
    if (Graph && height) {
      height > 0 ? Graph.height(height) : Graph.height(0);
    }
  }

  function initGraphWithData(): void {
    if (!Graph) graphInit();
    graphDataDisplay = $_graphData
    Graph.graphData(graphDataDisplay);
    Graph.height(innerHeight - 84)
      .width(graphWidth)
      .d3Force("charge", d3ForceManyBody().strength(-500).distanceMin(100))
      .d3Force("center", d3ForceCenter(graphWidth / 2, (innerHeight - 84) / 2))
      .warmupTicks(48)
      .cooldownTicks(48);
  }

  function switchPerf(): void {
    $_perfo === "speed" ? enablePerfo() : disablePerfo();
  }

  function disablePerfo(): void {
    if (!Graph) graphInit();
    Graph.linkDirectionalParticles(setParticlesNumber)
      .linkDirectionalParticleWidth(setParticleWidth)
      .linkDirectionalParticleSpeed(0.003)
      .linkDirectionalParticleColor(particlePaint)
      .enableNodeDrag(true);
  }

  function enablePerfo(): void {
    if (!Graph) graphInit();
    Graph.linkDirectionalParticles(0)
      .linkDirectionalParticleWidth(0)
      .enableNodeDrag(false);
  }

  export function centerOnSelectedNodeAndZoomToConnectedNodes(): void {

    const linksOfSelectedNode = $_graphData.links.filter((l:Edge) => {
      return l.source.id == $_selectedNode.id || l.target.id == $_selectedNode.id
    })
    const nodesOfSelectedNode = $_graphData.nodes.filter((n:NodesWithStories) => {
      return linksOfSelectedNode.some((l:Edge) => {
        return l.source.id == n.id || l.target.id == n.id
      })
    })

    graphDataDisplay = {nodes: nodesOfSelectedNode, links:linksOfSelectedNode}

    $_graphData && Graph.graphData(graphDataDisplay);
    Graph.onEngineStop(() => {
      if ($_graphData && $_selectedNode) {
        // TODO: optimize this code,
        // leads requestAnimationFrame Violation (~65ms instead of 16 ms recommended)
        const nodeOfGraph = $_graphData.nodes.find(
          (n) => n.id === $_selectedNode.id
        ) as GraphNodesWithStories;
        if (nodeOfGraph) {
          const listTarget = $_graphData.links
            .filter((l) => !l.fake)
            .filter((l) => l.source.id === nodeOfGraph.id)
            .map((l) => l.target);
          const listSource = $_graphData.links
            .filter((l) => !l.fake)
            .filter((l) => l.target.id === nodeOfGraph.id)
            .map((l) => l.source);
          const listNodes = [nodeOfGraph, ...listTarget, ...listSource] as NodeObject[];
          console.log(listNodes)
          Graph.zoomToFit(500, 10);
          if(listNodes.length == 2){
            Graph.centerAt((listNodes[0].x + listNodes[1].x)/2, (listNodes[0].y + listNodes[1].y)/2, 500);
          } else {
            Graph.centerAt(nodeOfGraph.x, nodeOfGraph.y, 500);
          }
        } else {
          Graph.zoomToFit(500, 96);
        }
      }
    });
  }

  function centerGraphOnEdge(link): void {
    if (link && link.source && link.target) {
      Graph.zoomToFit(
        500,
        10,
        (node) => node.id == link.source.id || node.id == link.target.id
      );
    } else {
      Graph.zoomToFit(500, 96);
    }
  }

  function borderColor(): void {
    styles["border-type"] = "solid";
    styles["border-color"] = $_theme == "dark" ? "#FFFFFF" : "#000000";

    if (!$_selectedNode && !$_selectedStory && !$_selectedEdge) {
      styles["border-type"] = "unset";
    }
    cssVarStyles = `--border-type:${styles["border-type"]}; --border-color:${styles["border-color"]}; position:relative;`;
  }

  const centerGraphOnSelectedStory = (): void => {
    if ($_selectedStory) {
      if ($_graphData) {

        const nodesOfSelectedStory = $_graphData
                      .nodes
                      .filter((n:NodesWithStories) => n.stories.length != 0)
                      .filter((n:NodesWithStories) => {
                        return n.stories.some((s:StoriesBase) => s.id == $_selectedStory.id)
                      })

        const linksOfSelectedStory = $_graphData
                      .links
                      .filter((l:Edge)=> {
                        const targetNode = nodesOfSelectedStory.some((n:NodesWithStories) => n.id == l.target.id)
                        const sourceNode = nodesOfSelectedStory.some((n:NodesWithStories) => n.id == l.source.id)
                        return targetNode && sourceNode
                      })

        const linksParentOfnodesOfSelectedStory = $_graphData
                      .links
                      .filter((l:Edge)=> {
                        const targetNode = nodesOfSelectedStory.some((n:NodesWithStories) => n.id == l.target.id)
                        const sourceNode = nodesOfSelectedStory.some((n:NodesWithStories) => n.id == l.source.id)
                        return targetNode || sourceNode
                      })

        const nodesParentOfnodesOfSelectedStory = $_graphData
                      .nodes
                      .filter((n:NodesWithStories) => {
                        return [...linksOfSelectedStory, ...linksParentOfnodesOfSelectedStory].some((l:Edge)=> n.id == l.target.id || n.id == l.source.id)
                      })
        graphDataDisplay = {nodes: [...nodesOfSelectedStory,...nodesParentOfnodesOfSelectedStory], links: [...linksOfSelectedStory, ...linksParentOfnodesOfSelectedStory]}
        Graph.graphData(graphDataDisplay);
        Graph.onEngineStop(() => Graph.zoomToFit(200));
      }
    } 
  };

  const zoomIn = () => {
    let zoomVal = Graph.zoom()
    Graph.zoom(zoomVal*1.1)
  }

  const zoomOut = () => {
    let zoomVal = Graph.zoom()
    Graph.zoom(zoomVal*0.9)
  }

  $: cssVarStyles = `--border-color:${styles["border-color"]}; position:relative`;
  $: Graph && $_graphData && !$_selectedStory && !$_selectedNode && initGraphWithData();
  $: Graph && $_graphData && setTimeout(() => Graph.zoomToFit(500, 96), 1000);
  $: Graph && $_selectedNode && centerOnSelectedNodeAndZoomToConnectedNodes();
  $: Graph && $_selectedStory && centerGraphOnSelectedStory();
  $: $_updateSelection && borderColor();
  $: $_theme && borderColor();
  $: ($_selectedStory || !$_selectedStory) && borderColor();
  $: Graph && $_graphData && !$_selectedStory && setTimeout(() => Graph.zoomToFit(500, 96), 1000);
  $: Graph && $_graphData && !$_selectedStory && Graph.graphData($_graphData);
  $: Graph && $_graphData && !$_selectedStory && !$_selectedNode && setTimeout(() => Graph.zoomToFit(500, 96), 1000);
  $: Graph && $_graphData && !$_selectedStory && !$_selectedNode && Graph.graphData($_graphData);
  $: Graph && isNil($_selectedEdge) && centerGraphOnEdge($_selectedEdge);
  $: $_selectedEdge && centerGraphOnEdge($_selectedEdge);
  $: innerWidth = 0;
  $: innerHeight = 0;
  $: graphWidth =
    innerWidth - $_display.leftWidthSize - $_display.rightWidthSize;
  $: innerHeight && setGraphHeight(innerHeight - 84); //TODO: 84 is for header height; should calc dynamically
  $: graphWidth && setGraphWidth(graphWidth);
  $: Graph && graphDataDisplay.nodes.length != 0 && $_wMode && Graph.graphData(graphDataDisplay);
  $: Graph && graphDataDisplay.nodes.length != 0 && $_perfo && switchPerf();
  $: Graph && graphDataDisplay.nodes.length == 0 && $_graphData && $_wMode && Graph.graphData($_graphData);
  $: Graph && graphDataDisplay.nodes.length == 0 && $_graphData && $_perfo && switchPerf();
</script>

<svelte:window bind:innerWidth bind:innerHeight />

<NodeMenu bind:this={nodeMenu} context="graph" />

<Dialog bind:this={dialog}>
  <div class="p-1 mt-2 mb-2 ml-4">
    <DeleteButton
      label="Delete connection"
      type="menu"
      confirmDialog={dialogDeleteConnection}
    >
      <DialogConfirmAction
        bind:this={dialogDeleteConnection}
        type="deleteConnection"
        actionOnYes={() => deleteLink(edge)}
      />
    </DeleteButton>
  </div>
</Dialog>

<DialogConfirmAction
  bind:this={dialogExitConceptEdition}
  type="exitConceptEdition"
/>

<div
  style={cssVarStyles}
  class="h-full graphBorder"
  bind:clientWidth={w}
  bind:clientHeight={h}
>
  {#if graphWidth > 400}
    <div class="absolute top-1 right-24">
      <Graphtoolbar />
    </div>
  {/if}
  <div bind:this={divGraph} />
  {#if graphWidth > 400}
    <div class="absolute bottom-0 flex justify-center w-full">
      <Legend />
    </div>
  {/if}
  {#if graphWidth > 400}
    <div class="absolute bottom-0 right-24 ">
      <div id="zoom-1" class="absolute bottom-0 right-[150px] tooltip" style="width:70px">
        <Zoom {zoomIn} {zoomOut}/>
        <span class="tooltiptext">mouse wheel and gestures also work</span>
      </div>
    </div>
  {/if}
  {#if $_selectedEdge || $_selectedNode || $_selectedStory}
    <div class="absolute top-0 flex justify-center w-full">
      <Mode/>
    </div>
  {/if}
</div>

<style>
  .graphBorder {
    border: var(--border-type, "unset") var(--border-color, white);
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>
