import { StakeholdersService } from "@client";
import type { Stakeholder } from "@models/Stakeholder";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createStakeholder = (stakeholder: Stakeholder) => {
  try {
    StakeholdersService.createStakeholderApiV1StakeholdersPost(stakeholder).then((res) => {
      notifySuccess("Stakeholder succesfully created");
      if (stakeholder.sectors) {
        StakeholdersService.updateStakeholderSectorsApiV1StakeholdersIdSectorsPatch(
          res.id, 
          stakeholder.sectors
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          res.sectors = stakeholder.sectors;
          supportingStore.addStakeholder(res);
          notifySuccess("Sectors succesfully added to the new stakeholder");
        });
      }
      else {
        supportingStore.addStakeholder(res);
      }
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateStakeholder = async (
  stakeholderId: number,
  stakeholder: Stakeholder = null
) => {
  try {
    StakeholdersService.putStakeholderApiV1StakeholdersIdPut(
      stakeholderId,
      stakeholder
    ).then((res) => {
      notifySuccess("Stakeholder succesfully updated");
      if (stakeholder.sectors) {
        StakeholdersService.updateStakeholderSectorsApiV1StakeholdersIdSectorsPatch(
          res.id, 
          stakeholder.sectors
        ).then((res2) => {
          // Patch return 204 code which implies no body is transferred
          // Everything went fine so we add payload sectors
          res.sectors = stakeholder.sectors;
          notifySuccess("Stakeholder sectors succesfully updated");
          supportingStore.updateSelectedStakeholder(res);
        });
      }
      else {
        res.sectors = [];
        supportingStore.updateSelectedStakeholder(res);
      }
    });    
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const deleteStakeholder = (id: number) => {
  try {
    StakeholdersService.deleteStakeholderApiV1StakeholdersIdDelete(id).then(() => {
      supportingStore.removeStakeholder(id);
      notifySuccess("Stakeholder succesfully deleted");
    });
  } catch (e) {
    notifyFailure(`An error occured: ${e.status}-${e.name}:${e.statusText}`);
  }
};

/**
 * Returns true if the provided name is already taken by an existing stakeholder.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean for if name is already in use.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.stakeholders.some(
      (s: Stakeholder) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
