import { EnvprocessService, type NodesWithStories } from "@client";
import type { EnvProcess, ParameterDefinition } from "@client";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";
import { dataStore } from "@stores/datastore";

export const createEnvProcess = (epData: EnvProcess) => {
  try {
    EnvprocessService.createEnvprocessApiV1EnvprocessPost(epData).then(
      (resp) => {
        notifySuccess("Environmental Process succesfully created!");
        supportingStore.addEnvProcess(resp);
      }
    );
  } catch (e) {
    notifyFailure(
      `An error occured while creating env process: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};

export const updateEnvProcess = async (epId: number, ep: EnvProcess = null) => {
  try {
    EnvprocessService.updateEnvprocessApiV1EnvprocessIdPut(epId, ep).then(
      (resp) => {
        notifySuccess("Environmental succesfully created!");
        supportingStore.updateSelectedEnvProcess(resp);
        if(dataStore.selectedNode){
          dataStore.updateSelectedNode(dataStore.nodes.find((n : NodesWithStories) => dataStore.selectedNode.id == n.id))
        }
      }
    );
  } catch (e) {
    notifyFailure(
      `An error occurred while creating the environmental process: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};

export const deleteEnvProcess = (id: number) => {
  let allNodes: NodesWithStories[] = dataStore.nodes
  EnvprocessService.deleteEnvprocessApiV1EnvprocessIdDelete(id).then(() => {
    supportingStore.removeEnvProcess(id);
    let nodes = allNodes.map(n =>{
      n.env_process = n.env_process.filter(e => e.id != id)
      return n
    })

    nodes.forEach(n => dataStore.updateNodeInAllNodes(n))
    if(dataStore.selectedNode){
      dataStore.updateSelectedNode(dataStore.nodes.find((n : NodesWithStories )=> dataStore.selectedNode.id == n.id))
    }
  });
};

/**
 * Returns true if the provided name is already taken by an existing Metric.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean true if name is already in use. Else false.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.envProcess.some(
      (s: EnvProcess) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};
