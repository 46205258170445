<script lang="ts">
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { type StateMetric } from "@client";
  import { getCtx } from "../context";
  import {
    createMetric,
    updateMetric,
    isNameAlreadyTaken,
  } from "@utils/supporting/stateMetric";
  import { Label, Helper, Select as SimpleSelect, Input } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import FormCreationButtons from "../FormCreationButtons.svelte";

  const { _selectedStateMetric, _metricsOfForm, _metricsOfFunctionalHealth } =
    supportingStore;
  let { metricFormDialog, editModeOn } = getCtx();

  // Properties to be used as label/value for Select (metrics of
  // form/functional health) and Multiselect (for parameter definitions)
  // components:
  const itemId = "id";
  const label = "name";
  let isParameterDefinitionsEmpty: boolean = false;
  let initSelectVal;

  const {
    form,
    data,
    setFields,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
    touched,
  } = createForm<StateMetric>({
    onSubmit: (values) => {
      // TODO @refact use setField
      values.metrics_of_form = getMetricOfFormById(values.metric_of_form_id);
      values.metrics_of_functional_health = getMetricOfFunctionalHealthById(
        values.metric_of_functional_health_id
      );
      if (!$editModeOn) {
        createMetric(values);
        // clear form
        handleFormReset(emptyMetric);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateMetric($_selectedStateMetric.id, values);
        // clear form
        handleFormReset(emptyMetric);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        unit: string[];
        metrics_of_form_id: string[];
        parameter_definitions: string[];
        metrics_of_functional_health_id: string[];
      } = {
        name: [],
        unit: [],
        metrics_of_form_id: [],
        parameter_definitions: [],
        metrics_of_functional_health_id: [],
      };

      if (!values.name) errors.name.push("Name is required.");
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedStateMetric &&
          $_selectedStateMetric.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (!values.metrics_of_form_id)
        errors.metrics_of_form_id.push("Metric of form is required.");
      if (!values.metrics_of_functional_health_id)
        errors.metrics_of_functional_health_id.push(
          "Metric of functional health is required."
        );
      return errors;
    },
    extend: [reporter],
  });

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields("parameter_definitions", val);
  };

  const handleFormReset = (metric: StateMetric) => {
    initSelectVal = metric.parameter_definitions;
    setInitialValues(metric);
    reset();
  };

  // move to utils
  const getMetricOfFormById = (id: number) => {
    return $_metricsOfForm.filter((m) => m.id == id)[0];
  };

  const getMetricOfFunctionalHealthById = (id: number) => {
    return $_metricsOfFunctionalHealth.filter((m) => m.id == id)[0];
  };

  const emptyMetric = {
    name: "",
    //definition: "",
    //unit: "",
    //notes: "",
    //parameter_definitions: [],
    //metric_of_form: undefined,
    metrics_of_form_id: "",
    //metric_of_functional_health: undefined,
    metrics_of_functional_health_id: "",
  } as StateMetric;

  $: isParameterDefinitionsEmpty = !$data?.parameter_definitions;
  $: $editModeOn &&
    $_selectedStateMetric &&
    handleFormReset($_selectedStateMetric);
  $: !$editModeOn && handleFormReset(emptyMetric);
  $: $touched;
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn ? "Update Metric" : "Create Metric"}
  </h1>
  <!-- NAME & INPUT -->

  <!-- NAME -->
  <div class="basis-1/2">
    <Label for="name" class="mt-4 mb-2">Name</Label>
    <Input
      class="mt-2"
      placeholder="Type metric name here"
      id="name"
      name="name"
      required
      color={"base"}
    />
    <ValidationMessage for="name" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <div class="basis-1/2">
    <!-- METRIC OF FORM -->
    <Label for="metrics_of_form_id" class="mt-4 mb-2">Metric of form</Label>
    <SimpleSelect
      class="mt-2"
      placeholder="Select metric of form"
      id="metrics_of_form_id"
      setLabel={(metric) => metric.name}
      items={$_metricsOfForm}
      name="metrics_of_form_id"
      withLabel
      required={true}
      color={"base"}
    />
    <ValidationMessage for="metrics_of_form_id" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <div class="basis-1/2">
    <!-- METRIC OF FUNCTIONAL HEALTH -->
    <Label for="metrics_of_functional_health_id" class="mt-4 mb-2">
      Metric of functional health
    </Label>
    <SimpleSelect
      class="mt-2"
      placeholder="Select metric of functional health"
      id="metrics_of_functional_health_id"
      setLabel={(metric) => metric.name}
      items={$_metricsOfFunctionalHealth}
      name="metrics_of_functional_health_id"
      required
      withLabel
      color={$errors?.metric_of_functional_health_id?.length > 0
        ? "red"
        : "base"}
    />
    <ValidationMessage for="metrics_of_functional_health_id" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <FormCreationButtons
    onClose={() => handleFormReset(emptyMetric)}
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    create={!$editModeOn}
  />
</form>
