<script lang="ts" context="module">
  export const durationUnitRegex = /[a-zA-Z]/;
  export const range = (size: number, startAt = 0) =>
    [...Array(size).keys()].map((i) => i + startAt);
</script>

<script lang="ts">
  import type { LoaderSpinnerTypes } from "@components/Core/types";
  export let color: LoaderSpinnerTypes["color"] = "#FF3E00";
  export let unit: LoaderSpinnerTypes["unit"] = "px";
  export let duration: LoaderSpinnerTypes["duration"] = "2s";
  export let size: LoaderSpinnerTypes["size"] = "36";
  export let pause: LoaderSpinnerTypes["pause"] = false;
  export let position: LoaderSpinnerTypes["position"] = "relative";
  let durationUnit: string = duration.match(durationUnitRegex)?.[0] ?? "s";
  let durationNum: string = duration.replace(durationUnitRegex, "");
</script>

<div
  class="wrapper"
  style="--size: {size}{unit}; --color: {color}; --duration: {duration}; position: {position}"
>
  {#each range(3, 1) as version}
    <div
      class="circle"
      class:pause-animation={pause}
      style="animation-delay: {(+durationNum / 3) * (version - 1) +
        durationUnit};"
    />
  {/each}
</div>

<style>
  .wrapper {
    width: var(--size);
    height: var(--size);
  }
  .circle {
    border-radius: 100%;
    animation-fill-mode: both;
    position: absolute;
    opacity: 0;
    width: var(--size);
    height: var(--size);
    background-color: var(--color);
    animation: bounce var(--duration) linear infinite;
  }
  .pause-animation {
    animation-play-state: paused;
  }
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
</style>
