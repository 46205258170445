<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";
  export let type: string;
</script>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
  {...$$restProps}
  on:click
  on:mouseover
  on:mouseenter
  on:mouseleave
  on:keydown
  ><slot />
  <path d="M14.5738 4.01229L11.2808 7.30529C11.0986 7.49389 10.9978 7.74649 11.0001 8.00869C11.0024 8.27089 11.1076 8.5217 11.293 8.70711C11.4784 8.89252 11.7292 8.99768 11.9914 8.99996C12.2536 9.00224 12.5062 8.90145 12.6948 8.71929L15.9878 5.42629L15.9878 7.01229C15.9878 7.2775 16.0931 7.53186 16.2807 7.7194C16.4682 7.90693 16.7226 8.01229 16.9878 8.01229C17.253 8.01229 17.5074 7.90693 17.6949 7.7194C17.8824 7.53186 17.9878 7.2775 17.9878 7.01229L17.9878 3.00929C17.9878 2.81191 17.9291 2.61899 17.8193 2.45502C17.7094 2.29104 17.5533 2.16339 17.3708 2.08829C17.2494 2.03796 17.1192 2.01213 16.9878 2.01229L12.9878 2.01229C12.7226 2.01229 12.4682 2.11765 12.2807 2.30518C12.0931 2.49272 11.9878 2.74707 11.9878 3.01229C11.9878 3.2775 12.0931 3.53186 12.2807 3.7194C12.4682 3.90693 12.7226 4.01229 12.9878 4.01229L14.5738 4.01229Z" fill={nodeTypes[type].color()}/>
<circle cx="5.5" cy="14.5" r="5.5" fill={nodeTypes[type].color()}/>
</svg>

