import type { LinkObject, NodeObject } from "force-graph";
import type { NodesWithStories, Edges } from "@client";
import type { Edge } from "@utils/formatData";
import chroma from "chroma-js";

type GraphNodesWithStories = NodeObject & NodesWithStories;
type EdgeObject = LinkObject & Edges;
interface GraphLinkObject extends EdgeObject {
  source: GraphNodesWithStories;
  target: GraphNodesWithStories;
}

export const drawNode = (
  ctx: CanvasRenderingContext2D,
  n: GraphNodesWithStories,
  size: number
) => {
  ctx.arc(n.x, n.y, size, 0, 2 * Math.PI, false);
  ctx.fill();
};

export const drawNodeRectangle = (
  ctx: CanvasRenderingContext2D,
  n: GraphNodesWithStories,
  size: number
) => {
  ctx.fillRect(n.x - size, n.y - size, size * 2, size * 2);
  // ctx.arc(n.x, n.y, size, 0, 2 * Math.PI, false);
  ctx.fill();
};

export const drawEdge = (
  ctx: CanvasRenderingContext2D,
  e: GraphLinkObject & Edge
) => {
  ctx.moveTo(e.source.x, e.source.y);
  // ctx.quadraticCurveTo(
  //   (e.source.x + (e.target.x - e.source.x) / 2) * 1.1,
  //   (e.source.y + (e.target.y - e.source.y) / 2) * 1.1,
  //   e.target.x,
  //   e.target.y
  // );
  ctx.lineTo(e.target.x, e.target.y);
  ctx.stroke();
};

export const color = (ctx: CanvasRenderingContext2D, c: string = "#D3D3D3") => {
  ctx.shadowBlur = 50;
  ctx.shadowColor = c;
  ctx.fillStyle = c;
};

export const scaleNodeColor = (options: {
  ctx: CanvasRenderingContext2D;
  color: string;
  scale: 1 | 0.75 | 0.5 | 0.25 | 0.1 | 0.0;
  theme: "light" | "dark";
}) => {
  const { ctx, color, scale, theme } = options;
  const bgColor = theme == "light" ? "#FAFAFA" : "#18181B";
  // NB: colorScale() could be move to nodeTypes for better performance
  const colorScale = chroma.scale([bgColor, color]);
  ctx.shadowColor = colorScale(scale).hex();
  ctx.fillStyle = colorScale(scale).hex();
};

export const scaleEdgeColor = (options: {
  ctx: CanvasRenderingContext2D;
  color: string;
  scale: 1 | 0.75 | 0.5 | 0.25 | 0.1;
  theme: "light" | "dark";
  blur: number;
  width: number;
}) => {
  const { ctx, color, scale, theme, blur, width } = options;
  const bgColor = theme == "light" ? "#FAFAFA" : "#18181B";
  // NB: colorScale() could be move to nodeTypes for better performance
  const colorScale = chroma.scale([bgColor, color]);
  ctx.shadowBlur = blur;
  ctx.shadowColor = colorScale(scale).hex();
  ctx.strokeStyle = colorScale(scale).hex();
  ctx.lineWidth = width;
};

export const colorEdge = (
  ctx: CanvasRenderingContext2D,
  c: string = "#E2E2E2",
  blur: number = 0,
  width: number = 1
) => {
  ctx.shadowBlur = blur;
  ctx.shadowColor = c;
  ctx.strokeStyle = c;
  ctx.lineWidth = width;
};
