<script lang="ts">
  import { Supporting } from "@utils/supporting"
  import { getCtx } from "./context";
  import { PlusCircleIcon as Plus } from "@rgossiaux/svelte-heroicons/solid";
  
  export const title = "Advanced properties";
  export let description: string | undefined = undefined;
  
  let {
    metricFormDialog,
    metricsManagerDialog,
    selectedSupportingClass,
    editModeOn,
  } = getCtx();

  let breadCrumbText: string;
  let buttontext: string = "";

  const showSupportingFormDialog = () => {
    $editModeOn = false;
    $metricFormDialog.show();
  };

  /**
   * Removes trailing 's' if any.
   * 
   * TODO: adapt to handle composed supporting class names.
   */
  const formatButtonText = () => { 
    
    if ( $selectedSupportingClass ) {
      buttontext = $selectedSupportingClass.endsWith('s') ? 
        Supporting[$selectedSupportingClass].slice(0, -1) : 
        Supporting[$selectedSupportingClass];
    }
    return true;
  };

  /**
   * Removes trailing 's' if any.
   * 
   * TODO: adapt to handle composed supporting class names.
   */
   const formatSupportingBreadCrumb = () => {
    if ( $selectedSupportingClass ) {
      breadCrumbText = Supporting[$selectedSupportingClass];
    }
    return true;
  };

  $: $selectedSupportingClass && formatSupportingBreadCrumb() && formatButtonText()

</script>

<div
  class="inline-block w-screen h-screen p-6 overflow-hidden text-left align-middle transition-all transform shadow-xl bg-base-200 rounded-2xl"
>
  <div class="flex items-stretch justify-between w-full">
    <div class="flex flex-row items-center justify-center">
      <h3 class="pr-2 text-lg font-medium leading-6 text-base-content">
        <span class="font-bold">{title}</span> / {breadCrumbText}
      </h3>
      <button
        class="gap-2 mr-6 normal-case border-transparent bg-base-300 btn-outline rounded-xl btn btn-sm"
        on:click={showSupportingFormDialog}
      >
        <Plus class="w-5 h-5" />
        New {buttontext.toLowerCase()}
      </button>
    </div>
    <button
      class="mr-6 kg-btn-outline"
      on:click={() => $metricsManagerDialog.close()}
    >
      Close
    </button>
  </div>
  {#if description}
    <h5 class="font-medium leading-6 text-md text-base-300 ">
      {description}
    </h5>
  {/if}

  <div class="flex items-start justify-center min-h-full p-4 text-center sm:items-start sm:p-0" style="height: 110%;width: 98%;">
    <div class="relative w-full overflow-hidden text-left transition-all transform h-5/6" >
      <div class="h-full overflow-x-auto">
        <slot name="content" />
      </div>
    </div>
  </div>
</div>
