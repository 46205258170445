<script lang="ts">
  import type { NodesWithStories, Type } from "@client";
  import Autocomplete from "@components/Autocomplete.svelte";
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import { createEdgeAndBindNodeToCurrentStory, createEdgeLinked } from "@utils/edge";
  import { checkNameForConnection } from "@utils/node";
  import {
    labelTypes,
    linkLabel,
    nodeTypes,
    type typeConnection,
  } from "@utils/nodeTypes";
  import { Label, Helper } from "@core-ui";
  import Dialog from "./Dialog.svelte";
  import BlocCancelConfirm from "@components/Generic/BlocCancelConfirm.svelte";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import classNames from "classnames";
  
  import ConceptTree from "@components/tree/ConceptTree.svelte";
  import { displayOrders } from "@utils/categories";
  
  const { _categoryDisplay } = displayStore;

  let node: NodesWithStories = null;
  let listNodeToLink;
  let listNodeLink;
  let selectedType: typeConnection;
  let dialog;
  let nodeName = "";
  let nodeLinkedName = "";
  let selectedDir: "to" | "from";
  let isLinkedConnection: boolean = false;

  let toType: typeConnection[];
  let fromType: typeConnection[];
  const { _nodes } = dataStore;

  let autoComplete;
  let autoCompleteLink;

  const baseErrors = {
    type : [],
    concept : [],
    link : []
  }

  let allErrors = baseErrors;
  let nodeClickAction:((node) => {})

  const purgeAllErrors = () => {
    for (let e of Object.keys(allErrors)) {
      allErrors[e] = [];
    }
  };

  const {
    form,
    isValid,
    isSubmitting,
    errors,
    setFields,
    setInitialValues
  } = createForm<Node>({
    onSubmit: async (values) => {
      if (isLinkedConnection) {
        create_node_linked();
      } else {
        create_node();
      }
    },
    validate(values){
      purgeAllErrors();
      const errors = allErrors;
      if(node){
        if(!values.type){
        errors.type.push("Concept type is required.")
        }
        if(checkNameForConnection(node.id, values.concept, selectedDir) || !listNodeToLink.includes(values.concept)){
          errors.concept.push(`${selectedDir === "from" ? "Incoming" : "Outcoming"} is mandatory and should not already be linked`)
        }
        if(isLinkedConnection && Object.keys(values).includes("link") && ( checkNameForConnection(node.id, values.link, "to") || !listNodeLink.includes(values.link))){
          errors.link.push("Incoming is mandatory and should not already be linked")
        }
      }
      return errors;
    },
    extend: [reporter],
  });

  function buildListNodeToLink() {
    listNodeToLink = $_nodes
      .filter((node) => selectedType.concept == node.type)
      .map((n2) => n2.name);

    listNodeLink = $_nodes
      .filter((node) => selectedType?.link == node.type)
      .map((n2) => n2.name);
  }

  export const show = (coord, element: NodesWithStories = null) => {
    node = element;
    toType = nodeTypes[node.type].createto;
    fromType = nodeTypes[node.type].createfrom;
    selectedType = toType[0] ?? fromType[0];
    selectedDir = toType[0] ? "to" : "from";
    buildListNodeToLink();
    setIsLinkedConnection();
    /** ?? coord is always null*/
    cleanForm()
    dialog.show(coord);
  };

  const cleanForm = () => {
    /** TODO
     * This is component logic: it should be handle directly in component <Autocomplete>
     * e.g: clean Input on component unmount
     * !Refactoring would be nice :)
     */
    autoComplete && autoComplete.cleanInput();
    autoCompleteLink && autoCompleteLink.cleanInput();
    
    setInitialValues({
        type: selectedType,
        concept: "",
        link: "",
    });
    setNodeName("");
    setNodeLinkedName("");
    purgeAllErrors();
  }

  function create_node() {
    const linkedNode = $_nodes.find((node) => node.name === nodeName);
    selectedDir == "to"
      ? createEdgeAndBindNodeToCurrentStory(node, linkedNode, node, false)
      : createEdgeAndBindNodeToCurrentStory(linkedNode, node, node, false);
    dialog.close();
    show(null, linkedNode)
  }

  function create_node_linked() {
    if (selectedType.connection == "multiple") {
      const esNode = $_nodes.find((node) => node.name === nodeName);
      const activityNode = $_nodes.find((node) => node.name === nodeLinkedName);
      createEdgeLinked(esNode, activityNode, node);
    } else if (node.type == "activity") {
      const gbNode = $_nodes.find((node) => node.name === nodeName);
      const esNode = $_nodes.find((node) => node.name === nodeLinkedName);
      createEdgeLinked(esNode, node, gbNode);
    } else {
      const gbNode = $_nodes.find((node) => node.name === nodeName);
      const activityNode = $_nodes.find((node) => node.name === nodeLinkedName);
      createEdgeLinked(node, activityNode, gbNode);
    }

    dialog.close();
  }

  function setNodeName(name) {
    nodeName = name;
    setFields("concept", name)
  }
  
  function setNodeLinkedName(name) {
    nodeLinkedName = name;
    setFields("link", name)
  }

  function changeType(e) {
    let concept;
    let connection;
    [selectedDir, concept, connection] = e.target.value.split(":");
    selectedType =
      selectedDir == "from"
        ? fromType.filter(
            (t) => t.concept == concept && t.connection == connection
          )[0]
        : toType.filter(
            (t) => t.concept == concept && t.connection == connection
          )[0];
    cleanForm()
    buildListNodeToLink();
    setIsLinkedConnection();
  }

  function setIsLinkedConnection() {
    isLinkedConnection = selectedType.connection !== "simple";
  }

</script>

<Dialog bind:this={dialog} dismissOnClickOutside={false} fullHeight={true}>
  <form use:form class="w-full h-full form-control" style="width:50vw;">
  <div class="flex flex-col justify-center h-full p-4 my-2">
    {#if node}
      <h1
        aria-labelledby="New connection"
        class="pb-2 text-xl text-base-content"> 
        Include Triplet in a Story </h1>

      <p class="pb-4 mb-4">
        You have chosen {node.name} ({labelTypes[node.type]}) as one end of the triple. Please now choose the concept type at the other end of the triple.
      </p>
      <Label for="nodeType-for-link-select" class="mb-2">Other Concept Type:</Label>
      <select
        class={classNames(displayStore.form.base, displayStore.textSizes.md, "w-full rounded-lg")}
        name="type"
        on:change={changeType}
        id="nodeType-for-link-select"
        data-qs="nodeType-select"
        required>
        <option disabled selected value=""
          >Select connection target/source...</option>
        {#if toType.length != 0}
          <optgroup label="to">
            {#each toType as type}
              <option
                data-qs={`nodeType-select-option-to-${type}`}
                selected={type.concept == selectedType.concept}
                value={`to:${type.concept}:${type.connection}`}
              >
                {labelTypes[type.concept] +
                  (type.connection == "linked"
                    ? ` (${labelTypes[type.link]})`
                    : "")}
              </option>
            {/each}
          </optgroup>
        {/if}
        <optgroup label="from">
          {#each fromType as type}
            <option
              data-qs={`nodeType-select-option-from-${type}`}
              selected={type.concept == selectedType.concept}
              value={`from:${type.concept}:${type.connection}`}
              >{node.type === "good_and_benefit" &&
              type.concept === "ecosystem_service"
                ? linkLabel
                : labelTypes[type.concept]}</option
            >
          {/each}
        </optgroup>
      </select>

      <ValidationMessage for="type" let:messages>
        <Helper class="mt-2 mb-2" color="red">
          {#each messages ?? [] as message}
            {message}
          {/each}
        </Helper>
      </ValidationMessage>

      <Label for="nodeType-for-link-autocomplete" class="mt-2 mb-2" required
        >Connect with {labelTypes[selectedType.concept]}:
      </Label>
      <Autocomplete
        name="concept"
        bind:this={autoComplete}
        list={listNodeToLink}
        nodeType={selectedType.concept}
        getInput={setNodeName}
        required
        class="w-full mb-2"
        id="nodeType-for-link-autocomplete"
        compact={true}
      />

      <ValidationMessage for="concept" let:messages>
        <Helper class="mt-2 mb-2" color="red">
          {#each messages ?? [] as message}
            {message}
          {/each}
        </Helper>
      </ValidationMessage>

      {#if isLinkedConnection}
        <Label for="autoCompleteLink-for-link-select" class="mt-4 mb-2" required
          >Connect
          {selectedType.connection == "linked"
            ? labelTypes[selectedType.concept]
            : labelTypes[node.type]} with {labelTypes[selectedType.link]}:
        </Label>
        <Autocomplete
          name="link"
          bind:this={autoCompleteLink}
          list={listNodeLink}
          nodeType={selectedType.link}
          getInput={setNodeLinkedName}
          required
          class="w-full mb-2"
          id="autoCompleteLink-for-link-select"
        />

        <ValidationMessage for="link" let:messages>
          <Helper class="mt-2 mb-2" color="red">
            {#each messages ?? [] as message}
              {message}
            {/each}
          </Helper>
        </ValidationMessage>
      {/if}

      <ConceptTree 
        displayOrders={displayOrders} 
        categoryDisplay={$_categoryDisplay} 
        compact={true} 
        conceptFilter={selectedType.concept}
        {nodeClickAction}
        useAlternativeContext={true}
      />

      <BlocCancelConfirm
          isValid={$isValid}
          isSubmitting={$isSubmitting}
          vLabel="Confirm creation"
          xConfirmType="cancelConnectionCreation"
          xOnConfirm={() => {cleanForm() ; dialog.close()}}
      />
    {/if}
  </div>
  </form> 
</Dialog>

<style>
  span {
    @apply flex  bg-slate-200 p-2 rounded-2xl font-bold;
  }

  .invalid {
    display: none;
  }
</style>
