/**
 * TODO: fix types!
 *
 * @returns
 */
export const setArrayFromKeys = <T>(
  obj: Record<string, T>
): { key: string }[] | T[] => {
  return Object.keys(obj).map((key) => ({ key, ...obj[key] }));
};
