import { 
  MetricsOfFunctionalHealthService as service, 
  type ParameterDefinition 
} from "@client";
import type { MetricOfFunctionalHealth, MetricOfFunctionalHealth as Model} from "@models/MetricOfFunctionalHealth";
import { supportingStore } from "@stores/supportingstore";
import {
  successToast as notifySuccess,
  failureToast as notifyFailure,
} from "../toast-theme";

export const createMetricOfFunctionalHealth = (model: Model) => {
  try {
    let params = model.parameter_definitions;
    service.createMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthPost(model).then((resp) => {
      notifySuccess("Metric of functional health succesfully created!");
      supportingStore.addMetricOFunctionalHealth(resp);
      resp.parameter_definitions = params;
      updateMetricParameterDefinitions(resp, params);

    });
  } catch (e) {
    notifyFailure(`An error occured while creating metric of functional health: ${e.status}-${e.name}:${e.statusText}`);
  }    
};

export const updateMetricOfFunctionalHealth = async (
  id: number,
  model: Model = null
) => {
  try {
    let params = model.parameter_definitions;    
    service.updateMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthIdPut(
      id,
      model
    ).then(resp => {
      notifySuccess("Metric of functional health succesfully created!");
      supportingStore.updateSelectedMetricOfFunctionalHealth(resp);
      resp.parameter_definitions = params;
      updateMetricParameterDefinitions(resp, params);

    });    
  } catch (e) {
    notifyFailure(`An error occurred while updating the metric of functional health: ${e.status}-${e.name}:${e.statusText}`);
  }
};

export const updateMetricParameterDefinitions = async (
  metric: Model = null,
  parameter_definitions: ParameterDefinition[]
) => {
  try {
    if (
      metric.parameter_definitions &&
      metric.parameter_definitions.length > 0
    ) {
      service
        .updateParametersApiV1MetricsOfFunctionalHealthIdParametersPatch(
          metric.id,
          parameter_definitions
        )
        .then((res2) => {
          notifySuccess(
            "State metric parameter definitions succesfully updated"
          );
          supportingStore.updateSelectedStateMetric(metric);
        });
    } else metric.parameter_definitions = [];
    supportingStore.updateSelectedStateMetric(metric);
  } catch (e) {
    notifyFailure(
      `An error occured while updating state metric parameter definitions: ${e.status}-${e.name}:${e.statusText}`
    );
  }
};


export const deleteMetricOfFunctionalHealth = (id: number) => {
  service.deleteMetricsOfFunctionalHealthApiV1MetricsOfFunctionalHealthIdDelete(id).then(() => {
    supportingStore.removeMetricOfFunctionalHealth(id);
  });
};

/**
 * Returns true if the provided name is already taken by an existing MetricOfFunctionalHealth.
 *
 * @param {string} name
 *
 * @returns {boolean} boolean true if name is already in use. Else false.
 */
// TODO  @refactor: make a generic super class to DRY code
export const isNameAlreadyTaken = (name: string | undefined) => {
  if (name) {
    return supportingStore.metricsOfFunctionalHealth.some(
      (s: MetricOfFunctionalHealth) => s.name.toLowerCase() == name.toLowerCase()
    );
  } else {
    return false;
  }
};