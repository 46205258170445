<script lang="ts">
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
  } from "@rgossiaux/svelte-heroicons/solid";
  import { displayStore } from "@stores/displaystore.js";
  import { dataStore } from "@stores/datastore";

  export let nodeCategory;
  export let nodeTypes;

  const { _categoryDisplay } = displayStore;
  const { _nodes } = dataStore;
</script>

<!--
@component
Displays a category header in the graph tree view.
-->

<li
  id="category-header"
  class="category-header"
  on:click={() =>
    // Toggles the visibility of all types under this category.
    displayStore.toggleCategoryDisplay(nodeCategory)}
>
  {#if $_categoryDisplay[nodeCategory]}
    <ChevronD class="w-6 h-6" />
  {:else}
    <ChevronR class="w-6 h-6" />
  {/if}
  <div>
    <span class="capitalize">{nodeCategory}</span>
  </div>
</li>

<style>
  li {
    @apply flex justify-between;
  }
  .category-header {
    @apply text-lg font-bold;
  }
</style>
