/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum MaslowLevel {
    PHYSIOLOGICAL_NEEDS = 'Physiological Needs',
    SAFETY_NEEDS = 'Safety Needs',
    LOVE_AND_BELONGING = 'Love and Belonging',
    ESTEEM = 'Esteem',
    SELF_ACTUALISATION = 'Self-Actualisation',
}
