<script lang="ts">
  import { onMount } from "svelte";
  import ForceGraph3D from "3d-force-graph";
  import { dataStore } from "@stores/datastore";
  import { displayStore } from "@stores/displaystore";
  import { nodeTypes } from "../../utils/nodeTypes";
  import { getCtx } from "../Editors/NodeEditor/context";
  let w: number;
  let h: number;
  let divGraph;
  let Graph;
  let innerHeight;
  let nodeMenu;
  let dialogExitConceptEdition;
  import NodeMenu from "@components/dialog/NodeMenu.svelte";
  import Graphtoolbar from "@components/Graph/Toolbar/GraphToolbar.svelte";
  import Legend from "@components/Graph/Legend.svelte";
  import Mode from "./mode.svelte";

  const {
    _graphData,
    _selectedNode,
    _selectedStory,
    _selectedEdge,
    _updateSelection,
  } = dataStore;
  const { _display } = displayStore;
  let styles = {
    "border-color": "#ffffff",
    "border-type": "",
  };
  let selectedItem: "node" | "edge" | "story" | "scenario" | null = null;
  const { _theme } = displayStore;

  const bgClick = (): void => {
    if ($_graphData) {
      Graph.graphData($_graphData);
    }
    Graph.zoomToFit(500, 96);
    dataStore.setSelectedNode(null);
    dataStore.setSelectedEdge(null);
    dataStore.setSelectedStory(null);
  };

  const { editMode, editOff, editOn } = getCtx();

  const nodeClick = (n): void => {
    if ($editMode) {
      dialogExitConceptEdition.actionOnYes = () => {
        dataStore.setSelectedNode(n);
      };
      dialogExitConceptEdition.show(null);
    } else {
      dataStore.setSelectedNode(n);
    }
  };

  const linkClick = (link, event: MouseEvent) => {
    dataStore.setSelectedEdge(link);
  };

  const nodeColor = (n) => {
    return nodeTypes[n.type].color();
  };

  const edgeLabel = (e): string => {
    let indexTarget = nodeTypes[e.source.type].to.findIndex(
      (el) => el == e.target.type
    );
    return `${e.source.name} > ${
      nodeTypes[e.source.type].sectionto[indexTarget]
    } > ${e.target.name} `;
  };

  const options = {
    includeScore: true,
    keys: [
      { name: "name", weight: 0.01 },
      { name: "type", weight: 0.01 },
      { name: "description", weight: 0.01 },
    ],
  };

  onMount(() => {
    graphInit();
  });

  function graphInit() {
    Graph = ForceGraph3D()(divGraph)
      .graphData({ nodes: [], links: [] })
      .width(w)
      .height(h)
      .backgroundColor(`${$_theme === "dark" ? "#000011" : "#FFFFFF"}`)
      .nodeId("id")
      .nodeRelSize(7)
      .nodeLabel("name")
      .linkLabel(edgeLabel)
      .nodeColor(nodeColor)
      .onBackgroundClick(bgClick)
      .onLinkClick(linkClick)
      .onNodeClick(nodeClick)
      .linkDirectionalArrowLength(6)
      .linkSource("source")
      .linkTarget("target")
      .d3VelocityDecay(0.7)
      .d3Force("center", null);
  }

  function setGraphWidth(width: number): void {
    if (Graph && width) {
      width > 0 ? Graph.width(width) : Graph.width(0);
    }
  }

  function setGraphHeight(height: number): void {
    if (Graph && height) {
      height > 0 ? Graph.height(height) : Graph.height(0);
    }
  }

  function initGraphWithData() {
    if (!Graph) graphInit();
    Graph.graphData($_graphData);
    Graph.height(innerHeight - 84).width(graphWidth);
  }

  function changeBgColor() {
    if (!Graph) graphInit();
    Graph.backgroundColor(`${$_theme === "dark" ? "#000011" : "#FFFFFF"}`);
  }

  function borderColor(): void {
    styles["border-type"] = "solid";
    if ($_selectedNode) {
      styles["border-color"] = "#66EEEE";
      selectedItem = "node";
    } else if ($_selectedStory) {
      styles["border-color"] = $_selectedStory.isScenario
        ? "#FCA5A5"
        : "#FDE047";
      selectedItem = $_selectedStory.isScenario ? "scenario" : "story";
    } else if ($_selectedEdge) {
      styles["border-color"] = "grey";
      selectedItem = "edge";
    } else {
      styles["border-type"] = "unset";
      selectedItem = null;
    }
    cssVarStyles = `--border-type:${styles["border-type"]}; --border-color:${styles["border-color"]}; position:relative;`;
  }

  $: cssVarStyles = `--border-color:${styles["border-color"]}; position:relative`;
  $: Graph && $_graphData && initGraphWithData();
  $: $_updateSelection && borderColor();
  $: innerWidth = 0;
  $: innerHeight = 0;
  $: Graph && $_theme && changeBgColor();
  $: graphWidth =
    innerWidth - $_display.leftWidthSize - $_display.rightWidthSize;
  $: innerHeight && setGraphHeight(innerHeight - 84); //TODO: 84 is for header height; should calc dynamically
  $: graphWidth && setGraphWidth(graphWidth);
</script>

<svelte:window bind:innerWidth bind:innerHeight />

<NodeMenu bind:this={nodeMenu} />

<div
  style={cssVarStyles}
  class="h-full graphBorder"
  bind:clientWidth={w}
  bind:clientHeight={h}
>
  {#if graphWidth > 400}
    <div class="absolute top-1 right-24">
      <Graphtoolbar />
    </div>
  {/if}
  <div bind:this={divGraph} />
  {#if graphWidth > 400}
    <div class="absolute bottom-0 w-full flex justify-center">
      <Legend />
    </div>
  {/if}
  {#if selectedItem}
    <div class="absolute top-0 w-full flex justify-center">
      <Mode {selectedItem} />
    </div>
  {/if}
</div>

<style>
  .graphBorder {
    border: var(--border-type, "unset") var(--border-color, white);
  }
</style>
