<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteParameterDefinition } 
    from "@utils/supporting/parameterDefinition";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"

  const { metricFormDialog, editModeOn } = getCtx();
  const { _parameterDefinitions, _selectedParameterDefinition, _unitsOfMeasure } =
    supportingStore;

  // TODO: erase this when schema is fixed, this is a workaround 
  // cos we cannot add the unit object to the param schema
  const getUnitFromId = (id) => {
    let u =  $_unitsOfMeasure.filter(function (u) {
      return u.id == id;
    })
    if (u?.length == 1)
      return u[0];
  }


  const showEditModal = (param) => {
    _selectedParameterDefinition.set(param);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Unit of measure</th>
        <th>Measuring method</th>
        <th>Data providers</th>
        <th>Data availability</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_parameterDefinitions as param}
        <tr>
          <td>{formatAtomicProperty(param.name)}</td>
          <td>{formatAtomicProperty(param.description)}</td>
          <td>{formatAtomicProperty(getUnitFromId(param.unit_id)?.unit)}</td>
          <td>{formatAtomicProperty(param.measuring_method)}</td>
          <td>
            {#each param.stakeholders as sh, index}
              {sh.name}{#if index < param.stakeholders.length - 1}, {/if}
            {/each}
          </td>
          <td>{formatAtomicProperty(param.data_availability)}</td>
          <GridAction
            onEdit={() => showEditModal(param)}
            onDelete = {() => deleteParameterDefinition(param.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>