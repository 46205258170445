<script lang="ts">
  import UserMenu from "@components/Core/UserDisplay/UserMenu.svelte";
  import SupportingMenu from "@components/Supporting/SupportingMenu.svelte";
  import { setCtx as setSupportingContext } from "@components/Supporting/context";
  import Can from "./AccessControl/Can.svelte";
  import { Roles } from "@utils/decorators";
  import HelpMenu from "./Core/UserDisplay/HelpMenu.svelte";
  import { authentication } from "@utils/keycloak";

  setSupportingContext();
</script>

<div
  class="flex flex-row justify-between px-4 py-2 border border-base-200 justify-items-center"
>
  <div class="flex flex-row font-bold">
    <div>KNOWLEDGE GRAPH</div>
  </div>

  <div class="flex items-center justify-center gap-4">
    <Can roles={[Roles.DEV, Roles.CONCEPT]}>
      <div class="pl-2"><SupportingMenu /></div>
    </Can>
    <!-- <NotificationsMenu /> -->
    <HelpMenu />
    <UserMenu />
    <div>
      <p class="text-m font-medium leading-5">
        {$authentication && `${$authentication.profile.username} (${
            $authentication.role === "Dev" ? "Admin" : $authentication.role
        })`}
      </p>
    </div>
  </div>
</div>
