/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EnvProcessType {
    BIOLOGICAL = 'Biological',
    CHEMICAL = 'Chemical',
    PHYSICAL = 'Physical',
}
