/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnvProcess } from '../models/EnvProcess';
import type { EnvProcessWithLink } from '../models/EnvProcessWithLink';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EnvprocessService {

    /**
     * Get Envprocess
     * @returns EnvProcessWithLink Successful Response
     * @throws ApiError
     */
    public static getEnvprocessApiV1EnvprocessGet(): CancelablePromise<Array<EnvProcessWithLink>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/envprocess/',
        });
    }

    /**
     * Create Envprocess
     * @param requestBody
     * @returns EnvProcessWithLink Successful Response
     * @throws ApiError
     */
    public static createEnvprocessApiV1EnvprocessPost(
        requestBody: EnvProcess,
    ): CancelablePromise<EnvProcessWithLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/envprocess/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Envprocess
     * @param id
     * @param requestBody
     * @returns EnvProcessWithLink Successful Response
     * @throws ApiError
     */
    public static updateEnvprocessApiV1EnvprocessIdPut(
        id: number,
        requestBody: EnvProcess,
    ): CancelablePromise<EnvProcessWithLink> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/envprocess/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Envprocess
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteEnvprocessApiV1EnvprocessIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/envprocess/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
