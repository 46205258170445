<script lang="ts">
  import {
    Label,
    Helper,
    Select,
    Input,
    Textarea,
    MultiSelect,
    RadioGroup,
  } from "@core-ui";
  import { onMount } from "svelte";

  export let label: string;
  export let name: string;
  export let required: boolean = false;
  export let errors: string[];
  export let fieldType:
    | "select"
    | "input"
    | "textarea"
    | "checkbox"
    | "multiselect"
    | "radiogroup";
  export let labelClass: string = "my-3";
  export let errorHelperClass: string = "my-3";
  export let options: string[];
  let field;
  let props;

  const setField = () => {
    switch (fieldType) {
      case "select":
        field = Select;
        props = {
          placeholder: "Please select an option...",
        };
        break;
      case "input":
        field = Input;
        props = {
          placeholder: "Please write some text...",
        };
        break;
      case "textarea":
        field = Textarea;
        props = {
          rows: 3,
          placeholder: "Please type...",
        };
        break;
      case "multiselect":
        field = MultiSelect;
        props = {
          placeholder: "Please select one or more...",
        };
        break;
      case "radiogroup":
        field = RadioGroup;
        props = {
          placeholder: "Please select a value...",
          name,
        };
        break;

      default:
        field = Input;
        break;
    }
  };
  onMount(() => {
    setField();
  });
  $: fieldType && setField();
</script>

<Label {required} for={name} class={labelClass}>{label}</Label>
<svelte:component
  this={field}
  id={name}
  {name}
  {required}
  bind:items={options}
  {...props}
/>
<Helper class="mt-2" color="red" {errorHelperClass}>
  {#each errors ?? [] as message}
    <span>{message}</span>
  {/each}
</Helper>
