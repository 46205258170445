<script>
  import { displayStore } from "@stores/displaystore";
  import {
    Switch,
    SwitchGroup,
    SwitchLabel,
  } from "@rgossiaux/svelte-headlessui";
  import { MoonIcon } from "@rgossiaux/svelte-heroicons/solid";
  import { SunIcon } from "@rgossiaux/svelte-heroicons/solid";

  export let withLabel = false;
  const { _theme } = displayStore;

  function handleThemeChange(e) {
    $_theme == "dark"
      ? displayStore.setTheme("light")
      : displayStore.setTheme("dark");
  }
</script>

<SwitchGroup>
  <div class="switch-container">
    {#if withLabel}
      <SwitchLabel class="switch-label">Theme {$_theme}</SwitchLabel>
    {/if}

    <Switch
      checked={$_theme == "light"}
      on:change={handleThemeChange}
      class={`bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-2xl border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        $_theme == "dark"
          ? "bg-indigo-600  focus:ring-indigo-500"
          : "bg-gray-200  focus:ring-yellow-500"
      }`}
    >
      <span class="sr-only">Enable {$_theme} mode</span>
      <span
        class={`pointer-events-none relative inline-flex h-5 w-5 transform rounded-2xl bg-white shadow ring-0 transition duration-200 ease-in-out ${
          $_theme == "dark" ? "translate-x-5" : "translate-x-0"
        }`}
      >
        <span
          class={`absolute inset-0 flex h-full w-full items-center justify-center transition-opacity ${
            $_theme == "dark"
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200"
          }`}
          aria-hidden="true"
        >
          <SunIcon class="w-3 h-3 text-yellow-500" />
        </span>
        <span
          class={`absolute inset-0 flex h-full w-full items-center justify-center transition-opacity ${
            $_theme == "dark"
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100"
          }`}
          aria-hidden="true"
        >
          <MoonIcon class="w-3 h-3 text-indigo-600" />
        </span>
      </span>
    </Switch>
  </div>
</SwitchGroup>

<style>
  .switch-container {
    display: flex;
    align-items: center;
  }

  :global(.switch-label) {
    margin-right: 1rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
</style>
