<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";
  import type { Edge } from "@utils/formatData";
  import {
    ChevronDownIcon as ChevronD,
    ChevronRightIcon as ChevronR,
    XIcon as ChevronUnfold,
  } from "@rgossiaux/svelte-heroicons/solid";
  import OutcomingIcon from "@components/icons/OutcomingIcon.svelte";
  import IncomingIcon from "@components/icons/IncomingIcon.svelte";
  import LinkEntry from "./LinkEntry.svelte";
  import NumberedPastille from "../NumberedPastille.svelte";
  import { dataStore } from "@stores/datastore";
  export let section;
  export let direction;
  export let links;
  export let nodeType;

  let display = false;
  let linkFiltered: Edge[];
  let hasElement: boolean = false 

  const {_selectedNode } = dataStore

  const connectionLabel = () =>  {
    if(direction === "from"){
      return nodeTypes[section].sectionto[
                    nodeTypes[section].to.findIndex(
                      (e) => e === $_selectedNode.type
                    )
                  ]

    }else{
      return nodeTypes[$_selectedNode.type].sectionto[
                    nodeTypes[$_selectedNode.type].to.findIndex(
                      (e) => e === section
                    )
                  ]

      
    }

  }

  $: if (links) {
    linkFiltered =
      direction === "from"
        ? links.filter((l: Edge) => l.source.type === section)
        : links.filter((l: Edge) => l.target.type === section);
    hasElement = linkFiltered.length !== 0
  }
</script>

{#if nodeTypes[nodeType][direction === "from" ? "from" : "to"].includes(section)}
  <div class="flex items-center mt-4 flew-row">
    <span class="mr-2" on:click={() => {
      if(hasElement)
        display = !display
      }}
    >
      {#if display}
        <ChevronD class="w-6 h-6" />
      {:else}
        <ChevronR class={`w-6 h-6 ${hasElement ? "" : "text-base-content opacity-50"}`}/>
      {/if}
    </span>

    <div class="flex items-center" class:disabled={!hasElement} style={`color: ${nodeTypes[section].color()}`}>
      {#if direction === "from"}
        <IncomingIcon type={section} class={`${hasElement ? "" : "opacity-50"}`} />
      {:else}
        <OutcomingIcon type={section} class={`${hasElement ? "" : "opacity-50"}`}  />
      {/if}
      <span class="ml-2 font-bold">{connectionLabel()}</span>
      <span class="ml-1 text-sm">({nodeTypes[section].label})</span>
      <NumberedPastille nbr={linkFiltered.length} class="ml-3" style={`color: black; background-color: ${nodeTypes[section].color()}`}/>
    </div>
  </div>
  {#if linkFiltered.length !== 0 && display}
    <ul class="ml-4 list-item">
      {#each linkFiltered as n}
        <LinkEntry {n} {direction} />
      {/each}
    </ul>
  {/if}
{/if}

<style>
  h3 {
    @apply font-bold;
  }
  li {
    @apply pl-2
    flex
    items-center;
  }

  .disabled{
    @apply opacity-50;
  }
</style>
