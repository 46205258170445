/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Edge } from '../models/Edge';
import type { EdgeLinked } from '../models/EdgeLinked';
import type { Edges } from '../models/Edges';
import type { Resource } from '../models/Resource';
import type { UpdateEdge } from '../models/UpdateEdge';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EdgesService {

    /**
     * Get Edge
     * @param id
     * @returns Edges Successful Response
     * @throws ApiError
     */
    public static getEdgeApiV1EdgesIdGet(
        id: number,
    ): CancelablePromise<Edges> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/edges/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Udpate Edge
     * @param id
     * @param requestBody
     * @returns Edges Successful Response
     * @throws ApiError
     */
    public static udpateEdgeApiV1EdgesIdPut(
        id: number,
        requestBody: UpdateEdge,
    ): CancelablePromise<Edges> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/V1/edges/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Edge
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteEdgeApiV1EdgesIdDelete(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/V1/edges/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Insert Edge
     * @param requestBody
     * @returns Edges Successful Response
     * @throws ApiError
     */
    public static insertEdgeApiV1EdgesPost(
        requestBody: Edge,
    ): CancelablePromise<Edges> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/edges/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Insert Linked Edge
     * @param requestBody
     * @returns Edges Successful Response
     * @throws ApiError
     */
    public static insertLinkedEdgeApiV1EdgesLinkedPost(
        requestBody: EdgeLinked,
    ): CancelablePromise<Array<Edges>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/edges/linked',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Edge Resources
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getEdgeResourcesApiV1EdgesIdResourcesGet(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/V1/edges/{id}/resources',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Resource To Edge
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addResourceToEdgeApiV1EdgesIdResourcesPost(
        id: number,
        requestBody: Resource,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/V1/edges/{id}/resources',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
