<script lang="ts">
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Transition,
    TransitionChild,
  } from "@rgossiaux/svelte-headlessui";

  import { displayStore } from "@stores/displaystore";

  const { _theme } = displayStore;

  let state = false;
  export const open = () => {state = !state} 
  export const close = () => {state = !state} 
</script>

<Transition appear show={state}>
  <Dialog as="div" class="fixed inset-0 z-10 overflow-y-auto" on:close={() => {close()}}>
    <div data-theme={$_theme} class="min-h-screen px-4 text-center bg-black/25">
      <TransitionChild
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogOverlay class="fixed inset-0" />
      </TransitionChild>

      <TransitionChild
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div
          class="inline-block min-w-max p-6 my-8 text-left align-middle transition-all transform drop-shadow-[0_35px_35px_rgba(0,0,0,0.85)] bg-base-200 rounded-2xl"
        >
          <DialogTitle
            as="h3"
            class="text-lg font-medium leading-6 text-base-content "
          >
            Tutorial
          </DialogTitle>
          <slot name="content">
            <div>Add content here</div>
          </slot>
        </div>
      </TransitionChild>
    </div>
  </Dialog>
</Transition>

<style>
  .label-required {
    content: "*";
    color: red;
  }

  input[required] + label:before {
    content: "*";
    color: red;
  }
</style>
