<script>
  import { supportingStore } from "@stores/supportingstore";
  import { deleteSector } from "@utils/supporting/sectors";
  import { getCtx } from "../context";
  import { formatAtomicProperty } from "../utils";
  import GridAction from "../GridAction.svelte"
  /**
   * Dialog state and methods
   */
  const { metricFormDialog, editModeOn } = getCtx();
  const { _sectors, _selectedSector } = supportingStore;

  const showEditModal = (sector) => {
    _selectedSector.set(sector);
    editModeOn.set(true);
    $metricFormDialog.show();
  };
</script>
  <table class="table w-full">
    <thead>
      <tr>
        <th>Name</th>
        <th>Members</th>
        <th>Notes</th>
        <th />
        <th />
      </tr>
    </thead>
    <tbody>
      {#each $_sectors as sector}
        <tr>
          <th>{formatAtomicProperty(sector.name)}</th>
          <td>
            {#each sector.stakeholders as sh, index}
              {sh.name}{#if index < sector.stakeholders.length - 1}, {/if}                  
            {/each}
          </td>              
          <td>{sector.notes}</td>
          <GridAction
            onEdit={() => showEditModal(sector)}
            onDelete = {() => deleteSector(sector.id)}
          />
        </tr>
      {/each}
    </tbody>
  </table>