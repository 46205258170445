<script lang="ts">
  import { InformationCircleIcon } from "@rgossiaux/svelte-heroicons/outline";
  import RessourcesMenu from "@components/dialog/RessourcesMenu.svelte";
  import { can, Roles } from "@utils/decorators";
  export let item: any;
  let menu;
  let selectedItem;

  function showContextMenu(e: PointerEvent) {
    menu.show(selectedItem, { x: e.x, y: e.y });
  }

  const restrictedShowContextMenu = can(
    [Roles.DEV, Roles.CONTENT],
    showContextMenu
  );
</script>

<RessourcesMenu bind:this={menu} />
<li
  class="py-1"
  on:contextmenu|preventDefault={(e) => {
    selectedItem = item;
    restrictedShowContextMenu(e);
  }}
>
  <div class="flex items-center justify-start gap-3">
    <a
      href={item.url}
      target="_blank"
      class="hover:underline hover:underline-offset-2"
    >
      {item.title}
    </a>
    <InformationCircleIcon class="w-4 h-4" />
  </div>
</li>
