/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Type {
    DRIVER = 'driver',
    ACTIVITY = 'activity',
    PRESSURE = 'pressure',
    GOOD_AND_BENEFIT = 'good_and_benefit',
    COMPLIMENTARY_CAPITAL = 'complimentary_capital',
    COMPONENT = 'component',
    ECOSYSTEM_SERVICE = 'ecosystem_service',
    RESPONSE = 'response',
    IMPACT_ON_WELFARE = 'impact_on_welfare',
}
