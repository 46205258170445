<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<script lang="ts">
  import { nodeTypes } from "@utils/nodeTypes";
  export let type: string;
</script>

<svg width="20" height="20" viewBox="0 0 20 20" fill={nodeTypes[type].color()} xmlns="http://www.w3.org/2000/svg"
  {...$$restProps}
  on:click
  on:mouseover
  on:mouseenter
  on:mouseleave
  on:keydown
  ><slot />
  <path d="M14.414 6.99992L17.707 3.70692C17.8892 3.51832 17.99 3.26571 17.9877 3.00352C17.9854 2.74132 17.8802 2.49051 17.6948 2.3051C17.5094 2.11969 17.2586 2.01452 16.9964 2.01224C16.7342 2.00997 16.4816 2.11076 16.293 2.29292L13 5.58592V3.99992C13 3.7347 12.8946 3.48035 12.7071 3.29281C12.5196 3.10528 12.2652 2.99992 12 2.99992C11.7348 2.99992 11.4804 3.10528 11.2929 3.29281C11.1054 3.48035 11 3.7347 11 3.99992V8.00292C11 8.20029 11.0587 8.39321 11.1685 8.55719C11.2784 8.72117 11.4345 8.84881 11.617 8.92392C11.7384 8.97425 11.8686 9.00008 12 8.99992H16C16.2652 8.99992 16.5196 8.89456 16.7071 8.70703C16.8946 8.51949 17 8.26514 17 7.99992C17 7.7347 16.8946 7.48035 16.7071 7.29281C16.5196 7.10528 16.2652 6.99992 16 6.99992H14.414Z" fill={nodeTypes[type].color()}/>
  <circle cx="5.5" cy="14.5" r="5.5" fill={nodeTypes[type].color()}/>
</svg>

