<script lang="ts">
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { getCtx } from "../context";
  import {
    createMetricOfFunctionalHealth,
    updateMetricOfFunctionalHealth,
    isNameAlreadyTaken,
  } from "@utils/supporting/metricOfFunctionalHealth";
  import {
    Label,
    Helper,
    Textarea,
    MultiSelect,
    Input,
    RadioGroup,
  } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import FormCreationButtons from "../FormCreationButtons.svelte";

  let { metricFormDialog, editModeOn } = getCtx();
  const {
    _selectedMetricOfFunctionalHealth,
    _parameterDefinitions,
    _unitsOfMeasure,
    _algorithms,
  } = supportingStore;

  // Parameter defintion properties to be used as MultiSelect label/value
  const itemId = "id";
  const label = "name";
  // Init values for parameter definition MultiSelect
  let initParamDefinitions;
  let initUnit;
  let initAlgorithm;
  let isQualitative = "qualitative";

  const {
    form,
    data,
    setFields,
    isValid,
    errors,
    isSubmitting,
    setInitialValues,
    reset,
  } = createForm({
    onSubmit: (values) => {
      // TODO user setFields on radio group change instead:
      values.isQualitative =
        values.isQualitativeAsString == "qualitative" ? true : false;
      if (!$editModeOn) {
        createMetricOfFunctionalHealth(values);
        // clear form
        handleFormReset(emptyMetric);
        $metricFormDialog.close();
      }
      if ($editModeOn) {
        updateMetricOfFunctionalHealth(
          $_selectedMetricOfFunctionalHealth.id,
          values
        );
        // clear form
        handleFormReset(emptyMetric);
        $metricFormDialog.close();
      }
    },
    validate(values) {
      const errors: {
        name: string[];
        unit: string[];
        definition: string[];
        parameter_definitions: string[];
        algorithm: string[];
      } = {
        name: [],
        unit: [],
        definition: [],
        parameter_definitions: [],
        algorithm: [],
      };
      if (!values.name) errors.name.push("Name is required.");
      // if (!values.definition)
      //   errors.definition.push("Algorithm definition is required.");
      if (values.isQualitativeAsString == "quantitative") {
        if (!values.algorithm_id)
          errors.algorithm.push(
            "Algorithm is required for quantitative metrics."
          );
      } else {
        if ((values.parameter_definitions ?? []).length == 0) {
          errors.parameter_definitions.push(
            "At least one parameter definition is required  for qualitative metrics.."
          );
        }
      }
      if (
        (!$editModeOn && isNameAlreadyTaken(values.name)) ||
        // Names must be unique, when in edit mode, we must check the name unicity
        // only if the name has been touched:
        ($editModeOn &&
          $_selectedMetricOfFunctionalHealth &&
          $_selectedMetricOfFunctionalHealth.name !== values.name &&
          isNameAlreadyTaken(values.name))
      )
        errors.name.push("The name is already in use.");
      if (!values.unit_id) errors.unit.push("Unit is required.");
      return errors;
    },
    extend: [reporter],
  });

  const handleFormReset = (metric) => {
    metric.isQualitativeAsString = metric.isQualitative
      ? "qualitative"
      : "quantitative";
    setInitialValues(metric);
    initParamDefinitions = metric.parameter_definitions;

    // Voodoo class workaround!
    // Without this, unit and algo select are not reset...
    // The worst in this is we cannot figure why...
    initUnit = $_unitsOfMeasure[0];
    initUnit = metric.unit;
    initAlgorithm = $_algorithms[0];
    initAlgorithm = metric.algorithm;

    isQualitative = metric.isQualitative ? "qualitative" : "quantitative";
    setFields("unit", metric.unit);
    setFields("algorithm", metric.algorithm);
    setFields("unit_id", metric.unit_id);
    setFields("algorithm_id", metric.algorithm_id);
    setFields("parameter_definitions", metric.parameter_definitions);
    reset();
  };

  const handleUnitChange = (e) => {
    let val;
    if (e.detail) {
      val = e.detail.id;
      setFields("unit_id", val);
    }
  };

  const handleSelectChange = (e) => {
    let val;
    if (e.detail) {
      if (Array.isArray(e.detail)) {
        val = e.detail;
      } else {
        val = [e.detail];
      }
    }
    setFields("parameter_definitions", val);
  };

  const handleAlgorithmChange = (e) => {
    if (e.detail) {
      setFields("algorithm_id", e.detail.id);
      setFields("algorithm", e.detail);
    }
  };

  const emptyMetric = {
    name: "",
    definition: "",
    parameter_definitions: [],
    isQualitative: false,
    unit: null,
    isQualitativeAsString: "quantitative",
  };

  $: $editModeOn &&
    $_selectedMetricOfFunctionalHealth &&
    handleFormReset($_selectedMetricOfFunctionalHealth);
  $: !$editModeOn && handleFormReset(emptyMetric);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <!-- <fieldset> -->
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn
      ? "Update Metric of functional health"
      : "Create Metric of functional health"}
  </h1>
  <!-- FORM -->

  <!-- NAME -->
  <div>
    <Label for="name" class="mt-4 mb-2">Name</Label>
    <Input
      class="mt-2"
      placeholder="Type metric of functional health name here"
      id="name"
      name="name"
      required
      color={"base"}
    />
    <ValidationMessage for="name" let:messages>
      {#each messages ?? [] as message}
        <Helper class="mt-2" color="red">
          <span>{message}</span>
        </Helper>
      {/each}
    </ValidationMessage>
  </div>

  <div class="basis-1/2">
    <MultiSelect
      items={$_unitsOfMeasure}
      placeholder="Select associated unit"
      initValues={$editModeOn ? initUnit : null}
      handleSelectChange={handleUnitChange}
      {itemId}
      {label}
      multiple={false}
      name="unit_id"
      componentLabel="Unit"
      requiredMessage="Unit is required"
      required
    />
  </div>

  <RadioGroup
    name="isQualitativeAsString"
    selected={isQualitative}
    items={[
      {
        label: "Quantitative",
        value: "quantitative",
      },
      {
        label: "Qualitative",
        value: "qualitative",
      },
    ]}
    required
  />

  {#if $data.isQualitativeAsString == "quantitative"}
    <!-- ALGORITHM -->
    <div>
      <MultiSelect
        items={$_algorithms}
        placeholder="Select associated algorithm"
        initValues={initAlgorithm}
        handleSelectChange={handleAlgorithmChange}
        {itemId}
        {label}
        multiple={false}
        name="algorithm_id"
        componentLabel="Algorithm"
        requiredMessage="Algorithm is required"
        required
      />
    </div>
  {:else}
    <!-- PARAMETER DEFINITIONS -->
    <div>
      <MultiSelect
        items={$_parameterDefinitions}
        placeholder="Select associated parameter definitions"
        initValues={initParamDefinitions}
        {handleSelectChange}
        {itemId}
        {label}
        name="parameter_definitions"
        componentLabel="Parameter definitions"
        requiredMessage="At Least one parameter definition is required"
        required
      />
      <ValidationMessage for="parameter_definitions" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>
  {/if}

  <div>
    <Label for="definition" class="mt-4 mb-2">Definition</Label>
    <Textarea
      type="text"
      id="definition"
      name="definition"
      placeholder="Add definition..."
      color="base"
    />
  </div>

  <FormCreationButtons
    onClose={() => {
      handleFormReset(emptyMetric);
      $metricFormDialog.close();
    }}
    isValid={$isValid}
    isSubmitting={$isSubmitting}
    create={!$editModeOn}
  />
</form>
