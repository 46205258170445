<script lang="ts">
  import { XIcon } from "@rgossiaux/svelte-heroicons/outline";
  import { createForm } from "felte";
  import { ValidationMessage, reporter } from "@felte/reporter-svelte";
  import { Label, Helper, Textarea, Input, MultiSelect } from "@core-ui";
  import { supportingStore } from "@stores/supportingstore";
  import { getCtx } from "../context";
  import { type UnitOfMeasure } from "@client";
  import {
    createUnitOfMeasure,
    isNameAlreadyTaken,
    updateUnitOfMeasure,
  } from "@utils/supporting/unitOfMeasure";
  import FormCreationButtons from "../FormCreationButtons.svelte";

  const { _selectedUnitOfMeasure } = supportingStore;
  let { metricFormDialog, editModeOn } = getCtx();

  const { form, isValid, errors, isSubmitting, setInitialValues, reset } =
    createForm<UnitOfMeasure>({
      onSubmit: (values) => {
        if (!$editModeOn) {
          createUnitOfMeasure(values);
          // clear form
          handleFormReset(emptyUnit);
          $metricFormDialog.close();
        }
        if ($editModeOn) {
          updateUnitOfMeasure($_selectedUnitOfMeasure.id, values);
          // clear form
          handleFormReset(emptyUnit);
          $metricFormDialog.close();
        }
      },
      validate(values) {
        const errors: {
          name: string[];
          unit: string[];
          notes: string[];
        } = {
          name: [],
          unit: [],
          notes: [],
        };
        if (!values.name) errors.name.push("Name is required.");
        if (
          (!$editModeOn && isNameAlreadyTaken(values.name)) ||
          // Names must be unique, when in edit mode, we must check the name unicity
          // only if the name has been touched:
          ($editModeOn &&
            $_selectedUnitOfMeasure &&
            $_selectedUnitOfMeasure.name !== values.name &&
            isNameAlreadyTaken(values.name))
        )
          errors.name.push("The name is already in use.");
        if (!values.unit) errors.unit.push("Unit is required.");
        return errors;
      },
      extend: [reporter],
    });

  const handleFormReset = (u: UnitOfMeasure): boolean => {
    setInitialValues(u);
    reset();
    return true;
  };

  const emptyUnit = {
    name: "",
    unit: "",
    notes: "",
  } as UnitOfMeasure;

  $: $editModeOn &&
    $_selectedUnitOfMeasure &&
    handleFormReset($_selectedUnitOfMeasure);
  $: !$editModeOn && handleFormReset(emptyUnit);
</script>

<!-- FORM -->
<form use:form class="w-full p-8 form-control">
  <h1
    class="w-full mt-3 mr-2 text-lg font-bold text-center text-opacity-75 text-base-content"
  >
    {$editModeOn ? "Update unit of measure" : "Create unit of measure"}
  </h1>
  <!-- NAME & INPUT -->
  <div class="flex flex-col items-start justify-start gap-2">
    <!-- NAME -->
    <div class="w-full">
      <Label for="name" class="mt-4 mb-2">Name</Label>
      <Input
        class="mt-2"
        placeholder="Type unit of measure name here"
        id="name"
        name="name"
        required
        color={"base"}
      />
      <ValidationMessage for="name" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- Unit -->
    <div class="w-full">
      <Label for="unit" class="mt-4 mb-2">Unit</Label>
      <Input
        class="mt-2"
        placeholder="Type unit here"
        required
        id="unit"
        name="unit"
        color={"base"}
      />
      <ValidationMessage for="unit" let:messages>
        {#each messages ?? [] as message}
          <Helper class="mt-2" color="red">
            <span>{message}</span>
          </Helper>
        {/each}
      </ValidationMessage>
    </div>

    <!-- Notes -->
    <div class="w-full">
      <Label for="notes" class="mt-4 mb-2">Notes</Label>
      <Textarea
        class="mt-2"
        placeholder="Type notes here"
        id="notes"
        name="notes"
        color={"base"}
      />
    </div>

    <FormCreationButtons
      onClose={() => handleFormReset(emptyUnit)}
      isValid={$isValid}
      isSubmitting={$isSubmitting}
      create={!$editModeOn}
    />
  </div>
</form>
