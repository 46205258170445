<script lang="ts">
  import Button from "@components/Actions/CreateResource/Button.svelte";
  import Dialog from "@components/Actions/CreateResource/Dialog.svelte";
  import Form from "@components/Actions/CreateResource/Form.svelte";

  let actionType: "create" | "edit" = "create";
</script>

<Dialog>
  <div slot="content">
    <Form />
  </div>
</Dialog>
<Button />
