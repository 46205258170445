import { dataStore, type SelectedItemType } from "@stores/datastore";
import { derived } from "svelte/store";

const { _selectedEdge, _selectedNode, _selectedStory } = dataStore;

export const getSelectedItem = derived(
  [_selectedNode, _selectedEdge, _selectedStory],
  ([$_selectedNode, $_selectedEdge, $_selectedStory]) => {
    return $_selectedNode
      ? { type: "node", id: $_selectedNode.id }
      : $_selectedEdge
      ? { type: "edge", id: $_selectedEdge.id }
      : $_selectedStory
      ? { type: "story", id: $_selectedStory.id }
      : undefined;
  }
);
